import React, { useEffect, FC } from 'react';
import { connect } from 'react-redux';

import { AuthState } from './redux/auth/types';
import { AuthActions } from './redux/auth/actions';
import { SideMenuProvider } from './components/SideMenu/SideMenuContext';

import './styles/globalStyles.scss';
import AppRoutes from './routes/AppRoutes';
import AuthRoutes from './routes/AuthRoutes';
import FullScreenLoader from './components/FullScreenLoader';
import MessagePopUpContainer from './components/MessagePopUpContainer';


type Props = {
    auth: AuthState;
    onLoad: () => Promise<void>;
};

const App: FC<Props> = ({ auth, onLoad }) => {
    const { isOnLoad, isAuth, infoPopUp } = auth;

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    if (isOnLoad) {
        return <FullScreenLoader />;
    }

    return (
        <SideMenuProvider>
            {<MessagePopUpContainer data={infoPopUp} />}
            {isAuth ? <AppRoutes /> : <AuthRoutes />}
        </SideMenuProvider>
    );
};

const mapStateProps = (state: any) => ({
    auth: state.auth
});

const mapDispatchToProps = {
    onLoad: AuthActions.onLoad
};

export default connect(mapStateProps, mapDispatchToProps)(App);
