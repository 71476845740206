import React, { FC } from "react";
import styled from "styled-components";

import { SmallText } from "styles/text";
import { greyColor } from "styles/colors";

import tableIcon from "assets/images/icons/cells.png";

interface Props {
  count: number;
}

const TableCount: FC<Props> = ({ count }) => {
  return (
    <Container>
      <SmallText>{count}</SmallText>
      <TableIcon src={tableIcon} alt="table" />
    </Container>
  );
};

export default TableCount;

const Container = styled.div`
  background: ${greyColor};
  border-radius: 15px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;

const TableIcon = styled.img`
  width: 18px;
  height: 20px;
  margin-left: 10px;
  filter: brightness(0) invert(1);
`;
