import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ICoordinates } from 'pages/EditReceipt/components/Images';
import { IAdminCompany } from 'redux/admin/types';
import { IContentSchema, IContentSchemaGrid, IReceiptImageBox } from 'redux/editReceipt/types';
import { IInviteInfo } from 'redux/invitation/types';
import {
    ICategory,
    IEditCategory,
    IItem,
    IStakeholder,
    IStakeholdersType,
    TIsNewStakeholderReturn
} from 'redux/list/types';
import { EnumReceiptStatus, EnumReceiptType, IReceipt } from 'redux/receipt/types';
import { IReceiptShema } from 'redux/setting/types';
import { ICompany, ICompanyUser, IProfile, IUserRole } from 'redux/user/types';

import { ApplicationConfig } from '../config/applicationConfig';

export interface APIListResponse<T> {
    items: T[];
    next?: string;
}

export interface APIListResponseModify<T> {
    items: T[];
    next?: string;
    next_company?: string;
}

export type IUploadType = 'single' | 'multiple' | 'combine';

export enum UploadImportType {
    Items = 'items',
    Categories = 'categories'
}

export interface IGetUpload {
    type: string;
    upload_type: IUploadType;
    receipt_type: EnumReceiptType;
    notes?: string;
    category?: string;
    receipt_id?: string;
    user_id?: string;
    original_file_name?: string;
    company_id?: string | 'NONE';
    process_rows?: boolean;
}

export interface IGetUploadImport {
    content_type: string;
    upload_type: UploadImportType;
}

export interface IEditReceiptParams {
    with_send?: 1;
}

export interface IUpdateContentItem {
    id: string;
    file?: string;
    content?: {
        value: string;
    };
    related_content?: {
        value: string;
    };
}

export interface IEditReceiptBody {
    category_id?: string;
    stakeholder_id?: string;
    external_category_id?: string;
    receipt_status?: string;
    receipt_type?: string;
    external?: {
        id: string;
        name: string;
        external?: boolean;
        type?: string;
    };
}

export interface IUpdateUserParams {
    address?: string;
    phone?: string;
    city?: string;
    country?: string;
    post_code?: string;
    email?: string;
    surname?: string;
    name?: string;
    global_email?: string;
}

export interface IInviteParams {
    name: string;
    surname: string;
    role: 'admin' | 'basic';
    email: string;
}

export interface IEditCompanyUser {
    name?: string;
    role?: IUserRole;
    surname?: string;
    email_prefix?: string;
}

export interface ICreateStakeholderParams {
    name: string;
    type: IStakeholdersType;
    code: string;
    vat_id?: string;
    street: string;
    city: string;
    zipcode: string;
    country_code: string;
    // country: string;
    category_id?: string;
}

export interface IEditStakeholder {
    id: string;
    name?: string;
    type?: string;
    code?: string;
    vat_id?: string;
    street?: string;
    city?: string;
    zipcode?: string;
    // country?: string;
    country_code?: string;
    category_id?: string;
    recognise_details?: boolean;
}

export interface IUpdateReceiptContent {
    company_id: string;
    receipt_id: string;
    receipt_type: EnumReceiptType;
}

export interface IGetReceiptParams {
    search_value?: string;
    selected_date?: number;
    selected?: 'ALL';
    duplicate_id?: string;
    next_company?: string;
    company_id?: string;
    next?: string;
    exclude_companies?: string; // company_id,company_id
    limit?: number;
    receipt_type?: EnumReceiptType;
    receipt_status?: EnumReceiptStatus;
    created_at_before?: string | number;
    created_at_after?: string | number;
    by_issue_date?: '1';
    receipt_id?: string;
}

export interface IGetUsersByCompanyParams {
    selected?: 'ALL';
    next_company?: string;
    next?: string;
    exclude_companies?: string; // company_id,company_id
    limit?: number;
}

export interface IGetCategoriesParams {
    search_name?: string;
    selected?: 'ALL';
    next_company?: string;
    next?: string;
    exclude_companies?: string; // company_id,company_id
    with_external_data?: 1; // має бути також присутне поле external_stakeholder_id
}

export interface IGetStakeholdersParams {
    with_external_data?: 1;
    search_name?: string;
    selected?: 'ALL';
    next_company?: string;
    next?: string;
    exclude_companies?: string; // company_id,company_id
}

export type IStakeholderType = 'supplier' | 'customer';

export interface IGetItemsParams {
    limit?: number;
    next?: string;
}

export interface ICreateItemData {
    name: string;
    words: string[];
}

export interface IUpdateItemData {
    name: string;
    words: string[];
}

export interface IExportParams {
    xml?: 1;
    base64?: 1;
}

export interface ISelectCompanyParams {
    company_id?: string | 'NONE';
    targe_company_id: string;
}

export interface IGetCountParams {
    selected?: 'ALL';
    exclude_companies?: string; // company_id,company_id
}

export interface IGetAdminCompanyStat {
    limit?: number;
    next?: string;
}

export interface IPostAdminCountParams {
    reset?: 1;
}

export interface IPostAdminCountBody {
    available_documents: number;
}

export interface IPostAdminCountResponse {
    available_documents: number;
    used_documents: number;
}

export enum EnumIntegrationServiceType {
    GOOGLE = 'google',
    DROPBOX = 'dropbox'
}

export enum EnumIntegrationType {
    FTP = 'ftp_export',
    NSOFT = 'nsoft_export',
    SQL = 'sql_import',
    RIVILE_EXPORT = 'rivile_export',
    RIVILE_IMPORT = 'rivile_import',
    DISPLAY_IMPORTED_STAKEHOLDERS = 'display_imported_stakeholders',
    DISPLAY_IMPORTED_CATEGORIES = 'display_imported_categories'
}

export interface IIntegrationBody {
    type: EnumIntegrationType;
    enabled: boolean;
    credential?:
        | {
              host: string;
              user: string;
              password: string;
              port?: string;
          }
        | { api_key: string };
}

export interface IDeleteReceiptParams {
    unassigned?: 1;
}

export interface IGetReceiptData {
    deleteCompanyId?: boolean;
    receipt_status?: EnumReceiptStatus.Unassigned;
}

export interface ISelectBox {
    block_id?: string;
    position?: {
        height: number;
        left: number;
        top: number;
        width: number;
    };
    file: string;
    is_related_content?: boolean;
}

export interface IValidateReceiptContent {
    id: string;
    schema_id: string;
    message: string;
}

export interface IUseGridBody {
    document: string;
    box: ICoordinates;
}

export class API {
    private static _instance: API;
    company_id = '';

    axiosInst = axios.create({
        baseURL: ApplicationConfig.API_URL
    });

    constructor() {
        this.axiosInst.interceptors.request.use(
            async (config) => {
                const currentSession = await Auth.currentSession();
                config.headers['Token'] = currentSession.getAccessToken().getJwtToken();
                if (!config.params) {
                    config.params = {};
                }
                if (config.params.company_id) {
                    return config;
                }
                config.params['company_id'] = this.company_id;
                if (config.params.deleteCompanyId) {
                    delete config.params.deleteCompanyId;
                    delete config.params.company_id;
                }
                return config;
            },
            (err) => {
                return Promise.reject(err);
            }
        );
    }

    static get instance(): API {
        if (API._instance == null) API._instance = new API();
        return API._instance;
    }

    static getUpload(params: IGetUpload): Promise<any> {
        return API.instance.axiosInst.get('upload/document', {
            params
        });
    }

    static getUploadImport(params: IGetUploadImport): Promise<any> {
        return API.instance.axiosInst.get('upload/import', {
            params
        });
    }

    static putUploadURL(url: string, imageBody: any): Promise<Response> {
        return fetch(url, {
            method: 'PUT',
            body: imageBody
        });
    }

    static getReceipts(params: IGetReceiptParams) {
        return API.instance.axiosInst.get<APIListResponse<IReceipt>>('receipts', {
            params: { ...params, limit: ApplicationConfig.LIST_LIMIT }
        });
    }

    static getReceiptData(receipt_id: string, params: IGetReceiptData) {
        return API.instance.axiosInst.get<IReceipt>(`receipts/${receipt_id}`, {
            params
        });
    }

    static convertReceipt(receipt_id: string, receipt_type: EnumReceiptType) {
        return API.instance.axiosInst.post(`receipt/${receipt_id}/type`, {
            receipt_type
        });
    }

    static getReceiptHistory(receipts_id: string) {
        return API.instance.axiosInst.get(`receipts/${receipts_id}/history`);
    }

    static getReceiptImage(receipt_id: string, company_id?: string) {
        return API.instance.axiosInst.get(`receipts/${receipt_id}/preview`, {
            params: { company_id }
        });
    }

    static getCompanies() {
        return API.instance.axiosInst.get<APIListResponse<ICompany>>('companies');
    }

    static deleteCompany(company_id: string) {
        return API.instance.axiosInst.delete('company', { data: { company_id } });
    }

    static createCompany(company_name: string, code: string) {
        return API.instance.axiosInst.post<ICompany>('company', {
            company_name,
            code
        });
    }

    static getUser() {
        return API.instance.axiosInst.get<IProfile>('user');
    }

    static updateUser(params: IUpdateUserParams) {
        return API.instance.axiosInst.post('user', params);
    }

    static editReceipt(receipt_id: string, body: IEditReceiptBody, params: IEditReceiptParams) {
        return API.instance.axiosInst.post(`receipt/${receipt_id}`, body, {
            params
        });
    }

    static changeStatus(receipt_id: string, status: EnumReceiptStatus) {
        return API.instance.axiosInst.post(`receipt/${receipt_id}/status`, {
            status
        });
    }

    static changeFlag(receipt_id: string, flag: boolean) {
        return API.instance.axiosInst.post(`receipt/${receipt_id}/flag`, {
            flag
        });
    }

    static deleteReceipt(receipt_id: string, params: IDeleteReceiptParams) {
        return API.instance.axiosInst.delete(`receipt/${receipt_id}`, { params });
    }

    static getCategories(params: IGetCategoriesParams) {
        return API.instance.axiosInst.get<APIListResponseModify<ICategory>>('categories', {
            params
        });
    }

    static postCategories(name: string, category_code: string) {
        return API.instance.axiosInst.post<ICategory>('category', { name, category_code });
    }

    static editCategories(data: IEditCategory, company_id?: string) {
        return API.instance.axiosInst.put<ICategory>('category', data, { params: { company_id } });
    }

    static getStakeholderById(id: string) {
        return API.instance.axiosInst.get<IStakeholder>(`stakeholders/${id}`);
    }

    static getStakeholders(params: IGetStakeholdersParams, type?: IStakeholderType) {
        /*
         * TODO: add one more response inteface
         * interface IStakeholderExternal{
         *    id:string
         *    external:boolean
         *    name: string
         *    type?: string
         * }
         */
        return API.instance.axiosInst.get<APIListResponseModify<IStakeholder>>(
            `stakeholders${type ? '?type=' + type : ''}`,
            { params }
        );
    }

    static moveCustomerSupplierContent(params: IUpdateReceiptContent) {
        return API.instance.axiosInst.put(`/receipts/${params.receipt_id}/content/move`, params);
    }

    static editStakeholder(data: IEditStakeholder) {
        return API.instance.axiosInst.put<IStakeholder>('stakeholder', data);
    }

    static postStakeholders(params: ICreateStakeholderParams) {
        return API.instance.axiosInst.post<IStakeholder>('stakeholder', params);
    }

    static deleteStakeholder(id: string) {
        return API.instance.axiosInst.request({
            method: 'DELETE',
            url: 'stakeholder',
            data: { id }
        });
    }

    static deleteCategory(id: string, company_id?: string) {
        return API.instance.axiosInst.request({
            method: 'DELETE',
            url: 'category',
            data: { id },
            params: { company_id }
        });
    }

    static sendInvite(params: IInviteParams, company_id?: string) {
        return API.instance.axiosInst.post(
            process.env.REACT_APP_ENV === 'development'
                ? 'invitation?__DEV__LINK__=http://localhost:3000/signup/invite'
                : 'invitation',
            params,
            {
                params: {
                    company_id
                }
            }
        );
    }

    static getUsersByCompany(params: IGetUsersByCompanyParams) {
        return API.instance.axiosInst.get<APIListResponseModify<ICompanyUser>>('company/users', {
            params
        });
    }

    static editCompanyUser(user_id: string, params: IEditCompanyUser, company_id?: string) {
        return API.instance.axiosInst.put<ICompanyUser>(`company/users/${user_id}`, params, {
            params: { company_id }
        });
    }

    static deleteCompanyUser(user_id: string, company_id?: string) {
        return API.instance.axiosInst.request({
            method: 'DELETE',
            url: `company/users/${user_id}`,
            params: { company_id }
        });
    }

    static getCounts(params: IGetCountParams) {
        return API.instance.axiosInst.get('receipts/total-documents', { params });
    }

    static getExport(params: IExportParams, receipts_id: string) {
        return API.instance.axiosInst.get(`receipts/${receipts_id}/export`, {
            params
        });
    }

    static selectCompany(params: ISelectCompanyParams, receipts_id: string) {
        return API.instance.axiosInst.post<{ receipt_id: string }>(
            `receipts/${receipts_id}/company`,
            { company_id: params.targe_company_id },
            { params: { company_id: params.company_id } }
        );
    }

    static getStatistics() {
        return API.instance.axiosInst.get('admin/statistics');
    }

    static getCompaniesStatistic(params: IGetAdminCompanyStat) {
        return API.instance.axiosInst.get<APIListResponse<IAdminCompany>>('admin/companies', {
            params
        });
    }

    static postAdminCount(
        company_id: string,
        body: IPostAdminCountBody,
        params: IPostAdminCountParams
    ) {
        return API.instance.axiosInst.post<IPostAdminCountResponse>(
            `admin/companies/${company_id}`,
            body,
            {
                params
            }
        );
    }

    static changeIntegration(body: IIntegrationBody) {
        return API.instance.axiosInst.post<ICompany>('company/service', body);
    }

    static getServiceImportAuthURL(service_type: EnumIntegrationServiceType, disable = false) {
        return API.instance.axiosInst.get<{ url: string }>('company/service/drive/switch/', {
            params: {
                redirect: '1',
                service_type: service_type,
                disable: disable ? '1' : undefined
            }
        });
    }

    static getServiceImportListFolders(params: {
        next?: string;
        service_type: EnumIntegrationServiceType;
    }) {
        return API.instance.axiosInst.get('company/service/drive/folders', {
            params
        });
    }

    static postServiceImportFolder(
        id: string,
        name: string,
        service_type: EnumIntegrationServiceType
    ) {
        return API.instance.axiosInst.post(
            'company/service/drive/folders',
            {
                id,
                name
            },
            { params: { service_type } }
        );
    }

    static getReceiptContent(receipt_id: string) {
        return API.instance.axiosInst.get<{
            items: IContentSchema[];
            parent_content_id: string;
        }>(`receipts/${receipt_id}/content`);
    }

    static getReceiptSchema() {
        return API.instance.axiosInst.get<IReceiptShema[]>('schemas');
    }

    static saveReceiptSchema(items: IReceiptShema[]) {
        return API.instance.axiosInst.put('schemas', { items });
    }

    static getReceiptImageBoxes(receipts_id: string) {
        return API.instance.axiosInst.get<{ results: IReceiptImageBox[] }>(
            `receipts/${receipts_id}/bboxes`
        );
    }

    static createVatLine(receipt_id: string, content_id: string) {
        return API.instance.axiosInst.post(`receipts/${receipt_id}/content/${content_id}`);
    }

    static deleteVatLine(receipt_id: string, content_id: string) {
        return API.instance.axiosInst.delete(`receipts/${receipt_id}/content/${content_id}`);
    }

    static updateContent(receipt_id: string, item: IUpdateContentItem) {
        return API.instance.axiosInst.put(`receipts/${receipt_id}/content`, {
            item
        });
    }

    static getReceiptEmailInfo(email_id: string) {
        return API.instance.axiosInst.get(`emails/${email_id}`);
    }

    static selectBox(receipts_id: string, content_id: string, body: ISelectBox) {
        return API.instance.axiosInst.post<IContentSchema>(
            `receipts/${receipts_id}/content/${content_id}/select`,
            body
        );
    }

    static mergeReceipts(receipts_id: string, related_id: string) {
        return API.instance.axiosInst.post(`receipts/${receipts_id}/combine`, {
            receipt_id: related_id
        });
    }

    static getDuplicateInfo(receipts_id: string) {
        return API.instance.axiosInst.get(`receipts/${receipts_id}/duplicates`);
    }

    static updateGrid(receipts_id: string, parts: IContentSchemaGrid[]) {
        return API.instance.axiosInst.post<IContentSchema[]>(`receipts/${receipts_id}/grid`, {
            parts
        });
    }

    static getItems(val: {
        selected?: 'ALL';
        next_company?: string;
        next?: string;
        limit?: number;
    }) {
        const params: {
            selected?: 'ALL';
            next_company?: string;
            next?: string;
            limit?: number;
        } = {};
        params.limit = val.limit;
        params.next = val.next;
        params.next_company = val.next_company;
        params.selected = val.selected;

        return API.instance.axiosInst.get<APIListResponseModify<IItem>>(`/items`, { params });
    }

    static createItem(name: string, words: string[]) {
        return API.instance.axiosInst.post<IItem>(`/item`, { name, words });
    }

    static addItemWord(name: string, word: string) {
        return API.instance.axiosInst.post(`/item/word`, { name, word });
    }

    static editItem(item: IItem) {
        return API.instance.axiosInst.put<IItem>(`/item`, item);
    }

    static deleteItem(name: string) {
        return API.instance.axiosInst.request({
            method: 'DELETE',
            url: 'item',
            data: { name }
        });
    }

    static restartReceipt(receipt_id: string, company_id?: string) {
        return API.instance.axiosInst.post<IItem>(
            `/receipts/${receipt_id}/restart`,
            {},
            { params: { company_id } }
        );
    }

    static validateReceiptContent(receipt_id: string) {
        return API.instance.axiosInst.post<{ errors: IValidateReceiptContent[] }>(
            `/receipts/${receipt_id}/content/validate`,
            {}
        );
    }

    static useTemplateAsGrid(receipt_id: string, body: IUseGridBody) {
        return API.instance.axiosInst.post<IContentSchemaGrid>(
            `/receipts/${receipt_id}/template/grid`,
            body
        );
    }

    static saveGridAsTemplate(receipt_id: string, part: IContentSchemaGrid) {
        return API.instance.axiosInst.put(`/receipts/${receipt_id}/template/grid`, { part });
    }

    static isNewStakeholder(
        stakeholder_type: IStakeholderType,
        stakeholder_name: string,
        stakeholder_code: string
    ) {
        return API.instance.axiosInst.get<TIsNewStakeholderReturn>(`isNewStakeholder`, {
            params: {
                stakeholder_type,
                stakeholder_name,
                stakeholder_code
            }
        });
    }
}

// ===========
// == API OPEN ==
// ===========
export class API_OPEN {
    private static _instance: API_OPEN;

    axiosInst = axios.create({ baseURL: ApplicationConfig.API_URL });

    static get instance(): API_OPEN {
        if (API_OPEN._instance == null) API_OPEN._instance = new API_OPEN();
        return API_OPEN._instance;
    }

    static getUserByToken(token: string) {
        return API_OPEN.instance.axiosInst.get<{
            invitation: IInviteInfo;
            new_account_required: boolean;
        }>(`invitation/${token}`);
    }

    static putUserByToken(token: string) {
        return API_OPEN.instance.axiosInst.put(`invitation/${token}`);
    }
}
