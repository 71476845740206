import React, { FC } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { SmallText } from 'styles/text';

import dndIcon from 'assets/images/icons/dndIcon.png';

export type FileType = {
    name: string;
    path: string;
    size: number;
    type: string;
    lastModified: number;
    lastModifiedDate: Date;
    webkitRelativePath: string;
};

export type UploadedFileType = {
  id: string;
  name: string;
  size: number;
  type: string;
  success: boolean;
  message: string;
};

interface Props {
    accept: string;
    desc?: string;

    uploadFileHandle: (file: FileType) => any;
    setNewResult: (file: any, success: boolean, message: string) => void;
}

export const DropZone: FC<Props> = ({ accept, setNewResult, uploadFileHandle, desc = '' }) => {
    const onDrop = (acceptedFiles: any) => {
        for (let i = 0; i < acceptedFiles.length; i++) {
            uploadFileHandle(acceptedFiles[i]);
        }
    };

    const onDropRejected = (files: Array<any>) => {
        for (let i = 0; i < files.length; i++) {
            setNewResult(files[i].file, false, 'This file type is not allowed');
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        onDropRejected,
        accept
    });

    return (
        <Container {...getRootProps()} active={isDragActive}>
            <Icon src={dndIcon} alt="dnd" />
            <SmallText margin="0 0 10px 0">
                {isDragActive ? 'Drop here...' : 'Drag and drop your items here'}
            </SmallText>
            <SmallText>or</SmallText>
            <Button>Click here to select files</Button>
            <Req>{desc}</Req>
            <input {...getInputProps()} />
        </Container>
    );
};

interface ContainerProps {
    active?: boolean;
}
const Container = styled.div`
    cursor: pointer;
    width: 390px;
    height: 180px;
    background: ${({ active }: ContainerProps) =>
        active
            ? 'linear-gradient(180deg, #ffbbcf 0%, #a665d1 100%)'
            : 'linear-gradient(180deg, #a665d1 0%, #ffbbcf 100%)'};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Button = styled.div`
    position: relative;
    width: 150px;
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    font-family: 'NunitoSans-SemiBold';
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    user-select: none;
    margin-top: 10px;
    &:hover {
        opacity: 0.7;
    }
`;

const Icon = styled.img`
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
`;

const Req = styled.p`
    font-size: 12px;
    margin-top: 20px;
    color: #fff;
    font-family: 'NunitoSans-SemiBold';
`;
