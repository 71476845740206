import React, { Component } from "react";
import arrayMove from "array-move";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import closeIcon from "assets/images/icons/closeWhite.png";
import csvSuccess from "assets/images/csvSuccess.png";
import pdfSuccess from "assets/images/pdfSuccess.png";
import { WhiteCheckBox } from "components/Inputs/WhiteCheckBox";
import { DnDList } from "./DnDList";
import { DnDItem } from "./DnDItem";
import PrimaryButton from "components/PrimaryButton";
import { capitalizeFirstLetter } from "helpers/other";
import { EnumReceiptStatus, IReceipt } from "redux/receipt/types";
import { Loader } from "components/Other/Loader";
import {
  ChooseDirContainer,
  ChooseInput,
  Container,
  CSVSuccessImage,
  ExportText,
  GenerateBlock,
  Header,
  HeaderIcon,
  HeaderTitle,
  LoaderContainer,
  Main,
  MoveArchive,
  MoveText,
  Selector,
  SelectorBlock,
  SelectorLine,
  Title,
  Wrapper,
} from "./styles";

export type ExportedItem = {
  id: string;
  key: string;
  isEditing: boolean;
};

interface Props {
  selected: IReceipt[];
  closeModal: () => void;
  changeStatusHandle: (status: EnumReceiptStatus) => any;
}

interface State {
  loading: boolean;
  newItem: string;
  archive: boolean;
  fileName: string;
  format: "PDF" | "CSV";
  items: ExportedItem[];
  exportedSuccess: boolean;
}

class Export extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      items: [],
      loading: false,
      newItem: "",
      format: "CSV",
      archive: false,
      exportedSuccess: false,
      fileName: `report-${moment(new Date()).format("LLL")}`,
    };
  }

  componentDidMount() {
    const necessaryKeys = [
      "user",
      "type", // receipt_type
      "date",
      "currency",
      "status",
      "total",
      "tax", // tax_total
      "supplier",
      "category",
      "notes",
    ];

    const invKeys = necessaryKeys.map((key, i) => ({
      id: i.toString(),
      key,
      isEditing: false,
      originalKey: key,
    }));

    this.setState({
      items: invKeys,
    });
  }

  onSortEnd = ({ oldIndex, newIndex }: any) => {
    this.setState(({ items }: any) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  deleteItem = (id: string) => {
    this.setState((prev) => ({
      ...prev,
      items: this.state.items.filter((item: ExportedItem) => item.id !== id),
    }));
  };

  toogleEdit = (id: string) => {
    this.setState((prev) => ({
      ...prev,
      items: this.state.items.map((item: ExportedItem) => {
        if (item.id === id) {
          return {
            ...item,
            isEditing: !item.isEditing,
          };
        }
        return {
          ...item,
          isEditing: false,
        };
      }),
    }));
  };

  onChangeItem = (id: string, text: string) => {
    this.setState((prev) => ({
      ...prev,
      items: this.state.items.map((item: ExportedItem) => {
        if (item.id === id) {
          return {
            ...item,
            key: text,
          };
        }
        return item;
      }),
    }));
  };

  addNewItem = (text: string) => {
    if (text.trim()) {
      this.setState((prev) => ({
        ...prev,
        items: [
          ...this.state.items,
          { id: Date.now().toString(), key: text, isEditing: false },
        ],
        newItem: "",
      }));
    }
  };

  onChangeNewItem = (text: string) => {
    this.setState((prev) => ({
      ...prev,
      newItem: text,
    }));
  };

  downloadCSV = (data: any) => {
    let name = this.state.fileName
      ? this.state.fileName
      : `report-${moment(new Date()).format("LLL")}`;
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${name}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  generateCSV = () => {
    // Change selected receipts
    const newArr = this.props.selected.map((item) => {
      return {
        ...item,
        date: moment(item.created_at).format("l"),
        // created_at: moment(item.created_at).format("l"),
        // total: item.total ? item.total : item.subtotal ? item.subtotal : 0,
        // user: `${item.user.name} ${item.user.surname}`,
        // tax: item.tax_total,
        // type: item.receipt_type,
      };
    });
    // Create new Array with all accepted changes
    const compereObj = newArr.map((doc: any) => {
      const obj: any = {};
      this.state.items.forEach((item: any) => {
        if (doc[item.originalKey]) {
          obj[item.key] = doc[item.originalKey];
        } else {
          obj[item.key] = "";
        }
      });
      return obj;
    });

    const csvRows = [];
    // Get the headers
    const headers = Object.keys(compereObj[0]);
    csvRows.push(
      headers.map((e) => e.toUpperCase().replace("_", " ")).join(",")
    );

    // Loop over the rows
    for (const row of compereObj) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    // Form escaped comma separated values
    const finalRes = csvRows.join("\n");
    this.downloadCSV(finalRes);
  };

  generatePDF = () => {
    // Add only necessary fields
    const newArr = this.props.selected.map((item) => {
      return {
        ...item,
        // date: moment(item.date).format("l"),
        // created_at: moment(item.created_at).format("l"),
        // total: item.total ? item.total : item.subtotal ? item.subtotal : 0,
        // user: `${item.user.name} ${item.user.surname}`,
        // tax: item.tax_total,
        // type: item.receipt_type,
      };
    });
    // Create new Array with all accepted changes
    const compereObj = newArr.map((doc: any) => {
      const obj: any = {};
      this.state.items.forEach((item: any) => {
        if (doc[item.originalKey]) {
          obj[item.key] = doc[item.originalKey];
        } else {
          obj[item.key] = "";
        }
      });
      return obj;
    });

    // Create table
    const table = document.createElement("table");
    table.style.border = "1";
    const header = document.createElement("tr");
    const keys = Object.keys(compereObj[0]);

    // Create header
    const thead = document.createElement("thead");
    for (const key of keys) {
      const th = document.createElement("th");
      th.appendChild(document.createTextNode(capitalizeFirstLetter(key)));
      header.appendChild(th);
    }
    thead.appendChild(header);
    table.appendChild(thead);

    // Create body
    for (const row of compereObj) {
      const tr = document.createElement("tr");
      for (const key of keys) {
        const td = document.createElement("td");
        const content = row[key] || "";
        td.appendChild(document.createTextNode(content));
        tr.appendChild(td);
      }
      table.appendChild(tr);
    }
    document.body.appendChild(table);

    // Generate PDF
    const doc = new jsPDF();
    autoTable(doc, {
      styles: { fillColor: "#A665D1" },
      html: table,
      margin: 1,
      tableWidth: 208,
      bodyStyles: { cellPadding: 1, fillColor: "#D8BAEC" },
      headStyles: { fontSize: 9 },
    });
    let name = this.state.fileName
      ? this.state.fileName
      : `report-${moment(new Date()).format("LLL")}`;
    doc.save(`${name}.pdf`);

    // Remove table from DOM
    document.body.removeChild(table);
  };

  generateHandle = async () => {
    this.setState({ loading: true });
    if (this.state.format === "CSV") {
      this.generateCSV();
    } else {
      this.generatePDF();
    }

    if (this.state.archive) {
      await this.props.changeStatusHandle(EnumReceiptStatus.Archive);
    }

    this.setState({ exportedSuccess: true, loading: false });
  };

  changeFormat = () => {
    let newFormat: "CSV" | "PDF" = "PDF";
    if (this.state.format === "PDF") {
      newFormat = "CSV";
    }
    this.setState((prev) => ({
      ...prev,
      format: newFormat,
    }));
  };

  achiveToogle = () => {
    this.setState((prev) => ({
      ...prev,
      archive: !prev.archive,
    }));
  };

  render() {
    const { items, exportedSuccess, format } = this.state;

    const CSVcolor =
      this.state.format === "CSV"
        ? {
            background: "linear-gradient(180deg, #A665D1 0%, #FFBBCF 100%)",
          }
        : {};
    const PDFcolor =
      this.state.format === "PDF"
        ? {
            background: "linear-gradient(180deg, #A665D1 0%, #FFBBCF 100%)",
          }
        : {};

    return (
      <Container className="fadeIn">
        <Wrapper>
          {this.state.loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}

          <Header>
            <HeaderTitle>Export Items</HeaderTitle>
            <HeaderIcon
              src={closeIcon}
              alt="close"
              onClick={this.props.closeModal}
            />
          </Header>

          <Main>
            {exportedSuccess ? (
              <>
                <CSVSuccessImage
                  src={format === "CSV" ? csvSuccess : pdfSuccess}
                  alt="success csv"
                />
                <Title style={{ fontSize: "22px" }}>
                  Document successfully downloaded
                </Title>
                <PrimaryButton
                  title="OK"
                  onClick={this.props.closeModal}
                  style={{
                    width: "150px",
                    height: "55px",
                    fontFamily: "NunitoSans-ExtraBold",
                    fontSize: "18px",
                    marginBottom: "80px",
                    marginTop: "160px",
                  }}
                />
              </>
            ) : (
              <>
                <Selector>
                  <SelectorBlock onClick={this.changeFormat}>
                    CSV
                    <SelectorLine style={CSVcolor} />
                  </SelectorBlock>
                  <SelectorBlock onClick={this.changeFormat}>
                    PDF
                    <SelectorLine style={PDFcolor} />
                  </SelectorBlock>
                </Selector>
                <Title>
                  You can drag-n-drop the fields and rename the titles to
                  compose the {this.state.format.toLowerCase()} format you need.
                </Title>
                {items.length < 1 ? (
                  <div>Loading...</div>
                ) : (
                  <DnDList
                    axis={"y"}
                    onSortEnd={this.onSortEnd}
                    useDragHandle
                    helperClass="sortableHelper"
                  >
                    {items.map((item: ExportedItem, index) => (
                      <DnDItem
                        key={item.id}
                        index={index}
                        item={item}
                        onDelete={this.deleteItem}
                        toogleEdit={this.toogleEdit}
                        onChangeItem={this.onChangeItem}
                      />
                    ))}
                  </DnDList>
                )}

                <ChooseDirContainer>
                  <ExportText>File name</ExportText>
                  <ChooseInput
                    value={this.state.fileName}
                    onChange={(e) =>
                      this.setState({ fileName: e.target.value })
                    }
                  />
                </ChooseDirContainer>

                <GenerateBlock>
                  <MoveArchive>
                    <WhiteCheckBox
                      active={this.state.archive}
                      name="archive"
                      onPress={this.achiveToogle}
                    />
                    <MoveText>Move items to archive after export</MoveText>
                  </MoveArchive>
                  <PrimaryButton
                    title="GENERATE"
                    onClick={this.generateHandle}
                    style={{
                      width: "150px",
                      height: "55px",
                      fontFamily: "NunitoSans-ExtraBold",
                      fontSize: "18px",
                    }}
                  />
                </GenerateBlock>
              </>
            )}
          </Main>
        </Wrapper>
      </Container>
    );
  }
}

export default Export;
