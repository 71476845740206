import styled from "styled-components/macro";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const Block = styled.div`
  display: flex;
`;
