import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { UserActions } from "redux/user/actions";

import { ICompany, UserState } from "../../redux/user/types";
import Component from "./Dashboard";

export type DashboardProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  user: UserState;
}

interface LinkDispatchProps {
  deleteCompany: (company_id: string) => any;
  toggleActiveCompany: (company: ICompany) => any;
  setActiveAllCompanies: (active: boolean) => any;
  createCompany: (company_name: string, code: string) => any;
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = {
  createCompany: UserActions.createCompany,
  deleteCompany: UserActions.deleteCompany,
  toggleActiveCompany: UserActions.toggleActiveCompany,
  setActiveAllCompanies: UserActions.setActiveAllCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
