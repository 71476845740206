import { useEffect, RefObject } from 'react';

export type TUseClickOutside = {
    ref: RefObject<HTMLElement>;
    callback: () => void;
};

export const useClickOutside = ({ ref, callback }: TUseClickOutside): void => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback, ref]);
};
