import moment from "moment";
import React, { FC } from "react";

import { IAdminCompany } from "redux/admin/types";

import { TableContainer } from "./styles";

interface Props {
  data: IAdminCompany[];
  onScrollEnd: () => any;
  onSelect: (item: IAdminCompany) => void;
}

const Table: FC<Props> = ({ data, onSelect, onScrollEnd }) => {
  const _onScroll = (event: any) => {
    var element = event.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      onScrollEnd();
    }
  };

  return (
    <TableContainer onScroll={_onScroll}>
      <thead>
        <tr>
          <th className="admin_col1">Date</th>
          <th className="admin_col2">User</th>
          <th className="admin_col3">Company</th>
          <th className="admin_col4">Email</th>
          <th className="admin_col5">Invoices per/mounth</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const onClick = () => onSelect(item);

          return (
            <tr key={index}>
              <td className="admin_col1" onClick={onClick}>
                {moment(item.created_at).format("DD MMM YY")}
              </td>
              <td className="admin_col2" onClick={onClick}>
                {item.user.name} {item.user.surname}
              </td>
              <td className="admin_col3" onClick={onClick}>
                {item.name}
              </td>
              <td className="admin_col4" onClick={onClick}>
                {item.user.email}
              </td>
              <td className="admin_col5" onClick={onClick}>
                {item.used_documents}/{item.available_documents}
              </td>
            </tr>
          );
        })}
      </tbody>
    </TableContainer>
  );
};

export default Table;
