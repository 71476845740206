import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IStakeholder } from 'redux/list/types';
import { API, IStakeholderType } from 'net/api';

import { Chip, ErrorInput, RegularText } from 'styles/text';
import { greyColorDark, primaryColor, whiteColor } from 'styles/colors';

import search from 'assets/images/icons/search.png';
import { Label } from 'pages/EditReceipt/components/Details/styles';
import { DisplayFlexCenter } from '../../styles/blocks';

interface Props {
    label?: string;
    error?: string;
    active: boolean;
    selected: string;
    disabled?: boolean;
    list: IStakeholder[];
    labelStyle?: CSSProperties;
    stakeholder_type: IStakeholderType;
    onFocus: () => any;
    loadData?: () => any;
    onChange: (t: string) => any;
    onSelect: (s: IStakeholder | null) => void;
    withUpdate?: boolean;
    onTimerChange?: () => any;
    displayNewMarker?: boolean;
}

const StakeHolderInput: FC<Props> = ({
    list,
    label,
    error,
    active,
    disabled,
    selected,
    labelStyle,
    stakeholder_type,
    onFocus,
    onChange,
    loadData,
    onSelect,
    withUpdate,
    onTimerChange,
    displayNewMarker = false
}) => {
    const timer = useRef<any>(null);
    const timerUpdate = useRef<any>(null);

    const [value, setValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [filteredList, setFiltered] = useState(list);

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    useEffect(() => {
        setValue(selected ? selected : '');
    }, [selected]);

    useEffect(() => {
        if (withUpdate) {
            timerUpdate.current = setTimeout(() => {
                onTimerChange && onTimerChange();
            }, 1 * 1000);
        }

        return () => {
            clearTimeout(timerUpdate.current);
        };
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const getDataHandle = async (t: string) => {
        try {
            const res = await API.getStakeholders(
                { search_name: t, with_external_data: 1 },
                stakeholder_type
            );
            setFiltered(res.data.items);
        } catch (error) {}
    };

    const _onChange = (t: string) => {
        if (!visible) {
            setVisible(true);
        }
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            onChange(t);
            getDataHandle(t);
        }, 1000);
        setValue(t);
    };

    const _onSelect = (s: IStakeholder) => {
        onSelect(s);
        setValue(s.name);
        setVisible(false);
        clearTimeout(timerUpdate.current);
    };

    const _onClose = () => {
        setVisible(false);
    };

    const _onFocus = () => {
        setFiltered(list);
        setVisible(true);
        onFocus();
    };

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (loadData) {
                loadData();
            }
        }
    };

    return (
        <>
            {visible && <Filter onClick={_onClose} />}
            <Container zIndex={visible}>
                <DisplayFlexCenter>
                    <Label active={active} style={labelStyle} onClick={() => setVisible(!visible)}>
                        {label}
                    </Label>

                    {displayNewMarker && <Chip>new</Chip>}
                </DisplayFlexCenter>

                <Block disabled={disabled}>
                    <InputBlock active={active}>
                        <InputText
                            disabled={disabled}
                            value={value}
                            onChange={(e) => _onChange(e.target.value)}
                            onFocus={_onFocus}
                        />
                        <Icon src={search} alt="search" />
                    </InputBlock>
                    {visible && (
                        <>
                            <List onScroll={handleScroll}>
                                {filteredList.length ? (
                                    filteredList.map((el, i) => (
                                        <Item key={el.name + i} onClick={() => _onSelect(el)}>
                                            <ItemText>{el.name}</ItemText>
                                            {el.external && <IsExternal>DB</IsExternal>}
                                        </Item>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </List>
                        </>
                    )}
                </Block>
                {error && <ErrorInput>{error}</ErrorInput>}
            </Container>
        </>
    );
};

export default StakeHolderInput;

const Filter = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
`;

interface ContainerProps {
    zIndex: boolean;
}

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    z-index: ${({ zIndex }: ContainerProps) => (zIndex ? 10 : 1)};
`;

interface BlockProps {
    disabled?: boolean;
}

export const Block = styled.div`
    flex: 1;
    position: relative;
    opacity: ${({ disabled }: BlockProps) => (disabled ? 0.2 : 1)};
`;

interface InputBlockProps {
    active: boolean;
}

export const InputBlock = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    height: 35px;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
    border: ${({ active }: InputBlockProps) =>
        active ? `0.4px solid ${primaryColor}` : '0.4px solid #eeeeee'};
`;

export const InputText = styled.input`
    width: calc(100% - 20px);
    font-size: 15px;
    color: ${greyColorDark};
    font-family: 'NunitoSans-SemiBold';
    text-indent: 15px;
    border: none;
    outline: none;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const Icon = styled.img`
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
`;

interface ItemProps {
    empty?: boolean;
}

export const Item = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    height: 35px;
    background: ${({ empty }: ItemProps) => (empty ? '#9191AF' : '#ffffff')};
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
`;

interface ItemTextProps {
    empty?: boolean;
}

export const ItemText = styled(RegularText)`
    color: ${({ empty }: ItemTextProps) => (empty ? whiteColor : greyColorDark)};
    text-indent: 15px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const List = styled.div`
    position: absolute;
    top: 38px; //72px;
    width: 100%;
    max-height: 222px;
    overflow: auto;
    z-index: 10;
`;

export const AddItem = styled(Item)`
    width: 99%;
    position: absolute;
    top: 35px;
    z-index: 10;
`;

const IsExternal = styled.p`
    position: absolute;
    font-size: 10px;
    color: #d16e6e;
    font-family: 'NunitoSans-Bold';
    right: 10px;
    background-color: ${whiteColor};
    width: 30px;
    text-align: center;
    right: 0;
`;
