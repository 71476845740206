import React from "react";
import styled from "styled-components";

const SmallLoader = () => {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
};

export default SmallLoader;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 30px;
  div {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fcf;
    animation: lds-grid 1.2s linear infinite;
    &:nth-child(1) {
      top: 0;
      left: 0;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      top: 0;
      left: 18px;
      animation-delay: -0.4s;
    }
    &:nth-child(3) {
      top: 0;
      left: 36px;
      animation-delay: -0.8s;
    }
    &:nth-child(4) {
      top: 18px;
      left: 0;
      animation-delay: -1.2s;
    }
    &:nth-child(5) {
      top: 18px;
      left: 18px;
      animation-delay: -1.2s;
    }
    &:nth-child(6) {
      top: 18px;
      left: 36px;
      animation-delay: -1.2s;
    }
  }
`;
