import PrimaryButton from 'components/PrimaryButton';
import { useFormik } from 'formik';
import { ListSchema, ListSchemaSimple } from 'helpers/validations';
import React, { FC, InputHTMLAttributes, useEffect, useState, useMemo } from 'react';
import { ICompany } from 'redux/user/types';
import styled, { CSSProperties } from 'styled-components';

import { nanoid } from 'nanoid';

import DropInput from 'components/DropInput';
import InputLabel from 'components/InputLabel';

import icon from 'assets/images/icons/close.png';

const styles = {
    inputContainer: {
        marginBottom: 18
    },
    labelStyle: {
        minWidth: 90,
        color: '#9191AF'
    }
};

interface Props {
    itemName?: string;
    itemWords?: string[];
    companies: ICompany[];
    activeAllCompanies?: boolean;
    onSubmit: (name: string, words: string[], company: string) => any;
    labelStyle?: CSSProperties;
    onUpload?: () => void
}

const NewItem: FC<Props> = ({
    itemName,
    itemWords,
    companies,
    activeAllCompanies,
    onSubmit,
    labelStyle = {},
    onUpload
}) => {
    const [loading, setLoading] = useState(false);

    const [words, setWords] = useState<any[]>(
        itemWords?.map((w) => ({ id: nanoid(), word: w })) || []
    );

    const formik = useFormik({
        validationSchema: activeAllCompanies ? ListSchema : ListSchemaSimple,
        initialValues: {
            name: itemName || '',
            company: ''
        },
        onSubmit: async ({ name, company }) => {
            setLoading(true);
            const err = await onSubmit(
                name,
                words.map((item) => item.word).filter((w) => !!w),
                company
            );
            if (err) {
                formik.setErrors({ name: err });
            } else {
                formik.resetForm();
                setWords([]);
            }
            setLoading(false);
        }
    });
    const { touched, values, errors } = formik;

    const companiesName = useMemo(() => {
        return companies.map((c) => c.name);
    }, [companies]);

    const onAddWord = (e: any) => {
        e.preventDefault();
        setWords([...words, { id: nanoid(), word: '' }]);
    };

    const onDeleteWord = (e: any, id: string) => {
        e.preventDefault();
        setWords(words.filter((w) => w.id !== id));
    };

    const onChangeWord = (id: string, value: string) => {
        setWords(words.map((word) => ({ ...word, word: id === word.id ? value : word.word })));
    };

    return (
        <Container onSubmit={formik.handleSubmit}>
            <Block>
                <InputLabel
                    mark
                    id="name"
                    name="name"
                    type="text"
                    label="Name"
                    error={errors.name}
                    touched={touched.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={values.name}
                    labelStyle={{ ...styles.labelStyle, ...labelStyle }}
                    containerStyle={styles.inputContainer}
                />
                {activeAllCompanies && (
                    <DropInput
                        mark
                        label="Company"
                        list={companiesName}
                        labelStyle={{ ...styles.labelStyle, ...labelStyle }}
                        selected={values.company}
                        onClick={(t) => formik.setFieldValue('company', t)}
                        error={touched.company && errors.company ? errors.company : ''}
                    />
                )}
            </Block>

            <WordsContainer>
                {words.map((word, ind) => (
                    <WordItem key={word.id}>
                        <InputLabel
                            mark
                            id={`${word.id}`}
                            name={`${word.id}`}
                            type="text"
                            label={`Word ${ind + 1}`}
                            // error={}
                            // touched={touched.name}
                            onChange={(e) => onChangeWord(word.id, e.target.value)}
                            value={word.word}
                            labelStyle={{ ...styles.labelStyle, ...labelStyle }}
                            containerStyle={styles.inputContainer}
                        />
                        <ContainerClose onClick={(e) => onDeleteWord(e, word.id)}>
                            <img src={icon} style={{ width: 10, height: 'auto' }} alt="remove" />
                        </ContainerClose>
                    </WordItem>
                ))}
            </WordsContainer>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <PrimaryButton
                    title="New Word"
                    onClick={onAddWord}
                    style={{ width: '90px', height: '35px' }}
                />

                <PrimaryButton
                    loading={loading}
                    title="Confirm"
                    onClick={formik.handleSubmit}
                    style={{ width: '90px', height: '35px', marginLeft: 8 }}
                />
                {onUpload && (
                    <PrimaryButton
                        loading={loading}
                        title="Upload XLSX"
                        onClick={onUpload}
                        style={{ width: '120px', height: '35px', marginLeft: 8 }}
                    />
                )}
            </div>
        </Container>
    );
};

export default NewItem;

const Container = styled.form`
    // display: flex;
    // justify-content: space-between;
    padding-left: 30px;
    max-width: 550px;
`;

const Block = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // margin-right: 20px;
`;
const ContainerClose = styled.div`
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    cursor: pointer;
`;

const WordsContainer = styled.div`
    max-height: 300px;
    overflow-y: auto;
`;
const WordItem = styled.div`
    position: relative;
`;
