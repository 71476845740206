import { IPostAdminCountBody, IPostAdminCountParams } from "net/api";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AdminActions } from "redux/admin/actions";
import { IAdminCompany } from "redux/admin/types";
import { AppState } from "redux/rotReducer";

import Component from "./AdminInfo";

export type AdminInfoProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  companies: IAdminCompany[];
}
interface LinkDispatchProps {
  updateAdminCount: (
    company_id: string,
    body: IPostAdminCountBody,
    params: IPostAdminCountParams
  ) => any;
}

const mapStateToProps = (state: AppState) => ({
  companies: state.admin.companies.items,
});

const mapDispatchToProps = {
  updateAdminCount: AdminActions.updateAdminCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
