import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "redux/rotReducer";
import { SettingActions } from "redux/setting/actions";
import { SettingState } from "redux/setting/types";

import Component from "./Settings";

export type SettingsProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  setting: SettingState;
}
interface LinkDispatchProps {
  updateHidden: (id: string) => void;
  updateRequired: (id: string) => void;
  getReceiptSchema: () => Promise<void>;
  saveReceiptSchema: () => Promise<void>;
}

const mapStateToProps = (state: AppState) => ({
  setting: state.setting,
});

const mapDispatchToProps = {
  updateHidden: SettingActions.updateHidden,
  updateRequired: SettingActions.updateRequired,
  getReceiptSchema: SettingActions.getReceiptSchema,
  saveReceiptSchema: SettingActions.saveReceiptSchema,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
