import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "redux/rotReducer";
import { IntegrationActions } from "redux/integration/actions";

import Component from "./IntegrationGoogle";
import { EnumIntegrationServiceType } from "net/api";
import { GooglePathItem } from "redux/integration/types";

export type IntegrationGoogleProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};
interface LinkStateProps {
  googlePathList: GooglePathItem[]; //APIListResponse<GooglePathItem>;
  hasMoreFolders: boolean;
  google_folder_name: string;
  dropbox_folder_name: string;
  google_active: boolean;
  dropbox_active: boolean;
}
interface LinkDispatchProps {
  postServiceImportFolder: (
    p: GooglePathItem,
    service_type: EnumIntegrationServiceType
  ) => any;
  getServiceImportListFolders: (
    service_type: EnumIntegrationServiceType,
    use_next?: boolean
  ) => Promise<void>;
}

const mapStateToProps = (state: AppState) => ({
  googlePathList: state.integration.googlePathList.items,
  hasMoreFolders: !!state.integration.googlePathList.next,
  google_folder_name:
    state.user.activeCompany.google_selected_import_folder_name,
  dropbox_folder_name:
    state.user.activeCompany.dropbox_selected_import_folder_name,
  google_active: state.user.activeCompany.google_drive_import || false,
  dropbox_active: state.user.activeCompany.dropbox_import || false,
});

const mapDispatchToProps = {
  postServiceImportFolder: IntegrationActions.postServiceImportFolder,
  getServiceImportListFolders: IntegrationActions.getServiceImportListFolders,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
