import React, { FC } from 'react';

import signIcon from 'assets/images/icons/sign.png';
import styled from 'styled-components';
import { greyColor, whiteColor } from 'styles/colors';

interface CheckBoxProps {
    active: boolean;
    onClick: (event: any) => void;
    color?: 'default' | 'white';
}

const CheckBox: FC<CheckBoxProps> = ({ active, color = 'default', onClick }) => {
    return (
        <Container className="rectangle" onClick={onClick} color={color}>
            {active && <Sign src={signIcon} alt="sign" />}
        </Container>
    );
};

export default CheckBox;

interface ContainerProps {
    color: 'default' | 'white';
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: ${({ color }: ContainerProps) =>
        color === 'default' ? `1px solid ${greyColor}` : `1px solid ${whiteColor}`};
    border-radius: 5px;
    cursor: pointer;
`;
const Sign = styled.img`
    width: 15px;
    height: 15px;
`;
