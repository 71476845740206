import { APIListResponse, APIListResponseModify } from 'net/api';

export enum EnumLocalStorageItems {
    Active_company = 'active_company',
    Active_all_companies = 'active_all_companies'
}

export interface UserState {
    profile: IProfile;
    companies: APIListResponse<ICompany>;
    activeCompany: ICompany;
    loading: boolean;
    users: APIListResponseModify<ICompanyUser>;
    activeAllCompanies: boolean;
}

export interface IProfile {
    is_admin: boolean;
    address: string;
    city: string;
    country: string;
    created_at: number;
    email: string;
    id: string;
    last_login: number;
    name: string;
    phone: string;
    post_code: string;
    surname: string;
    total_amount: number;
    total_receipts: number;
    waiting_for_approval: number;
    total_unassigned_receipts: number;
    total_unassigned_invoices: number;
    global_email: string;
}

export interface ICompanyUser {
    // companies?: { company_id: string; name: string }[];
    user_id: string;
    company_id: string;
    role: IUserRole;
    email: string;
    name?: string;
    surname?: string;
    can_be_deleted?: boolean;
    user: {
        id: string;
        name: string;
        surname: string;
        email: string;
        created_at: string;
        last_login: string;
    };
}

export interface ICompany {
    company_id: string;
    name: string;
    user_id: string;
    role: IUserRole;
    email: string;
    code: string;
    rivile_export: boolean;
    rivile_import: boolean;
    ftp_export: boolean;
    nsoft_export: boolean;
    google_drive_import?: boolean;
    dropbox_import?: boolean;
    google_selected_import_folder_name: string;
    dropbox_selected_import_folder_name: string;
    display_imported_stakeholders?: boolean;
    display_imported_categories?: boolean;
    sql_import: boolean;
    surname: string;
    unique_name: string;
    used_documents_per_month: number;
    duplicated_documents_per_month: number;
}

export type IUserRole = 'basic' | 'admin';

const CREATE_COMPANY = 'CREATE_COMPANY';
const CHANGE_INTEGRATION = 'CHANGE_INTEGRATION';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USERS_BY_COMPANY = 'SET_USERS_BY_COMPANY';
export const EDIT_USER_BY_COMPANY = 'EDIT_USER_BY_COMPANY';
export const DELETE_USER_FROM_COMPANY = 'DELETE_USER_FROM_COMPANY';
export const SET_ACTIVE_COMPANY = 'SET_ACTIVE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const SET_ACTIVE_ALL_COMPANIES = 'SET_ACTIVE_ALL_COMPANIES';

export interface SetProfileAction {
    type: typeof SET_PROFILE;
    profile: any;
}

export interface SetCompaniesAction {
    type: typeof SET_COMPANIES;
    companies: APIListResponse<ICompany>;
}

export interface SetUserLoadingAction {
    type: typeof SET_USER_LOADING;
    loading: boolean;
}

export interface SetUserByCompanyAction {
    type: typeof SET_USERS_BY_COMPANY;
    data: APIListResponseModify<ICompanyUser>;
}

export interface EditUserByCompanyAction {
    type: typeof EDIT_USER_BY_COMPANY;
    user: ICompanyUser;
}
export interface DeleteUserFromCompanyAction {
    type: typeof DELETE_USER_FROM_COMPANY;
    user: ICompanyUser;
}

export interface SetActiveCompanyAction {
    type: typeof SET_ACTIVE_COMPANY;
    company: ICompany;
}

export interface CreateCompanyAction {
    type: typeof CREATE_COMPANY;
    company: ICompany;
}

export interface DeleteCompanyAction {
    type: typeof DELETE_COMPANY;
    company_id: string;
}

export interface SetActiveAllCompanies {
    type: typeof SET_ACTIVE_ALL_COMPANIES;
    active: boolean;
}

export interface ChangeIntegrationAction {
    type: typeof CHANGE_INTEGRATION;
    company: ICompany;
}

export type UserActionTypes =
    | ChangeIntegrationAction
    | SetActiveAllCompanies
    | DeleteCompanyAction
    | CreateCompanyAction
    | SetActiveCompanyAction
    | EditUserByCompanyAction
    | SetUserByCompanyAction
    | SetProfileAction
    | SetUserLoadingAction
    | SetCompaniesAction
    | DeleteUserFromCompanyAction;
