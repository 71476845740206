import { API, IGetItemsParams, IPostAdminCountBody, IPostAdminCountParams } from 'net/api';
import { Dispatch } from 'redux';
import { ReduxActions } from 'redux/types';
import { AppState } from 'redux/rotReducer';
import { IGetAdminCompanyStat } from '../../net/api';

export class AdminActions {
    static getAdminCompanies =
        (params: IGetAdminCompanyStat) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            // const { companies } = getState().admin;
            // if (companies.items.length > 0) {
            //   return;
            // }
            try {
                const res = await API.getCompaniesStatistic(params);
                if (res.data) {
                    dispatch({ type: 'SET_ADMIN_COMPANIES', data: res.data });
                }
            } catch (error) {}
        };

    static loadAdminCompanies =
        (params: IGetItemsParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { companies } = getState().admin;
            if (!companies.next) {
                return;
            }
            if (companies.next) {
                params.next = companies.next;
            }

            try {
                const res = await API.getCompaniesStatistic(params);
                if (res.data) {
                    dispatch({
                        type: 'SET_ADMIN_COMPANIES',
                        data: {
                            ...res.data,
                            items: [...companies.items, ...res.data.items]
                        }
                    });
                }
            } catch (error) {}
        };

    static getAdminInfo = () => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.getStatistics();
            dispatch({ type: 'SET_ADMIN_INFO', data: res.data });
        } catch (error) {}
    };

    static updateAdminCount =
        (company_id: string, body: IPostAdminCountBody, params: IPostAdminCountParams) =>
        async (dispatch: Dispatch<ReduxActions>) => {
            try {
                const res = await API.postAdminCount(company_id, body, params);
                dispatch({
                    type: 'SET_ADMIN_COMPANY_COUNT',
                    company_id,
                    data: res.data
                });
            } catch (error) {}
        };
}
