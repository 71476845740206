import { ListActionTypes, ListState } from './types';

const initialState: ListState = {
    items: {
        items: []
    },
    categories: {
        items: []
    },
    customers: {
        items: []
    },
    suppliers: {
        items: []
    },
    categoriesByCompany: {
        items: []
    },
    isLoading: false
};

const listReducer = (state = initialState, actions: ListActionTypes): ListState => {
    switch (actions.type) {
        case 'SET_LIST':
            return {
                ...state,
                [actions.key]: { ...actions.data }
            };
        case 'SET_IS_LOADING':
            return {
                ...state,
                isLoading: actions.value
            };
        case 'ADD_LIST_ITEM':
            if (actions.key === 'categories') {
                return {
                    ...state,
                    [actions.key]: {
                        ...state[actions.key],
                        items: state[actions.key].items
                            .concat(actions.item)
                            .sort((a, b) =>
                                a.name.toLowerCase() > b.name.toLowerCase()
                                    ? 1
                                    : a.name.toLowerCase() < b.name.toLowerCase()
                                    ? -1
                                    : 0
                            )
                    }
                };
            }
            return {
                ...state,
                [actions.key]: {
                    ...state[actions.key],
                    items: [...state[actions.key].items, actions.item]
                }
            };
        case 'DELETE_LIST_ITEM':
            return {
                ...state,
                [actions.key]: {
                    ...state[actions.key],
                    // @ts-ignore
                    items: state[actions.key].items.filter((i: any) => {
                        if (actions.key === 'items') {
                            return i.name_id !== actions.id;
                        }
                        return i.id !== actions.id;
                    })
                }
            };
        case 'EDIT_LIST_ITEM':
            return {
                ...state,
                [actions.key]: {
                    ...state[actions.key],
                    items: state[actions.key].items
                        // @ts-ignore
                        .filter((item) => item.id !== actions.item.id)
                        .concat(actions.item)
                }
            };
        default:
            return state;
    }
};

export default listReducer;
