import React, { FC } from 'react';
import styled from 'styled-components';
import { ButtonGreen, ButtonReject } from '../Actions/styles';

import mergeIcon from 'assets/images/icons/merge.png';
import rotatingArrowIcon from 'assets/images/icons/rotatingArrow.png';

interface Props {
    onMergePress: () => void;
    is_edit_grid?: boolean;
    onCancelGrid: () => void;
    onApplyGrid: () => void;
    onEditGrid: () => void;
    onRotatePress: () => void;
}

const ImageActions: FC<Props> = ({
    is_edit_grid,
    onCancelGrid,
    onApplyGrid,
    onEditGrid,
    onMergePress,
    onRotatePress
}) => {
    return (
        <Container>
            <Icon src={mergeIcon} alt="merge" onClick={onMergePress} />
            <Icon src={rotatingArrowIcon} alt="rotate" onClick={onRotatePress} />
            {/* <BottomGridActions> */}
            {is_edit_grid ? (
                <>
                    <ButtonReject isValid={true} onClick={onCancelGrid}>
                        Cancel
                    </ButtonReject>
                    <ButtonGreen isValid={true} onClick={onApplyGrid}>
                        Apply
                    </ButtonGreen>
                </>
            ) : (
                <ButtonGreen isValid={true} onClick={onEditGrid}>
                    Edit Grid
                </ButtonGreen>
            )}
            {/* </BottomGridActions> */}
        </Container>
    );
};

export default ImageActions;

const Container = styled.div`
    position: absolute;
    top: 40vh;
    left: 0;
    z-index: 30;
    padding: 15px 10px 0 10px;
    // opacity: 0.5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-10px);
    background-color: #1b1922;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    filter: invert(100%);
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }

    cursor: pointer;
    margin-bottom: 15px;
`;

export const BottomGridActions = styled.div`
    // position: absolute;
    // // bottom: 24px;
    // // right: 4%;
    // z-index: 21;
    display: flex;
`;
