import React, { FC, useEffect, useRef, useState } from 'react';

import { ListProps } from './index';
import { capitalizeFirstLetter } from 'helpers/other';
import { UploadImportType } from 'net/api';

import Item from './components/Item';
import CreateForm from './components/CreateForm';
import PrimaryHeader from 'components/PrimaryHeader';

import { Label } from 'styles/text';
import { ListContainer } from './styles';
import { Container } from 'styles/blocks';
import { IStakeholder } from 'redux/list/types';
import NewItem from './components/NewItem';
import NewItems from './components/NewItems';
import EditItem from './components/NewItems/EditItem';
import EditCategory from './components/EditCategory';
import EditStakeholder from 'components/EditStakeholder';
import UploadXLSX from './components/UploadXLSX';
import InputLabel from 'components/InputLabel';
import DeletePopUp from '../../components/DeletePopUp';
import { Loader } from '../../components/Other/Loader';

const List: FC<ListProps> = ({
    user,
    list,
    match,
    history,
    companies,
    loadListPage,
    loadCategories,
    createCategory,
    editStakeholder,
    editCategory,
    createStakeholder,
    deleteStakeholder,
    getCategoriesByCompany,
    createItem,
    updateItem,
    uploadImport
}) => {
    const title = match.path.replace('/', '');
    const capitalTitle = capitalizeFirstLetter(title);
    const [popUp, setPopUp] = useState({
        importXLS: false,
        stakeholder: false,
        items: false,
        category: false,
        uploadImport: false,
        isDeleteStakeholderPopUpOpen: false
    });
    const [editItem, setEditItem] = useState<any>();
    const [uploadType, setUploadType] = useState<UploadImportType>();
    const [search, setSearch] = useState('');
    const loadTimer = useRef<any>();
    const [stakeholderToDelete, setStakeholderToDelete] = useState<any>();

    useEffect(() => {
        loadListPage(title);
        clearTimeout(loadTimer.current);
        setSearch('');
    }, [title]);

    useEffect(() => {
        loadTimer.current = setTimeout(() => {
            loadListPage(title, search);
        }, 1 * 1000);

        return () => {
            clearTimeout(loadTimer.current);
        };
    }, [search]);

    const tooglePopUp = (key: string) => {
        return () => {
            // @ts-ignore
            setPopUp({ ...popUp, [key]: !popUp[key] });
        };
    };

    // @ts-ignore
    const data = list[title];

    const _deleteItem = async (item: any) => {
        switch (title) {
            case 'categories':
                await deleteStakeholder(item.id, 'categories');
                return tooglePopUp('isDeleteStakeholderPopUpOpen')();
            case 'items':
                await deleteStakeholder(item.name_id, 'items');
                return tooglePopUp('isDeleteStakeholderPopUpOpen')();
            case 'suppliers':
                await deleteStakeholder(item.id, 'suppliers');
                return tooglePopUp('isDeleteStakeholderPopUpOpen')();
            case 'customers':
                await deleteStakeholder(item.id, 'customers');
                return tooglePopUp('isDeleteStakeholderPopUpOpen')();
        }
    };

    const onItemPress = (item: IStakeholder) => {
        switch (title) {
            case 'suppliers':
            case 'customers':
                setEditItem(item);
                tooglePopUp('stakeholder')();
                return;
            case 'categories':
                setEditItem(item);
                tooglePopUp('category')();
                return;
            case 'items':
                setEditItem(item);
                tooglePopUp('items')();
                return;
        }
    };

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            loadCategories({});
        }
    };

    const onCreate = (name: string, code: string, company: string) => {
        switch (title) {
            case 'categories':
                return createCategory(name, code, company);
        }
    };
    const onCreateItem = (name: string, words: string[], company: string) => {
        return createItem(name, words, company);
    };

    const _updateItem = (name: string, words: string[], company: string) => {
        editItem.name = name;
        editItem.words = words;
        updateItem(editItem);
        tooglePopUp('items')();
    };

    const onSearch = (value: string) => {
        setSearch(value);
    };

    return (
        <Container>
            <PrimaryHeader
                title={capitalTitle}
                margin={`0 0 15px 0`}
                onClick={() => history.push('/my-profile')}
            />

            <Label margin="0 0 15px 0">Add new {title}</Label>
            {title === 'categories' ? (
                <NewItem
                    path={match.path}
                    companies={companies}
                    activeAllCompanies={user.activeAllCompanies}
                    createCategory={onCreate}
                    onUpload={() => {
                        tooglePopUp('uploadImport')();
                        setUploadType(UploadImportType.Categories);
                    }}
                />
            ) : title === 'items' ? (
                <NewItems
                    companies={companies}
                    activeAllCompanies={user.activeAllCompanies}
                    onSubmit={onCreateItem}
                    onUpload={() => {
                        tooglePopUp('uploadImport')();
                        setUploadType(UploadImportType.Items);
                    }}
                />
            ) : (
                <CreateForm
                    path={match.path}
                    companies={companies}
                    categories={list.categoriesByCompany.items}
                    activeCompany={user.activeCompany}
                    activeAllCompanies={user.activeAllCompanies}
                    type={title === 'customers' ? 'customer' : 'supplier'}
                    createStakeholder={createStakeholder}
                    getCategoriesByCompany={getCategoriesByCompany}
                />
            )}

            <Label margin="15px 0 15px 0">{capitalTitle} name</Label>
            <div style={{ width: '100%', maxWidth: 904, marginBottom: 20, height: 45 }}>
                <InputLabel
                    mark
                    id="search"
                    name="search"
                    type="text"
                    onChange={(e) => onSearch(e.target.value)}
                    value={search}
                    placeholder="Search..."
                />
            </div>

            <ListContainer onScroll={handleScroll}>
                {data &&
                    data.items.map((item: any, ind: number) => (
                        <Item
                            key={ind}
                            item={item}
                            onItemPress={() => onItemPress(item)}
                            deleteItem={() => {
                                setStakeholderToDelete(item);
                                tooglePopUp('isDeleteStakeholderPopUpOpen')();
                            }}
                        />
                    ))}
            </ListContainer>

            {!list.isLoading && !data.items.length && <Label>{`No ${capitalTitle} found`}</Label>}

            {list.isLoading && (
                <div style={{ display: 'flex', marginLeft: '30%' }}>
                    <Loader />
                </div>
            )}

            {popUp.stakeholder && editItem && (
                <EditStakeholder
                    editItem={editItem}
                    categories={list.categoriesByCompany.items}
                    name={title === 'customers' ? 'CUSTOMER' : 'SUPPLIER'}
                    onSubmit={editStakeholder}
                    closePopUp={tooglePopUp('stakeholder')}
                    getCategoriesByCompany={getCategoriesByCompany}
                />
            )}
            {popUp.category && editItem && (
                <EditCategory
                    item={editItem}
                    onSubmit={editCategory}
                    closePopUp={tooglePopUp('category')}
                />
            )}
            {popUp.items && editItem && (
                <EditItem item={editItem} onClose={tooglePopUp('items')} onSubmit={_updateItem} />
            )}
            {popUp.uploadImport && uploadType && (
                <UploadXLSX
                    onUpload={uploadImport}
                    onClose={tooglePopUp('uploadImport')}
                    uploadType={uploadType}
                />
            )}

            {popUp.isDeleteStakeholderPopUpOpen && (
                <DeletePopUp
                    loading={list.isLoading}
                    title={`Are you sure to delete ${stakeholderToDelete?.name}?`}
                    headerTitle="Delete stakeholder"
                    onDelete={() => _deleteItem(stakeholderToDelete)}
                    closePopUp={tooglePopUp('isDeleteStakeholderPopUpOpen')}
                />
            )}
        </Container>
    );
};
export default List;
