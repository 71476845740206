import React from 'react';

type TLoaderProps = {
    className?: string;
};

export const Loader = ({ className }: TLoaderProps) => {
    return (
        <div className={`lds-grid ${className}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
