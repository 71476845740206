import React, { Component } from 'react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface IProps {
    title: string;
    path: string;
    info: number;
}

const MAX_COUNT_TO_DISPLAY = 999;

class ItemList extends Component<IProps & RouteComponentProps> {
    render() {
        const { path, title, info: totalCount } = this.props;

        const isNotCountZero = totalCount !== 0;
        const maxCountToShow =
            totalCount > MAX_COUNT_TO_DISPLAY ? `${MAX_COUNT_TO_DISPLAY}+` : totalCount;

        return (
            <NavLink to={path} style={{ textDecoration: 'none', color: '#565682' }}>
                <Li className={matchPath(this.props.location.pathname, { path }) ? 'active' : ''}>
                    {title}

                    {isNotCountZero && (
                        <Tooltip>
                            <Info>{maxCountToShow}</Info>

                            <TooltipText>{`${totalCount} in total`}</TooltipText>
                        </Tooltip>
                    )}
                </Li>
            </NavLink>
        );
    }
}

export default withRouter(ItemList);

const Li = styled.li`
    height: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'NunitoSans-SemiBold';
    font-size: 20px;
    color: #565682;
    padding-right: 25px;
    padding-left: 105px;
    background-color: #eef2f6;

    &:hover {
        font-family: 'NunitoSans-ExtraBold';
    }
`;
const Info = styled.div`
    width: 36px;
    height: 20px;
    border-radius: 5px;
    background: linear-gradient(180deg, #a665d1 0%, #ffbbcf 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.270132);
    font-family: 'NunitoSans-Bold';
`;

const TooltipText = styled.span`
    visibility: hidden;
    background: linear-gradient(180deg, #a665d1 0%, #ffbbcf 100%);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    font-family: 'NunitoSans-Bold';
    position: absolute;
    font-size: 12px;
    right: 20px;
    z-index: 1;
    white-space: nowrap;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
`;

const Tooltip = styled.div`
    position: relative;

    &:hover {
        ${TooltipText} {
            visibility: visible;
        }
    }
`;
