import React, { FC, useState } from "react";
import styled from "styled-components";

import { GooglePathItem } from "redux/integration/types";

import { Loader } from "components/Other/Loader";

import { Title } from "styles/text";
import { Filter } from "styles/blocks";
import PrimaryButton from "components/PrimaryButton";


import closePng from "assets/images/icons/closeWhite.png";

interface Props {
  data: GooglePathItem[];
  hasMore?: boolean;
  onLoadMore?: () => void;
  onClose:()=>void;
  selectPath: (i: GooglePathItem) => any;
}

const ChooseGooglePath: FC<Props> = ({
  data,
  selectPath,
  hasMore = false,
  onLoadMore,
  onClose
}) => {
  const [loading, setLoading] = useState(false);

  const onChoose = async (e: GooglePathItem) => {
    setLoading(true);
    await selectPath(e);
  };

  const load = () => {
    if (onLoadMore) {
      onLoadMore();
    }
  };

  return (
    <Filter className="fadeIn">
      <Container>
        <Header>
          <HeaderText>
            Specify the directory from which to read the documents
          </HeaderText>
          <Close src={closePng} alt="close" onClick={onClose} />
        </Header>
        <Main>
          <List>
            {loading ? (
              <Loader />
            ) : (
              <>
                {data.map((e) => (
                  <Item onClick={() => onChoose(e)} key={e.id}>
                    <Title>/{e.name}</Title>
                  </Item>
                ))}
                {hasMore && (
                  <PrimaryButton
                    title="Load More"
                    style={styles.buttonOk}
                    onClick={load}
                  />
                )}
              </>
            )}
          </List>
        </Main>
      </Container>
    </Filter>
  );
};

const styles = {
  buttonOk: {
    marginTop: 10,
    paddingTop: 15,
    paddingBottom: 15,
  },
};

export default ChooseGooglePath;

const Container = styled.div`
  width: 600px;
  background-color: #666975;
  z-index: 2;
  font-family: "NunitoSans-Bold";
`;

const Close = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;


const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
  font-size: 18px;
  color: #fff;
`;

const Main = styled.div`
  padding: 30px;
  font-size: 16px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 325px;
  overflow-y: scroll;
`;

const Item = styled.div`
  background: #fff;
  border: 0.4px solid #eeeeee;
  border-top: 0;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;
