import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import Switch from "react-switch";

import { greyColorDark } from "styles/colors";
import { RegularText } from "styles/text";

interface Props {
  label: string;
  disabled?: boolean;
  checked: boolean;
  labelStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  handleChange: (b: boolean) => any;
}

const ItemCheck: FC<Props> = ({
  label,
  checked,
  disabled,
  labelStyle,
  handleChange,
  containerStyle,
}) => {
  return (
    <Container style={containerStyle}>
      <Label style={labelStyle}>{label}</Label>
      <Switch disabled={disabled} onChange={handleChange} checked={checked} />
    </Container>
  );
};

export default ItemCheck;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
// margin-bottom: 20px;

export const Label = styled(RegularText)`
  width: 175px;
  margin-right:12px;
`;

export const Input = styled.p`
  flex: 1;
  font-family: "NunitoSans-SemiBold";
  font-size: 15px;
  color: ${greyColorDark};
  text-indent: 15px;
`;
