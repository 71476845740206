import { Dispatch } from 'redux';
import { AppState } from 'redux/rotReducer';
import {
    API,
    IEditReceiptBody,
    IEditReceiptParams,
    IGetCategoriesParams,
    IGetReceiptData,
    IGetStakeholdersParams,
    ISelectBox,
    IStakeholderType,
    IUseGridBody
} from '../../net/api';
import { EnumReceiptStatus, IReceipt } from '../receipt/types';
import { ReduxActions } from '../types';
import { ReceiptActions } from 'redux/receipt/actions';
import { IContentSchema, IContentSchemaGrid } from './types';
import { IReceiptShemaItem } from 'redux/setting/types';
import moment from 'moment';
import { DateUtils } from '../../utils/dateUtils';

export interface SignUpParams {
    name: string;
    surname: string;
    company: string;
    email: string;
    password: string;
}

export class EditReceiptActions {
    static onLoadEditScene =
        (receipt_id: string, company_id: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            // Clear redux
            dispatch(EditReceiptActions.setEditSceneLoading(true));
            dispatch({ type: 'SET_EDITABLE_RECEIPT', receipt: null });
            dispatch({ type: 'SET_EDIT_CATEGORIES_LIST', data: { items: [] } });
            dispatch({ type: 'SET_EDIT_STAKEHOLDERS_LIST', data: { items: [] } });

            const isReceipts = getState().receipt.receipts.items.length > 0;

            // Step 1
            // Set active company
            await dispatch<any>(ReceiptActions.checkActiveAll(company_id));

            // Step 2
            // Get receipt data
            const receipt: IReceipt = await dispatch<any>(
                EditReceiptActions.getReceiptData(receipt_id, {})
            );

            // Step 3
            // Get all necessary data for edit scene
            Promise.all([
                API.getReceiptContent(receipt_id),
                API.getReceiptSchema(),
                API.getReceiptImageBoxes(receipt_id)
            ])
                .then((values) => {
                    const content = createContent(values[0].data.items);
                    const shema = values[1].data;
                    const boxes = values[2].data.results;

                    const schemas = createContent(shema as any) as any;

                    dispatch({ type: 'SET_ORIGINAL_SHEMA', data: shema });
                    dispatch({ type: 'SET_SCHEMAS', data: schemas });
                    dispatch({
                        type: 'SET_CONTENT',
                        data: content
                    });
                    dispatch({ type: 'SET_RECEIPT_IMAGE_BOXES', data: boxes });
                    dispatch(EditReceiptActions.setEditSceneLoading(false));
                })
                .then(() => {
                    // Step 4
                    // Get lists
                    if (!isReceipts) {
                        dispatch<any>(
                            ReceiptActions.getReceipts({
                                receipt_status: receipt.status,
                                receipt_type: receipt.receipt_type
                            })
                        );
                    }
                    dispatch<any>(
                        EditReceiptActions.getEditStakeholders({ with_external_data: 1 }, receipt)
                    );
                    dispatch<any>(
                        EditReceiptActions.getEditCategories({
                            with_external_data: 1
                        })
                    );
                });
        };

    static setEditSceneLoading = (loading: boolean): ReduxActions => ({
        type: 'SET_EDIT_SCENE_LOADING',
        loading
    });

    static setEditSceneActionLoading = (loading: boolean): ReduxActions => ({
        type: 'SET_EDIT_SCENE_ACTION_LOADING',
        loading
    });

    static editReceipt =
        (receipt: IReceipt, body: IEditReceiptBody, params: IEditReceiptParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            dispatch(EditReceiptActions.setEditSceneLoading(true));
            try {
                try {
                    const rr = await API.validateReceiptContent(receipt.receipt_id);
                    if (rr.data.errors.length > 0) {
                        const { content } = getState().editReceipt;

                        rr.data.errors.forEach((t) => {
                            let ttt = content.findIndex((item) => item.id === t.id);
                            if (ttt === -1) {
                                return;
                            }
                            content[ttt].error = { message: t.message };
                        });
                        dispatch({
                            type: 'SET_CONTENT',
                            data: content
                        });
                        return false;
                    }
                } catch (err) {}

                const res = await API.editReceipt(receipt.receipt_id, body, params);
                dispatch({
                    type: 'UPDATE_RECEIPTS',
                    receipt: res.data
                });
                dispatch<any>(ReceiptActions.updateReceiptCount(receipt, res.data));

                //TODO: make navigation here ?
                return true;
            } catch (error) {
            } finally {
                dispatch(EditReceiptActions.setEditSceneLoading(false));
            }
            return false;
        };

    static onLoadSelectCompanyScene =
        (receipt_id: string, company_id: string) => async (dispatch: Dispatch<ReduxActions>) => {
            // Clear redux
            dispatch(EditReceiptActions.setEditSceneLoading(true));
            dispatch({ type: 'SET_EDITABLE_RECEIPT', receipt: null });

            // Step 1
            // Set active company
            await dispatch<any>(ReceiptActions.checkActiveAll(company_id));

            // Step 2
            // Set active company
            dispatch<any>(
                EditReceiptActions.getReceiptData(receipt_id, {
                    deleteCompanyId: true,
                    receipt_status: EnumReceiptStatus.Unassigned
                })
            ).then(() => {
                dispatch(EditReceiptActions.setEditSceneLoading(false));
            });
        };

    static getEditStakeholders =
        (params: IGetStakeholdersParams, receipt: IReceipt) =>
        async (dispatch: Dispatch<ReduxActions>) => {
            dispatch({ type: 'SET_EDIT_STAKEHOLDERS_LIST', data: { items: [] } });
            dispatch({ type: 'SET_IS_STAKEHOLDERS_LOADING', val: true });
            dispatch({ type: 'SET_EDIT_LIST_LOADING', val: true });

            let type: IStakeholderType =
                receipt.receipt_type === 'receipt' ? 'supplier' : 'customer';

            try {
                const res = await API.getStakeholders(params, type);
                dispatch({ type: 'SET_EDIT_STAKEHOLDERS_LIST', data: res.data });
            } catch (error) {
            } finally {
                dispatch({ type: 'SET_IS_STAKEHOLDERS_LOADING', val: false });
                dispatch({ type: 'SET_EDIT_LIST_LOADING', val: false });
            }
        };

    static loadEditStakeholders =
        (params: IGetStakeholdersParams, receipt: IReceipt) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { stakeholdersList } = getState().editReceipt;
            let type: IStakeholderType =
                receipt.receipt_type === 'receipt' ? 'supplier' : 'customer';
            if (!stakeholdersList.next) {
                return;
            } else {
                params.next = stakeholdersList.next;
            }

            try {
                const res = await API.getStakeholders(params, type);
                dispatch({
                    type: 'SET_EDIT_STAKEHOLDERS_LIST',
                    data: {
                        ...res.data,
                        items: [...res.data.items, ...stakeholdersList.items]
                    }
                });
            } catch (error) {}
        };

    static getEditCategories =
        (params: IGetStakeholdersParams) => async (dispatch: Dispatch<ReduxActions>) => {
            dispatch({ type: 'SET_EDIT_LIST_LOADING', val: true });
            dispatch({ type: 'SET_EDIT_CATEGORIES_LIST', data: { items: [] } });

            try {
                const res = await API.getCategories(params);
                dispatch({ type: 'SET_EDIT_CATEGORIES_LIST', data: res.data });
            } catch (error) {
            } finally {
                dispatch({ type: 'SET_EDIT_LIST_LOADING', val: false });
            }
        };

    static loadEditCategories =
        (params: IGetCategoriesParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { categoriesList } = getState().editReceipt;
            if (!categoriesList.next) {
                return;
            } else {
                params.next = categoriesList.next;
            }

            try {
                const res = await API.getCategories(params);
                dispatch({
                    type: 'SET_EDIT_CATEGORIES_LIST',
                    data: {
                        ...res.data,
                        items: [...res.data.items, ...categoriesList.items]
                    }
                });
            } catch (error) {}
        };

    static getReceiptData =
        (receipt_id: string, params: IGetReceiptData) =>
        async (dispatch: Dispatch<ReduxActions>) => {
            try {
                const res = await API.getReceiptData(receipt_id, params);
                dispatch({ type: 'SET_EDITABLE_RECEIPT', receipt: res.data });
                return res.data;
            } catch (error) {}
        };

    static updateContentItem = (item: IContentSchema): ReduxActions => ({
        type: 'UPDATE_CONTENT_ITEM',
        item
    });

    static updateStakeholderDateFormat = (stakeholderDateFormat?: string): ReduxActions => ({
        type: 'UPDATE_STAKEHOLDER_DATE_FORMAT',
        stakeholderDateFormat
    });

    static createMultiLine =
        (receipt_id: string, content_id: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { content } = getState().editReceipt;
            try {
                const res = await API.createVatLine(receipt_id, content_id);
                const parent = content.find((c) => c.id === res.data.parent_content_id);
                if (parent) {
                    if (parent.children) {
                        parent.children.push(res.data.id);
                    } else {
                        parent.children = [res.data.id];
                    }
                    dispatch({ type: 'UPDATE_CONTENT_ITEM', item: parent });
                }
                dispatch({ type: 'ADD_CONTENT', data: createContent([res.data]) });
            } catch (error) {}
        };

    static deleteMultiLine =
        (receipt_id: string, content_id: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { content } = getState().editReceipt;

            try {
                const res = await API.deleteVatLine(receipt_id, content_id);
                const line = content.find((c) => c.id === content_id);
                if (line) {
                    const parent = content.find((c) => c.id === line.parent_content_id);
                    if (parent && parent.children) {
                        dispatch({
                            type: 'UPDATE_CONTENT_ITEM',
                            item: {
                                ...parent,
                                children: parent.children.filter((c) => c !== content_id)
                            }
                        });
                    }
                    if (line.children) {
                        dispatch({
                            type: 'DELETE_CONTENT',
                            data: [content_id, ...line.children]
                        });
                    }
                }
            } catch (error) {}
        };

    static updateActiveKey(item: boolean) {
        return {
            type: 'UPDATE_ACTIVE_KEY',
            item
        };
    }

    static updateContent =
        (receipt_id: string, content_id: string, value?: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { content, schemas, editableReceipt, activeKey } = getState().editReceipt;
            const item = content.find((c) => c.id === content_id);
            if (item) {
                // TODO: add proper validation
                const schema = schemas.find((sch) => sch.id === item.schema_id);
                if (
                    schema?.type === 'date' &&
                    !moment(item.content?.value, DateUtils.DEFAULT_DATE_FORMAT, true).isValid() &&
                    item.content?.value !== ''
                ) {
                    return;
                }
                try {
                    const res = await API.updateContent(receipt_id, {
                        id: item.id,
                        file: item.file,
                        ...(activeKey
                            ? {
                                  related_content: {
                                      value: item.related_content?.value || ''
                                  }
                              }
                            : {
                                  content: {
                                      value: item.content?.value || ''
                                  }
                              })
                    });
                    const { data } = res;
                    dispatch(EditReceiptActions.updateContentItem(data));
                } catch (error) {}
            }
        };

    static selectBox =
        (receipts_id: string, receipt_content_id: string, body: ISelectBox) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            dispatch(EditReceiptActions.setEditSceneActionLoading(true));

            const { schemas, editableReceipt } = getState().editReceipt;

            try {
                const res = await API.selectBox(receipts_id, receipt_content_id, body);
                const { data } = res;

                dispatch(
                    EditReceiptActions.updateContentItem(
                        normalazeContentValue(
                            [data],
                            schemas,
                            editableReceipt?.stakeholder_date_format as string
                        )[0]
                    )
                );
            } catch (error) {
            } finally {
                dispatch(EditReceiptActions.setEditSceneActionLoading(false));
            }
        };

    static mergeReceipt =
        (receipts_id: string, related_id: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            dispatch(EditReceiptActions.setEditSceneLoading(true));
            const { activeCompany } = getState().user;
            try {
                const res = await API.mergeReceipts(receipts_id, related_id);
                dispatch<any>(
                    EditReceiptActions.onLoadEditScene(receipts_id, activeCompany.company_id)
                );
            } catch (error) {}
        };

    static updateGrid =
        (parts: IContentSchemaGrid[]) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                const receipt_id = getState().editReceipt.editableReceipt!.receipt_id;
                let content = getState().editReceipt.content;
                const res = await API.updateGrid(receipt_id, parts);
                if (content) {
                    const l = content.find((item) => item.schema_id === 'line_items');
                    if (l) {
                        dispatch({
                            type: 'SET_CONTENT',
                            data: filterContent(content, [l.id])
                        });
                    }
                }

                dispatch({ type: 'ADD_NEW_CONTENT_ITEMS', data: createContent(res.data) });
                return true;
            } catch (error) {
                console.error('[mergeReceipt] Err: ', error);
            }

            return false;
        };

    static saveGridAsTemplate =
        (part: IContentSchemaGrid) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const receipt_id = getState().editReceipt.editableReceipt!.receipt_id;
            try {
                await API.saveGridAsTemplate(receipt_id, part);
            } catch (error) {
                console.error('[saveGridAsTemplate] Err: ', error);
            }
        };

    static useTemplateAsGrid =
        (v: IUseGridBody) => async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                const receipt_id = getState().editReceipt.editableReceipt!.receipt_id;
                const { data } = await API.useTemplateAsGrid(receipt_id, v);

                dispatch({ type: 'UPDATE_GRID_TEMPLATE', data });
            } catch (error) {
                console.error('[useTemplateAsGrid] Err: ', error);
            }
        };

    static setSelectedContent(selectedContent?: IContentSchema) {
        return {
            type: 'SET_SELECTED_CONTENT',
            item: selectedContent
        };
    }

    static setSelectedSchema(schema?: IReceiptShemaItem) {
        return {
            type: 'SET_SELECTED_SCHEMA',
            item: schema
        };
    }

    static adddItemWord =
        (category_content: IContentSchema, content: IContentSchema) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                if (!content.content?.value || !category_content.content?.value) {
                    return;
                }

                await API.addItemWord(category_content.content.value, content.content.value);
            } catch (error) {}
        };
}

export function filterContent(arr: IContentSchema[], parent?: string[]): IContentSchema[] {
    const data: IContentSchema[] = [];

    if (!parent || parent.length === 0) {
        return arr;
    }

    let ch = [];
    for (let i = 0, len = arr.length; i < len; i++) {
        let e = arr[i];
        if (
            !parent.includes(e.id) &&
            (!e.parent_content_id || !parent.includes(e.parent_content_id))
        ) {
            data.push(e);
        } else if (e.children?.length) {
            ch.push(...e.children);
        }
    }

    if (ch.length) {
        return filterContent(data, ch);
    }

    return data;
}

export function createContent(arr: IContentSchema[], parent?: string) {
    const data: IContentSchema[] = [];

    arr.forEach((e) => {
        if (parent) {
            e.parent = parent;
        }
        if (e.items) {
            const { items, ...payload } = e;
            items.forEach((item) => {
                if (!payload.children) {
                    payload['children'] = [];
                }
                payload.children.push(item.id);
            });
            const a = createContent(items, payload.id);
            data.push(payload, ...a);
            return;
        }
        data.push(e);
    });

    return data;
}

function normalazeContentValue(content: IContentSchema[], schemas: any[], date_format?: string) {
    const new_content: IContentSchema[] = [];

    content.forEach((item) => {
        const schema = schemas.find((sch) => sch.id === item.schema_id);
        if (schema?.type === 'date' && item?.content?.value) {
            item.content.value = DateUtils.parseDate({
                date: item.content.value,
                fromFormat: date_format
            });
        }

        new_content.push(item);
    });

    return new_content;
}
