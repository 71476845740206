import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { AppState } from 'redux/rotReducer';
import { IReceiptShemaItem } from 'redux/setting/types';
import { IContentSchema } from 'redux/editReceipt/types';
import { EditReceiptActions } from 'redux/editReceipt/actions';

import Item from './Item';
import HeaderData from './HeaderData';
import PrimaryButton from 'components/PrimaryButton';

import closePng from 'assets/images/icons/closeWhite.png';
import { IItem } from 'redux/list/types';
import { ListActions } from 'redux/list/actions';

interface Props {
    receiptId: string;
    multivalue: {
        schema: IReceiptShemaItem | null;
    };
    content: IContentSchema[];
    items: IItem[];
    disabled?: boolean;
    closePopUp: () => void;
    updateContentItem: (item: IContentSchema) => any;
    updateContent: (receiptId: string, content_id: string) => any;
    createMultiLine: (receipt_id: string, content_id: string) => any;
    deleteMultiLine: (receipt_id: string, content_id: string) => any;
    setSelectedContent: (selectedContent?: IContentSchema) => void;
    setSelectedSchema: (schema?: IReceiptShemaItem) => void;
    getItems: () => void;
    addItemWord: (c: IContentSchema, v: IContentSchema) => void;
}

const MultivaluePopUp: FC<Props> = ({
    content,
    items,
    receiptId,
    multivalue,
    closePopUp,
    updateContent,
    deleteMultiLine,
    createMultiLine,
    updateContentItem,
    setSelectedContent,
    setSelectedSchema,
    getItems,
    addItemWord,
    disabled
}) => {
    const { schema } = multivalue;
    const config = useMemo(() => {
        // @ts-ignore
        return schema.items[0].items.filter((el) => !el.hidden);
    }, [schema]);

    const [tableContent, contetnId, openAITableContent] = useMemo(() => {
        const data: IContentSchema[] = [];
        const multi_content = content.find((e) => e.schema_id === schema?.id);
        if (multi_content && multi_content.children) {
            multi_content.children.forEach((child) => {
                const table = content.find((t) => t.id === child);
                if (table && table.children) {
                    table.items = [];
                    table.children.forEach((id) => {
                        const contentItem = content.find((i) => i.id === id);
                        if (contentItem) {
                            table.items?.push(contentItem);
                        }
                    });
                    data.push(table);
                }
            });
        }

        return [data, multi_content?.id, multi_content?.grid_data || []];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    useEffect(() => {
        getItems();
        return () => {
            setSelectedContent();
            setSelectedSchema();
        };
    }, []);

    const onCreate = () => {
        if (contetnId) {
            createMultiLine(receiptId, contetnId);
        }
    };

    const onTimerChange = (content_id: string) => {
        updateContent(receiptId, content_id);
    };

    const onChangeDropHandle = (item: IContentSchema, value: string) => {
        const newItem = {
            ...item,
            content: { ...item.content!, value }
        };
        updateContentItem(newItem);
        updateContent(receiptId, item.id);
    };

    const onChangeHandle = (item: IContentSchema, value: string) => {
        const newItem = {
            ...item,
            content: { ...item.content!, value }
        };
        updateContentItem(newItem);
    };

    const onFoucs = (item: IContentSchema) => {
        setSelectedContent(item);
        setSelectedSchema(config.find((c) => c.id === item.schema_id));
    };

    const onEndList = () => {
        getItems();
    };

    const t: string[] = useMemo(() => {
        return items.map((t) => t.name);
    }, [items]);

    const onAddItemWord = (c: IContentSchema[], v: IContentSchema) => {
        const setting_items = config.find((t) => t.settings?.items);
        if (!setting_items) {
            return;
        }

        const item = c.find((t) => t.schema_id === setting_items.id);

        if (!item) {
            return;
        }

        addItemWord(item, v);
    };

    if (!schema) {
        return <></>;
    }

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderData config={config} />
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                <ContainerIn>
                    <Main>
                        {openAITableContent.map(
                            ({
                                item_number,
                                product_code,
                                description,
                                quantity,
                                unit_of_measurement,
                                unit_price_without_vat,
                                vat_rate,
                                vat,
                                price_per_unit,
                                total_price
                            }) => (
                                <GridDataItemsContainer key={`grid_data_item${item_number}`}>
                                    <GridDataItem>{item_number}</GridDataItem>
                                    <GridDataItem>{product_code}</GridDataItem>
                                    <GridDataItem>{description}</GridDataItem>
                                    <GridDataItem>{quantity}</GridDataItem>
                                    <GridDataItem>{unit_of_measurement}</GridDataItem>
                                    <GridDataItem>{unit_price_without_vat}</GridDataItem>
                                    <GridDataItem>{vat_rate}</GridDataItem>
                                    <GridDataItem>{vat}</GridDataItem>
                                    <GridDataItem>{price_per_unit}</GridDataItem>
                                    <GridDataItem>{/*TODO*/}</GridDataItem>
                                    <GridDataItem>{total_price}</GridDataItem>
                                </GridDataItemsContainer>
                            )
                        )}

                        {tableContent.map((item) => (
                            <Item
                                disabled={disabled}
                                onEndList={onEndList}
                                onAddItemWord={onAddItemWord}
                                items={t}
                                item={item}
                                key={item.id}
                                config={config}
                                onTimerChange={onTimerChange}
                                onChangeHandle={onChangeHandle}
                                onChangeDropHandle={onChangeDropHandle}
                                onFoucs={onFoucs}
                                onDelete={() => deleteMultiLine(receiptId, item.id)}
                            />
                        ))}
                    </Main>
                </ContainerIn>

                {!disabled && (
                    <Bottom>
                        <PrimaryButton
                            title="Add line"
                            onClick={onCreate}
                            style={{ width: 90, height: 30 }}
                        />
                    </Bottom>
                )}
            </Container>
        </Filter>
    );
};

const mapStateToProps = (state: AppState) => ({
    content: state.editReceipt.content,
    items: state.list.items.items
});

const mapDispatchToProps = {
    updateContent: EditReceiptActions.updateContent,
    deleteMultiLine: EditReceiptActions.deleteMultiLine,
    createMultiLine: EditReceiptActions.createMultiLine,
    updateContentItem: EditReceiptActions.updateContentItem,
    setSelectedContent: EditReceiptActions.setSelectedContent,
    setSelectedSchema: EditReceiptActions.setSelectedSchema,
    getItems: ListActions.getItems,
    addItemWord: EditReceiptActions.adddItemWord
};

export default connect(mapStateToProps, mapDispatchToProps)(MultivaluePopUp);

const Filter = styled.div`
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.7);

    overflow: auto;
    z-index: 100;
`;

const Container = styled.div`
    height: 100%;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const ContainerIn = styled.div`
    max-height: 275px;
    min-height: 100px;
    overflow: auto;
`;

const Header = styled.div`
    padding-left: 15px;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    min-height: 30px;
`;

const Close = styled.img`
    width: 15px;
    height: 15px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 15px;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: center;
    padding: 15px 0;
`;

const GridDataItemsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
`;

const GridDataItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 300px;
    border: 1px solid #a665d1;
    font-size: 14px;
    color: white;
    font-family: 'NunitoSans-SemiBold';
`;
