import { AuthActionTypes, AuthState } from "./types";

const initialState: AuthState = {
  isOnLoad: true,
  loading: false,
  isAuth: false,
  email: "",
  password: "",
  infoPopUp: [],
};

const authReducer = (
  state = initialState,
  actions: AuthActionTypes
): AuthState => {
  switch (actions.type) {
    case "SET_IS_ON_LOAD":
      return {
        ...state,
        isOnLoad: actions.isOnLoad,
      };
    case "SET_IS_AUTH":
      return {
        ...state,
        isAuth: actions.isAuth,
      };
    case "SET_AUTH_LOADING":
      return {
        ...state,
        loading: actions.loading,
      };
    case "SET_AUTH_DATA":
      return {
        ...state,
        email: actions.email,
        password: actions.password,
      };
    case "USER_LOGOUT":
      return {
        ...state,
        isOnLoad: false,
      };

    case "SET_INFO_POPUP":
      return {
        ...state,
        infoPopUp: state.infoPopUp.concat(actions.data),
      };
    case "DELETE_INFO_POPUP":
      return {
        ...state,
        infoPopUp: state.infoPopUp.filter((i) => i.id !== actions.id),
      };
    default:
      return state;
  }
};

export default authReducer;
