import React, { FC } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { IReceiptEmailInfo } from 'redux/receipt/types';

import { Loader } from 'components/Other/Loader';

import { greyColor, primaryColor, greyColorDark, greyColorBright } from 'styles/colors';
import { SmallText } from 'styles/text';

import emailIcon from 'assets/images/icons/email.png';
import closePng from 'assets/images/icons/closeWhite.png';
import clockIcon from 'assets/images/icons/clock.png';
import profileIcon from 'assets/images/icons/myProfile.png';
import documentIcon from 'assets/images/icons/document.png';
import deleteIcon from 'assets/images/icons/trash.png';
import textIcon from 'assets/images/icons/text.png';

interface Props {
    data: null | IReceiptEmailInfo;
    closePopUp: () => void;
}

const EmailInfo: FC<Props> = ({ data, closePopUp }) => {
    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderBlock>
                        <EmailIcon src={emailIcon} alt="email" />
                        <HeaderText>Email detail</HeaderText>
                    </HeaderBlock>
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                {!data ? (
                    <LoaderContainer>
                        <Loader />
                    </LoaderContainer>
                ) : (
                    <Main>
                        <Block>
                            <InfoBlock>
                                <Icon src={emailIcon} alt="infoe email" />
                                <SmallText>{data.subject}</SmallText>
                            </InfoBlock>
                            <InfoBlock>
                                <Icon src={profileIcon} alt="profile" />
                                <SmallText>{data.from}</SmallText>
                            </InfoBlock>
                            <InfoBlock>
                                <Icon src={clockIcon} alt="clock" />
                                <SmallText>
                                    {moment(data.arrived_at).format('llll')} ({' '}
                                    {moment(data.arrived_at).fromNow()} )
                                </SmallText>
                            </InfoBlock>
                        </Block>

                        <Block>
                            <LabelBlock>
                                <Icon src={documentIcon} alt="docs" />
                                <SmallText>Documents</SmallText>
                                <DocCount>
                                    <SmallText color={greyColorDark}>{data.files.length}</SmallText>
                                </DocCount>
                            </LabelBlock>

                            {data.files.map((file) => {
                                return (
                                    <DocWrapper key={file.receipt_id}>
                                        <DocBlock>
                                            <DocTag />
                                            <SmallText>{file.original_file_name}</SmallText>
                                        </DocBlock>
                                        {/* <DeleteIcon src={deleteIcon} alt="delete" /> */}
                                    </DocWrapper>
                                );
                            })}
                        </Block>

                        <Block>
                            <LabelBlock>
                                <Icon src={textIcon} alt="text" />
                                <SmallText>Message</SmallText>
                            </LabelBlock>
                            <SmallText>{data.text}</SmallText>
                        </Block>
                    </Main>
                )}
            </Container>
        </Filter>
    );
};

export default EmailInfo;

const Filter = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: auto;
    z-index: 100;
`;

const Container = styled.div`
    width: 40%;
    height: 100%;
    overflow: auto;
    background-color: ${greyColorDark};
    z-index: 2;
    font-family: 'NunitoSans-Bold';
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid ${greyColor};
`;

const Main = styled.div``;

const Block = styled.div`
    padding: 20px;
    border-bottom: 1px solid ${greyColor};
`;

const InfoBlock = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const HeaderBlock = styled.div`
    display: flex;
    align-items: center;
`;

const EmailIcon = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    filter: brightness(2);
    margin-right: 10px;
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 15px;
    height: 15px;
    cursor: pointer;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    filter: brightness(2);
    margin-right: 10px;
`;

const DocCount = styled.div`
    background-color: ${greyColorBright};
    border-radius: 10px;
    text-align: center;
    min-width: 32px;
    padding: 3px;
    line-height: 1;
    flex: 0 0 auto;
    margin-left: 10px;
`;

const DocWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const DocBlock = styled.div`
    display: flex;
    align-items: center;
`;

const DocTag = styled.span`
    background-color: ${primaryColor};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
`;

const DeleteIcon = styled.img`
    width: 15px;
    height: 15px;
`;

const LabelBlock = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;

const LoaderContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;
