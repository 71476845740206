import {
  CLEAR_GOOGLE_PATH_LIST,
  IntegrationActionTypes,
  IntegrationState,
  UPDATE_GOOGLE_PATH_LIST,
} from "./types";

const initialState: IntegrationState = {
  googlePathList: { items: [], next: "" },
};

const integrationReducer = (
  state = initialState,
  actions: IntegrationActionTypes
): IntegrationState => {
  switch (actions.type) {
    case "SET_GOOGLE_PATH_LIST":
      return {
        ...state,
        googlePathList: actions.data,
      };
    case UPDATE_GOOGLE_PATH_LIST:
      return {
        ...state,
        googlePathList: {
          ...actions.data,
          items: [...state.googlePathList.items, ...actions.data.items],
        },
      };
    case CLEAR_GOOGLE_PATH_LIST: {
      return {
        ...state,
        googlePathList: { ...initialState.googlePathList },
      };
    }
    default:
      return state;
  }
};

export default integrationReducer;
