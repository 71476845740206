import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import auth from "./auth/reducer";
import user from "./user/reducer";
import list from "./list/reducer";
import admin from "./admin/reducer";
import receipt from "./receipt/reducer";
import setting from "./setting/reducer";
import invitation from "./invitation/reducer";
import editReceipt from "./editReceipt/reducer";
import integration from "./integration/reducer";

export const appReducer = combineReducers({
  auth,
  user,
  list,
  admin,
  receipt,
  setting,
  invitation,
  editReceipt,
  integration,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
