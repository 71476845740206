import React, { FC } from 'react';
import { EnumReceiptStatus, EnumReceiptType } from 'redux/receipt/types';
import styled from 'styled-components';

interface Props {
    isArchive: boolean;
    receipt_type: EnumReceiptType | undefined;
    closePopUp: () => void;
    convertReceipt: () => any;
    chnageSelectedFlag: (flag: boolean) => any;
    changeStatusHandle: (status: EnumReceiptStatus) => any;
}

const Setting: FC<Props> = ({
    isArchive,
    receipt_type,
    closePopUp,
    convertReceipt,
    chnageSelectedFlag,
    changeStatusHandle
}) => {
    const onFlag = async (flag: boolean) => {
        await chnageSelectedFlag(flag);
        closePopUp();
    };

    const onConvert = async () => {
        await convertReceipt();
        closePopUp();
    };

    const onArchivePress = async () => {
        if (isArchive) {
            await changeStatusHandle(EnumReceiptStatus.Review);
        } else {
            await changeStatusHandle(EnumReceiptStatus.Archive);
        }
        closePopUp();
    };

    return (
        <>
            <Filter onClick={closePopUp} />
            <Container className="fadeIn">
                <Item onClick={onConvert}>
                    Convert to {receipt_type === 'invoice' ? 'Receipt' : 'Invoice'}{' '}
                </Item>
                <Item onClick={onArchivePress}>{isArchive ? 'Unarchive' : 'Move to Archive'}</Item>
                <Item onClick={() => onFlag(true)}>Flag</Item>
                <Item style={{ marginBottom: 0 }} onClick={() => onFlag(false)}>
                    Unflag
                </Item>
                <Triangle />
            </Container>
        </>
    );
};

export default Setting;

const Filter = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
`;

const Container = styled.div`
    position: absolute;
    top: 30px;
    left: 55px;
    width: 143px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #9191af;
    padding: 15px;
    border-radius: 5px;
    z-index: 11;
`;
const Item = styled.div`
    font-size: 15px;
    color: #fff;
    font-family: 'NunitoSans-SemiBold';
    margin-bottom: 8px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
const Triangle = styled.div`
    position: absolute;
    top: -5px;
    left: 10px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #9191af;
`;
