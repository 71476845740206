import React, { FC } from 'react'
import styled from 'styled-components'

import signIcon from '../../assets/images/icons/sign.png'

type IProps = {
   name: string
   active: boolean
   onPress: Function
}

export const WhiteCheckBox: FC<IProps> = ({
   name,
   active,
   onPress
}) => {
   return (
      <Container onClick={() => onPress()}>
         {active && <Sign src={signIcon} />}
         <Input 
            type='checkbox' 
            name={name} 
         /> 
      </Container>
   )
}

const Container = styled.div`
   position: relative;
   width: 25px;
   height: 25px;
   border: 1px solid #FFF;
   border-radius: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
`
const Sign = styled.img`
   width: 15px;
   height: 15px;
`
const Input = styled.input`
   position: absolute;
   width: 100%;
   height: 100%;
   opacity: 0;
`