import React, { FC } from 'react';
import { IReceiptShemaItem } from 'redux/setting/types';
import styled from 'styled-components';

import { primaryColor } from 'styles/colors';
import { Mark } from 'styles/text';

interface Props {
    config: IReceiptShemaItem[];
}

const HeaderData: FC<Props> = ({ config }) => {
    return (
        <StyledBlock>
            {config.map((l) => (
                <StyledItem style={{ width: 100 / config.length + '%' }} key={l.id}>
                    {l.label}
                    {l.constraints.required && <Mark> *</Mark>}
                </StyledItem>
            ))}
            <StyledEmpty />
        </StyledBlock>
    );
};

export default HeaderData;

const StyledBlock = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const StyledItem = styled.div`
    flex: 1;
    font-size: 14px;
    color: white;
    font-family: 'NunitoSans-SemiBold';
    padding-bottom: 6px;
    border-bottom: 1px solid ${primaryColor};
`;

const StyledEmpty = styled.div`
    width: 50px;
`;
