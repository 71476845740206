import styled from "styled-components/macro";

export const Inner = styled.div`
  display: flex;
`;

export const ImageContainer = styled.div`
  max-height: calc(100vh - 131px);
  max-width: 700px;
  min-width: 400px;
  overflow: auto;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const DetailContainer = styled.div`
  flex: 1;
  max-width: 400px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
`;
