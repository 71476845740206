import { Auth } from 'aws-amplify';

import { API, IEditCompanyUser, IGetUsersByCompanyParams, IUpdateUserParams } from '../../net/api';
import { Dispatch } from 'redux';
import { ReduxActions } from '../types';
import { ListActions } from 'redux/list/actions';
import { AssistantNet, AssistantNetEvent } from 'net/assistantNet';
import { EnumLocalStorageItems, ICompany, ICompanyUser } from './types';
import { AppState } from 'redux/rotReducer';
import { ReceiptActions } from 'redux/receipt/actions';

export class UserActions {
    static fetchData = () => async (dispatch: Dispatch<ReduxActions>) => {
        dispatch({ type: 'SET_USER_LOADING', loading: true });
        try {
            await Promise.all([
                dispatch<any>(UserActions.getUser()),
                dispatch<any>(UserActions.getCompanies())
            ]);
            await dispatch<any>(ReceiptActions.getCounts());
        } catch (err) {
        } finally {
            dispatch({ type: 'SET_USER_LOADING', loading: false });
        }
    };

    static getUser = () => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.getUser();
            dispatch({ type: 'SET_PROFILE', profile: res.data });
            dispatch({
                type: 'SET_UNASSIGNED_LIST',
                data: {
                    total_unassigned_invoices: res.data.total_unassigned_invoices,
                    total_unassigned_receipts: res.data.total_unassigned_receipts
                }
            });
        } catch (error) {}
    };

    static updateUser = (params: IUpdateUserParams) => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.updateUser(params);
            dispatch({ type: 'SET_PROFILE', profile: res.data });
        } catch (error) {}
    };

    static getCompanies = () => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.getCompanies();
            // if (res.data.items.length < 1) {
            //   dispatch<any>(AuthActions.logOut());
            //   return;
            // }
            dispatch({ type: 'SET_COMPANIES', companies: res.data });

            const active_all =
                localStorage.getItem(EnumLocalStorageItems.Active_all_companies) === 'true';
            if (active_all && res.data.items.length > 1) {
                dispatch({ type: 'SET_ACTIVE_ALL_COMPANIES', active: true });
            }

            const active_company_id = localStorage.getItem(EnumLocalStorageItems.Active_company);
            const active_company = res.data.items.find((c) => c.company_id === active_company_id);
            if (active_company_id && active_company) {
                await dispatch<any>(UserActions.setActiveCompany(active_company));
            } else {
                await dispatch<any>(UserActions.setActiveCompany(res.data.items[0]));
            }
        } catch (error) {}
    };

    static deleteCompany = (company_id: string) => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.deleteCompany(company_id);
            dispatch({ type: 'DELETE_COMPANY', company_id });
        } catch (error) {}
    };

    static deleteCompanyEvent =
        (company_id: string) => async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            const { activeCompany } = getState().user;
            if (activeCompany.company_id !== company_id) {
                dispatch({ type: 'DELETE_COMPANY', company_id });
            }
        };

    static createCompany =
        (company_name: string, code: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { companies } = getState().user;

            try {
                const res = await API.createCompany(company_name, code);
                dispatch({ type: 'CREATE_COMPANY', company: res.data });
                if (companies.items.length === 0) {
                    dispatch<any>(UserActions.toggleActiveCompany(res.data));
                }
                return '';
            } catch (error) {
                return 'Something went wrong';
            }
        };

    static setActiveCompany =
        (company: ICompany) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { activeCompany } = getState().user;
            if (activeCompany.company_id === company.company_id) {
                return;
            }
            const currentSession = await Auth.currentSession();
            API.instance.company_id = company.company_id;
            if (!AssistantNet.instance.isConnected) {
                AssistantNet.instance.connect(
                    currentSession.getAccessToken().getJwtToken(),
                    company.company_id
                );
            } else {
                AssistantNet.instance.send(AssistantNetEvent.company, {
                    company_id: company.company_id
                });
            }
            dispatch({ type: 'SET_ACTIVE_COMPANY', company });
            localStorage.setItem(EnumLocalStorageItems.Active_company, company.company_id);
        };

    static reconectEvent = () => async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
        const { activeCompany } = getState().user;
        if (activeCompany.company_id) {
            dispatch<any>(UserActions.setActiveCompany(activeCompany));
        }
    };

    static getUsersByCompany =
        () => async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            const { activeCompany, activeAllCompanies } = getState().user;
            let params: IGetUsersByCompanyParams = {};

            if (activeCompany.role !== 'basic') {
                if (activeAllCompanies) {
                    params.selected = 'ALL';
                }

                try {
                    const res = await API.getUsersByCompany(params);
                    dispatch({ type: 'SET_USERS_BY_COMPANY', data: res.data });
                } catch (error) {}
            }
        };

    static editCompanyUser =
        (user_id: string, params: IEditCompanyUser, company_id?: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            try {
                const res = await API.editCompanyUser(user_id, params, company_id);
                dispatch({ type: 'EDIT_USER_BY_COMPANY', user: res.data });
            } catch (error) {
                return error;
            }
        };

    static deleteCompanyUser =
        (user: ICompanyUser) => async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            try {
                await API.deleteCompanyUser(user.user_id, user.company_id);
                dispatch({ type: 'DELETE_USER_FROM_COMPANY', user: user });
            } catch (error) {
                return error;
            }
        };

    static toggleActiveCompany =
        (company: ICompany) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { activeCompany, activeAllCompanies } = getState().user;
            if (activeAllCompanies) {
                return;
            }
            dispatch<any>(UserActions.restoreCompanyData());
            if (activeCompany.company_id === company.company_id) {
                return;
            }
            dispatch<any>(UserActions.setActiveCompany(company));
        };

    static setActiveAllCompanies = (active: boolean) => (dispatch: Dispatch<ReduxActions>) => {
        localStorage.setItem(EnumLocalStorageItems.Active_all_companies, active ? 'true' : 'false');
        dispatch({
            type: 'SET_ACTIVE_ALL_COMPANIES',
            active
        });
        dispatch<any>(UserActions.restoreCompanyData());
    };

    static restoreCompanyData = () => async (dispatch: Dispatch<ReduxActions>) => {
        dispatch<any>(ListActions.restoreLists());
        dispatch<any>(ReceiptActions.getCounts());
        dispatch({ type: 'SET_RECEIPTS', receipts: { items: [] } });
        dispatch({ type: 'SET_USERS_BY_COMPANY', data: { items: [] } });
    };
}
