import React, { FC, useEffect, useState } from 'react';

import { IntegrationGoogleProps } from './index';

import SmallLoader from 'components/SmallLoader';
import PrimaryHeader from 'components/PrimaryHeader';
import PrimaryButton from 'components/PrimaryButton';
import ChooseGooglePath from 'components/ChooseGooglePath';

import { Title } from 'styles/text';
import { Box, Main } from './styles';
import { Container } from 'styles/blocks';
import { GooglePathItem } from 'redux/integration/types';
import { EnumIntegrationServiceType } from 'net/api';

const styles = {
    buttonOk: {
        width: 100,
        height: 35,
        marginTop: 30
    }
};

const IntegrationGoogle: FC<IntegrationGoogleProps> = ({
    history,
    location,
    match,
    hasMoreFolders,
    googlePathList,
    google_folder_name,
    dropbox_folder_name,
    google_active,
    dropbox_active,
    postServiceImportFolder,
    getServiceImportListFolders
}) => {
    const [loading, setLoading] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [selectedFolder, setSelectedFolder] = useState('');

    const params = match.params as any;
    const state = location.state as any;

    //TODO: change default value?
    const [service_type, setServiceType] = useState<EnumIntegrationServiceType>(
        params.service_type as any
    );

    useEffect(() => {
        switch (service_type) {
            case EnumIntegrationServiceType.DROPBOX:
                if (dropbox_active) {
                    setServiceType(EnumIntegrationServiceType.DROPBOX);
                    setTitle('Integration Dropbox');
                    setSelectedFolder(dropbox_folder_name);
                }
                break;
            case EnumIntegrationServiceType.GOOGLE:
                if (google_active) {
                    setServiceType(EnumIntegrationServiceType.GOOGLE);
                    setTitle('Integration Google');
                    setSelectedFolder(google_folder_name);
                }
                break;

            default:
                history.replace('/integration');
                return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service_type, dropbox_active, google_active, google_folder_name, dropbox_folder_name]);

    useEffect(() => {
        (async () => {
            switch (service_type) {
                case EnumIntegrationServiceType.DROPBOX:
                case EnumIntegrationServiceType.GOOGLE:
                    break;
                default:
                    history.replace('/integration');
                    return;
            }

            if (state && state.auth_response) {
                const data = state.auth_response;
                setLoading(true);
                if (data.success) {
                    setPopUp(true);
                } else {
                    setText('Connection error!');
                }
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (popUp) {
            getServiceImportListFolders(service_type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popUp, service_type]);

    const selectPath = async (p: GooglePathItem) => {
        await postServiceImportFolder(p, service_type);
        setPopUp(false);
    };

    const onLoadMoreFoldres = () => {
        getServiceImportListFolders(service_type, true);
    };

    return (
        <Container>
            <PrimaryHeader
                title={title}
                margin={`0 0 0 0`}
                onClick={() => history.push('/admin')}
            />
            <Main>
                {loading ? (
                    <SmallLoader />
                ) : (
                    <Box>
                        <Title>{text}</Title>
                        <Title>
                            {selectedFolder
                                ? `Selected directory from which we can read documents: ${selectedFolder}`
                                : 'Please select directory from which we can read documents'}
                        </Title>

                        <div style={{ display: 'flex' }}>
                            <PrimaryButton
                                title="OK"
                                style={{ ...styles.buttonOk, marginRight: 12 }}
                                onClick={() => history.push('/integration')}
                            />
                            <PrimaryButton
                                title="Select Folder"
                                style={styles.buttonOk}
                                onClick={() => setPopUp(true)}
                            />
                        </div>
                    </Box>
                )}
            </Main>
            {popUp && (
                <ChooseGooglePath
                    data={googlePathList}
                    selectPath={selectPath}
                    hasMore={hasMoreFolders}
                    onLoadMore={onLoadMoreFoldres}
                    onClose={() => setPopUp(false)}
                />
            )}
        </Container>
    );
};

export default IntegrationGoogle;
