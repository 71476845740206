import React, { CSSProperties, FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { ErrorInput } from 'styles/text';

import { redColor, greyColorDark, greyColor } from '../../styles/colors';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    note?: string;
    label?: string;
    mark?: boolean;
    error?: string;
    touched?: boolean;
    labelStyle?: CSSProperties;
    containerStyle?: CSSProperties;
}

const InputLabel: FC<Props> = ({
    note,
    label,
    mark,
    labelStyle,
    containerStyle,
    error,
    touched,
    ...rest
}) => {
    return (
        <Container style={containerStyle}>
            {label && (
                <Label style={labelStyle}>
                    {label} {mark && <Mark>*</Mark>}
                </Label>
            )}
            <Block>
                <Input autoComplete="off" {...rest} />
                {error && touched && <ErrorInput>{error}</ErrorInput>}
                {!error && note && <Note>{note}</Note>}
            </Block>
        </Container>
    );
};

export default InputLabel;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Block = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: #fff;
    margin-top: 7px;
    min-width: 140px;
`;

const Mark = styled.span`
    color: ${redColor};
`;

const Input = styled.input`
    height: 35px;
    background: white;
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    outline: none;
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: ${greyColorDark};
    text-indent: 15px;
`;

const Note = styled.p`
    position: absolute;
    bottom: -16px;
    font-size: 10px;
    color: ${greyColor};
    margin-left: 24px;
    font-family: 'NunitoSans-ExtraBold';
`;
