import React from "react";
import styled from "styled-components/macro";

import appStoreIcon from "assets/images/icons/appStore.png";
import playStoreIcon from "assets/images/icons/playStore.png";
import phoneImg from "assets/images/phonePicture.png";

const GetApp = () => {
  return (
    <Container>
      <Content>
        <Label>Get the Free App!</Label>
        <Text>
          Our FREE apps have been designed
          <br />
          to give you an easy,secure way to submit
          <br />
          receipts and invoices.
        </Text>
        <Block>
          <Button style={{ marginRight: "44px" }}>
            <Icon src={appStoreIcon} />
            <ButtonContent>
              <ButtonTitle>Download on the</ButtonTitle>
              <ButtonText>App Store</ButtonText>
            </ButtonContent>
          </Button>
          <Button>
            <Icon src={playStoreIcon} />
            <ButtonContent style={{ alignItems: "flex-start" }}>
              <ButtonTitle>GET IT ON</ButtonTitle>
              <ButtonText>Google Play</ButtonText>
            </ButtonContent>
          </Button>
        </Block>
      </Content>
      <PhoneImage src={phoneImg} />
    </Container>
  );
};

export default GetApp;

const Container = styled.div`
  display: inline-flex;
  min-width: 651px;
  height: 420px;
  background: #ffffff;
  border-radius: 16px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
`;
const Label = styled.label`
  font-size: 20px;
  color: #565682;
  font-family: "NunitoSans-SemiBold";
  margin-top: 82px;
  margin-bottom: 30px;
`;
const Text = styled.p`
  text-align: center;
  font-size: 18px;
  color: #9191af;
  font-family: "NunitoSans-SemiBold";
  margin-bottom: 74px;
`;
const Block = styled.div`
  display: flex;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  height: 50px;
  background: #565682;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #43436b;
  }
`;
const Icon = styled.img`
  width: 26px;
  height: 24px;
  margin-left: 10px;
  margin-right: 8px;
`;
const PhoneImage = styled.img`
  width: 250px;
  height: 295px;
  margin-top: 62px;
  margin-left: 16px;
`;
const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonTitle = styled.p`
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-family: "NunitoSans-Regular";
`;
const ButtonText = styled.p`
  text-align: center;
  font-size: 12px;
  color: #fff;
  font-family: "NunitoSans-Bold";
`;
