import React from 'react';
import styled from 'styled-components/macro';
import { nanoid } from 'nanoid';

import { ICoordinates } from './index';
import { SelectedArea } from './Editor';
import { greenColor, greyColor, greyColorBright, greyColorDark } from 'styles/colors';
import { IContentSchemaGrid } from 'redux/editReceipt/types';

import linkImg from 'assets/images/icons/link.png';
import arrow from 'assets/images/icons/arrow-down-white.png';
import { IUseGridBody } from 'net/api';

export interface IGridProps {
    available_template?: boolean;
    labels: IGridLabel[];
    grid?: IContentSchemaGrid;
    img_width: number;
    img_height: number;
    naturalWidth: number;
    naturalHeight: number;
    document: string;
    gridTemplate?: IContentSchemaGrid;
    onSaveAsTemplate: (part: IContentSchemaGrid) => void;
    onUseTemplate: (v: IUseGridBody) => void;
}

interface IGridItem {
    id: string;
    position: ICoordinates;
    tx: ICoordinates;
    hidden?: boolean;
    label?: {
        value: string;
        label: string;
    };
}

export interface IGridLabel {
    value: string;
    label: string;
    selected?: boolean;
}

interface IGridState {
    box: ICoordinates;
    tx: ICoordinates;
    line: {
        position: ICoordinates;
        delete?: boolean;
    };
    columns: IGridItem[];
    rows: IGridItem[];
    hovered_bar: STATUS_BAR;
    visible_labels: boolean;
    visible_grid_actions: boolean;
    selected_label: IGridItem | undefined;
    mode: MODE;
    is_new_grid: boolean;
    labels: IGridLabel[];
}

const LINE_OFFSET = 0;
const LINE_SIZE = 2;
const NN = 6;

enum STATUS {
    Empty,
    Move_Area,
    Move_Line,
    Selection,
    Resize_Left,
    Resize_Right,
    Resize_Top,
    Resize_Bottom
}

enum STATUS_BAR {
    Empty,

    LEFT_BAR,
    TOP_BAR
}

enum GRID_ACTION {
    Delete_All_Rows,
    Delete_All_Columns,
    Delete_Grid,
    Use_Template,
    Save_Template
}

enum MODE {
    View,
    Edit
}

export class Grid extends React.PureComponent<IGridProps, IGridState> {
    state: IGridState = {
        is_new_grid: false,
        mode: MODE.Edit,
        tx: {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        },
        box: {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        },
        line: {
            position: { left: 0, top: 0, width: 0, height: 0 }
        },
        columns: [],
        rows: [],
        visible_labels: false,
        visible_grid_actions: false,
        selected_label: undefined,
        labels: [],
        hovered_bar: STATUS_BAR.Empty
    };

    selected_area = new SelectedArea();

    container_image_ref = React.createRef<any>();
    container_area_ref = React.createRef<any>();
    container_ref = React.createRef<any>();

    area_top_resize_ref = React.createRef<any>();
    area_left_resize_ref = React.createRef<any>();
    area_right_resize_ref = React.createRef<any>();
    area_bottom_resize_ref = React.createRef<any>();

    top_bar = React.createRef<any>();
    left_bar = React.createRef<any>();

    status = STATUS.Empty;

    componentDidMount() {
        this.subscribeEvents();

        const { grid } = this.props;

        if (grid) {
            this.setupGrid(grid);
        } else {
            const labels = this.props.labels.map((item) => ({ ...item }));
            this.setState({ mode: MODE.Edit, is_new_grid: true, labels });
        }
    }

    componentWillUnmount() {
        this.unSubscribeEvents();
    }

    componentDidUpdate(prevProps: IGridProps) {
        if (this.props.gridTemplate !== prevProps.gridTemplate && this.props.gridTemplate) {
            this.setupGrid(this.props.gridTemplate);
        }
    }

    setupGrid(grid: IContentSchemaGrid) {
        let labels = this.props.labels.map((item) => ({ ...item }));

        let columns = grid.columns.map((col) => {
            let position = this.convertFromTextract({
                top: grid.box.top,
                left: col.left_position,
                width: 0,
                height: grid.box.height
            });
            // position.width = LINE_SIZE;

            let label;

            if (col.schema_id) {
                let _labelIndex = labels.findIndex((lb) => lb.value === col.schema_id);

                if (_labelIndex !== -1) {
                    label = {
                        value: labels[_labelIndex].value,
                        label: labels[_labelIndex].label
                    };
                    labels[_labelIndex].selected = true;
                }
            }

            return {
                label,
                id: nanoid(),
                position,
                hidden: !(
                    col.left_position > grid.box.left &&
                    col.left_position < grid.box.left + grid.box.width
                ),
                tx: {
                    top: grid.box.top,
                    left: col.left_position,
                    width: 0,
                    height: grid.box.height
                }
            };
        });

        let rows = grid.rows.map((row) => {
            let position = this.convertFromTextract({
                top: row.top_position,
                left: grid.box.left,
                width: grid.box.width,
                height: 0
            });
            // position.height = 0;
            return {
                id: nanoid(),
                position,
                hidden: !(
                    row.top_position > grid.box.top &&
                    row.top_position < grid.box.top + grid.box.height
                ),
                tx: {
                    top: row.top_position,
                    left: grid.box.left,
                    width: grid.box.width,
                    height: 0
                }
            };
        });
        this.setState(
            {
                box: this.convertFromTextract(grid.box),
                tx: grid.box,
                columns: columns,
                rows: rows,
                labels
            },
            () => {
                const elemRect = this.container_image_ref.current.getBoundingClientRect();
                this.selected_area.width = this.state.box.width;
                this.selected_area.height = this.state.box.height;
                this.selected_area.startPointX = this.state.box.left + elemRect.left;
                this.selected_area.startPointY = this.state.box.top + elemRect.top;
                this.selected_area.offsetStartPointX = this.state.box.left;
                this.selected_area.offsetStartPointY = this.state.box.top;
                this.selected_area.scrollOffsetY = elemRect.top;
            }
        );
    }

    onContextMenu = (e: any) => {
        e.preventDefault();
    };

    subscribeEvents() {
        this.container_image_ref.current.addEventListener('mousedown', this.onMouseDown);
        this.container_image_ref.current.addEventListener('mouseup', this.onMouseUp);
        this.container_image_ref.current.addEventListener('mousemove', this.onMoveMouse);
        this.container_image_ref.current.addEventListener('contextmenu', this.onContextMenu);

        window.addEventListener('resize', this.onResize);

        this.container_area_ref.current.addEventListener('mouseleave', this.onMouseLeaveArea);
    }

    unSubscribeEvents() {
        this.container_image_ref.current.removeEventListener('mouseup', this.onMouseUp);
        this.container_image_ref.current.removeEventListener('mousemove', this.onMoveMouse);
        this.container_image_ref.current.removeEventListener('mousedown', this.onMouseDown);
        this.container_image_ref.current.removeEventListener('contextmenu', this.onContextMenu);
        this.container_area_ref.current.removeEventListener('mouseleave', this.onMouseLeaveArea);

        window.removeEventListener('resize', this.onResize);
    }

    onMouseLeaveArea = () => {
        if (this.state.hovered_bar !== STATUS_BAR.Empty) {
            this.setState({ hovered_bar: STATUS_BAR.Empty });
        }
    };

    onResize = () => {
        this.setState(
            {
                box: this.convertFromTextract(this.state.tx),
                rows: this.state.rows.map((row) => ({
                    ...row,
                    position: this.convertFromTextract(row.tx)
                })),
                columns: this.state.columns.map((col) => ({
                    ...col,
                    position: this.convertFromTextract(col.tx)
                }))
            },
            () => {
                const elemRect = this.container_image_ref.current.getBoundingClientRect();
                this.selected_area.width = this.state.box.width;
                this.selected_area.height = this.state.box.height;
                this.selected_area.startPointX = this.state.box.left + elemRect.left;
                this.selected_area.startPointY = this.state.box.top + elemRect.top;
                this.selected_area.offsetStartPointX = this.state.box.left;
                this.selected_area.offsetStartPointY = this.state.box.top;
            }
        );
    };

    removeContainer = () => {
        this.setState({
            box: { left: 0, top: 0, width: 0, height: 0 },
            tx: { left: 0, top: 0, width: 0, height: 0 },
            is_new_grid: true,
            mode: MODE.Edit
        });
    };

    createBox = ({ left, top, width, height }: ICoordinates, state?: Partial<IGridState>) => {
        if (!state) {
            state = {};
        }
        this.setState({
            ...(state as any),
            box: { left, top, width, height },
            tx: this.convertResultToTextract({ left, top, width, height })
        });
    };

    calculateNewSelectedArea = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const elemRect = this.container_image_ref.current.getBoundingClientRect();

        const START_POINT_X_OFFSET = elemRect.left;
        const START_POINT_Y_OFFSET = elemRect.top;

        const startPointX = e.clientX;
        const startPointY = e.clientY;

        const offsetStartPointX = startPointX - START_POINT_X_OFFSET;
        const offsetStartPointY = startPointY - START_POINT_Y_OFFSET;

        // debugger

        let width = 0;
        let height = 0;
        let isOppositeDirectionX = false;
        let isOppositeDirectionY = false;

        const selected_area = new SelectedArea();

        selected_area.width = width;
        selected_area.height = height;
        selected_area.startPointX = startPointX;
        selected_area.startPointY = startPointY;
        selected_area.isOppositeDirectionX = isOppositeDirectionX;
        selected_area.isOppositeDirectionY = isOppositeDirectionY;
        selected_area.offsetStartPointX = offsetStartPointX;
        selected_area.offsetStartPointY = offsetStartPointY;
        selected_area.dx = 0;
        selected_area.dy = 0;
        selected_area.initStartPointX = selected_area.startPointX;
        selected_area.initStartPointY = selected_area.startPointY;
        selected_area.initHeight = selected_area.width;
        selected_area.initWidth = selected_area.height;
        selected_area.scrollOffsetY = START_POINT_Y_OFFSET;

        return selected_area;
    };

    mm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string, is_row = false) => {
        const label = (is_row ? this.state.rows : this.state.columns).find((t) => t.id === id);

        if (!label) {
            return;
        }
        const elemRect = this.container_image_ref.current.getBoundingClientRect();

        const START_POINT_X_OFFSET = elemRect.left;
        const START_POINT_Y_OFFSET = elemRect.top;
        // const startPointX = e.clientX;
        // const startPointY = e.clientY;

        // const dx = startPointX - START_POINT_X_OFFSET;
        // const dy = startPointY - START_POINT_Y_OFFSET;

        const move = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const offsetStartPointX = e.clientX - START_POINT_X_OFFSET;
            const offsetStartPointY = e.clientY - START_POINT_Y_OFFSET;

            if (!is_row) {
                if (offsetStartPointX < this.state.box.left) {
                    return;
                }

                if (offsetStartPointX > this.state.box.width + this.state.box.left) {
                    return;
                }

                this.setState({
                    columns: this.state.columns.map((item) => {
                        if (item.id === id) {
                            const position = { ...item.position };
                            position.left = offsetStartPointX;
                            return {
                                ...item,
                                position: position,
                                tx: this.convertResultToTextract(position)
                            };
                        }
                        return item;
                    })
                });
            } else {
                if (offsetStartPointY < this.state.box.top) {
                    return;
                }

                if (offsetStartPointY > this.state.box.height + this.state.box.top) {
                    return;
                }

                this.setState({
                    rows: this.state.rows.map((item) => {
                        if (item.id === id) {
                            const position = { ...item.position };
                            position.top = offsetStartPointY;
                            return {
                                ...item,
                                position: position,
                                tx: this.convertResultToTextract(position)
                            };
                        }
                        return item;
                    })
                });
            }
        };

        const up = () => {
            this.container_ref.current.removeEventListener('mousemove', move);
            this.container_ref.current.removeEventListener('mouseup', up);
        };

        this.container_ref.current.addEventListener('mousemove', move);
        this.container_ref.current.addEventListener('mouseup', up);
    };

    onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        if (this.state.mode === MODE.View) {
            return;
        }

        if (this.state.hovered_bar !== STATUS_BAR.Empty) {
            // const selected_area = this.calculateNewSelectedArea(e);

            // const area = this.container_area_ref.current.getBoundingClientRect();
            const r = this.state.line;

            if (r.delete) {
                let { rows, columns } = this.state;
                if (this.state.hovered_bar === STATUS_BAR.LEFT_BAR) {
                    rows = rows.filter((item) => {
                        return !(Math.abs(item.position.top - r.position.top) < NN);
                    });
                } else {
                    columns = columns.filter((item) => {
                        return !(Math.abs(item.position.left - r.position.left) < NN);
                    });
                }

                this.setState({
                    rows,
                    columns,
                    visible_labels: false,
                    line: {
                        position: r.position,
                        delete: false
                    }
                });
                return;
            }

            if (this.state.hovered_bar === STATUS_BAR.LEFT_BAR) {
                this.setState({
                    rows: [
                        ...this.state.rows,
                        {
                            id: nanoid(),
                            position: { ...r.position, height: 0 },
                            tx: this.convertResultToTextract({ ...r.position, height: 0 })
                        }
                    ]
                });
            } else {
                this.setState({
                    columns: [
                        ...this.state.columns,
                        {
                            id: nanoid(),
                            position: { ...r.position, width: 0 },
                            tx: this.convertResultToTextract({ ...r.position, width: 0 })
                        }
                    ]
                });
            }
            return;
        }

        if (e.target === this.container_area_ref.current) {
            const elemRect = this.container_area_ref.current.getBoundingClientRect();

            const START_POINT_X_OFFSET = elemRect.left;
            const START_POINT_Y_OFFSET = elemRect.top;
            const startPointX = e.clientX;
            const startPointY = e.clientY;

            const offsetStartPointX = startPointX - START_POINT_X_OFFSET;
            const offsetStartPointY = startPointY - START_POINT_Y_OFFSET;

            const dx = offsetStartPointX;
            const dy = offsetStartPointY;

            // debugger
            this.selected_area.dx = dx;
            this.selected_area.dy = dy;
            this.status = STATUS.Move_Area;
            return;
        }

        if (
            e.target === this.area_left_resize_ref.current ||
            e.target === this.area_right_resize_ref.current ||
            e.target === this.area_top_resize_ref.current ||
            e.target === this.area_bottom_resize_ref.current
        ) {
            if (e.target === this.area_left_resize_ref.current) {
                this.status = STATUS.Resize_Left;
            } else if (e.target === this.area_right_resize_ref.current) {
                this.status = STATUS.Resize_Right;
            } else if (e.target === this.area_top_resize_ref.current) {
                this.status = STATUS.Resize_Top;
            } else if (e.target === this.area_bottom_resize_ref.current) {
                this.status = STATUS.Resize_Bottom;
            } else {
                return;
            }

            this.selected_area.initStartPointX = this.selected_area.startPointX;
            this.selected_area.initStartPointY = this.selected_area.startPointY;
            this.selected_area.initHeight = this.selected_area.height;
            this.selected_area.initWidth = this.selected_area.width;

            return;
        }

        if (this.state.is_new_grid) {
            this.selected_area = this.calculateNewSelectedArea(e);
            this.status = STATUS.Selection;
            this.container_image_ref.current.addEventListener('mouseleave', this.onMouseUp);
            this.setState({ rows: [], columns: [] });
        }
    };
    onMoveMouse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (this.state.mode === MODE.View) {
            return;
        }
        if (e.target === this.left_bar.current || e.target === this.top_bar.current) {
            const selected_area = this.calculateNewSelectedArea(e);

            const area = this.container_area_ref.current.getBoundingClientRect();

            if (e.target === this.left_bar.current) {
                selected_area.width = area.width; // + (area.left - bar.left);
                selected_area.height = 1;
                selected_area.offsetStartPointX =
                    area.left - (selected_area.startPointX - selected_area.offsetStartPointX);
                selected_area.startPointX = area.left;
                // this. selected_area.startPointY = startPointY;
            } else {
                selected_area.height = area.height; //+ (area.top - bar.top);
                selected_area.width = 1;
                selected_area.offsetStartPointY =
                    area.top - (selected_area.startPointY - selected_area.offsetStartPointY);
                selected_area.startPointY = area.top;
            }
            const l = {
                position: this.normalizedSelectedAreaCoordinates(selected_area),
                delete: false
            };

            if (e.target === this.left_bar.current) {
                const rr = this.state.rows.find((item) => {
                    const x = Math.abs(item.position.top - l.position.top);
                    return x < NN;
                });
                if (rr) {
                    l.position = { ...rr.position };
                    l.delete = true;
                }
            } else {
                const rr = this.state.columns.find((item) => {
                    const x = Math.abs(item.position.left - l.position.left);
                    return x < NN;
                });
                if (rr) {
                    l.position = { ...rr.position };
                    l.delete = true;
                }
            }

            this.setState({
                hovered_bar:
                    e.target === this.left_bar.current ? STATUS_BAR.LEFT_BAR : STATUS_BAR.TOP_BAR,
                line: l
            });

            return;
        }

        if (this.status === STATUS.Empty) {
            return;
        }

        const currentPointX = e.clientX;
        const currentPointY = e.clientY;

        switch (this.status) {
            case STATUS.Selection:
                {
                    const old_width = this.selected_area.width;
                    const old_height = this.selected_area.height;

                    this.selected_area.width = Math.abs(
                        this.selected_area.startPointX - currentPointX
                    );
                    this.selected_area.height = Math.abs(
                        this.selected_area.startPointY - currentPointY
                    );

                    this.selected_area.isOppositeDirectionX =
                        this.selected_area.startPointX > currentPointX;
                    this.selected_area.isOppositeDirectionY =
                        this.selected_area.startPointY > currentPointY;

                    if (
                        Math.abs(old_width - this.selected_area.width) > 0 &&
                        Math.abs(old_height - this.selected_area.height) > 0
                    ) {
                        this.createBox(this.normalizedSelectedAreaCoordinates(this.selected_area), {
                            is_new_grid: false
                        });
                    }
                }
                break;
            case STATUS.Resize_Bottom:
            case STATUS.Resize_Left:
            case STATUS.Resize_Top:
            case STATUS.Resize_Right:
                {
                    const elemRect = this.container_image_ref.current.getBoundingClientRect();

                    // debugger;
                    switch (this.status) {
                        case STATUS.Resize_Left:
                        case STATUS.Resize_Right:
                            const START_POINT_X_OFFSET = elemRect.left;
                            const diff_left = currentPointX - this.selected_area.startPointX;
                            const new_left = this.selected_area.startPointX + diff_left;
                            let new_width;

                            switch (this.status) {
                                case STATUS.Resize_Left:
                                    new_width =
                                        this.selected_area.initWidth -
                                        (new_left - this.selected_area.initStartPointX);

                                    if (new_width > 0) {
                                        this.selected_area.offsetStartPointX =
                                            new_left - START_POINT_X_OFFSET;
                                        this.selected_area.startPointX = currentPointX;
                                    }
                                    break;
                                case STATUS.Resize_Right:
                                    new_width = new_left - this.selected_area.initStartPointX;
                                    // if (new_width < 0) {
                                    //     this.selected_area.offsetStartPointX =
                                    //         new_left - START_POINT_X_OFFSET;
                                    //     this.selected_area.startPointX = currentPointX;
                                    // }
                                    break;
                            }

                            this.selected_area.width = Math.abs(new_width);

                            this.selected_area.isOppositeDirectionX =
                                this.selected_area.startPointX > currentPointX;

                            break;

                        case STATUS.Resize_Top:
                        case STATUS.Resize_Bottom:
                            const START_POINT_Y_OFFSET = elemRect.top;

                            const scrool_offset_y =
                                this.selected_area.scrollOffsetY - START_POINT_Y_OFFSET;

                            const y =
                                this.selected_area.startPointY +
                                (currentPointY + scrool_offset_y - this.selected_area.startPointY);

                            let new_height = 0;

                            switch (this.status) {
                                case STATUS.Resize_Top:
                                    new_height =
                                        this.selected_area.initHeight -
                                        (y - this.selected_area.initStartPointY);

                                    if (new_height > 0) {
                                        this.selected_area.offsetStartPointY =
                                            y - START_POINT_Y_OFFSET - scrool_offset_y;
                                        this.selected_area.startPointY =
                                            currentPointY + scrool_offset_y;
                                    }
                                    break;
                                case STATUS.Resize_Bottom:
                                    new_height = y - this.selected_area.initStartPointY;

                                    // if (new_height < 0) {
                                    //     // this.selected_area.offsetStartPointY = y - START_POINT_Y_OFFSET;
                                    //     // this.selected_area.startPointY = currentPointY + __x__;

                                    //     // this.selected_area.__x = elemRect.top;
                                    //     // this.selected_area.__x_x = 0;
                                    //     // this.selected_area.__s_x = elemRect.top;

                                    // }
                                    break;

                                default:
                                    break;
                            }

                            this.selected_area.height = Math.abs(new_height);

                            this.selected_area.isOppositeDirectionY =
                                this.selected_area.startPointY > currentPointY + scrool_offset_y;

                            break;
                    }

                    const { columns, rows, box } = this.state;
                    const new_box = this.normalizedSelectedAreaCoordinates(this.selected_area);

                    this.createBox(new_box, {
                        rows: rows.map((item) => {
                            const width = new_box.width;
                            const y = new_box.top - box.top;

                            const new_top = item.position.top + y;
                            const hidden = new_top > new_box.top + new_box.height;

                            return {
                                ...item,
                                hidden,
                                position: {
                                    ...item.position,
                                    width,
                                    left: new_box.left,
                                    top: new_top
                                }
                            };
                        }),
                        columns: columns.map((item) => {
                            const height = new_box.height;
                            const x = new_box.left - box.left;

                            const new_left = item.position.left + x;
                            const hidden = new_left > new_box.left + new_box.width;

                            return {
                                ...item,
                                hidden,
                                position: {
                                    ...item.position,
                                    height,
                                    left: new_left,
                                    top: new_box.top
                                }
                            };
                        })
                    });
                }
                break;
            case STATUS.Move_Area:
                {
                    const elemRect = this.container_image_ref.current.getBoundingClientRect();

                    const START_POINT_X_OFFSET = elemRect.left;
                    const START_POINT_Y_OFFSET = elemRect.top;

                    const offsetStartPointX = currentPointX - START_POINT_X_OFFSET;
                    const offsetStartPointY = currentPointY - START_POINT_Y_OFFSET;

                    const rr = this.normalizedSelectedAreaCoordinates(this.selected_area);

                    if (
                        offsetStartPointX - this.selected_area.dx < 0 ||
                        offsetStartPointX - this.selected_area.dx + rr.width > elemRect.width
                    ) {
                        return;
                    }

                    if (
                        offsetStartPointY - this.selected_area.dy < 0 ||
                        offsetStartPointY - this.selected_area.dy + rr.height > elemRect.height
                    ) {
                        return;
                    }

                    this.selected_area.offsetStartPointX =
                        offsetStartPointX - this.selected_area.dx;
                    this.selected_area.offsetStartPointY =
                        offsetStartPointY - this.selected_area.dy;
                    this.selected_area.startPointY = currentPointY - this.selected_area.dy;
                    this.selected_area.startPointX = currentPointX - this.selected_area.dx;
                    this.selected_area.scrollOffsetY = START_POINT_Y_OFFSET;

                    //his.createBox(this.normalizedSelectedAreaCoordinates(this.selected_area));

                    const { box, columns, rows } = this.state;
                    const new_box = this.normalizedSelectedAreaCoordinates(this.selected_area);
                    new_box.width = box.width;
                    new_box.height = box.height;

                    // this.selected_area.startPointX = currentPointX;

                    this.createBox(new_box, {
                        rows: rows.map((item) => {
                            const y = new_box.top - box.top;

                            return {
                                ...item,
                                position: {
                                    ...item.position,
                                    left: new_box.left,
                                    top: item.position.top + y
                                }
                            };
                        }),
                        columns: columns.map((item) => {
                            const x = new_box.left - box.left;
                            return {
                                ...item,
                                position: {
                                    ...item.position,
                                    left: item.position.left + x,
                                    top: new_box.top
                                }
                            };
                        })
                    });
                }
                break;
        }
    };

    normalizedSelectedAreaCoordinates(selected_area: SelectedArea) {
        let result: ICoordinates = {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        };

        result = {
            left: selected_area.offsetStartPointX, // - selected_area.dx,
            top: selected_area.offsetStartPointY, // - selected_area.dy,
            width: selected_area.width,
            height: selected_area.height
        };

        if (selected_area.isOppositeDirectionX) {
            result.left -= result.width;
        }

        if (selected_area.isOppositeDirectionY) {
            result.top -= result.height;
        }

        return result;
    }

    onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (this.state.mode === MODE.View) {
            return;
        }
        this.container_image_ref.current.removeEventListener('mouseleave', this.onMouseUp);

        const status = this.status;
        this.status = STATUS.Empty;
    };

    onSelectedLabel = (item: IGridLabel) => {
        const { labels, columns, selected_label } = this.state;

        let col = columns.find((col) => {
            return col.label && col.label.value === item.value;
        });

        let col2 = columns.find((col) => {
            return col.id === selected_label?.id;
        });

        this.setState({
            visible_labels: false,
            selected_label: undefined,
            labels: labels.map((it) => {
                const payload = { ...it, selected: !!it.selected };

                if (col?.label?.value === payload.value) {
                    payload.selected = false;
                }

                if (col2?.label?.value === payload.value) {
                    payload.selected = false;
                }

                if (payload.value === item.value) {
                    payload.selected = true;
                }

                return payload;
            }),
            //.sort((a, b) => (a.selected > b.selected ? 1 : a.selected < b.selected ? -1 : 0)),
            columns: columns.map((col) => {
                const payload = { ...col };

                if (payload.label && payload.label.value === item.value) {
                    payload.label = undefined;
                }

                if (payload.id === selected_label?.id) {
                    payload.label = { label: item.label, value: item.value };
                }

                return payload;
            })
        });
    };

    onToggleLables = (id: string) => {
        const { visible_labels, selected_label, columns } = this.state;

        if (selected_label && selected_label.id === id) {
            this.setState({
                visible_labels: false,
                selected_label: undefined,
                visible_grid_actions: false
            });
            return;
        }

        const l = columns.find((item) => item.id === id);

        if (!l) {
            this.setState({
                visible_labels: false,
                selected_label: undefined,
                visible_grid_actions: false
            });
            return;
        }

        this.setState({ visible_labels: true, selected_label: l, visible_grid_actions: false });
    };

    onToggleGridActions = () => {
        this.setState({
            visible_labels: false,
            visible_grid_actions: !this.state.visible_grid_actions
        });
    };

    onSelectedGridAction = (action: GRID_ACTION) => {
        switch (action) {
            case GRID_ACTION.Save_Template:
                const part = this.onApply();
                if (!part) {
                    return;
                }
                this.props.onSaveAsTemplate(part);
                break;
            case GRID_ACTION.Use_Template:
                this.props.onUseTemplate({
                    document: this.props.document,
                    box: this.convertResultToTextract(this.state.box)
                });
                break;
            case GRID_ACTION.Delete_All_Columns:
                this.setState({ columns: [], labels: [...this.props.labels] });
                break;
            case GRID_ACTION.Delete_All_Rows:
                this.setState({ rows: [] });
                break;
            case GRID_ACTION.Delete_Grid:
                this.setState({ columns: [], rows: [], labels: [...this.props.labels] }, () =>
                    this.removeContainer()
                );
                break;
        }
    };

    onCancel = () => {
        this.setState({ mode: MODE.View });
    };

    onApply = (): IContentSchemaGrid | undefined => {
        const { box, rows, columns } = this.state;
        const result: IContentSchemaGrid = {
            document: this.props.document,
            box: this.convertResultToTextract(box),
            rows: rows
                .filter((row) => !row.hidden)
                .map((item) => ({
                    top_position: this.convertResultToTextract(item.position).top
                })),
            columns: columns
                .filter((col) => !col.hidden)
                .map((col) => ({
                    schema_id: col.label ? col.label.value : '',
                    left_position: this.convertResultToTextract(col.position).left
                }))
        };

        if (
            !result.document ||
            result.columns.length === 0 ||
            !('left' in result.box) ||
            !('top' in result.box) ||
            !('width' in result.box) ||
            !('height' in result.box)
        ) {
            return;
        }

        return result;
    };

    onEditGrid = () => {
        this.setState({ mode: MODE.Edit });
    };

    convertResultToTextract = ({ left: x, top: y, width, height }: ICoordinates) => {
        const img_width = this.props.img_width;
        const img_height = this.props.img_height;

        const original_width = this.props.naturalWidth;
        const original_height = this.props.naturalHeight;

        const result: ICoordinates = { left: 0, top: 0, width: 0, height: 0 };

        const xx = original_width / img_width;
        const yy = original_height / img_height;

        result.left = (x * xx) / original_width;
        result.top = (y * yy) / original_height;

        result.width = (width * xx) / original_width;
        result.height = (height * yy) / original_height;

        // this.props.onSelectedArea(this.props.imageId, { x, y, width, height }, result, isMoved);
        return result;
    };

    convertFromTextract(box: ICoordinates) {
        const IMAGE_WIDTH = this.props.img_width; ///this.image_ref.current.getBoundingClientRect().width;
        const IMAGE_HEIGHT = this.props.img_height; //this.image_ref.current.getBoundingClientRect().height;
        const left = IMAGE_WIDTH * box.left;
        const top = IMAGE_HEIGHT * box.top;

        const width = IMAGE_WIDTH * box.width;
        const height = IMAGE_HEIGHT * box.height;

        return {
            left,
            top,
            width,
            height
        };
    }

    render() {
        const {
            hovered_bar,
            line,
            box,
            columns,
            rows,
            labels,
            visible_labels,
            selected_label,
            visible_grid_actions,
            mode
        } = this.state;

        const { available_template } = this.props;

        return (
            <div
                ref={this.container_ref}
                style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
            >
                <div
                    style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
                    ref={this.container_image_ref}
                >
                    <div
                        ref={this.container_area_ref}
                        style={{
                            zIndex: 10,
                            position: 'absolute',
                            left: box.left,
                            top: box.top,
                            width: box.width,
                            height: box.height
                            // cursor: 'grab'
                            // border: '2px solid #11adff'
                        }}
                    >
                        <GridBoxBar
                            ref={this.top_bar}
                            style={{
                                position: 'absolute',
                                width: box.width,
                                top: -15,
                                height: 10
                            }}
                        />

                        <GridBoxBar
                            ref={this.left_bar}
                            style={{
                                height: box.height,
                                left: -15,
                                width: 10
                            }}
                        />

                        {/* {columns.map((item) => {
                            if (item.hidden) {
                                return null;
                            }
                            return (
                                <GridLine
                                    onClick={(e) => {
                                        e?.stopPropagation();
                                        e.preventDefault();
                                        console.log('click');
                                    }}
                                    left={item.position.left - box.left}
                                    top={item.position.top - box.top}
                                    width={item.position.width}
                                    height={item.position.height}
                                    dir={STATUS_BAR.TOP_BAR}
                                    label={item.label?.label}
                                    id={item.id}
                                    key={item.id}
                                    onToggleLables={this.onToggleLables}
                                    withLink
                                />
                            );
                        })} */}

                        {/* {rows.map((item) => {
                            if (item.hidden) {
                                return null;
                            }
                            return (
                                <GridLine
                                    onClick={(e) => {
                                        e?.stopPropagation();
                                        e.preventDefault();
                                        console.log('click');
                                    }}
                                    id={item.id}
                                    left={item.position.left - box.left}
                                    top={item.position.top - box.top}
                                    width={item.position.width}
                                    height={item.position.height}
                                    dir={STATUS_BAR.LEFT_BAR}
                                    key={item.id}
                                />
                            );
                        })} */}

                        <GridBoxSide
                            side={STATUS_BAR.LEFT_BAR}
                            style={{
                                height: box.height,
                                left: LINE_OFFSET,
                                width: LINE_SIZE,
                                cursor: 'ew-resize'
                            }}
                            ref={this.area_left_resize_ref}
                        />
                        <GridBoxSide
                            style={{
                                right: LINE_OFFSET,
                                height: box.height,
                                width: LINE_SIZE,
                                cursor: 'ew-resize'
                            }}
                            ref={this.area_right_resize_ref}
                            side={STATUS_BAR.LEFT_BAR}
                        />
                        <GridBoxSide
                            style={{
                                top: LINE_OFFSET,
                                width: box.width,
                                height: LINE_SIZE,
                                cursor: 'ns-resize'
                            }}
                            ref={this.area_top_resize_ref}
                            side={STATUS_BAR.TOP_BAR}
                        />
                        <GridBoxSide
                            style={{
                                bottom: LINE_OFFSET,
                                width: box.width,
                                height: LINE_SIZE,
                                cursor: 'ns-resize'
                            }}
                            ref={this.area_bottom_resize_ref}
                            side={STATUS_BAR.TOP_BAR}
                        />
                    </div>
                </div>

                {columns.map((item) => {
                    if (item.hidden) {
                        return null;
                    }
                    return (
                        <GridLine
                            onMouseDown={(e) => {
                                this.mm(e, item.id, false);
                            }}
                            left={item.position.left}
                            top={item.position.top}
                            width={item.position.width}
                            height={item.position.height}
                            dir={STATUS_BAR.TOP_BAR}
                            label={item.label?.label}
                            id={item.id}
                            key={item.id}
                            onToggleLables={this.onToggleLables}
                            withLink
                        />
                    );
                })}

                {rows.map((item) => {
                    if (item.hidden) {
                        return null;
                    }
                    return (
                        <GridLine
                            onMouseDown={(e) => {
                                this.mm(e, item.id, true);
                            }}
                            id={item.id}
                            left={item.position.left}
                            top={item.position.top}
                            width={item.position.width}
                            height={item.position.height}
                            dir={STATUS_BAR.LEFT_BAR}
                            key={item.id}
                        />
                    );
                })}

                {hovered_bar !== STATUS_BAR.Empty && hovered_bar === STATUS_BAR.LEFT_BAR && (
                    <GridLine
                        id={''}
                        width={line.position.width}
                        height={2}
                        top={line.position.top}
                        left={line.position.left}
                        dir={STATUS_BAR.LEFT_BAR}
                        deletion={line.delete}
                        dashedHidden
                        selection={!line.delete}
                        // withHead
                    />
                )}

                {hovered_bar !== STATUS_BAR.Empty && hovered_bar === STATUS_BAR.TOP_BAR && (
                    <GridLine
                        id={''}
                        width={2}
                        height={line.position.height}
                        top={line.position.top}
                        left={line.position.left}
                        dir={STATUS_BAR.TOP_BAR}
                        deletion={line.delete}
                        dashedHidden
                        selection={!line.delete}
                        // withHead
                    />
                )}

                <ActionGridContainer
                    style={{ top: box.top - 25, left: box.left - 30 }}
                    onClick={this.onToggleGridActions}
                >
                    <Icon src={arrow} alt="arrow" selected={visible_grid_actions} />
                    {visible_grid_actions && (
                        <ActionGridList>
                            {available_template && (
                                <>
                                    <ActionGridItem
                                        onClick={() =>
                                            this.onSelectedGridAction(GRID_ACTION.Save_Template)
                                        }
                                    >
                                        Save as template
                                    </ActionGridItem>
                                    <ActionGridItem
                                        onClick={() =>
                                            this.onSelectedGridAction(GRID_ACTION.Use_Template)
                                        }
                                    >
                                        Use template
                                    </ActionGridItem>
                                </>
                            )}
                            <ActionGridItem
                                onClick={() => this.onSelectedGridAction(GRID_ACTION.Delete_Grid)}
                            >
                                Delete grid
                            </ActionGridItem>
                            <ActionGridItem
                                onClick={() =>
                                    this.onSelectedGridAction(GRID_ACTION.Delete_All_Columns)
                                }
                            >
                                Delete all colunms
                            </ActionGridItem>
                            <ActionGridItem
                                onClick={() =>
                                    this.onSelectedGridAction(GRID_ACTION.Delete_All_Rows)
                                }
                            >
                                Delete all rows
                            </ActionGridItem>
                        </ActionGridList>
                    )}
                </ActionGridContainer>

                {visible_labels && selected_label && (
                    <ContainerLabels
                        style={{
                            top: selected_label.position.top - 45,
                            left: selected_label.position.left + 25
                        }}
                    >
                        {[...labels]
                            .sort((a, b) =>
                                !!a.selected > !!b.selected
                                    ? 1
                                    : !!a.selected < !!b.selected
                                    ? -1
                                    : 0
                            )
                            .map((item, ind) => {
                                return (
                                    <LabelBlock
                                        onClick={() => this.onSelectedLabel(item)}
                                        key={ind}
                                        selected={item.selected}
                                    >
                                        {item.label}
                                    </LabelBlock>
                                );
                            })}
                    </ContainerLabels>
                )}
            </div>
        );
    }
}

interface IGridLineProps extends ICoordinates {
    dir: STATUS_BAR;
    id: string;
    deletion?: boolean;
    withHead?: boolean;
    withLink?: boolean;
    label?: string;
    dashedHidden?: boolean;
    selection?: boolean;
    onToggleLables?: (id: string) => void;
    onMouseDown?: (e: any) => void;
}

const GridLine: React.FC<IGridLineProps> = React.memo(
    ({
        onMouseDown,
        id,
        top,
        left,
        width,
        height,
        dir,
        deletion,
        withHead,
        withLink,
        label,
        onToggleLables,
        dashedHidden,
        selection
    }) => {
        return (
            <div
                style={{ position: 'absolute', left: left, top: top, width: width, height: height }}
            >
                <GridLineContainer
                    onMouseDown={onMouseDown}
                    selection={selection}
                    deletion={deletion}
                    side={dir}
                    style={{ width: width, height: height }}
                >
                    {!deletion && (
                        <GridLineContainer2
                            side={dir}
                            dashedHidden={dashedHidden}
                        ></GridLineContainer2>
                    )}

                    {withHead && <GridLineHead side={dir} color={deletion ? '#f00' : ''} />}
                </GridLineContainer>
                {withLink && (
                    <LineLabelContainerBox onClick={() => onToggleLables && onToggleLables(id)}>
                        {label && <LineLabel>{label}</LineLabel>}
                        <LineLabelBox>
                            <img
                                src={linkImg}
                                style={{
                                    width: '10px',
                                    height: 'auto',
                                    display: 'block',
                                    userSelect: 'none'
                                }}
                                alt={'link'}
                            />
                        </LineLabelBox>
                    </LineLabelContainerBox>
                )}
            </div>
        );
    }
);

export const GridLineContainer = styled.div<{
    side?: STATUS_BAR;
    selection?: boolean;
    deletion?: boolean;
}>`
    user-select: none;
    position: absolute;
    z-index: 10;
    ${({ selection }) => selection && `background-color:#11adff;`}
    ${({ deletion }) => (deletion ? `background-color:#f00;` : ``)}

  ${({ side, selection, deletion }) => {
        if (side === STATUS_BAR.LEFT_BAR) {
            return `
                top: 50%;
                transform: translateY(-50%);
                padding:${selection || deletion ? 1 : 3}px 0px;
                cursor: ns-resize;
              `;
        }

        if (side === STATUS_BAR.TOP_BAR) {
            return `
                left: 50%;
                transform: translateX(-50%);
                padding:0px ${selection || deletion ? 1 : 3}px;
                cursor: ew-resize;
              `;
        }

        return '';
    }}
`;
export const GridLineContainer2 = styled.div<{
    side?: STATUS_BAR;
    dashedHidden?: boolean;
    deletion?: boolean;
}>`
    position: absolute;
    left: 0px;
    top: 0px;

    ${({ side, deletion }) => {
        if (side === STATUS_BAR.LEFT_BAR) {
            return `
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: 100%;
                ${
                    !deletion &&
                    `border-top-width: thin !important;
                    border: 0px dashed #11adff;`
                }
              `;
        }

        if (side === STATUS_BAR.TOP_BAR) {
            return `
                left: 50%;
                transform: translateX(-50%);
                // width: 1px;
                height: 100%;
                ${
                    !deletion &&
                    `border-left-width: thin !important;
                    border: 0px dashed #11adff;`
                }
              `;
        }

        return '';
    }}

    ${({ dashedHidden }) => dashedHidden && 'border:none !important;'}
`;

export const GridLineHead = styled.div<{ side?: STATUS_BAR; color?: string }>`
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 32px;
    background-color: ${({ color }) => color || '#388EFF'};
    ${({ side }) => {
        if (side === STATUS_BAR.LEFT_BAR) {
            return `
            left: -18px;
            transform: translateY(-50%);
            top: 50%;
            `;
        }
        if (side === STATUS_BAR.TOP_BAR) {
            return `
            top:-18px;
            transform: translateX(-50%);
            left: 50%;
            `;
        }

        return '';
    }}

    cursor: pointer;
`;
export const ContainerLabels = styled.div<{}>`
    position: absolute;
    left: 0px;
    top: 20px;
    max-height: 275px;
    width: 200px;
    background-color: ${greyColorDark};
    color: #fff;
    overflow: auto;
    z-index: 15;
    border-radius: 4px;
`;
export const LabelBlock = styled.div<{ selected?: boolean }>`
    display: block;
    padding: 8px 12px;
    user-select: none;
    cursor: pointer;

    ${({ selected }) => selected && `background-color:${greyColor};`}
    &:hover {
        background-color: ${greyColorBright};
    }
`;

export const ActionGridContainer = styled.div`
    position: absolute;
    width: 18px;
    height: 18px;
    z-index: 20;
    background-color: ${greenColor};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ActionGridList = styled.div`
    position: absolute;
    top: 20px;
    width: 200px;
    background-color: ${greyColorDark};
    border-radius: 4px;
    left: 0px;
    overflow: hidden;
    user-select: none;
`;

export const ActionGridItem = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    color: #fff;

    &:hover {
        background-color: ${greyColorBright};
    }
`;

export const GridBoxSide = styled.div<{ side?: STATUS_BAR }>`
    position: absolute;
    background-color: #11adff;
    z-index: 20;

    &:before {
        content: '';
        position: absolute;
        ${({ side }) => {
            if (side === STATUS_BAR.LEFT_BAR) {
                return `
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 0;
                        padding: 0 4px;
                    `;
            }
            if (side === STATUS_BAR.TOP_BAR) {
                return `
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        padding: 4px 0px;
                    `;
            }
        }}
    }
`;
export const GridBoxBar = styled.div`
    position: absolute;
    height: box .height;
    background-color: #11adff;
    cursor: pointer;
    border-radius: 4px;
`;

export const LineLabel = styled.div`
    user-select: none;
    background-color: ${greenColor};
    color: #fff;
    position: absolute;
    top: -5px;
    transform: rotateZ(-90deg);
    font-size: 11px;
    transform-origin: left top;
    padding: 2px 4px;
    border-radius: 4px;
    white-space: nowrap;
`;

export const LineLabelBox = styled.div`
    width: 18px;
    height: 18px;
    background-color: ${greenColor};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
`;

export const LineLabelContainerBox = styled.div`
    position: absolute;
    top: -31px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: ${10};
        background-color: #fff;
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
    }
`;

export const Buttons = styled.div`
    position: fixed;
    display: flex;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
`;

const Icon = styled.img<{ selected?: boolean }>`
    width: 80%;
    height: auto;
    transition: transform 0.35s ease-out;
    transform: ${({ selected }) => (!selected ? 'rotate(0deg)' : 'rotate(180deg)')};
`;
