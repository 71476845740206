import React, { FC, useState } from 'react';
import styled from 'styled-components';

import InputLabel from 'components/InputLabel';
import PrimaryButton from 'components/PrimaryButton';
import { CategorySchema } from 'helpers/validations';

import { Filter } from 'styles/blocks';
import { CancelButton } from 'styles/text';

import closePng from 'assets/images/icons/closeWhite.png';
import { useFormik } from 'formik';
import { ICategory, IEditCategory } from 'redux/list/types';

const styles = {
    inputContainer: {
        marginBottom: 20
    },
    labelStyle: {
        minWidth: 90,
        color: 'white'
    },
    selectedStyle: {
        minWidth: 200
    },
    editBtn: { width: 145, height: 55, marginLeft: 28 }
};

interface Props {
    item: ICategory;
    closePopUp: () => void;
    onSubmit: (data: IEditCategory, company_id?: string) => Promise<any>;
}

const EditCategory: FC<Props> = ({ item, onSubmit, closePopUp }) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validationSchema: CategorySchema,
        initialValues: {
            name: item.name,
            code: item.category_code
        },
        onSubmit: async (values) => {
            if (values.name === item.name && values.code === item.category_code) {
                return;
            }

            const params: IEditCategory = {
                name: values.name,
                category_code: values.code,

                id: item.id
            };

            setLoading(true);
            try {
                await onSubmit(params, item.company_id);
                closePopUp();
            } catch (err) {
                formik.setErrors({ name: 'Something went wrong' });
                setLoading(false);
            }
        }
    });
    const { values, touched, errors } = formik;

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>EDIT Category</HeaderText>
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                <Main>
                    <Form onSubmit={formik.handleSubmit}>
                        <InputLabel
                            mark
                            id="name"
                            name="name"
                            type="text"
                            label="Name"
                            error={errors.name}
                            onChange={formik.handleChange}
                            touched={touched.name}
                            value={values.name}
                            labelStyle={styles.labelStyle}
                            containerStyle={styles.inputContainer}
                        />
                        <InputLabel
                            id="code"
                            name="code"
                            type="text"
                            label="Code"
                            error={errors.code}
                            touched={touched.code}
                            onChange={formik.handleChange}
                            value={values.code}
                            labelStyle={styles.labelStyle}
                            containerStyle={styles.inputContainer}
                        />
                    </Form>
                    <Buttons>
                        <CancelButton light onClick={closePopUp}>
                            Cancel
                        </CancelButton>
                        <PrimaryButton
                            loading={loading}
                            title={`EDIT Category`}
                            onClick={formik.handleSubmit}
                            style={styles.editBtn}
                        />
                    </Buttons>
                </Main>
            </Container>
        </Filter>
    );
};

export default EditCategory;

const Container = styled.div`
    width: 650px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;

const Form = styled.form`
    // max-width: 350px;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`;
