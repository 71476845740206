import React from "react";

import { ColoredTitle } from "styles/text";
import loginPicture from "assets/images/log-in-picture.png";
import {
  SideBarImage,
  SideBarText,
  SideBarTitle,
  SideBarContainer,
  SideBarImageContainer,
} from "./styles";

const SideBar = () => {
  return (
    <SideBarContainer>
      <ColoredTitle fs={36}>Assistant</ColoredTitle>
      <SideBarTitle>Powered by Added Value.</SideBarTitle>
      <SideBarText>
        Assistant was created by Added Value, a licensed audit and accounting
        company, a proud member of the global association DFK. Our work is based
        on the principle of permanent progress. Added Value customers are
        guaranteed that their finances are, and will be, handled with
        top-of-the-line accounting technology.
      </SideBarText>
      <SideBarImageContainer>
        <SideBarImage src={loginPicture} alt="Log in" />
      </SideBarImageContainer>
    </SideBarContainer>
  );
};

export default SideBar;
