import React, { useState } from 'react';

import { IUploadType } from 'net/api';
import { EnumReceiptType } from 'redux/receipt/types';

import { DropZone, FileType, UploadedFileType } from '../../../../components/DropZone/DropZone';
import { ResultList } from './ResultList';
import DropInput from 'components/DropInput';
import DocCheckBox from '../../components/DocCheckBox';
import {
    AddUser,
    AddUserIcon,
    Body,
    DNDContainer,
    DocTypeBlock,
    DropContainer,
    DropInputContainer,
    Empty,
    Header,
    IconClose,
    Inner
} from './styles';
import { Filter } from 'styles/blocks';
import { whiteColor } from 'styles/colors';
import { PopUpTitle, SmallText } from 'styles/text';

import closeIcon from 'assets/images/icons/closeWhite.png';
import addUser from 'assets/images/icons/addUser.png';
import { UserState } from 'redux/user/types';
import styled from 'styled-components/macro';
import { Loader } from '../../../../components/Other/Loader';
import CheckBox from '../../components/CheckBox';
import { Text } from '../../../EditReceipt/components/Details/styles';

const MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.oasis.opendocument.text',
    'application/rtf',
    'application/pdf'
];

const TYPES = ['.xlsx', '.xls', '.ods', '.docx', '.doc', '.odt', '.rtf', '.pdf'];

const styles = {
    dropContainer: {
        margin: 0
    },
    dropLabel: {
        minWidth: 88,
        color: whiteColor
    }
};

export const LoaderStyled = styled(Loader)`
    margin-left: 20px;
`;

interface IProps {
    user: UserState;
    receipt_type: EnumReceiptType | undefined;
    closePopUp: () => void;
    onUserClick: () => void;
    uploadPhoto: (
        photo: Array<any>,
        upload_type: IUploadType,
        user_id?: string,
        process_rows?: boolean
    ) => any;
}

const AddReceipt = ({
    user,
    receipt_type,
    uploadPhoto,
    closePopUp,
    onUserClick
}: IProps): JSX.Element => {
    const [uploaded, setUploaded] = useState<UploadedFileType[]>([]);
    const [type, setType] = useState<'single' | 'multiple'>('single');
    const [selectedUser, setSelectedUser] = useState(
        `${user.profile.name} ${user.profile.surname}`
    );
    const [isLoading, setIsLoading] = useState(false);
    const [processRows, setProcessRows] = useState(false);

    const setNewResult = (file: any, success: boolean, message: string) => {
        let fileName = file ? file.name : 'Unknown';
        let fileSize = file ? file.size : 0;
        let fileType = file ? file.type : 'unknown';

        setUploaded((prevState) => [
            ...prevState,
            {
                id: fileName + fileType,
                name: fileName,
                size: fileSize,
                type: fileType,
                success,
                message
            }
        ]);
    };

    const uploadFileHandle = async (file: FileType) => {
        setIsLoading(true);
        let success = false;
        let message = 'This file type is not allowed';

        if (file) {
            // if (file.type === "application/pdf") {
            if (MIME_TYPES.includes(file.type as string)) {
                success = true;
                message = 'Successfully uploaded item';
                if (file.size > 31457280) {
                    success = false;
                    message = 'PDF size limits 500MB';
                }
            }
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                success = true;
                message = 'Successfully uploaded item';
                if (file.size > 10485760) {
                    success = false;
                    message = 'Image size limits 10MB';
                }
            }
        }

        // Create Item to DB
        if (success) {
            let creator = user.users.items.find(
                (u) => `${u.name} ${u.surname}` === selectedUser
            )?.user_id;
            if (creator === user.profile.id) {
                creator = '';
            }
            const err = await uploadPhoto([{ file, receipt_type }], type, creator, processRows);
            if (err) {
                message = err;
                success = false;
            }
        }
        return setNewResult(file, success, message);
    };

    return (
        <Filter className="fadeIn">
            <Inner>
                <Header>
                    <PopUpTitle>
                        Add {receipt_type === 'receipt' ? 'Cost' : 'Sales'} Items by Upload
                    </PopUpTitle>

                    <IconClose src={closeIcon} onClick={closePopUp} alt="close" />
                </Header>

                <Body>
                    {user.activeCompany.role === 'admin' && (
                        <PopUpTitle>Please choose user or add new ones</PopUpTitle>
                    )}

                    <DropContainer>
                        <DropInputContainer>
                            <DropInput
                                disabled={user.activeCompany.role === 'basic'}
                                label="Owner"
                                selected={selectedUser}
                                list={user.users.items.map((u) => `${u.name} ${u.surname}`)}
                                onClick={setSelectedUser}
                                labelStyle={styles.dropLabel}
                                containerStyle={styles.dropContainer}
                            />
                        </DropInputContainer>
                        {user.activeCompany.role === 'admin' && (
                            <AddUser onClick={onUserClick}>
                                <AddUserIcon src={addUser} alt="add user" />
                                <SmallText>Add a user</SmallText>
                            </AddUser>
                        )}
                    </DropContainer>

                    <DNDContainer>
                        <div style={{ marginRight: 30 }}>
                            <DropZone
                                setNewResult={setNewResult}
                                uploadFileHandle={(file) =>
                                    uploadFileHandle(file).finally(() => setIsLoading(false))
                                }
                                desc={'Max file size is 10 mb for JPEG, PNG and 30 mb for PDF'}
                                accept={
                                    type === 'single'
                                        ? //".jpeg,.jpg,.png,.pdf"
                                          `.jpeg,.jpg,.png,${TYPES.join(',')}`
                                        : TYPES.join(',')
                                }
                            />
                        </div>
                        <DocTypeBlock>
                            <DocCheckBox
                                active={type === 'single'}
                                onClick={() => setType('single')}
                                title="One document per file"
                                description="PDF,JPG,PNG"
                            />
                            <DocCheckBox
                                active={type === 'multiple'}
                                onClick={() => setType('multiple')}
                                title="One document per page"
                                description="PDF files only"
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}
                            >
                                <Text style={{ color: '#fff' }}>Process rows</Text>

                                <CheckBox
                                    onClick={() => setProcessRows((prevState) => !prevState)}
                                    active={processRows}
                                />
                            </div>
                        </DocTypeBlock>

                        {isLoading && <LoaderStyled />}
                    </DNDContainer>

                    {uploaded.length > 0 ? <ResultList uploaded={uploaded} /> : <Empty />}
                </Body>
            </Inner>
        </Filter>
    );
};

export default AddReceipt;
