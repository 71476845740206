import { SettingState, SettingActionTypes } from "./types";

const initialState: SettingState = {
  loading: false,
  schema: [],
  initialSchema: [],
};

const settingReducer = (
  state = initialState,
  actions: SettingActionTypes
): SettingState => {
  switch (actions.type) {
    case "SET_RECEIPT_SCHEMA":
      return {
        ...state,
        schema: actions.data,
        initialSchema: actions.initialData,
      };
    case "SET_SETTING_LOADING":
      return {
        ...state,
        loading: actions.loading,
      };
    case "UPDATE_SHEMA_REQUIRED":
      return {
        ...state,
        schema: state.schema.map((item) => {
          if (item.id === actions.id) {
            return {
              ...item,
              constraints: {
                ...item.constraints,
                required: !item.constraints.required,
              },
            };
          }
          return item;
        }),
      };
    case "UPDATE_SHEMA_HIDDEN":
      return {
        ...state,
        schema: state.schema.map((item) => {
          if (item.id === actions.id) {
            return {
              ...item,
              hidden: !item.hidden,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export default settingReducer;
