import React, { FC, useState } from "react";
import styled from "styled-components";
import { Label } from "styles/text";

import arrow from "assets/images/icons/arrow-down.png";

interface Props {
  label: string;
}

const ToogleContainer: FC<Props> = ({ label, children }) => {
  const MAX_HEIGHT = 1500;
  const [height, setHeight] = useState(MAX_HEIGHT);

  const toogleHeight = () => {
    if (height === 0) {
      setHeight(MAX_HEIGHT);
    } else {
      setHeight(0);
    }
  };

  return (
    <Container>
      <LabelBlock onClick={toogleHeight}>
        <Label>{label}</Label>
        <Icon height={height} src={arrow} alt="arrow hide" />
      </LabelBlock>
      <HideBlock height={height}>{children}</HideBlock>
    </Container>
  );
};

export default ToogleContainer;

interface StyleProps {
  height: number;
}
const Container = styled.div`
  border-top: thin solid rgba(102, 105, 117, 0.3);
  border-bottom: thin solid rgba(102, 105, 117, 0.3);
  padding-top: 20px;
  margin-bottom: 20px;
`;

const LabelBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`;

const HideBlock = styled.div`
  transition: max-height 0.35s ease-out;
  overflow: ${({ height }: StyleProps) =>
    height === 0 ? "hidden" : "initial"};
  max-height: ${({ height }: StyleProps) => height}px;
`;

const Icon = styled.img`
  width: 20px;
  height: 15px;
  transition: transform 0.35s ease-out;
  transform: ${({ height }: StyleProps) =>
    height === 0 ? "rotate(0deg)" : "rotate(180deg)"};
`;
