import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'

interface IProps {
    style: CSSProperties
}

const Title = styled.p`
    font-family: 'NunitoSans-ExtraBold';
    background: -webkit-linear-gradient(90deg, #FFBBCF 5.73%, #A665D1 87.75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const TitleMain: FC<IProps> = ({ style }) => <Title style={style}>Assistant</Title>
