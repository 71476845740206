import React, { FC } from 'react';

import arrowBack from 'assets/images/icons/arrowBack.png';

import { Icon, Block, Button, Container, IconsBlock } from './styles';
import { ButtonReject, ButtonsContainer, GreenContainer } from 'pages/EditReceipt/components/Actions/styles';

interface Props {
    isValid: boolean;
    loading: boolean;
    goBack: () => void;
    onSave: () => void;
    onDelete: () => void;
}

const Actions: FC<Props> = ({ loading, isValid, onSave, goBack, onDelete }) => {
    return (
        <Container>
            <IconsBlock>
                <Block onClick={goBack}>
                    <Icon src={arrowBack} style={{ height: '15px' }} />
                    Back
                </Block>
            </IconsBlock>
            <div>
                {loading ? (
                    <Button type="loading" isValid={false}>
                        Processing
                    </Button>
                ) : (
                    <ButtonsContainer>
                        <Button isValid={isValid} onClick={onSave}>
                            SAVE
                        </Button>
                        <ButtonReject isValid={true} onClick={onDelete}>
                            DELETE
                        </ButtonReject>
                    </ButtonsContainer>
                )}
            </div>
        </Container>
    );
};

export default Actions;
