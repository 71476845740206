import React, { CSSProperties, FC, InputHTMLAttributes } from "react";
import styled from "styled-components";

import { redColor, greyColorDark, greyColor } from "../../styles/colors";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  note?: string;
  error?: string;
  touched?: boolean;
  containerStyle?: CSSProperties;
  errorStyle?: CSSProperties;
}

const SimpleInput: FC<Props> = ({
  note,
  error,
  touched,
  containerStyle,
  errorStyle,
  ...rest
}) => {
  return (
    <Container style={containerStyle} error={!!error}>
      <Input {...rest} />
      {error && touched && <Error style={errorStyle}>{error}</Error>}
      {!error && note && <Note>{note}</Note>}
    </Container>
  );
};

export default SimpleInput;

const Container = styled.div<{error?:boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({error})=>error ? `border: 1px solid ${redColor}`:`border: 1px solid transparent`}

`;

const Input = styled.input`
  width: 100%;
  height: 35px;
  background: white;
  border: 0.4px solid #eeeeee;
  box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
  outline: none;
  font-family: "NunitoSans-SemiBold";
  font-size: 15px;
  color: ${greyColorDark};
  text-indent: 15px;
  &::placeholder {
    opacity: 0.4;
  }
`;

const Error = styled.p`
  position: absolute;
  bottom: -16px;
  font-size: 10px;
  color: ${redColor};
  margin-left: 24px;
  font-family: "NunitoSans-ExtraBold";
`;

const Note = styled.p`
  position: absolute;
  bottom: -16px;
  font-size: 10px;
  color: ${greyColor};
  margin-left: 24px;
  font-family: "NunitoSans-ExtraBold";
`;
