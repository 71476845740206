import React from 'react';
import { ICoordinates } from './index';
// import { useSideMenuContext } from 'components/SideMenu/SideMenuContext';
import { Mark } from './styles';
import { IContent, IContentSchema, IReceiptImageBox } from 'redux/editReceipt/types';
import { ISelectedBox } from 'pages/EditReceipt/EditReceipt';
import FloatInput from '../FloatInput';

export interface IEditorProps {
    url: string;
    isKey?: boolean;
    document: string;
    selected: boolean;
    floatText?: string;
    floatLabel?: string;
    selectedBoxes: ISelectedBox[];
    boxes: IReceiptImageBox[];
    selectedContent?: IContentSchema;
    isActive?: boolean;
    img_width: number;
    img_height: number;
    naturalWidth: number;
    naturalHeight: number;
    onStartSelectedArea: (document_id: string) => void;
    onEndSelectedArea: (document_id: string) => void;
    onSelected: (id: string, state?: boolean) => void;
    setHeight: (height: number) => void;
    onCancelText: () => void;
    onAcceptText: (document_id: string, value: string) => void;
    onSelectedArea: (
        document_id: string,
        original_coordinates: ICoordinates,
        textract_coordinates: ICoordinates
    ) => void;
}

interface State {
    loading: boolean;
    selectedBoxes: ISelectedBox[];
    float_popup: ICoordinates;
    box: ICoordinates;
    tx: ICoordinates;
    hoverd_box?: ICoordinates;
    only_float?: boolean;
    is_selection_area?: boolean;
    hidden?: boolean;
}

export enum STATUS {
    Empty,
    Move_Area,
    Selection,
    Resize_Left,
    Resize_Right,
    Resize_Top,
    Resize_Bottom
}

export class SelectedArea {
    width = 0;
    height = 0;
    startPointX = 0;
    startPointY = 0;
    dx = 0;
    dy = 0;
    offsetStartPointX = 0;
    offsetStartPointY = 0;
    initWidth = 0;
    initHeight = 0;
    initStartPointX = 0;
    initStartPointY = 0;
    isOppositeDirectionX = false;
    isOppositeDirectionY = false;
    scrollOffsetY = 0;
}

const LINE_OFFSET = 0;
const LINE_SIZE = 2;

class Editor extends React.PureComponent<IEditorProps, State> {
    state: State = {
        loading: true,
        selectedBoxes: [],
        float_popup: {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        },
        box: {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        },
        tx: {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        },
        is_selection_area: false,
        only_float: false,
        hidden: false,
        hoverd_box: undefined
    };

    selected_area = new SelectedArea();
    hovered_area = new SelectedArea();

    status = STATUS.Empty;

    container_image_ref = React.createRef<any>();
    container_area_ref = React.createRef<any>();

    area_top_resize_ref = React.createRef<any>();
    area_left_resize_ref = React.createRef<any>();
    area_right_resize_ref = React.createRef<any>();
    area_bottom_resize_ref = React.createRef<any>();

    componentDidMount() {
        this.renderMark();
        this.subscribeEvents();
    }

    componentWillUnmount() {
        this.unSubscribeEvents();
    }

    subscribeEvents() {
        this.container_image_ref.current.addEventListener('mousedown', this.onMouseDown);
        this.container_image_ref.current.addEventListener('mouseup', this.onMouseUp);
        this.container_image_ref.current.addEventListener('mousemove', this.onMoveMouse);
        window.addEventListener('resize', this.onResize);
    }

    unSubscribeEvents() {
        this.container_image_ref.current.removeEventListener('mouseup', this.onMouseUp);
        this.container_image_ref.current.removeEventListener('mousemove', this.onMoveMouse);
        this.container_image_ref.current.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps: IEditorProps, prevState: State) {
        const { selectedContent, document: imageId, selectedBoxes, isKey } = this.props;

        if (
            selectedContent !== prevProps.selectedContent ||
            selectedContent?.id !== prevProps.selectedContent?.id ||
            isKey !== prevProps.isKey
        ) {
            let content: IContent | undefined;
            if (selectedContent && imageId === selectedContent.file) {
                if (isKey) {
                    content = selectedContent.related_content;
                } else {
                    content = selectedContent.content;
                }
            }

            this.removeContainer();
            this.props.onSelected(this.props.document, false);

            if (content && content.position.top !== null) {
                // TODO:
                const result = this.convertFromTextract(content.position);

                this.createBox(result);

                const elemRect = this.container_image_ref.current.getBoundingClientRect();

                const START_POINT_X_OFFSET = elemRect.left;
                const START_POINT_Y_OFFSET = elemRect.top;

                this.selected_area = new SelectedArea();
                this.selected_area.width = result.width;
                this.selected_area.height = result.height;
                this.selected_area.startPointX = result.left + START_POINT_X_OFFSET;
                this.selected_area.startPointY = result.top + START_POINT_Y_OFFSET;
                this.selected_area.offsetStartPointX = result.left;
                this.selected_area.offsetStartPointY = result.top;
            }

            this.renderMark();
        }

        if (selectedBoxes !== prevProps.selectedBoxes) {
            this.renderMark();
        }
    }

    onResize = () => {
        this.renderMark();

        if (this.props.selected) {
            const ps = this.convertFromTextract(this.state.tx);
            this.createBox(ps, this.state.only_float);
        }
    };

    removeContainer = () => {
        this.setState({
            box: { left: 0, top: 0, width: 0, height: 0 },
            hidden: true
        });
    };

    createBox = ({ left, top, width, height }: ICoordinates, only_float = false) => {
        this.setState({
            float_popup: { left, top, width, height },
            box: { left, top, width, height },
            tx: this.convertResultToTextract({ left, top, width, height }),
            only_float: only_float,
            hidden: false
        });
    };

    calculateNewSelectedArea = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const elemRect = this.container_image_ref.current.getBoundingClientRect();

        const START_POINT_X_OFFSET = elemRect.left;
        const START_POINT_Y_OFFSET = elemRect.top;

        const startPointX = e.clientX;
        const startPointY = e.clientY;

        const offsetStartPointX = startPointX - START_POINT_X_OFFSET;
        const offsetStartPointY = startPointY - START_POINT_Y_OFFSET;

        // debugger

        let width = 0;
        let height = 0;
        let isOppositeDirectionX = false;
        let isOppositeDirectionY = false;

        const selected_area = new SelectedArea();

        selected_area.width = width;
        selected_area.height = height;
        selected_area.startPointX = startPointX;
        selected_area.startPointY = startPointY;
        selected_area.isOppositeDirectionX = isOppositeDirectionX;
        selected_area.isOppositeDirectionY = isOppositeDirectionY;
        selected_area.offsetStartPointX = offsetStartPointX;
        selected_area.offsetStartPointY = offsetStartPointY;
        selected_area.dx = 0;
        selected_area.dy = 0;
        selected_area.initStartPointX = selected_area.startPointX;
        selected_area.initStartPointY = selected_area.startPointY;
        selected_area.initHeight = selected_area.width;
        selected_area.initWidth = selected_area.height;

        return selected_area;
    };

    onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();

        this.container_image_ref.current.removeEventListener('mouseleave', this.onMouseUp);

        if (!this.props.isActive) {
            return;
        }

        if (e.target === this.container_area_ref.current) {
            const elemRect = this.container_area_ref.current.getBoundingClientRect();

            const START_POINT_X_OFFSET = elemRect.left;
            const START_POINT_Y_OFFSET = elemRect.top;
            const startPointX = e.clientX;
            const startPointY = e.clientY;

            const offsetStartPointX = startPointX - START_POINT_X_OFFSET;
            const offsetStartPointY = startPointY - START_POINT_Y_OFFSET;

            const dx = offsetStartPointX;
            const dy = offsetStartPointY;

            // debugger
            this.selected_area.dx = dx;
            this.selected_area.dy = dy;
            this.status = STATUS.Move_Area;
        }
        // else if (
        //     e.target === this.area_left_resize_ref.current ||
        //     e.target === this.area_right_resize_ref.current ||
        //     e.target === this.area_top_resize_ref.current ||
        //     e.target === this.area_bottom_resize_ref.current
        // ) {
        //     if (e.target === this.area_left_resize_ref.current) {
        //         this.status = STATUS.Resize_Left;
        //     } else if (e.target === this.area_right_resize_ref.current) {
        //         this.status = STATUS.Resize_Right;
        //     } else if (e.target === this.area_top_resize_ref.current) {
        //         this.status = STATUS.Resize_Top;
        //     } else if (e.target === this.area_bottom_resize_ref.current) {
        //         this.status = STATUS.Resize_Bottom;
        //     } else {
        //         return;
        //     }

        //     this.selected_area.initStartPointX = this.selected_area.startPointX;
        //     this.selected_area.initStartPointY = this.selected_area.startPointY;
        //     this.selected_area.initHeight = this.selected_area.height;
        //     this.selected_area.initWidth = this.selected_area.width;
        // }
        else {
            this.selected_area = this.calculateNewSelectedArea(e);
            this.status = STATUS.Selection;
            this.container_image_ref.current.addEventListener('mouseleave', this.onMouseUp);
        }
    };

    onMoveMouse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!this.props.isActive) {
            return;
        }

        const currentPointX = e.clientX;
        const currentPointY = e.clientY;

        switch (this.status) {
            case STATUS.Selection:
                {
                    const old_width = this.selected_area.width;
                    const old_height = this.selected_area.height;

                    this.selected_area.width = Math.abs(
                        this.selected_area.startPointX - currentPointX
                    );
                    this.selected_area.height = Math.abs(
                        this.selected_area.startPointY - currentPointY
                    );

                    this.selected_area.isOppositeDirectionX =
                        this.selected_area.startPointX > currentPointX;
                    this.selected_area.isOppositeDirectionY =
                        this.selected_area.startPointY > currentPointY;

                    if (
                        Math.abs(old_width - this.selected_area.width) > 0 &&
                        Math.abs(old_height - this.selected_area.height) > 0
                    ) {
                        this.props.onStartSelectedArea(this.props.document);
                        if (!this.state.is_selection_area) {
                            this.setState({ is_selection_area: true });
                        }
                        this.createBox(this.normalizedSelectedAreaCoordinates(this.selected_area));
                    }
                }
                break;
            case STATUS.Resize_Bottom:
            case STATUS.Resize_Left:
            case STATUS.Resize_Top:
            case STATUS.Resize_Right:
                {
                    const elemRect = this.container_image_ref.current.getBoundingClientRect();

                    switch (this.status) {
                        case STATUS.Resize_Left:
                        case STATUS.Resize_Right:
                            const START_POINT_X_OFFSET = elemRect.left;
                            const diff_left = currentPointX - this.selected_area.startPointX;
                            const new_left = this.selected_area.startPointX + diff_left;
                            let new_width;

                            switch (this.status) {
                                case STATUS.Resize_Left:
                                    new_width =
                                        this.selected_area.initWidth -
                                        (new_left - this.selected_area.initStartPointX);

                                    if (new_width > 0) {
                                        this.selected_area.offsetStartPointX =
                                            new_left - START_POINT_X_OFFSET;
                                        this.selected_area.startPointX = currentPointX;
                                    }
                                    break;
                                case STATUS.Resize_Right:
                                    new_width = new_left - this.selected_area.initStartPointX;
                                    if (new_width < 0) {
                                        this.selected_area.offsetStartPointX =
                                            new_left - START_POINT_X_OFFSET;
                                        this.selected_area.startPointX = currentPointX;
                                    }
                                    break;
                            }

                            this.selected_area.width = Math.abs(new_width);

                            this.selected_area.isOppositeDirectionX =
                                this.selected_area.startPointX > currentPointX;

                            break;

                        case STATUS.Resize_Top:
                        case STATUS.Resize_Bottom:
                            const START_POINT_Y_OFFSET = elemRect.top;
                            const diff_top = currentPointY - this.selected_area.startPointY;
                            const new_top = this.selected_area.startPointY + diff_top;
                            let new_height = 0;

                            switch (this.status) {
                                case STATUS.Resize_Top:
                                    new_height =
                                        this.selected_area.initHeight -
                                        (new_top - this.selected_area.initStartPointY);

                                    if (new_height > 0) {
                                        this.selected_area.offsetStartPointY =
                                            new_top - START_POINT_Y_OFFSET;
                                        this.selected_area.startPointY = currentPointY;
                                    }
                                    break;
                                case STATUS.Resize_Bottom:
                                    new_height = new_top - this.selected_area.initStartPointY;
                                    if (new_height < 0) {
                                        this.selected_area.offsetStartPointY =
                                            new_top - START_POINT_Y_OFFSET;
                                        this.selected_area.startPointY = currentPointY;
                                    }
                                    break;
                            }

                            this.selected_area.height = Math.abs(new_height);

                            this.selected_area.isOppositeDirectionY =
                                this.selected_area.startPointY > currentPointY;

                            break;
                    }

                    this.createBox(this.normalizedSelectedAreaCoordinates(this.selected_area));
                }
                break;
            case STATUS.Move_Area:
                {
                    const elemRect = this.container_image_ref.current.getBoundingClientRect();

                    const START_POINT_X_OFFSET = elemRect.left;
                    const START_POINT_Y_OFFSET = elemRect.top;

                    const offsetStartPointX = currentPointX - START_POINT_X_OFFSET;
                    const offsetStartPointY = currentPointY - START_POINT_Y_OFFSET;

                    const rr = this.normalizedSelectedAreaCoordinates(this.selected_area);

                    if (
                        offsetStartPointX - this.selected_area.dx < 0 ||
                        offsetStartPointX - this.selected_area.dx + rr.width > elemRect.width
                    ) {
                        return;
                    }

                    if (
                        offsetStartPointY - this.selected_area.dy < 0 ||
                        offsetStartPointY - this.selected_area.dy + rr.height > elemRect.height
                    ) {
                        return;
                    }

                    this.selected_area.offsetStartPointX = offsetStartPointX;
                    this.selected_area.offsetStartPointY = offsetStartPointY;

                    this.createBox(this.normalizedSelectedAreaCoordinates(this.selected_area));
                }
                break;
            default:
                if (e.target !== this.container_area_ref.current) {
                    this.hovered_area = this.calculateNewSelectedArea(e);
                    const textract_result = this.convertResultToTextract(
                        this.normalizedSelectedAreaCoordinates(this.hovered_area)
                    );
                    const box = this.selectBox(textract_result);
                    if (box) {
                        const result = this.convertFromTextract(box.position);
                        this.setState({
                            hoverd_box: {
                                left: result.left,
                                top: result.top,
                                width: result.width,
                                height: result.height
                            }
                        });
                    } else {
                        if (this.state.hoverd_box) {
                            this.setState({ hoverd_box: undefined });
                        }
                    }
                }
        }
    };

    normalizedSelectedAreaCoordinates(selected_area: SelectedArea) {
        let result: ICoordinates = {
            left: 0,
            top: 0,
            width: 0,
            height: 0
        };

        result = {
            left: selected_area.offsetStartPointX - selected_area.dx,
            top: selected_area.offsetStartPointY - selected_area.dy,
            width: selected_area.width,
            height: selected_area.height
        };

        if (selected_area.isOppositeDirectionX) {
            result.left -= result.width;
        }

        if (selected_area.isOppositeDirectionY) {
            result.top -= result.height;
        }

        return result;
    }

    onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.container_image_ref.current.removeEventListener('mouseleave', this.onMouseUp);

        const status = this.status;
        this.status = STATUS.Empty;

        this.props.onEndSelectedArea(this.props.document);
        if (this.state.is_selection_area) {
            this.setState({ is_selection_area: false });
        }
        if (!this.props.isActive) {
            return;
        }

        const result = this.normalizedSelectedAreaCoordinates(this.selected_area);
        if (result.left === 0 || result.top === 0) {
            this.props.onSelected(this.props.document, false);
            return;
        }

        const textract_result = this.convertResultToTextract(result);
        if (status === STATUS.Move_Area) {
            this.props.onSelectedArea(this.props.document, result, textract_result);

            const elemRect = this.container_area_ref.current.getBoundingClientRect();

            this.selected_area.offsetStartPointX -= this.selected_area.dx;
            this.selected_area.offsetStartPointY -= this.selected_area.dy;

            this.selected_area.startPointX = elemRect.left;
            this.selected_area.startPointY = elemRect.top;

            this.selected_area.dy = 0;
            this.selected_area.dx = 0;
        } else {
            if (result.width === 0 || result.height === 0) {
                const result = this.normalizedSelectedAreaCoordinates(this.hovered_area);

                if (result.left === 0 || result.top === 0) {
                    this.props.onSelected(this.props.document, false);
                    return;
                }
                const textract_result = this.convertResultToTextract(result);

                const box = this.selectBox(textract_result);
                if (box) {
                    const cor = this.convertFromTextract(box.position);
                    this.createBox(cor);

                    if (!this.props.selected) {
                        this.props.onSelected(this.props.document, true);
                    }
                    this.props.onSelectedArea(this.props.document, result, box.position);
                }
            } else {
                this.props.onSelectedArea(this.props.document, result, textract_result);
            }
        }
    };

    selectBox(textract_coordinates: ICoordinates) {
        const boxes = this.props.boxes;

        let selectedBox;

        for (let i = 0, len = boxes.length; i < len; i++) {
            const box = boxes[i];

            const { left, top, width, height } = box.position;

            if (
                textract_coordinates.left >= left &&
                textract_coordinates.left <= left + width &&
                textract_coordinates.top >= top &&
                textract_coordinates.top <= top + height
            ) {
                selectedBox = box;
                break;
            }
        }

        return selectedBox;
    }

    convertResultToTextract = ({ left: x, top: y, width, height }: ICoordinates) => {
        const img_width = this.props.img_width; //this.image_ref.current.getBoundingClientRect().width;
        const img_height = this.props.img_height; //this.image_ref.current.getBoundingClientRect().height;

        const original_width = this.props.naturalWidth; //this.image_ref.current.naturalWidth;
        const original_height = this.props.naturalHeight; //this.image_ref.current.naturalHeight;

        const result: ICoordinates = { left: 0, top: 0, width: 0, height: 0 };

        const xx = original_width / img_width;
        const yy = original_height / img_height;

        result.left = (x * xx) / original_width;
        result.top = (y * yy) / original_height;

        result.width = (width * xx) / original_width;
        result.height = (height * yy) / original_height;

        // this.props.onSelectedArea(this.props.imageId, { x, y, width, height }, result, isMoved);
        return result;
    };

    convertFromTextract(box: ICoordinates) {
        const IMAGE_WIDTH = this.props.img_width; ///this.image_ref.current.getBoundingClientRect().width;
        const IMAGE_HEIGHT = this.props.img_height; //this.image_ref.current.getBoundingClientRect().height;
        const left = IMAGE_WIDTH * box.left;
        const top = IMAGE_HEIGHT * box.top;

        const width = IMAGE_WIDTH * box.width;
        const height = IMAGE_HEIGHT * box.height;

        return {
            left,
            top,
            width,
            height
        };
    }

    renderMark = () => {
        const { selectedContent, isKey, selectedBoxes } = this.props;

        const arr = selectedBoxes.map((el: any) => {
            return {
                ...el,
                position: this.convertFromTextract(el.position),
                isKey: isKey
            };
        });

        let result = arr;

        if (selectedContent && this.props.document === selectedContent.file) {
            result = arr.filter((item) => item.id !== selectedContent.id);

            let content: IContent | undefined;
            if (this.props.isKey) {
                if (selectedContent.content?.position.top !== null) {
                    content = selectedContent.content;
                }
            } else if (selectedContent.related_content?.position.top !== null) {
                content = selectedContent.related_content;
            }

            if (content) {
                result.push({
                    ...content,
                    isKey: !this.props.isKey,
                    position: this.convertFromTextract(content.position)
                });
            }
        }

        this.setState({ selectedBoxes: result });
    };

    onAcceptText = (value: string) => {
        this.removeContainer();
        this.props.onSelected(this.props.document, false);
        this.props.onAcceptText(this.props.document, value);
    };

    onCancelText = () => {
        this.removeContainer();
        this.props.onSelected(this.props.document, false);
        this.props.onCancelText();
    };

    render() {
        const { url, floatLabel, floatText, isKey } = this.props;
        const { only_float, hoverd_box, selectedBoxes, float_popup, is_selection_area, hidden } =
            this.state;

        return (
            <>
                <div
                    style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}
                    ref={this.container_image_ref}
                >
                    <SelectedBoxes selectedBoxes={selectedBoxes} />
                    {hoverd_box && (
                        <Mark
                            hover={true}
                            isKeyActive={isKey}
                            isActive={false}
                            style={{
                                top: `${hoverd_box.top}px`,
                                left: `${hoverd_box.left}px`,
                                width: `${hoverd_box.width}px`,
                                height: `${hoverd_box.height}px`
                            }}
                        />
                    )}

                    {!hidden && this.props.selected && !only_float && (
                        <>
                            <div
                                ref={this.container_area_ref}
                                style={{
                                    zIndex: 10,
                                    position: 'absolute',
                                    left: this.state.box.left,
                                    top: this.state.box.top,
                                    width: this.state.box.width,
                                    height: this.state.box.height
                                    // cursor: 'grab'
                                    // border: '2px solid #11adff'
                                }}
                            >
                                <span
                                    ref={this.area_left_resize_ref}
                                    style={{
                                        position: 'absolute',
                                        left: LINE_OFFSET,
                                        height: this.state.box.height,
                                        width: LINE_SIZE,
                                        backgroundColor: '#11adff'
                                        // cursor: 'col-resize'
                                    }}
                                />
                                <span
                                    ref={this.area_right_resize_ref}
                                    style={{
                                        position: 'absolute',
                                        right: LINE_OFFSET,
                                        height: this.state.box.height,
                                        width: LINE_SIZE,
                                        backgroundColor: '#11adff'
                                        // cursor: 'col-resize'
                                    }}
                                />
                                <span
                                    ref={this.area_top_resize_ref}
                                    style={{
                                        position: 'absolute',
                                        top: LINE_OFFSET,
                                        width: this.state.box.width,
                                        height: LINE_SIZE,
                                        backgroundColor: '#11adff'
                                        // cursor: 'row-resize'
                                    }}
                                />
                                <span
                                    ref={this.area_bottom_resize_ref}
                                    style={{
                                        position: 'absolute',
                                        bottom: LINE_OFFSET,
                                        width: this.state.box.width,
                                        height: LINE_SIZE,
                                        backgroundColor: '#11adff'
                                        // cursor: 'row-resize'
                                    }}
                                />

                                {/* {is_selection_area && (
                                    <FloatInput
                                        label={floatLabel || ''}
                                        top={float_popup.height + 15}
                                        left={
                                            float_popup.width < 150
                                                ? (float_popup.width - 150) / 2
                                                : 0
                                        }
                                        width={float_popup.width}
                                        text={floatText || ''}
                                        onCancelText={this.onCancelText}
                                        onAcceptText={this.onAcceptText}
                                    />
                                )} */}
                            </div>
                        </>
                    )}
                </div>
                {!hidden && this.props.selected && !is_selection_area && (
                    <FloatInput
                        label={floatLabel || ''}
                        top={float_popup.top + float_popup.height + 15}
                        //TODO:
                        left={
                            float_popup.width < 150
                                ? float_popup.left - (150 - float_popup.width) / 2
                                : float_popup.left
                        }
                        width={float_popup.width}
                        text={floatText || ''}
                        onCancelText={this.onCancelText}
                        onAcceptText={this.onAcceptText}
                    />
                )}
            </>
        );
    }
}

const SelectedBoxes: React.FC<{ selectedBoxes: ISelectedBox[]; isKey?: boolean }> = React.memo(
    ({ selectedBoxes }) => {
        return (
            <>
                {selectedBoxes.map((box) => {
                    return (
                        <Mark
                            hover={false}
                            key={box.id + `${box.isKey}`}
                            isKeyActive={box.isKey}
                            isActive={false}
                            isSelected={box.key}
                            style={{
                                top: `${box.position.top}px`,
                                left: `${box.position.left}px`,
                                width: `${box.position.width}px`,
                                height: `${box.position.height}px`
                            }}
                        />
                    );
                })}
            </>
        );
    }
);

export default Editor;
