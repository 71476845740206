import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';

import { ChangePassSchemaSchema } from 'helpers/validations';

import InputLabel from 'components/InputLabel';
import PrimaryButton from 'components/PrimaryButton';

import { Filter } from 'styles/blocks';
import { CancelButton } from 'styles/text';

import closePng from 'assets/images/icons/closeWhite.png';

const styles = {
    inputContainer: {
        marginBottom: 15
    },
    labelStyle: {
        minWidth: 160
    },
    btn: { width: 125, height: 55, fontSize: 18, marginLeft: 28 }
};

interface Props {
    closePopUp: () => void;
}

const ChangePass: FC<Props> = ({ closePopUp }) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);

    const formik = useFormik({
        validationSchema: ChangePassSchemaSchema,
        initialValues: {
            current_password: '',
            new_password: '',
            repeat_password: ''
        },
        onSubmit: ({ current_password, new_password }) => {
            setLoading(true);
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    return Auth.changePassword(user, current_password, new_password);
                })
                .then(() => setStep(2))
                .finally(() => setLoading(false))
                .catch((err) => {
                    formik.setErrors({ current_password: 'Wrong password' });
                });
        }
    });
    const { errors, values, touched, handleChange, handleSubmit } = formik;

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>CHANGE PASSWORD</HeaderText>
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                <Main>
                    {step === 1 ? (
                        <form>
                            <InputLabel
                                id="current_password"
                                name="current_password"
                                mark
                                type="password"
                                label="Current Password"
                                error={errors.current_password}
                                touched={touched.current_password}
                                onChange={handleChange}
                                value={values.current_password}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                id="new_password"
                                name="new_password"
                                mark
                                type="password"
                                label="New Password"
                                error={errors.new_password}
                                touched={touched.new_password}
                                onChange={handleChange}
                                value={values.new_password}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                id="repeat_password"
                                name="repeat_password"
                                mark
                                type="password"
                                label="Confirm Password"
                                error={errors.repeat_password}
                                touched={touched.repeat_password}
                                onChange={handleChange}
                                labelStyle={styles.labelStyle}
                                value={values.repeat_password}
                            />

                            <Buttons>
                                <CancelButton light onClick={closePopUp}>
                                    Cancel
                                </CancelButton>
                                <PrimaryButton
                                    loading={loading}
                                    title="SAVE"
                                    onClick={handleSubmit}
                                    style={styles.btn}
                                />
                            </Buttons>
                        </form>
                    ) : (
                        <>
                            <HeaderText>Your password has been successfully changed.</HeaderText>
                            <Buttons>
                                <PrimaryButton title="OK" onClick={closePopUp} style={styles.btn} />
                            </Buttons>
                        </>
                    )}
                </Main>
            </Container>
        </Filter>
    );
};

export default ChangePass;

const Container = styled.div`
    min-width: 600px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`;
