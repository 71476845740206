import React, { FC } from "react";

import { MyProfileProps } from "./index";

import PrimaryHeader from "components/PrimaryHeader";

import { Body, Main } from "./styles";
import { Container } from "styles/blocks";
import MyDetails from "./components/MyDetails";

const MyProfile: FC<MyProfileProps> = ({ user, history, updateUser }) => {
  return (
    <Container>
      <PrimaryHeader
        title="My Profile"
        margin={`0 0 30px 0`}
        onClick={() => history.push("/my-profile")}
      />
      <Main>
        <Body>
          <MyDetails user={user} updateUser={updateUser} />
        </Body>
      </Main>
    </Container>
  );
};

export default MyProfile;
