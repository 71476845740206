import { ReceiptActionTypes, ReceiptState } from './types';

const CAPTURED_CONFIG: ReceiptState['capturedConfig'] = {
    document_category: { label: 'Category', visible: false },
    account_number: { label: 'Account Number', visible: false },
    bank_code: { label: 'Bank Code', visible: false },
    iban: { label: 'IBAN', visible: false },
    bic_swift: { label: 'BIC/SWIFT', visible: false },
    code: { label: 'Code', visible: false },
    vat_id: { label: 'VAT ID', visible: false },
    street: { label: 'Street', visible: false },
    city: { label: 'City', visible: false },
    zipcode: { label: 'Zip code', visible: false },
    country: { label: 'Country', visible: false },
    document_id: { label: 'Document ID', visible: false },
    issue_date: { label: 'Issue Date', visible: false, is_date: true },
    amount_total: { label: 'Total Amount', visible: false },
    amount_total_base: { label: 'Total Without Tax', visible: false },
    amount_tax: { label: 'Total Tax', visible: false },
    currency: { label: 'Currency', visible: false }
};

const initialState: ReceiptState = {
    receipts: {
        items: []
    },
    loading: false,
    receiptHistory: [],
    receiptPreview: null,
    receiptEmailInfo: null,
    duplicateInfo: null,
    capturedConfig: CAPTURED_CONFIG,
    count: {
        processing: 0,
        archive: 0,
        error: 0,
        review: 0,
        ready: 0,
        unassigned: 0,
        converting: 0,
        rejected: 0,
        saving: 0,
        failed: 0
    }
};

const receiptReducer = (state = initialState, actions: ReceiptActionTypes): ReceiptState => {
    switch (actions.type) {
        case 'SET_RECEIPT_LOADING':
            return {
                ...state,
                loading: actions.loading
            };
        case 'SET_RECEIPTS':
            return {
                ...state,
                receipts: actions.receipts
            };
        case 'ADD_RECEIPT':
            return {
                ...state,
                receipts: {
                    ...state.receipts,
                    items: [actions.receipt, ...state.receipts.items]
                }
            };
        case 'UPDATE_RECEIPTS':
            return {
                ...state,
                receipts: {
                    ...state.receipts,
                    items: state.receipts.items.map((item) => {
                        if (item.receipt_id === actions.receipt.receipt_id) {
                            return actions.receipt;
                        }
                        return item;
                    })
                }
            };
        case 'DELETE_RECEIPT':
            return {
                ...state,
                receipts: {
                    ...state.receipts,
                    items: state.receipts.items.filter((item) => {
                        return item.receipt_id !== actions.receipt_id;
                    })
                }
            };
        case 'SET_RECEIPT_HISTORY':
            return {
                ...state,
                receiptHistory: actions.history
            };
        case 'SET_COUNT_LIST':
            return {
                ...state,
                count: {
                    ...state.count,
                    processing: actions.data.receipts.processing,
                    archive: actions.data.receipts.archive,
                    error: actions.data.receipts.error,
                    review: actions.data.receipts.review,
                    ready: actions.data.receipts.ready,
                    unassigned: actions.data.receipts.unassigned,
                    converting: actions.data.receipts.converting,
                    rejected: actions.data.receipts.rejected,
                    saving: actions.data.receipts.saving,
                    failed: actions.data.receipts.failed
                }
            };
        case 'SET_UNASSIGNED_LIST':
            return {
                ...state,
                count: {
                    ...state.count,
                    unassigned:
                        actions.data.total_unassigned_receipts +
                        actions.data.total_unassigned_invoices
                }
            };
        case 'SET_COUNT':
            // if (!actions.num || !actions.receipt_type || !actions.status){
            //     return {...state}
            // }
            return {
                ...state,
                count: {
                    ...state.count,
                    [actions.status]: Math.max(0, state.count[actions.status] + actions.num)
                }
            };
        case 'SET_RECEIPT_EMAIL_INFO':
            return {
                ...state,
                receiptEmailInfo: actions.data
            };
        case 'SET_RECEIPT_PREVIEW':
            return {
                ...state,
                receiptPreview: actions.data
            };
        case 'TOOGLE_CAPTURE_DATA':
            return {
                ...state,
                capturedConfig: {
                    ...state.capturedConfig,
                    [actions.key]: {
                        // @ts-ignore
                        ...state.capturedConfig[actions.key],
                        // @ts-ignore
                        visible: !state.capturedConfig[actions.key].visible
                    }
                }
            };
        case 'SET_DUPLICATE_INFO':
            return {
                ...state,
                duplicateInfo: actions.data
            };
        default:
            return state;
    }
};

export default receiptReducer;
