import React, { FC } from "react";
import styled from "styled-components/macro";

import { Loader } from "components/Other/Loader";

interface Props {
  preview: null | any;
}

const FullPicture: FC<Props> = ({ preview }) => {
  return (
    <Filter>
      {!preview ? <Loader /> : <Picture src={preview} alt="preview" />}
    </Filter>
  );
};

export default FullPicture;

const Filter = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Picture = styled.img`
  width: 100%;
  height: auto;
`;
