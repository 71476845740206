import { Dispatch } from 'redux';
import { AppState } from 'redux/rotReducer';

import { ReduxActions } from '../types';
import { API } from '../../net/api';

export class SettingActions {
    static getReceiptSchema = () => async (disaptch: Dispatch<ReduxActions>) => {
        disaptch({ type: 'SET_SETTING_LOADING', loading: true });
        try {
            const res = await API.getReceiptSchema();
            disaptch({
                type: 'SET_RECEIPT_SCHEMA',
                data: createShema(res.data),
                initialData: res.data
            });
        } catch (error) {
        } finally {
            disaptch({ type: 'SET_SETTING_LOADING', loading: false });
        }
    };

    static updateRequired = (id: string): ReduxActions => ({
        type: 'UPDATE_SHEMA_REQUIRED',
        id
    });

    static updateHidden = (id: string): ReduxActions => ({
        type: 'UPDATE_SHEMA_HIDDEN',
        id
    });

    static saveReceiptSchema = () => async (disaptch: any, getState: () => AppState) => {
        try {
            disaptch({ type: 'SET_SETTING_LOADING', loading: true });

            const { schema, initialSchema } = getState().setting;
            // const data = organizeShema(schema);
            const data_2: typeof schema = createShema(initialSchema);

            let temp: any[] = [];

            data_2.forEach((item: any) => {
                const t = schema.find((it) => item.id === it.id);

                if (!t || t.category !== 'datapoint') {
                    return;
                }

                if (!!t.constraints.required !== !!item.constraints.required) {
                    temp.push(t);
                    return;
                }
                if (!!t.hidden !== !!item.hidden) {
                    temp.push(t);
                    return;
                }
            });

            if (temp.length === 0) {
                return;
            }
            await API.saveReceiptSchema(temp);

            disaptch(SettingActions.getReceiptSchema());
        } catch (error) {
        } finally {
            disaptch({ type: 'SET_SETTING_LOADING', loading: false });
        }
    };
}

function createShema(arr: any) {
    const data: any[] = [];

    arr.forEach((a: any) => {
        const e = { ...a };
        if (e.items) {
            const { items, ...payload } = e;
            const a = createShema(items);
            data.push(payload, ...a);
            return;
        }
        data.push(e);
    });

    return data;
}

function organizeShema(items: any): any {
    const data = items.filter((p: any) => !p.parent_schema_id);

    for (let i = 0; i < items.length; i++) {
        const t = items[i];

        if (t.parent_schema_id) {
            let p = data.find((a: any) => a.id === t.parent_schema_id);

            if (!p) {
                p = items.find((a: any) => a.id === t.parent_schema_id);
                p = { ...p };
                data.push(p);
            }

            if (!p.items) {
                p.items = [];
            }

            let index = p.items.findIndex((a: any) => a.id === t.id);
            if (index !== -1) {
                p.items[index] = t;
            } else {
                p.items.push(t);
            }
        }
    }

    if (data.some((item: any) => !!item.parent_schema_id)) {
        return organizeShema(data);
    }

    return data;
}
