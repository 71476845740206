import PrimaryButton from 'components/PrimaryButton';
import { useFormik } from 'formik';
import { ListSchema, ListSchemaSimple } from 'helpers/validations';
import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { ICompany } from 'redux/user/types';
import styled from 'styled-components';

import DropInput from 'components/DropInput';
import InputLabel from 'components/InputLabel';

const styles = {
    inputContainer: {
        marginBottom: 18
    },
    labelStyle: {
        minWidth: 90,
        color: '#9191AF'
    }
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    path: string;
    companies: ICompany[];
    activeAllCompanies: boolean;
    createCategory: (name: string, code: string, company: string) => Promise<any>;
    onUpload: () => void;

}

const NewItem: FC<Props> = ({ path, companies, activeAllCompanies, createCategory,onUpload }) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validationSchema: activeAllCompanies ? ListSchema : ListSchemaSimple,
        initialValues: {
            name: '',
            code: '',
            company: ''
        },
        onSubmit: async ({ name, company, code }) => {
            setLoading(true);
            const err = await createCategory(name, code, company);
            if (err) {
                formik.setErrors({ name: err });
            } else {
                formik.resetForm();
            }
            setLoading(false);
        }
    });
    const { touched, values, errors } = formik;

    useEffect(() => {
        if (path) {
            formik.resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    const companiesName = companies.map((c) => c.name);

    return (
        <Container onSubmit={formik.handleSubmit}>
            <Block>
                <InputLabel
                    mark
                    id="name"
                    name="name"
                    type="text"
                    label="Name"
                    error={errors.name}
                    touched={touched.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={values.name}
                    labelStyle={styles.labelStyle}
                    containerStyle={styles.inputContainer}
                />
                <InputLabel
                    id="code"
                    name="code"
                    type="text"
                    label="Code"
                    error={errors.code}
                    touched={touched.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={values.code}
                    labelStyle={styles.labelStyle}
                    containerStyle={styles.inputContainer}
                />
                {activeAllCompanies && (
                    <DropInput
                        mark
                        label="Company"
                        list={companiesName}
                        labelStyle={styles.labelStyle}
                        selected={values.company}
                        onClick={(t) => formik.setFieldValue('company', t)}
                        error={touched.company && errors.company ? errors.company : ''}
                    />
                )}
            </Block>

            <Block row>
                <PrimaryButton
                    loading={loading}
                    title="ADD"
                    onClick={formik.handleSubmit}
                    style={{ width: '90px', height: '35px' }}
                />
                <PrimaryButton
                    loading={loading}
                    title="Upload XLSX"
                    onClick={onUpload}
                    style={{ width: '120px', height: '35px',marginLeft:8 }}
                />
            </Block>
        </Container>
    );
};

export default NewItem;

const Container = styled.form`
    // display: flex;
    // justify-content: space-between;
    padding-left: 30px;
    max-width: 550px;
`;

const Block = styled.div<{ row?: boolean }>`
    flex: 1;
    display: flex;
    ${({ row }) => (row ? '' : 'flex-direction: column;')}
    margin-right: 20px;
`;
