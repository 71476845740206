import React, { CSSProperties, FC, InputHTMLAttributes, useState } from "react";
import styled from "styled-components";

import icon from "assets/images/icons/pass.png";
import iconHide from "assets/images/icons/pass-hide.png";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  secure?: boolean;
  touched?: boolean;
  style?: CSSProperties;
}

const AutInput: FC<Props> = ({ secure, style, error, touched, ...rest }) => {
  const [showPass, setPass] = useState(false);
  const onIconPress = () => setPass(!showPass);

  let borderStyle = { border: "1px solid rgba(0, 0, 0, 0.1)" };
  if (touched && error) {
    borderStyle = { border: "1px solid #D16E6E" };
  }
  if (touched && !error) {
    borderStyle = { border: "1px solid #CC8AD0" };
  }

  return (
    <InputContainer style={style}>
      <InputBlock>
        <Input
          {...rest}
          style={borderStyle}
          type={showPass ? "text" : rest.type}
        />
        {secure && (
          <IconContainer onClick={onIconPress}>
            <Icon src={showPass ? iconHide : icon} alt="password" />
          </IconContainer>
        )}
      </InputBlock>
      {touched && error && <Error>{error}</Error>}
    </InputContainer>
  );
};

const InputContainer = styled.div``;

const InputBlock = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 332px;
  height: 53px;
  border: none;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05);
  outline: none;
  font-size: 14px;
  font-family: "NunitoSans-Bold";
  padding-left: 24px;
  border-radius: 5px;
  &::placeholder {
    color: #acacac;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-right: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 1;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Error = styled.p`
  font-size: 10px;
  margin-top: 5px;
  color: #d16e6e;
  margin-left: 24px;
  font-family: "NunitoSans-ExtraBold";
`;

export default AutInput;
