import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { UserState } from 'redux/user/types';
import { IReceipt } from 'redux/receipt/types';
import { UserActions } from 'redux/user/actions';
import { ReceiptActions } from 'redux/receipt/actions';
import { AssistantNet, AssistantNetEvent, IAssistantNetMessage } from 'net/assistantNet';

import List from 'pages/List';
import Admin from 'pages/Admin';
import MyTeam from 'pages/MyTeam';
import Receipts from 'pages/Receipts';
import Settings from 'pages/Settings';
import MyProfile from 'pages/MyProfile';
import Dashboard from 'pages/Dashboard';
import AdminInfo from 'pages/AdminInfo';
import EditReceipt from 'pages/EditReceipt';
import Integration from 'pages/Integration';
import SelectCompany from 'pages/SelectCompany';
import IntegrationGoogle from 'pages/IntegrationGoogle';
import IntegrationGoogleAuth from 'pages/IntegrationGoogleAuth';

import InfoPopUp from 'components/InfoPopUp';
import SideMenu from 'components/SideMenu/SideMenu';
import FullScreenLoader from 'components/FullScreenLoader';

import { Wrapper } from 'styles/blocks';
import { IGetReceiptParams } from '../net/api';

interface Props {
    user: UserState;
    reconectEvent: () => any;
    fetchData: () => Promise<void>;
    addReceipt: (receipt: IReceipt) => void;
    updateReceipt: (receipt: IReceipt) => void;
    deleteCompanyEvent: (company_id: string) => any;
    deleteReceiptAction: (receipt_id: string) => void;
    getReceipts: (params: IGetReceiptParams) => any;
}

const AppRoutes: FC<Props> = ({
    user: { loading, activeCompany, profile, activeAllCompanies },
    fetchData,
    addReceipt,
    updateReceipt,
    reconectEvent,
    deleteCompanyEvent,
    deleteReceiptAction,
    getReceipts
}) => {
    const [infoPop, setInfoPop] = useState(false);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        AssistantNet.instance.on('close', (e) => {
            setInfoPop(true);
        });

        const onMessage = async (e: IAssistantNetMessage<any>) => {
            switch (e.event) {
                case AssistantNetEvent.receipt:
                    addReceipt(e.payload);
                    break;
                case AssistantNetEvent['receipt-update']:
                    updateReceipt(e.payload);
                    break;
                case AssistantNetEvent['receipt-delete']:
                    deleteReceiptAction(e.payload.receipt_id);
                    break;
                case AssistantNetEvent['company-delete']:
                    deleteCompanyEvent(e.payload.company_id);
                    break;
                default:
                    break;
            }
        };

        AssistantNet.instance.on('message', onMessage);

        return () => {
            AssistantNet.instance.off('message', onMessage);
            AssistantNet.instance.close();
            AssistantNet.instance.removeAllListeners();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Keep alive WebSocket connection
    useEffect(() => {
        var intervalId = setInterval(() => {
            AssistantNet.instance.send('ping', {});
        }, 60 * 5 * 1000);

        return () => {
            clearInterval(intervalId!);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <FullScreenLoader />;
    }

    return (
        <BrowserRouter>
            <Wrapper>
                {infoPop && (
                    <InfoPopUp
                        buttonText="RECONNECT"
                        title="Session expired"
                        text="Your previous session has expired."
                        onPress={reconectEvent}
                        closePopUp={() => setInfoPop(false)}
                    />
                )}
                <SideMenu />
                <Switch>
                    <Route component={Dashboard} path="/dashboard" exact />
                    <Route component={Receipts} path="/receipts/:status" exact />
                    {/* List */}
                    <Route component={List} path="/customers" exact />
                    <Route component={List} path="/suppliers" exact />
                    <Route component={List} path="/categories" exact />
                    <Route component={List} path="/items" exact />
                    <Route component={Settings} path="/settings" exact />

                    <Route component={EditReceipt} path="/edit-receipt/:id" exact />
                    <Route component={SelectCompany} path="/select-company/:id" exact />

                    {/* Manage */}
                    {activeCompany.role === 'admin' && (
                        <Route component={MyTeam} path="/my-team" exact />
                    )}
                    <Route component={MyProfile} path="/my-profile" exact />
                    {profile.is_admin && <Route component={Admin} path="/admin" exact />}
                    {profile.is_admin && (
                        <Route component={AdminInfo} path="/admin/:company_id" exact />
                    )}
                    {activeCompany.role === 'admin' && !activeAllCompanies && (
                        <Route component={Integration} path="/integration" exact />
                    )}
                    <Route
                        component={IntegrationGoogleAuth}
                        path="/integration/:service_type/authcallback"
                        exact
                    />
                    <Route component={IntegrationGoogle} path="/integration/:service_type" exact />
                    <Redirect to="/dashboard" />
                </Switch>
            </Wrapper>
        </BrowserRouter>
    );
};

const mapStateToProps = (state: any) => ({
    user: state.user
});

const mapDispatchToProps = {
    fetchData: UserActions.fetchData,
    addReceipt: ReceiptActions.addReceipt,
    reconectEvent: UserActions.reconectEvent,
    updateReceipt: ReceiptActions.updateReceipt,
    deleteCompanyEvent: UserActions.deleteCompanyEvent,
    deleteReceiptAction: ReceiptActions.deleteReceiptAction,
    getReceipts: ReceiptActions.getReceipts
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
