import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Component from "./SelectCompany";
import { AppState } from "redux/rotReducer";
import { IReceipt } from "redux/receipt/types";
import { ReceiptActions } from "redux/receipt/actions";
import { ICompany } from "redux/user/types";
import { ISelectCompanyParams } from "net/api";
import { EditReceiptActions } from "redux/editReceipt/actions";
import { EditReceiptState } from "redux/editReceipt/types";

export type SelectCompanyProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  companies: ICompany[];
  editReceiptState: EditReceiptState;
}
interface LinkDispatchProps {
  onLoadSelectCompanyScene: (receipt_id: string, company_id: string) => any;
  selectCompany: (params: ISelectCompanyParams, receipt: IReceipt) => any;
  deleteReceipt: (receipt: IReceipt) => any;
}

const mapStateToProps = (state: AppState) => ({
  companies: state.user.companies.items,
  editReceiptState: state.editReceipt,
});

const mapDispatchToProps = {
  selectCompany: ReceiptActions.selectCompany,
  onLoadSelectCompanyScene: EditReceiptActions.onLoadSelectCompanyScene,
  deleteReceipt: ReceiptActions.deleteReceipt,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
