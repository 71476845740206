import styled from "styled-components";
import { greyColorDark } from "styles/colors";
import { BigTitle } from "styles/text";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  flex: 1;
  background: #fff;
  border: 0.4px solid #eeeeee;
  border-top: 0;
  border-radius: 10px;
  margin-top: 30px;
`;

export const Label = styled(BigTitle)`
  color: ${greyColorDark};
  margin: 30px 0 30px 30px;
`;

export const Block = styled.div`
  max-height: 660px;
  overflow: auto;
`;

export const SaveBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`;

export const ItemWrapper = styled.div``;
