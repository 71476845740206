import React, { FC } from "react";

import signIcon from "assets/images/icons/sign.png";
import styled from "styled-components";

interface CheckBoxProps {
  active?: boolean;
  onClick?: () => void;
}

const DarkCheckBox: FC<CheckBoxProps> = ({ active, onClick }) => {
  return (
    <Container className="rectangle" onClick={onClick}>
      {active && <Sign src={signIcon} alt="sign" />}
    </Container>
  );
};

export default DarkCheckBox;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 1px solid #9191af;
  border-radius: 5px;
  cursor: pointer;
`;
const Sign = styled.img`
  width: 15px;
  height: 15px;
`;
