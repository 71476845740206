import React from "react";
import styled from "styled-components";

import CheckBox from "../../components/CheckBox";
import { greyColorDark, whiteColor } from "styles/colors";
import { PopUpTitle, RegularText, SmallText } from "styles/text";

import infoIcon from "assets/images/icons/info.png";
import { ReceiptState } from "redux/receipt/types";

const CapturedItem = React.memo(
  ({ item, onClick }: { item: any; onClick: () => void }) => (
    <Item>
      <CheckBox color="white" active={item.visible} onClick={onClick} />
      <RegularText margin="0 0 0 10px" color={whiteColor}>
        {item.label}
      </RegularText>
    </Item>
  )
);

interface Props {
  capturedConfig: ReceiptState["capturedConfig"];
  onClose: () => void;
  toogleCaptureData: (k: string) => void;
}

const CapturedData: React.FC<Props> = ({
  capturedConfig,
  onClose,
  toogleCaptureData,
}) => {
  return (
    <Container onBlur={onClose}>
      <Header>
        <PopUpTitle>Captured Data</PopUpTitle>
      </Header>
      <List>
        {Object.keys(capturedConfig).map((key) => (
          <CapturedItem
            key={key}
            // @ts-ignore
            item={capturedConfig[key]}
            onClick={() => toogleCaptureData(key)}
          />
        ))}
      </List>
      <Footer>
        <FooterBlock>
          <InfoIcon src={infoIcon} alt="info" />
          <SmallText>Data from documents is shown once extracted.</SmallText>
        </FooterBlock>
      </Footer>
    </Container>
  );
};

export default CapturedData;

const Container = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  width: 400px;
  background-color: ${greyColorDark};
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${whiteColor};
  padding: 15px;
`;
const List = styled.div`
  max-height: 200px;
  overflow: auto;
  padding: 15px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const InfoIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid ${whiteColor};
`;
const FooterBlock = styled.div`
  display: flex;
  align-items: center;
`;
