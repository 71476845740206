import React, { FC, useEffect } from "react";

import { AdminProps } from "./index";

import PrimaryHeader from "components/PrimaryHeader";

import { Container } from "styles/blocks";
import Table from "./components/Table";
import LabelInfo from "components/LabelInfo";
import { Block } from "./styles";

const styles = {
  label: {
    minWidth: 80,
  },
  containerInfo: {
    marginRight: 20,
  },
};

const Admin: FC<AdminProps> = ({
  admin,
  history,
  getAdminInfo,
  getAdminCompanies,
  loadAdminCompanies,
}) => {
  const { companies, info } = admin;

  useEffect(() => {
    getAdminInfo();
    getAdminCompanies({});
  }, [getAdminCompanies, getAdminInfo]);

  const onScrollEnd = () => {
    loadAdminCompanies({});
  };

  const onCompanyPress = (company_id: string) => {
    history.push(`/admin/${company_id}`);
  };

  return (
    <Container>
      <PrimaryHeader
        title="Admin"
        margin={`0 0 54px 0`}
        onClick={() => history.push("/my-profile")}
      />
      <Block>
        <LabelInfo
          label="Customers"
          value={info.customers}
          labelStyle={styles.label}
          containerStyle={styles.containerInfo}
        />
        <LabelInfo
          label="Invoices"
          labelStyle={styles.label}
          value={info.documents}
        />
      </Block>

      <Table
        data={companies.items}
        onScrollEnd={onScrollEnd}
        onSelect={(i) => onCompanyPress(i.company_id)}
      />
    </Container>
  );
};

export default Admin;
