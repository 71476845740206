import styled, { css } from 'styled-components';
import { greenColor, orangeColor, primaryColor, redColor } from 'styles/colors';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;
export const Block = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #9191af;
    font-family: 'NunitoSans-Bold';
    margin-right: 25px;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;

interface IconProps {
    inActive?: boolean;
}

export const Icon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 5px;
    opacity: ${({ inActive }: IconProps) => (inActive ? 0.5 : 1)};
`;

export const IconsBlock = styled.div`
    display: flex;
    align-items: center;
`;
export const PagesBlock = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #9191af;
    font-family: 'NunitoSans-Bold';
`;

const buttonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: 'NunitoSans-ExtraBold';
    cursor: pointer;
    padding: 0 10px;
    height: 36px;
    border-radius: 5px;
    margin: 5px;

    &:hover {
        opacity: 0.8;
    }
`;

interface ButtonGreenProps {
    isValid: boolean;
}

export const ButtonGreen = styled.div`
    background-color: ${greenColor};
    color: #fff;
    ${buttonStyle};

    &:hover {
        opacity: ${({ isValid }: ButtonGreenProps) => (isValid ? 0.5 : 0.5)};
    }

    opacity: ${({ isValid }: ButtonGreenProps) => (isValid ? 1 : 0.5)};
`;

export const ButtonToReview = styled.div`
    background-color: ${orangeColor};
    color: #fff;
    ${buttonStyle};

    &:hover {
        opacity: 0.5;
    }

    opacity: ${({ isValid }: ButtonGreenProps) => (isValid ? 1 : 0.5)};
`;

export const ButtonReject = styled.div`
    background-color: ${redColor};
    color: #fff;
    ${buttonStyle};

    &:hover {
        opacity: 0.5;
    }

    opacity: ${({ isValid }: ButtonGreenProps) => (isValid ? 1 : 0.5)};
    z-index: 1;
`;

export const Loading = styled.div`
    background-color: ${primaryColor};
    opacity: 0.5;
    color: #fff;
    ${buttonStyle};
`;

export const ButtonsContainer = styled.div`
    display: flex;
`;

export const GreenContainer = styled.div`
    display: flex;
    @media only screen and (max-width: 1380px) {
        flex-direction: column;
    }
`;
