export function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}

export function isEmptyObj(obj: any) {
  return Object.keys(obj).length === 0;
}

function easeInOutQuad(t: any, b: any, c: any, d: any) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

export function scrollWithAnimation(
  element: any,
  to: number,
  duration: number
) {
  const start = element.scrollTop,
    change = to - start,
    increment = 20;
  let currentTime = 0;
  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}
