import React, { FC } from "react";

import { Image } from "./styles";

interface Props {
  url: string;
}

const Img: FC<Props> = ({ url }) => {
  return <Image src={url} alt="edit image" />;
};

export default React.memo(Img);
