import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';

import { IntegrationProps } from './index';
import { IntegrationSchema, SelectApiKeySchema } from 'helpers/validations';
import { EnumIntegrationServiceType, EnumIntegrationType, IIntegrationBody } from 'net/api';

import ItemCheck from './components/ItemCheck';
import SubmitPopUp from './blocks/SubmitPopUp';
import ApiKeyPopUp from './blocks/ApiKeyPopUp';
import PrimaryHeader from 'components/PrimaryHeader';

import { Box, Main } from './styles';
import { Container } from 'styles/blocks';
import { BigTitle } from 'styles/text';
import { greyColorDark } from 'styles/colors';

const Integration: FC<IntegrationProps> = ({
    history,
    activeCompany,
    changeIntegration,
    activateImportReceipts
}) => {
    const [loading, setLoading] = useState(false);
    const [submitPopUp, setSubmitPopUp] = useState(false);
    const [apiKeyPopUp, setApiKeyPopUp] = useState(false);
    const [activeOption, setActiveOption] = useState(EnumIntegrationType.FTP);

    const onSubmitAction = async (body: IIntegrationBody) => {
        setLoading(true);
        const err = await changeIntegration(body);
        if (err) {
            formik.setErrors({ host: err });
        } else {
            setSubmitPopUp(false);
            setApiKeyPopUp(false);
        }
        setLoading(false);
    };

    const formik = useFormik({
        validationSchema: IntegrationSchema,
        initialValues: {
            host: '',
            user: '',
            password: '',
            port: ''
        },
        onSubmit: (credential) => {
            onSubmitAction({ credential, enabled: true, type: activeOption });
        }
    });

    const formikRevile = useFormik({
        validationSchema: SelectApiKeySchema,
        initialValues: {
            api_key: ''
        },
        onSubmit: ({ api_key }) => {
            onSubmitAction({
                credential: { api_key },
                enabled: true,
                type: activeOption
            });
        }
    });

    const handleChange = (enabled: boolean, key: EnumIntegrationType) => {
        const REQ_INFO = [EnumIntegrationType.NSOFT, EnumIntegrationType.FTP];
        if (REQ_INFO.includes(key) && enabled) {
            setActiveOption(key);
            setSubmitPopUp(true);
        } else {
            onSubmitAction({
                enabled,
                type: key
            });
        }
    };

    const onGooglePress = async (enabled: boolean, service_type: EnumIntegrationServiceType) => {
        setLoading(true);
        await activateImportReceipts(service_type, !enabled);
        setLoading(false);
    };

    const onRevilePress = async (enabled: boolean, type: EnumIntegrationType) => {
        if (enabled) {
            setActiveOption(type);
            setApiKeyPopUp(true);
        } else {
            onSubmitAction({
                enabled,
                type
            });
        }
    };

    return (
        <Container>
            <PrimaryHeader
                title="Integration"
                margin={`0 0 0 0`}
                onClick={() => history.push('/admin')}
            />
            <Main>
                <Box>
                    <InfoBlockWrapper>
                        <Label>Export</Label>
                    </InfoBlockWrapper>
                    <Block>
                        <InfoBlock>
                            <InfoBlockWrapper>
                                <ItemCheck
                                    label="FTP"
                                    disabled={loading}
                                    checked={activeCompany.ftp_export}
                                    handleChange={(b) => handleChange(b, EnumIntegrationType.FTP)}
                                />
                            </InfoBlockWrapper>
                            <ItemCheck
                                label="NSOFT"
                                disabled={loading}
                                checked={activeCompany.nsoft_export}
                                handleChange={(b) => handleChange(b, EnumIntegrationType.NSOFT)}
                            />
                        </InfoBlock>
                        <InfoBlock>
                            <InfoBlockWrapper>
                                <ItemCheck
                                    label="Rivile"
                                    disabled={loading}
                                    checked={activeCompany.rivile_export}
                                    handleChange={(b) =>
                                        onRevilePress(b, EnumIntegrationType.RIVILE_EXPORT)
                                    }
                                />
                            </InfoBlockWrapper>
                        </InfoBlock>
                    </Block>
                </Box>
                <Box>
                    <InfoBlockWrapper>
                        <Label>Import</Label>
                    </InfoBlockWrapper>
                    <Block>
                        <InfoBlock>
                            <InfoBlockWrapper>
                                <ItemCheck
                                    label="SQL"
                                    disabled={loading}
                                    checked={activeCompany.sql_import}
                                    handleChange={(b) => handleChange(b, EnumIntegrationType.SQL)}
                                />
                            </InfoBlockWrapper>

                            <EditFolder>
                                <ItemCheck
                                    label="Google"
                                    disabled={loading}
                                    checked={!!activeCompany.google_drive_import}
                                    handleChange={(b) =>
                                        onGooglePress(b, EnumIntegrationServiceType.GOOGLE)
                                    }
                                />
                                {!!activeCompany.google_drive_import && (
                                    <EditFolderBtn
                                        onClick={() =>
                                            history.push(
                                                `/integration/${EnumIntegrationServiceType.GOOGLE}`
                                            )
                                        }
                                    >
                                        <Label>Edit Folder</Label>
                                    </EditFolderBtn>
                                )}
                            </EditFolder>
                        </InfoBlock>
                        <InfoBlock>
                            <InfoBlockWrapper>
                                <EditFolder>
                                    <ItemCheck
                                        label="Dropbox"
                                        disabled={loading}
                                        checked={!!activeCompany.dropbox_import}
                                        handleChange={(b) =>
                                            onGooglePress(b, EnumIntegrationServiceType.DROPBOX)
                                        }
                                    />
                                    {!!activeCompany.dropbox_import && (
                                        <EditFolderBtn
                                            onClick={() =>
                                                history.push(
                                                    `/integration/${EnumIntegrationServiceType.DROPBOX}`
                                                )
                                            }
                                        >
                                            <Label>Edit Folder</Label>
                                        </EditFolderBtn>
                                    )}
                                </EditFolder>
                            </InfoBlockWrapper>
                            <InfoBlockWrapper>
                                <ItemCheck
                                    label="Rivile"
                                    disabled={loading}
                                    checked={activeCompany.rivile_import}
                                    handleChange={(b) =>
                                        onRevilePress(b, EnumIntegrationType.RIVILE_IMPORT)
                                    }
                                />
                            </InfoBlockWrapper>
                        </InfoBlock>
                    </Block>
                </Box>
                <Box>
                    <InfoBlockWrapper>
                        <Label>Import</Label>
                    </InfoBlockWrapper>
                    <Block>
                        <InfoBlock>
                            <InfoBlockWrapper>
                                <ItemCheck
                                    label="Display only imported list of Suppliers/Customers"
                                    disabled={loading}
                                    checked={!!activeCompany.display_imported_stakeholders}
                                    handleChange={(b) =>
                                        handleChange(
                                            b,
                                            EnumIntegrationType.DISPLAY_IMPORTED_STAKEHOLDERS
                                        )
                                    }
                                />
                            </InfoBlockWrapper>
                        </InfoBlock>
                        <InfoBlock>
                            <InfoBlockWrapper>
                                <ItemCheck
                                    label="Display only imported list of categories"
                                    disabled={loading}
                                    checked={!!activeCompany.display_imported_categories}
                                    handleChange={(b) =>
                                        handleChange(
                                            b,
                                            EnumIntegrationType.DISPLAY_IMPORTED_CATEGORIES
                                        )
                                    }
                                />
                            </InfoBlockWrapper>
                        </InfoBlock>
                    </Block>
                </Box>
            </Main>

            {submitPopUp && (
                <SubmitPopUp
                    formik={formik}
                    loading={loading}
                    label={activeOption === EnumIntegrationType.FTP ? 'FTP' : 'NSOFT'}
                    closePopUp={() => setSubmitPopUp(false)}
                />
            )}
            {apiKeyPopUp && (
                <ApiKeyPopUp
                    formik={formikRevile}
                    loading={loading}
                    closePopUp={() => setApiKeyPopUp(false)}
                />
            )}
        </Container>
    );
};

export default Integration;

const Label = styled(BigTitle)`
    color: ${greyColorDark};
`;

const Block = styled.div`
    display: flex;
    justify-content: space-between;
`;

const InfoBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    &:first-child {
        margin-right: 60px;
    }
`;

const InfoBlockWrapper = styled.div`
    margin-bottom: 20px;
`;

const EditFolder = styled.div`
    display: flex;
    align-items: center;
`;

const EditFolderBtn = styled.div`
    margin-left: 12px;
    cursor: pointer;
`;
