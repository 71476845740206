import React, { createRef, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { IContentSchema, IContentSchemaGrid, IReceiptImageBox } from 'redux/editReceipt/types';
import { EnumReceiptDocumentStatus, IReceiptPage } from 'redux/receipt/types';
import { Container, Image, Wrapper } from './styles';

import Img from './Img';
import ImageActions from './ImageActions';
import { ISelectedBox } from 'pages/EditReceipt/EditReceipt';
import { redColor } from 'styles/colors';
import { IGridLabel } from './Grid';
import { IUseGridBody } from 'net/api';

export interface IEdtiImageBox {
    top: number;
    left: number;
    width: number;
    height: number;
    originalData: IReceiptImageBox;
}

export interface ICoordinates {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface Props {
    disabled?: boolean;
    available_template?: boolean;
    gridTemplate?: IContentSchemaGrid;
    activeKey?: boolean;
    grids: IContentSchemaGrid[];
    pages: IReceiptPage[];
    selectedBoxes: ISelectedBox[];
    boxes: IReceiptImageBox[];
    floatText?: string;
    floatLabel?: string;
    selectedContent?: IContentSchema;
    isActive?: boolean;
    labels: IGridLabel[];
    onSaveAsTemplate: (part: IContentSchemaGrid) => void;
    onUseTemplate: (v: IUseGridBody) => void;
    updateGrid: (parts: IContentSchemaGrid[]) => void;
    onCancelText: () => any;
    onMergePress: () => void;
    setHeight: (n: number) => void;
    onAcceptText: (document_id: string, value: string) => any;
    onSelectedArea: (
        document_id: string,
        original_coordinates: ICoordinates,
        textract_coordinates: ICoordinates
    ) => void;
}

const Images = React.forwardRef<any, Props>(
    (
        {
            disabled,
            grids,
            labels,
            activeKey,
            pages,
            boxes,
            isActive,
            floatText,
            floatLabel,
            selectedBoxes,
            setHeight,
            onCancelText,
            onAcceptText,
            onMergePress,
            onSelectedArea,
            selectedContent,
            updateGrid,
            onSaveAsTemplate,
            onUseTemplate,
            gridTemplate,
            available_template
        },
        ref
    ) => {
        const [selectedImage, setSelectedImage] = useState<{ [key: string]: boolean }>({});
        const [is_edit_grid, setIsEditGrid] = useState(false);
        const [rotateDeg, setRotateDeg] = useState(0);

        const cache = useMemo(() => {
            const cache: { [key: string]: IReceiptImageBox[] } = {};

            for (let i = 0, len = pages.length; i < len; i++) {
                const page = pages[i];
                cache[page.document] = boxes.filter((box) => box.file === page.document);
            }

            return cache;
        }, [boxes]);

        const selectedBoxesCache = useMemo(() => {
            const cache: { [key: string]: ISelectedBox[] } = {};

            for (let i = 0, len = pages.length; i < len; i++) {
                const page = pages[i];
                cache[page.document] = selectedBoxes.filter(
                    (box) => box.document === page.document
                );
            }

            return cache;
        }, [selectedBoxes]);

        const img_refs = useMemo(() => {
            const o: any = {};

            pages.forEach((page) => {
                o[page.document] = createRef<Img>();
            });

            return o;
        }, [pages]);

        useEffect(() => {
            const t: typeof selectedImage = {};

            pages.forEach((page) => {
                t[page.document] = false;
            });
            setSelectedImage(t);
        }, [pages]);

        const onSelect = (document_id: string, state: boolean = false) => {
            const temp = { ...selectedImage };
            for (const key in temp) {
                temp[key] = false;
            }

            temp[document_id] = state;

            setSelectedImage(temp);
        };

        const onStartSeleced = (document_id: string) => {
            onSelect(document_id, true);
        };

        const onEndSeleced = (document_id: string) => {
            onSelect(document_id, false);
        };

        const getParts = () => {
            const payload = [];

            for (const key in img_refs) {
                if (img_refs[key].current) {
                    const grid = img_refs[key].current.getGrid();
                    grid && payload.push(grid);
                }
            }

            return payload;
        };

        const onApplyGrid = () => {
            updateGrid(getParts());
            setIsEditGrid(false);
        };

        const onRotatePress = () => {
            setRotateDeg((prevState) => (prevState === 360 ? 90 : prevState + 90));
        };

        return (
            <Wrapper>
                {!disabled && (
                    <ImageActions
                        onMergePress={onMergePress}
                        is_edit_grid={is_edit_grid}
                        onCancelGrid={() => setIsEditGrid(false)}
                        onApplyGrid={onApplyGrid}
                        onEditGrid={() => setIsEditGrid(true)}
                        onRotatePress={onRotatePress}
                    />
                )}
                <Container ref={ref}>
                    {pages.map((p, ind) => {
                        return p.status === EnumReceiptDocumentStatus.Error ? (
                            <ContainerImage key={p.url}>
                                <Image $rotateDeg={rotateDeg} src={p.url} />
                                <Cover>
                                    <MessageBlock>
                                        <span>The page was not processed</span>
                                    </MessageBlock>
                                </Cover>
                            </ContainerImage>
                        ) : (
                            <ContainerImage key={p.url}>
                                <Img
                                    available_template={available_template}
                                    ref={img_refs[p.document]}
                                    grid={grids.find((item) => item.document == p.document)}
                                    is_edit_grid={is_edit_grid}
                                    labels={labels}
                                    onStartSelectedArea={onStartSeleced}
                                    onEndSelectedArea={onEndSeleced}
                                    onSelectedArea={onSelectedArea}
                                    url={p.url}
                                    document={p.document}
                                    boxes={cache[p.document] || []}
                                    selectedBoxes={selectedBoxesCache[p.document] || []}
                                    setHeight={setHeight}
                                    floatText={floatText}
                                    floatLabel={floatLabel}
                                    onSelected={onSelect}
                                    selected={
                                        selectedImage[p.document] ||
                                        selectedContent?.file === p.document
                                    }
                                    onAcceptText={onAcceptText}
                                    onCancelText={onCancelText}
                                    selectedContent={selectedContent}
                                    onUseTemplate={onUseTemplate}
                                    isActive={isActive}
                                    isKey={activeKey}
                                    onSaveAsTemplate={onSaveAsTemplate}
                                    gridTemplate={
                                        gridTemplate && gridTemplate.document === p.document
                                            ? gridTemplate
                                            : undefined
                                    }
                                    rotateDeg={rotateDeg}
                                />
                            </ContainerImage>
                        );
                    })}
                </Container>
            </Wrapper>
        );
    }
);

export default Images;

export const ContainerImage = styled.div`
    position: relative;
    padding: 0px 18px;
`;
export const Cover = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
`;

export const MessageBlock = styled.div`
    background-color: ${redColor};
    padding: 8px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
`;
