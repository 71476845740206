import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

import { IRelatedDoc } from 'redux/receipt/types';

import mergeIcon from 'assets/images/icons/merge.png';
import checkIcon from 'assets/images/icons/check-mark.png';
import clearIcon from 'assets/images/icons/clear-button.png';

import { SmallText, Title } from 'styles/text';

interface Props {
    receipt_id: string;
    related_documents: IRelatedDoc[];
    onClose: () => void;
    mergeReceipt: (receipts_id: string, related_id: string) => any;
}

const RelatedPages: FC<Props> = ({ related_documents, receipt_id, onClose, mergeReceipt }) => {
    const [selected, setSelected] = useState<IRelatedDoc | null>(null);
    const [documentToEnlargeUrl, setDocumentToEnlargeUrl] = useState<string | undefined>();

    const onMerge = () => {
        if (selected) {
            mergeReceipt(receipt_id, selected.receipt_id);
        }
    };

    const enlargeDocument = useCallback(() => {
        if (!documentToEnlargeUrl) {
            return;
        }

        return (
            <LargeDocumentContainer>
                <LargeImage src={documentToEnlargeUrl} alt="image" />
            </LargeDocumentContainer>
        );
    }, [documentToEnlargeUrl]);

    return (
        <Container>
            <Header>
                <HeaderBlock>
                    <MergeIcon src={mergeIcon} alt="merge" />
                    <Title color="white">Merge documents</Title>
                </HeaderBlock>
                <HeaderBlock>
                    <Button margin="0 10px 0 0" onClick={onClose}>
                        <ButtonIcon src={clearIcon} alt="check" />
                    </Button>
                    <Button back="#2f72ff" disabled={!selected} onClick={onMerge}>
                        <ButtonIcon src={checkIcon} alt="check" />
                    </Button>
                </HeaderBlock>
            </Header>
            <Inner>
                {related_documents.map((item, index) => (
                    <ImageBlock
                        key={item.url}
                        onClick={() => setSelected(item)}
                        onMouseOver={() => setDocumentToEnlargeUrl(item.url)}
                        onMouseLeave={() => setDocumentToEnlargeUrl(undefined)}
                    >
                        {selected === item && (
                            <Selected>
                                <ButtonIcon src={checkIcon} alt="check" />
                            </Selected>
                        )}
                        <ImageCount color="white">{++index}</ImageCount>
                        <Image src={item.url} alt="image" />
                    </ImageBlock>
                ))}
            </Inner>

            {enlargeDocument()}
        </Container>
    );
};

export default RelatedPages;

const Container = styled.div`
    width: 480px;
    max-height: calc(100vh - 131px);
    overflow-y: scroll;
    background-color: #1b1922;
`;

const Header = styled.div`
    display: flex;
    padding: 10px;
    background-color: #121117;
    justify-content: space-between;
`;

const HeaderBlock = styled.div`
    display: flex;
`;

const MergeIcon = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 10px;
    filter: invert(100%);
`;

interface ButtonProps {
    back?: string;
    margin?: string;
    disabled?: boolean;
}

const Button = styled.button`
    width: 36px;
    padding: 0;
    justify-content: center;
    height: 24px;
    border: none;
    border-radius: 5px;
    margin: ${({ margin }: ButtonProps) => (margin ? margin : '0px')};
    background-color: ${({ back }: ButtonProps) => (back ? back : '#979797')};
    cursor: pointer;
    opacity: ${({ disabled }: ButtonProps) => (disabled ? 0.5 : 1)};

    &:hover {
        opacity: 0.5;
    }
`;

const ButtonIcon = styled.img`
    width: 14px;
    height: 14px;
    filter: invert(100%);
`;

const Inner = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 12px 12px 0 12px;
    justify-content: flex-start;
`;

const ImageBlock = styled.div`
    padding: 16px 8px 8px;
    width: 174px;
    height: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
`;
const ImageCount = styled(SmallText)`
    position: absolute;
    top: 0;
    left: 8px;
`;

const Image = styled.img`
    transition: all 0.3s ease 0s;
    width: 158px;
    height: auto;
`;

const Selected = styled.div`
    position: absolute;
    left: 74px;
    top: 100px;
    background-color: #2f72ff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;

const LargeDocumentContainer = styled.div`
    display: flex;
    width: 50%;
    height: auto;
    border: 5px solid #dce1ee;

    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 21;
`;

const LargeImage = styled.img`
    transition: all 0.3s ease 0s;
    width: 100%;
    height: auto;
`;
