import React, { FC, InputHTMLAttributes, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Filter } from 'styles/blocks';

import NewItem2 from './index';

import closePng from 'assets/images/icons/closeWhite.png';
import { IItem } from 'redux/list/types';

interface Props {
    item: IItem;
    onClose: () => any;
    onSubmit: (name: string, words: string[], company: string) => any;
}

const EditItem: FC<Props> = ({ item, onClose, onSubmit }) => {
    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>EDIT Item</HeaderText>
                    <Close src={closePng} alt="close" onClick={onClose} />
                </Header>

                <Main>
                    <NewItem2
                        itemName={item.name}
                        itemWords={item.words}
                        labelStyle={{ color: '#fff' }}
                        companies={[]}
                        onSubmit={onSubmit}
                    />
                </Main>
            </Container>
        </Filter>
    );
};

export default EditItem;

const Container = styled.div`
    width: 580px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;