import React, { createRef, CSSProperties, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { greyColor, greyColorBright, greyColorDark, whiteColor } from 'styles/colors';
import { Chip, ErrorInput, Mark, RegularText } from 'styles/text';

import arrow from 'assets/images/icons/arrowDownBig.png';
import { DisplayFlexCenter } from '../../styles/blocks';
import { useClickOutside } from '../../utils/useClickOutside';

interface Props {
    error?: string;
    mark?: boolean;
    list: any;
    label?: string;
    search?: boolean;
    selected: string;
    disabled?: boolean;
    objKey?: string;
    type?: 'str' | 'obj';
    placeholder?: string;
    labelStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    listContainer?: CSSProperties;
    inputContainer?: CSSProperties;
    listItem?: CSSProperties;
    loadData?: () => any;
    onClick: (item: any) => void;
    onFocus?: () => any;
    displayNewMarker?: boolean;
}

const DropInput: FC<Props> = ({
    type,
    objKey,
    list,
    mark,
    label,
    error,
    search,
    selected,
    disabled,
    listItem,
    labelStyle,
    listContainer,
    containerStyle,
    onFocus,
    onClick,
    loadData,
    placeholder,
    inputContainer,
    displayNewMarker = false
}) => {
    const [visible, setVisible] = useState(false);
    const [newList, setNewList] = useState(list);

    const ref_search = useRef<any>();
    const containerRef = createRef<HTMLDivElement>();
    useClickOutside({ ref: containerRef, callback: () => setVisible(false) });

    useEffect(() => {
        if (visible && search) {
            if (ref_search.current) {
                ref_search.current.focus();
            }
        }
    }, [search, visible]);

    const setVisibleHandle = () => {
        if (disabled) {
            return;
        }
        setVisible(!visible);
        setNewList(list);
    };

    const onSelect = (item: any) => {
        onClick(item);
        setVisible(false);
    };

    const onChange = (t: string) => {
        if (type === 'obj') {
            setNewList(
                // @ts-ignore
                list.filter((i) => i[objKey].toLowerCase().includes(t.toLowerCase()))
            );
        } else {
            // @ts-ignore
            setNewList(list.filter((i) => i.toLowerCase().includes(t.toLowerCase())));
        }
    };

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (loadData) {
                loadData();
            }
        }
    };

    return (
        <Container style={containerStyle} ref={containerRef}>
            {label && (
                <DisplayFlexCenter>
                    <Label style={labelStyle} onClick={() => setVisible(!visible)}>
                        {label} {mark && <Mark>*</Mark>}
                    </Label>

                    {displayNewMarker && <Chip>new</Chip>}
                </DisplayFlexCenter>
            )}

            <Block>
                <InputBlock onClick={setVisibleHandle} style={inputContainer}>
                    {!selected && placeholder ? (
                        <InputText style={{ opacity: 0.45 }} color={greyColorDark}>
                            {placeholder}
                        </InputText>
                    ) : (
                        <InputText color={greyColorDark}>{selected}</InputText>
                    )}
                    <Icon src={arrow} alt="arrow" />
                </InputBlock>
                {visible && (
                    <DropContainer onScroll={handleScroll}>
                        {search && (
                            <SearchInput
                                ref={ref_search}
                                placeholder="Start enter..."
                                onChange={(e) => onChange(e.target.value)}
                                onFocus={onFocus}
                            />
                        )}
                        <ListContainer style={listContainer}>
                            {newList.length ? (
                                newList.map((el: any, i: number) => {
                                    // @ts-ignore
                                    let key = type === 'obj' ? el[objKey] + i : el + i;
                                    // @ts-ignore
                                    let item = type === 'obj' ? el[objKey] : el;
                                    return (
                                        <Item
                                            style={listItem}
                                            key={key}
                                            onClick={() => onSelect(el)}
                                        >
                                            <InputText>{item}</InputText>
                                        </Item>
                                    );
                                })
                            ) : (
                                <Item>
                                    <InputText>No items...</InputText>
                                </Item>
                            )}
                        </ListContainer>
                    </DropContainer>
                )}
                {error && <ErrorInput>{error}</ErrorInput>}
            </Block>
        </Container>
    );
};

export default DropInput;

interface StyleProps {
    color?: string;
}

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const Label = styled(RegularText)`
    min-width: 100px;
`;

const Block = styled.div`
    flex: 1;
    position: relative;
`;

const InputBlock = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;
    background: #ffffff;
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
`;

const InputText = styled(RegularText)`
    max-width: 300px;
    color: ${({ color }: StyleProps) => (color ? color : whiteColor)};
    text-indent: 15px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

const Icon = styled.img`
    position: absolute;
    right: 5px;
    width: 24px;
    height: 24px;
`;

const DropContainer = styled.div`
    position: absolute;
    top: 35px;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    max-height: 318px;
    overflow: auto;
`;

const ListContainer = styled.div`
    max-height: 140px;
`;

const SearchInput = styled.input`
    min-height: 35px;
    outline: none;
    text-indent: 15px;
    border-radius: 5px;
    background: ${greyColorDark};
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: ${greyColorBright};

    &::placeholder {
        color: ${greyColor};
        opacity: 0.7;
    }
`;

const Item = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    height: 35px;
    background: ${greyColorDark};
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
`;
