import React, { FC, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import { isEmptyObj } from 'helpers/other';
import { COUNTRIES_ISO } from 'utils/countries';
import { EditStakeholderSchema } from 'helpers/validations';
import { IEditStakeholder, IGetCategoriesParams } from 'net/api';
import { ICategory, IStakeholder, IStakeholdersType } from 'redux/list/types';

import DropInput from 'components/DropInput';
import InputLabel from 'components/InputLabel';
import PrimaryButton from 'components/PrimaryButton';

import { Filter } from 'styles/blocks';
import { CancelButton } from 'styles/text';

import closePng from 'assets/images/icons/closeWhite.png';

const styles = {
    inputContainer: {
        marginBottom: 20
    },
    labelStyle: {
        minWidth: 90,
        color: 'white'
    },
    selectedStyle: {
        minWidth: 200
    },
    editBtn: { width: 145, height: 55, marginLeft: 28 }
};

interface Props {
    name: string;
    editItem: IStakeholder;
    categories: ICategory[];
    closePopUp: () => void;
    onSubmit: (params: IEditStakeholder) => any;
    getCategoriesByCompany: (companyName: string, params: IGetCategoriesParams) => any;
}

const EditStakeholder: FC<Props> = ({
    name,
    editItem,
    categories,
    onSubmit,
    closePopUp,
    getCategoriesByCompany
}) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validationSchema: EditStakeholderSchema,
        initialValues: {
            name: editItem && (editItem.name || ''),
            code: editItem && (editItem.code || ''),
            vat_id: editItem && (editItem.vat_id || ''),
            street: editItem && (editItem.street || ''),
            city: editItem && (editItem.city || ''),
            zipcode: editItem && (editItem.zipcode || ''),
            country: editItem && (editItem.country || ''),
            selected_date_format: editItem && (editItem.selected_date_format || ''),
            country_data: { code: '' },
            category:
                editItem && editItem.category && editItem.category.name
                    ? editItem.category.name
                    : '',
            category_data: { id: '' }
        },
        onSubmit: async (values) => {
            if (editItem) {
                setLoading(true);
                let type: IStakeholdersType = name === 'CUSTOMER' ? 'customer' : 'supplier';

                const params: any = {};
                if (editItem.name !== values.name) {
                    params['name'] = values.name;
                }
                if (editItem.code !== values.code) {
                    params['code'] = values.code;
                }
                if (editItem.vat_id !== values.vat_id) {
                    params['vat_id'] = values.vat_id;
                }
                if (editItem.street !== values.street) {
                    params['street'] = values.street;
                }
                if (editItem.city !== values.city) {
                    params['city'] = values.city;
                }
                if (editItem.zipcode !== values.zipcode) {
                    params['zipcode'] = values.zipcode;
                }
                if (editItem.country !== values.country_data.code) {
                    params['country_code'] = values.country_data.code;
                }
                if (
                    (editItem.category && editItem.category.name !== values.category) ||
                    values.category
                ) {
                    params['category_id'] = values.category_data.id;
                }
                if (editItem.selected_date_format !== values.selected_date_format) {
                    params['selected_date_format'] = values.selected_date_format;
                }

                if (!isEmptyObj(params)) {
                    params.id = editItem.id;
                    params.type = type;

                    const err = await onSubmit(params);
                    if (err) {
                        formik.setErrors({ name: err });
                    } else {
                        closePopUp();
                    }
                }
                // setLoading(false);
                closePopUp();
            }
        }
    });
    const { values, touched, errors, setFieldValue } = formik;

    const onCategoryChange = (c: ICategory) => {
        setFieldValue('category', c.name);
        setFieldValue('category_data', c);
    };

    const onCountryChange = (c: { name: string; code: string }) => {
        setFieldValue('country', c.name);
        setFieldValue('country_data', c);
    };

    useEffect(() => {
        if (editItem) {
            getCategoriesByCompany(editItem.company_name, {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (editItem) {
            let country = COUNTRIES_ISO.find((c) => c.code === editItem.country);
            if (country) {
                setFieldValue('country', country.name);
                setFieldValue('country_data', country);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>EDIT {name}</HeaderText>
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                <Main>
                    <Form onSubmit={formik.handleSubmit}>
                        <Block>
                            <InputLabel
                                mark
                                id="name"
                                name="name"
                                type="text"
                                label="Name"
                                error={errors.name}
                                touched={touched.name}
                                onChange={formik.handleChange}
                                value={values.name}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                mark
                                id="code"
                                name="code"
                                type="text"
                                label="Code"
                                error={errors.code}
                                touched={touched.code}
                                onChange={formik.handleChange}
                                value={values.code}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                id="vat_id"
                                name="vat_id"
                                type="text"
                                label="VAT ID"
                                error={errors.vat_id}
                                touched={touched.vat_id}
                                onChange={formik.handleChange}
                                value={values.vat_id}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <DropInput
                                type="obj"
                                objKey="name"
                                label="Category"
                                list={categories}
                                selected={formik.values.category}
                                labelStyle={styles.labelStyle}
                                onClick={(t) => onCategoryChange(t)}
                                error={touched.category && errors.category ? errors.category : ''}
                            />
                        </Block>

                        <Block>
                            <InputLabel
                                id="street"
                                name="street"
                                type="text"
                                label="Street"
                                error={errors.street}
                                touched={touched.street}
                                onChange={formik.handleChange}
                                value={values.street}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                id="city"
                                name="city"
                                type="text"
                                label="City"
                                error={errors.city}
                                touched={touched.city}
                                onChange={formik.handleChange}
                                value={values.city}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <DropInput
                                search
                                type="obj"
                                objKey="name"
                                label="Country"
                                list={COUNTRIES_ISO}
                                selected={formik.values.country}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                                onClick={(t) => onCountryChange(t)}
                                error={touched.country && errors.country ? errors.country : ''}
                            />
                            <InputLabel
                                id="zipcode"
                                name="zipcode"
                                type="text"
                                label="Zip code"
                                error={errors.zipcode}
                                touched={touched.zipcode}
                                onChange={formik.handleChange}
                                value={values.zipcode}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />

                            <DropInput
                                type="obj"
                                objKey="name"
                                label="Date formats"
                                list={editItem.list_date_formats.map((item) => ({
                                    name: item,
                                    code: item
                                }))}
                                selected={formik.values.selected_date_format}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                                onClick={(t) => {
                                    setFieldValue('selected_date_format', t.name);
                                }}
                            />
                        </Block>
                    </Form>
                    <Buttons>
                        <CancelButton light onClick={closePopUp}>
                            Cancel
                        </CancelButton>
                        <PrimaryButton
                            loading={loading}
                            title={`EDIT ${name}`}
                            onClick={formik.handleSubmit}
                            style={styles.editBtn}
                        />
                    </Buttons>
                </Main>
            </Container>
        </Filter>
    );
};

export default EditStakeholder;

const Container = styled.div`
    width: 900px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;

const Form = styled.form`
    display: flex;
`;

const Block = styled.div`
    flex: 1;
    &:first-child {
        margin-right: 55px;
    }
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`;
