import styled from "styled-components/macro";

export const Inner = styled.div`
  background-color: #666975;
  width: 966px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 30px;
`;

export const IconClose = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

export const Body = styled.div`
  padding: 30px;
`;

export const DropContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 50px 0;
`;

export const AddUser = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
  cursor: pointer;
  user-select: none;
`;

export const AddUserIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const DNDContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Empty = styled.div`
  min-height: 30vh;
`;

export const DocTypeBlock = styled.div`
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropInputContainer = styled.div`
  width: 350px;
`;
