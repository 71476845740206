import React, { useState, FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { MyTeamProps } from './index';
import { ICompanyUser } from 'redux/user/types';

import AddUser from './blocks/AddUser';
import PrimaryHeader from 'components/PrimaryHeader';
import PrimaryButton from 'components/PrimaryButton';
import UserBox from './components/UserBox';
import { Label } from 'styles/text';
import { ListContainer } from './styles';
import DeletePopUp from 'components/DeletePopUp';

const MyTeam: FC<MyTeamProps> = ({
    user,
    location,
    history,
    sendInvite,
    editCompanyUser,
    getUsersByCompany,
    deleteCompanyUser
}) => {
    const { users, activeAllCompanies } = user;
    const state: any = location.state;

    const [visibleDeletePopup, setVisibleDeletePopup] = useState(false);
    const [selectedDeleteUser, setSelectedDeleteUser] = useState<ICompanyUser>();
    const [addUserPopUp, setAddUserPopUp] = useState(state ? state.addUser : false);
    const [selectedUser, setUser] = useState<null | ICompanyUser>(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');

    useEffect(() => {
        getUsersByCompany();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onEditPress = (item: ICompanyUser) => {
        setUser(item);
        setAddUserPopUp(true);
    };

    const onAddUserPress = () => {
        setUser(null);
        setAddUserPopUp(true);
    };

    const onDelete = (item: ICompanyUser) => {
        // deleteCompanyUser(item);
        setSelectedDeleteUser(item);
        setVisibleDeletePopup(true);
    };

    const onDeleteConfrim = () => {
        if (selectedDeleteUser) {
            deleteCompanyUser(selectedDeleteUser);
        }
        setVisibleDeletePopup(false);
        setSelectedDeleteUser(undefined)
    };

    const data = users.items;
    const objData: any = useMemo(() => {
        let t: any = {};
        if (activeAllCompanies) {
            data.forEach((item) => {
                if (!t[item.company_id]) {
                    t[item.company_id] = [item];
                } else {
                    t[item.company_id].push(item);
                }
            });
        }

        return t;
    }, [data, activeAllCompanies]);

    return (
        <div className="main">
            <div className="body">
                <PrimaryHeader title="My Team" onClick={() => history.push('/my-profile')} />

                <Container>
                    <PrimaryButton
                        title="ADD A USER"
                        onClick={onAddUserPress}
                        style={{ width: '110px', height: '35px' }}
                    />
                </Container>
                {activeAllCompanies
                    ? Object.keys(objData).map((key) => {
                          const c = user.companies.items.find((item) => item.company_id === key);
                          return (
                              <ListContainer key={key}>
                                  <Label margin="0 0 10px 0">{c!.name}</Label>
                                  {objData[key].map((item: any) => (
                                      <UserBox
                                          key={item.user_id + item.company_id}
                                          user={item}
                                          onDelete={() => onDelete(item)}
                                          onClick={() => {
                                              onEditPress(item);
                                              setSelectedCompanyId(item.company_id);
                                          }}
                                      />
                                  ))}
                              </ListContainer>
                          );
                      })
                    : data.map((item) => {
                          return (
                              <UserBox
                                  key={item.user_id + item.company_id}
                                  user={item}
                                  onDelete={() => onDelete(item)}
                                  onClick={() => onEditPress(item)}
                              />
                          );
                      })}

                {addUserPopUp && (
                    <AddUser
                        company_id={selectedCompanyId}
                        user={selectedUser}
                        sendInvite={sendInvite}
                        editCompanyUser={editCompanyUser}
                        closePopUp={() => setAddUserPopUp(false)}
                    />
                )}
            </div>

            {visibleDeletePopup && (
                <DeletePopUp
                    loading={false}
                    title="Are you sure you want to delete the selected items?"
                    onDelete={onDeleteConfrim}
                    closePopUp={() => setVisibleDeletePopup(false)}
                />
            )}
        </div>
    );
};

export default MyTeam;

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
    margin-bottom: 32px;
`;
