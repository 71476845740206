import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { EditReceiptState, IContentSchema } from 'redux/editReceipt/types';
import { IStakeholder, IStakeholdersType } from 'redux/list/types';
import {
    API,
    ICreateStakeholderParams,
    IGetCategoriesParams,
    IGetStakeholdersParams
} from 'net/api';
import { EnumReceiptType, IReceipt, IReceiptHistory } from 'redux/receipt/types';

import History from './History';
import { SimpleField } from '../Fields';
import DetailsHeader from './DetailsHeader';
import DropInput from 'components/DropInput';
import TableCount from 'components/TableCount';
import CategoriesInput from 'components/CategoriesInput';
import StakeholderInput from 'components/StakeholderInput';

import { Block, Container, ErrorText, FieldsContainer, Label, Text } from './styles';
import { ToogleContainer } from 'containers';
import { IReceiptShema, IReceiptShemaItem } from 'redux/setting/types';
import { IEditValue } from 'pages/EditReceipt/EditReceipt';
import { COUNTRIES_ISO } from 'utils/countries';
import { DateUtils } from '../../../../utils/dateUtils';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components/macro';
import { redColor } from '../../../../styles/colors';

const styles = {
    dropContainer: {
        marginBottom: 20
    },
    dropLabel: {
        minWidth: 210,
        fontSize: 18
    },
    buttonCreate: {
        width: 90,
        height: 35,
        fontSize: 14,
        marginLeft: 10
    }
};

export const STAKEHOLDER_MAP = {
    receipt: {
        supplier_name: 'name',
        supplier_code: 'code',
        supplier_vat: 'vat_id',
        supplier_street: 'street',
        supplier_city: 'city',
        supplier_zip_code: 'zipcode',
        supplier_country: 'country',
        supplier_email: 'email',
        supplier_date_format: 'selected_date_format'
    },
    invoice: {
        customer_name: 'name',
        customer_code: 'code',
        customer_vat: 'vat_id',
        customer_street: 'street',
        customer_city: 'city',
        customer_zip_code: 'zipcode',
        customer_country: 'country',
        customer_email: 'email',
        customer_delivery: 'delivery',
        customer_date_format: 'selected_date_format'
    }
};

interface Props {
    formik: any;
    fieldsRef: any;
    receipt: IReceipt;
    shema: IReceiptShema[];
    content: IContentSchema[];
    activeContent?: IReceiptShemaItem;
    receiptHistory: IReceiptHistory[];
    editReceiptState: EditReceiptState;
    disabled?: boolean;
    onFocusElement: (item: IEditValue) => any;
    onTimerChange: (content_id: string) => any;
    getReceiptHistory: (receipt_id: string) => any;
    loadEditCategories: (params: IGetCategoriesParams) => any;
    createStakeholder: (data: ICreateStakeholderParams) => any;
    handleChange: (content: IContentSchema, value: string) => any;
    onMultivaluePress: (shema: IReceiptShemaItem) => () => void;
    loadEditStakeholders: (params: IGetStakeholdersParams, receipt: IReceipt) => any;
    getEditStakeholders: (params: IGetStakeholdersParams, receipt: IReceipt) => any;
    onBlurElement: () => void;
    setFieldPosition: React.Dispatch<
        React.SetStateAction<{
            [key: string]: number;
        }>
    >;
    visibleStakeholderFields: string[];
    onStakeholderSelect: (s: Partial<IStakeholder> | null | undefined) => void;
    stakeholderKeys: any;
    stakeholdersList: IStakeholder[];
}

const STAKEHOLDER_DATE_FORMATS = ['YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY', 'DD/MM/YYYY'];
const STAKEHOLDER_DEBOUNCE_WAIT_TIME = 1000;

const Details: FC<Props> = ({
    shema,
    formik,
    receipt,
    content,
    fieldsRef,
    activeContent,
    receiptHistory,
    editReceiptState,
    handleChange,
    onTimerChange,
    onFocusElement,
    setFieldPosition,
    onMultivaluePress,
    getReceiptHistory,
    createStakeholder,
    loadEditCategories,
    getEditStakeholders,
    loadEditStakeholders,
    onBlurElement,
    disabled,
    visibleStakeholderFields,
    onStakeholderSelect,
    stakeholderKeys,
    stakeholdersList
}) => {
    const [advanced, setAdvanced] = useState(false);
    const [isNewStakeholder, setIsNewStakeholder] = useState(false);

    const stakeholder_type: IStakeholdersType = useMemo(
        () => (formik.values.receipt_type === EnumReceiptType.Receipt ? 'supplier' : 'customer'),
        [formik.values.receipt_type]
    );

    const currentStakeholderName = useMemo(() => {
        const stakeholderNameId =
            stakeholder_type === 'supplier' ? 'supplier_name' : 'customer_name';

        const contentNameItem = content.find((c) => c.schema_id === stakeholderNameId);
        return contentNameItem?.content?.value;
    }, [content, stakeholder_type]);

    const currentStakeholderCode = useMemo(() => {
        const stakeholderCodeId =
            stakeholder_type === 'supplier' ? 'supplier_code' : 'customer_code';

        const contentCodeItem = content.find((c) => c.schema_id === stakeholderCodeId);
        return contentCodeItem?.content?.value;
    }, [content, stakeholder_type]);

    useEffect(() => {
        if (!currentStakeholderCode || !currentStakeholderName) {
            return;
        }

        const checkIfNew = async () => {
            const response = await API.isNewStakeholder(
                stakeholder_type,
                currentStakeholderName,
                currentStakeholderCode
            );

            setIsNewStakeholder(response.data.isNewStakeholder);
        };

        checkIfNew();
    }, [currentStakeholderCode, currentStakeholderName, stakeholder_type]);

    useEffect(() => {
        if (advanced) {
            getReceiptHistory(receipt.receipt_id);
        }
    }, [advanced, getReceiptHistory, receipt.receipt_id]);

    const findStakeholderByName = ({ name }: { name: string }): IStakeholder | undefined => {
        return stakeholdersList.find((stakeholder) => stakeholder.name === name);
    };

    const findStakeholderByCode = ({ code }: { code: string }): IStakeholder | undefined => {
        return stakeholdersList.find((stakeholder) => stakeholder.code === code);
    };

    const onContentChange = (content: IContentSchema, value: string) => {
        handleChange(content, value);
        onTimerChange(content.id);
    };

    const onChangeStakeholder = (content: IContentSchema, value: string) => {
        switch (content.schema_id) {
            case 'customer_code':
            case 'supplier_code': {
                const stakeholder = findStakeholderByCode({ code: value });
                if (!stakeholder) {
                    // setting only code and clearing other stakeholder fields, only if it's not NEW stakeholder state
                    if (!isNewStakeholder) {
                        onStakeholderSelect({
                            [stakeholderKeys[content.schema_id]]: value
                        });
                    }
                    break;
                }

                debouncedStakeholderSelect(stakeholder);
                break;
            }
            case 'customer_name':
            case 'supplier_name': {
                const stakeholder = findStakeholderByName({ name: value });

                if (!stakeholder) {
                    // setting only name and clearing other stakeholder fields, only if it's not NEW stakeholder state
                    if (!isNewStakeholder) {
                        onStakeholderSelect({
                            [stakeholderKeys[content.schema_id]]: value
                        });
                    }
                    break;
                }

                debouncedStakeholderSelect(stakeholder);
                break;
            }
        }

        handleChange(content, value);
    };

    const debouncedStakeholderSelect = useDebouncedCallback((value) => {
        onStakeholderSelect(value);
    }, STAKEHOLDER_DEBOUNCE_WAIT_TIME);

    const onTypeChange = (type: EnumReceiptType) => {
        getEditStakeholders({ with_external_data: 1 }, { ...receipt, receipt_type: type });
        formik.setFieldValue('receipt_type', type);

        API.moveCustomerSupplierContent({
            company_id: receipt.company_id,
            receipt_id: receipt.receipt_id,
            receipt_type: type
        });
    };

    return (
        <Container>
            {isNewStakeholder && <Note>NOTE: new 'Supplier & Customer'</Note>}

            <DetailsHeader advanced={advanced} onPress={() => setAdvanced(!advanced)} />
            {receipt.error && receipt.error.message && (
                <ErrorText>{receipt.error.message}</ErrorText>
            )}
            {!advanced ? (
                <FieldsContainer ref={fieldsRef}>
                    <Block>
                        <Label>Company</Label>
                        <Text>{receipt.company_name}</Text>
                    </Block>
                    {/* <CategoriesInput
                        label="Category"
                        disabled={editReceiptState.listIsLoading}
                        list={editReceiptState.categoriesList.items}
                        selected={formik.values.category.name}
                        labelStyle={styles.dropLabel}
                        loadData={() => loadEditCategories({ with_external_data: 1 })}
                        onSelect={(c) => onCategoryChange(c)}
                    /> */}
                    {!disabled && (
                        <DropInput
                            label="Type"
                            list={[EnumReceiptType.Invoice, EnumReceiptType.Receipt]}
                            selected={formik.values.receipt_type}
                            onClick={(t: any) => onTypeChange(t)}
                            containerStyle={styles.dropContainer}
                            labelStyle={styles.dropLabel}
                        />
                    )}

                    {/* All DATA */}
                    {shema.map((section) => {
                        if (section.id === 'supplier_customer') {
                            return (
                                <ToogleContainer label={section.label} key={section.id}>
                                    {section.items.map((item) => {
                                        if (item.hidden) {
                                            return null;
                                        }

                                        if (!visibleStakeholderFields.includes(item.id)) {
                                            return null;
                                        }

                                        const c = content.find((c) => c.schema_id === item.id);

                                        if (!c) {
                                            return null;
                                        }

                                        return (
                                            <FieldBlock
                                                schema_id={item.id}
                                                setFieldPosition={(id: string, value: number) => {
                                                    setFieldPosition((prev) => ({
                                                        ...prev,
                                                        [id]: value
                                                    }));
                                                }}
                                                key={item.id}
                                            >
                                                {item.settings?.stakeholder && !disabled ? (
                                                    <StakeholderInput
                                                        label={item.label}
                                                        labelStyle={styles.dropLabel}
                                                        stakeholder_type={stakeholder_type}
                                                        disabled={
                                                            editReceiptState.listIsLoading ||
                                                            editReceiptState.isStakeholdersLoading
                                                        }
                                                        list={
                                                            editReceiptState.stakeholdersList.items
                                                        }
                                                        selected={c.content?.value || ''}
                                                        active={activeContent?.id === item.id}
                                                        onSelect={onStakeholderSelect}
                                                        loadData={() =>
                                                            loadEditStakeholders(
                                                                { with_external_data: 1 },
                                                                receipt
                                                            )
                                                        }
                                                        onFocus={() =>
                                                            onFocusElement({
                                                                shema: item,
                                                                content: c
                                                            })
                                                        }
                                                        // // onChange={(t) => onContentChange(c, t)}
                                                        onChange={(t) => onChangeStakeholder(c, t)}
                                                        onTimerChange={() => onTimerChange(c.id)}
                                                        displayNewMarker={isNewStakeholder}
                                                        withUpdate
                                                    />
                                                ) : item.settings?.country && !disabled ? (
                                                    <DropInput
                                                        type="obj"
                                                        objKey="name"
                                                        mark={item.constraints.required}
                                                        search={true}
                                                        error={c.error?.message || ''}
                                                        label={item.label}
                                                        list={COUNTRIES_ISO}
                                                        selected={
                                                            c.content?.value ||
                                                            receipt.stakeholder?.country ||
                                                            ''
                                                        }
                                                        onClick={(t: any) =>
                                                            onContentChange(c, t.name)
                                                        }
                                                        onFocus={onBlurElement}
                                                        containerStyle={styles.dropContainer}
                                                        labelStyle={styles.dropLabel}
                                                        displayNewMarker={isNewStakeholder}
                                                    />
                                                ) : item.settings?.date_format && !disabled ? (
                                                    <DropInput
                                                        type="obj"
                                                        objKey="name"
                                                        mark={item.constraints.required}
                                                        search={true}
                                                        error={c.error?.message || ''}
                                                        label={item.label}
                                                        list={STAKEHOLDER_DATE_FORMATS.map(
                                                            (item) => ({
                                                                name: item,
                                                                code: item
                                                            })
                                                        )}
                                                        selected={
                                                            c.content?.value ||
                                                            receipt.stakeholder_date_format ||
                                                            ''
                                                        }
                                                        onClick={(t: any) =>
                                                            onContentChange(c, t.name)
                                                        }
                                                        onFocus={onBlurElement}
                                                        containerStyle={styles.dropContainer}
                                                        labelStyle={styles.dropLabel}
                                                        displayNewMarker={isNewStakeholder}
                                                    />
                                                ) : (
                                                    <SimpleField
                                                        disabled={disabled}
                                                        item={{
                                                            shema: item,
                                                            content: c as IContentSchema
                                                        }}
                                                        active={activeContent?.id === item.id}
                                                        // handleChange={handleChange}
                                                        handleChange={onChangeStakeholder}
                                                        onTimerChange={onTimerChange}
                                                        onFocusElement={onFocusElement}
                                                        displayNewMarker={isNewStakeholder}
                                                    />
                                                )}
                                            </FieldBlock>
                                        );
                                    })}
                                </ToogleContainer>
                            );
                        }
                        return (
                            <React.Fragment key={section.id}>
                                <ToogleContainer label={section.label}>
                                    {section.items.map((item) => {
                                        if (item.hidden) return null;
                                        const c = content.find((c) => c.schema_id === item.id);
                                        if (!c) {
                                            return null;
                                        }
                                        if (item.category === 'multivalue') {
                                            return (
                                                <Block
                                                    key={item.id}
                                                    onClick={onMultivaluePress(item)}
                                                >
                                                    <Label>{item.label}</Label>
                                                    <TableCount count={c.children?.length || 0} />
                                                </Block>
                                            );
                                        }
                                        if (item.type === 'date' && c.content?.value) {
                                            const dateValue = c.content?.value;
                                            // in dynamoDB ReceiptContent date is kept in ISO format string
                                            const isIsoDate = DateUtils.isIso8601Format({
                                                date: dateValue
                                            });

                                            const dateToDisplay = DateUtils.parseDate({
                                                date: dateValue,
                                                fromFormat: isIsoDate
                                                    ? DateUtils.ISO_8601_DATE_FORMAT
                                                    : DateUtils.DEFAULT_DATE_FORMAT,
                                                strict: true
                                            });

                                            if (dateToDisplay) {
                                                c.content.value = dateToDisplay;
                                            }
                                        }
                                        return (
                                            <FieldBlock
                                                schema_id={item.id}
                                                setFieldPosition={(id: string, value: number) => {
                                                    setFieldPosition((prev) => ({
                                                        ...prev,
                                                        [id]: value
                                                    }));
                                                }}
                                                key={item.id}
                                            >
                                                {item.type === 'enum' && !disabled ? (
                                                    <DropInput
                                                        type="obj"
                                                        objKey="value"
                                                        search={true}
                                                        error={c.error?.message || ''}
                                                        label={item.label}
                                                        list={item.options}
                                                        selected={c.content?.value || ''}
                                                        onClick={(t: any) =>
                                                            onContentChange(c, t.value)
                                                        }
                                                        containerStyle={styles.dropContainer}
                                                        labelStyle={styles.dropLabel}
                                                        // onFocus={() =>
                                                        //     onFocusElement({
                                                        //         shema: item,
                                                        //         content: c as IContentSchema
                                                        //     })
                                                        // }
                                                        onFocus={onBlurElement}
                                                    />
                                                ) : item.settings?.category && !disabled ? (
                                                    <CategoriesInput
                                                        label={item.label}
                                                        disabled={editReceiptState.listIsLoading}
                                                        list={editReceiptState.categoriesList.items}
                                                        selected={c.content?.value || ''}
                                                        labelStyle={styles.dropLabel}
                                                        loadData={() =>
                                                            loadEditCategories({
                                                                with_external_data: 1
                                                            })
                                                        }
                                                        onFocus={() =>
                                                            onFocusElement({
                                                                shema: item,
                                                                content: c as IContentSchema
                                                            })
                                                        }
                                                        onSelect={(i) => onContentChange(c, i.name)}
                                                        withUpdate
                                                    />
                                                ) : (
                                                    <SimpleField
                                                        disabled={disabled}
                                                        item={{
                                                            shema: item,
                                                            content: c as IContentSchema
                                                        }}
                                                        active={activeContent?.id === item.id}
                                                        handleChange={handleChange}
                                                        onTimerChange={onTimerChange}
                                                        onFocusElement={onFocusElement}
                                                    />
                                                )}
                                            </FieldBlock>
                                        );
                                    })}
                                </ToogleContainer>
                            </React.Fragment>
                        );
                    })}
                </FieldsContainer>
            ) : (
                <FieldsContainer>
                    <Block>
                        <Label>Owned by</Label>
                        <Text>
                            {receipt.user.name} {receipt.user.surname}
                        </Text>
                    </Block>
                    <Block>
                        <Label>History</Label>
                    </Block>
                    <History receiptHistory={receiptHistory} />
                </FieldsContainer>
            )}
        </Container>
    );
};

const FieldBlock: React.FC<{
    setFieldPosition: (id: string, value: number) => void;
    schema_id: string;
}> = ({ children, setFieldPosition, schema_id }) => {
    const NOT_CALCULATED_ITEMS = 300;

    const ref = useRef<any>();

    useEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setFieldPosition(schema_id, rect.top - NOT_CALCULATED_ITEMS);
        }
        // TODO: need to refactor this, because adding dependencies causes UI to freeze, probably infinity loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={ref}>{children}</div>;
};

const Note = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background: ${redColor};
    color: #fff;
    font-family: 'NunitoSans-SemiBold';
`;

export default Details;
