import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import Component from "./MyProfile";
import { UserState } from "../../redux/user/types";
import { UserActions } from "redux/user/actions";
import { IUpdateUserParams } from "net/api";

export type MyProfileProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  user: UserState;
}
interface LinkDispatchProps {
  updateUser: (params: IUpdateUserParams) => any;
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
