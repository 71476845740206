import styled from "styled-components";
import { AuthTitle } from "styles/text";

export const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #dce1ee;
  padding: 160px 30px 85px 30px;
  @media only screen and (max-height: 1445px) {
    padding-top: 40px;
  }
`;

export const SideBarTitle = styled(AuthTitle)`
  text-align: left;
`;

export const SideBarText = styled.p`
  width: 434px;
  margin-bottom: 75px;
  margin-top: 23px;
  line-height: 19px;
  color: #222222;
  text-align: justify;
  font-family: "NunitoSans-SemiBold";
  @media only screen and (max-height: 1445px) {
    margin-bottom: 45px;
  }
`;

export const SideBarImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SideBarImage = styled.img`
  width: 434px;
  height: 384px;
  margin-bottom: 75px;
  @media only screen and (max-height: 1445px) {
    margin-bottom: 35px;
  }
`;
