import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  flex: 1;
  background: #fff;
  border: 0.4px solid #eeeeee;
  border-top: 0;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
`;
