import React, { FC } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { ICompanyUser } from 'redux/user/types';

import userIcon from 'assets/images/icons/user.png';
import quillIcon from 'assets/images/icons/quill.png';
import userIconSmall from 'assets/images/icons/userIconSmall.png';
import trashIcon from 'assets/images/icons/trash.png';

interface Props {
    user: ICompanyUser;
    onClick: () => void;
    onDelete: () => void;
}

const UserBox: FC<Props> = ({ user, onClick, onDelete }) => {
    let email = '';

    if ('email' in user) {
        email = user['email'];
    }
    return (
        <ListItem>
            <UserInfo>
                <UserIcon src={userIcon} alt="user" />
                <Info>
                    <UserName>
                        {user.name} {user.surname}
                    </UserName>
                    <InfoText>
                        Date created: {moment(user.user.created_at).format('DD MMM YY HH:mm')}
                        <br />
                        Last login: {moment(user.user.last_login).format('DD MMM YY HH:mm')}
                        <br />
                        {user.user.email}
                    </InfoText>
                    <EmailIn>
                        {/* @ts-ignore */}
                        Email-in: <EmailInValue>{email}</EmailInValue>
                    </EmailIn>
                </Info>
            </UserInfo>

            <Actions>
                <ActionTitle>
                    <ActionIconTitle src={quillIcon} alt="quill" />
                    {user.role === 'admin' ? (
                        <React.Fragment>
                            Administrator
                            <br />
                            Account owner
                        </React.Fragment>
                    ) : (
                        <React.Fragment>Basic user</React.Fragment>
                    )}
                </ActionTitle>
                <ActionBlock>
                    {user.can_be_deleted && (
                        <ActionItem onClick={onDelete}>
                            <ActionIcon src={trashIcon} alt="trash" />
                            Delete User
                        </ActionItem>
                    )}
                    <ActionItem onClick={onClick}>
                        <ActionIcon src={userIconSmall} alt="delete" />
                        Edit User Details
                    </ActionItem>
                </ActionBlock>
            </Actions>
        </ListItem>
    );
};

export default UserBox;

const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #ffffff;
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 10px;
    margin-bottom: 2px;
`;
const UserInfo = styled.div`
    display: flex;
`;
const UserIcon = styled.img`
    width: 44px;
    height: 44px;
    margin-right: 18px;
`;
const Info = styled.div``;
const UserName = styled.p`
    font-family: 'NunitoSans-SemiBold';
    font-size: 18px;
    color: #565682;
    margin-bottom: 2px;
`;
const InfoText = styled.p`
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: #9191af;
    line-height: 20px;
    margin-bottom: 15px;
`;
const EmailIn = styled.p`
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: #9191af;
`;
const EmailInValue = styled.span`
    color: #565682;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 22px;
`;
const ActionTitle = styled.div`
    display: flex;
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: #565682;
`;
const ActionIconTitle = styled.img`
    width: 18px;
    height: 18px;
    margin-right: 10px;
    margin-top: 3px;
`;
const ActionBlock = styled.div``;

const ActionItem = styled.div`
    display: flex;
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: #9191af;
    margin-top: 20px;
    cursor: pointer;
    user-select: none;
`;
const ActionIcon = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;
