import styled from "styled-components/macro";

export const CompanyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const PeopleContainer = styled.div`
  display: inline-block;
  margin-top: 30px;
`;

export const EmptyContainer = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyText = styled.p`
  font-size: 18px;
  color: #9191af;
  font-family: "NunitoSans-SemiBold";
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
