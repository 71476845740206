import { UserActionTypes, UserState } from './types';

const initialState: UserState = {
    activeAllCompanies: false,
    profile: {
        id: '',
        email: '',
        name: '',
        surname: '',
        address: '',
        city: '',
        country: '',
        phone: '',
        post_code: '',
        is_admin: false,
        created_at: 0,
        last_login: 0,
        total_amount: 0,
        total_receipts: 0,
        waiting_for_approval: 0,
        total_unassigned_invoices: 0,
        total_unassigned_receipts: 0,
        global_email: ''
    },
    companies: {
        items: []
    },
    loading: true,
    users: {
        items: [],
        next: ''
    },
    activeCompany: {
        company_id: '',
        name: '',
        user_id: '',
        role: 'basic',
        email: '',
        code: '',
        ftp_export: false,
        nsoft_export: false,
        sql_import: false,
        google_drive_import: false,
        rivile_export: false,
        rivile_import: false,
        surname: '',
        unique_name: '',
        google_selected_import_folder_name: '',
        dropbox_selected_import_folder_name: '',
        used_documents_per_month: 0,
        duplicated_documents_per_month: 0
    }
};

const userReducer = (state = initialState, actions: UserActionTypes): UserState => {
    switch (actions.type) {
        case 'SET_ACTIVE_ALL_COMPANIES':
            return {
                ...state,
                activeAllCompanies: actions.active
            };
        case 'SET_PROFILE':
            return {
                ...state,
                profile: { ...actions.profile }
            };
        case 'SET_COMPANIES':
            return {
                ...state,
                companies: actions.companies
            };
        case 'DELETE_COMPANY':
            return {
                ...state,
                companies: {
                    items: state.companies.items.filter((c) => c.company_id !== actions.company_id),
                    next: state.companies.next
                }
            };
        case 'SET_ACTIVE_COMPANY':
            return {
                ...state,
                activeCompany: actions.company
            };
        case 'SET_USER_LOADING':
            return {
                ...state,
                loading: actions.loading
            };
        case 'SET_USERS_BY_COMPANY':
            return {
                ...state,
                users: actions.data
            };
        case 'EDIT_USER_BY_COMPANY':
            return {
                ...state,
                users: {
                    items: state.users.items.map((item) => {
                        if (
                            actions.user.user_id === item.user_id &&
                            actions.user.company_id === item.company_id
                        ) {
                            return actions.user;
                        }
                        return item;
                    }),
                    next: state.users.next
                }
            };
        case 'DELETE_USER_FROM_COMPANY':
            return {
                ...state,
                users: {
                    items: state.users.items.filter((item) => {
                        if (actions.user.user_id === item.user_id) {
                            return !(actions.user.company_id === item.company_id);
                        }
                        return true;
                    }),
                    next: state.users.next
                }
            };

        case 'CREATE_COMPANY':
            return {
                ...state,
                companies: {
                    items: state.companies.items
                        .concat(actions.company)
                        .sort((a, b) =>
                            a.name.toLowerCase() > b.name.toLowerCase()
                                ? 1
                                : a.name.toLowerCase() < b.name.toLowerCase()
                                ? -1
                                : 0
                        ),
                    next: state.companies.next
                }
            };
        case 'CHANGE_INTEGRATION':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    items: state.companies.items.map((company) => {
                        if (company.company_id === actions.company.company_id) {
                            return {
                                ...company,
                                ...actions.company
                            };
                        }
                        return company;
                    })
                },
                activeCompany: { ...state.activeCompany, ...actions.company }
            };
        default:
            return state;
    }
};

export default userReducer;
