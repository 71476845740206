let awsmobile: any;

if (process.env.REACT_APP_ENV == 'development') {
    awsmobile = {
        aws_project_region: 'eu-west-1',
        aws_cognito_identity_pool_id: 'eu-west-1:dc8a649b-0b9e-4c3d-bbc4-ba96a01bad3b',
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: 'eu-west-1_zUmJau6pG',
        aws_user_pools_web_client_id: 'c2e1nottn43t6rq5hnjas9ak6',
        oauth: {}
    };
} else {
    awsmobile = {
        aws_project_region: 'eu-west-1',
        aws_cognito_identity_pool_id: 'eu-west-1:31999f93-c38b-44f3-8442-7c9c7e061d8a',
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: 'eu-west-1_5PtAw0YMW',
        aws_user_pools_web_client_id: '240rbo9du8si7gciisbjhbiq1g',
        oauth: {}
    };
}

export default awsmobile;
