import React, { Component } from "react";
import styled from "styled-components";

import arrowDown from "../../assets/images/icons/arrow-down.png";
import arrowTop from "../../assets/images/icons/arrow-top.png";

interface IProps {
  icon: any;
  title: string;
  openMenu: Function;
  active: boolean;
}

class SideMenuItemMultiple extends Component<IProps> {
  render() {
    const { icon, title, openMenu, active } = this.props;
    return (
      <Li onClick={() => openMenu()}>
        <Icon src={icon} alt={title} />
        <Block>
          {title}
          {active ? (
            <Arrow src={arrowTop} alt="arrow" />
          ) : (
            <Arrow src={arrowDown} alt="arrow" />
          )}
        </Block>
      </Li>
    );
  }
}

export default SideMenuItemMultiple;

const Li = styled.li`
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  font-family: "NunitoSans-SemiBold";
  font-size: 20px;
  color: #565682;
  padding-right: 25px;
  padding-left: 50px;
  cursor: pointer;
`;
const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 37px;
`;
const Block = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Arrow = styled.img`
  width: 15px;
  height: 12px;
`;
