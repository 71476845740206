import React, { FC, useEffect } from 'react';

import { IntegrationGoogleProps } from './index';

import SmallLoader from 'components/SmallLoader';

import { Main } from './styles';
import { Container } from 'styles/blocks';

const IntegrationGoogleAuth: FC<IntegrationGoogleProps> = ({ history, location, match }) => {
    function getQueryVariable(variable: any) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }

    const sendHadle = async () => {
        if (location.search) {
            const data =
                location.search[0] === '?' ? location.search.substring(1) : location.search;

            if (data) {
                const params = match.params as any;
                const service_type = params.service_type as any;
                history.replace(`/integration/${service_type}`, {
                    auth_response: { success: !!getQueryVariable('success') }
                });
                return;
            }
        }

        history.replace('/integration', {});
    };

    useEffect(() => {
        sendHadle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Main>
                <SmallLoader />
            </Main>
        </Container>
    );
};

export default IntegrationGoogleAuth;
