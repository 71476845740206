import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  active: boolean;
  description: string;
  onClick: () => void;
}

const DocCheckBox: FC<Props> = ({ title, active, description, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Block>
        <Check>{active && <Sign />}</Check>
        <Title>{title}</Title>
      </Block>
      <Description>{description}</Description>
    </Container>
  );
};

export default DocCheckBox;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  user-select: none;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Check = styled.div`
  width: 20px;
  height: 20px;
  background: #eeeeee;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

export const Sign = styled.div`
  background-color: #e9c718;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

export const Title = styled.p`
  font-family: "NunitoSans-Bold";
  font-size: 15px;
  color: #404040;
`;

export const Description = styled.p`
  font-family: "NunitoSans-Regular";
  font-size: 14px;
  color: #404040;
`;
