import { InvitationState, InvitationActionTypes } from "./types";

const initialState: InvitationState = {
  user: {
    id: "",
    email: "",
    company_id: "",
    new_account_required:false
  },
  loading: false,
};

const invitationReducer = (
  state = initialState,
  actions: InvitationActionTypes
): InvitationState => {
  switch (actions.type) {
    case "SET_INVITATION_USER":
      return {
        ...state,
        user: actions.user,
      };
    case "SET_INVITATION_LOADING":
      return {
        ...state,
        loading: actions.loading,
      };
    default:
      return state;
  }
};

export default invitationReducer;
