import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";

import {
  CreateStakeholderSchema,
  CreateStakeholderSimpleSchema,
} from "helpers/validations";
import { ICompany } from "redux/user/types";
import { COUNTRIES_ISO } from "utils/countries";
import { ICategory, IStakeholdersType } from "redux/list/types";
import { ICreateStakeholderParams, IGetCategoriesParams } from "net/api";

import DropInput from "components/DropInput";
import InputLabel from "components/InputLabel";
import PrimaryButton from "components/PrimaryButton";

import { CancelButton } from "styles/text";

const styles = {
  inputContainer: {
    marginBottom: 18,
  },
  labelStyle: {
    minWidth: 90,
    color: "#9191AF",
  },
  addBtn: { width: 90, height: 35, marginLeft: 28 },
};

interface Props {
  path: string;
  companies: ICompany[];
  categories: ICategory[];
  type: IStakeholdersType;
  activeCompany: ICompany;
  activeAllCompanies: boolean;
  createStakeholder: (
    params: ICreateStakeholderParams,
    company_name?: string
  ) => any;
  getCategoriesByCompany: (
    companyName: string,
    params: IGetCategoriesParams
  ) => any;
}

const CreateForm: FC<Props> = ({
  type,
  path,
  companies,
  categories,
  activeCompany,
  activeAllCompanies,
  createStakeholder,
  getCategoriesByCompany,
}) => {
  useEffect(() => {
    if (!activeAllCompanies) {
      getCategoriesByCompany(activeCompany.name, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAllCompanies]);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    validationSchema: activeAllCompanies
      ? CreateStakeholderSchema
      : CreateStakeholderSimpleSchema,
    initialValues: {
      name: "",
      code: "",
      vat_id: "",
      street: "",
      city: "",
      zipcode: "",
      country: "",
      country_data: { name: "", code: "" },
      category: "",
      category_data: { id: "" },
      company: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const err = await createStakeholder(
        {
          name: values.name,
          type,
          code: values.code,
          vat_id: values.vat_id,
          street: values.street,
          city: values.city,
          zipcode: values.zipcode,
          country_code: values.country_data.code,
          category_id: values.category_data.id,
        },
        values.company
      );
      if (err && err.error) {
        formik.setErrors({ name: err.text });
      } else {
        formik.resetForm();
      }
      setLoading(false);
    },
  });
  const { values, touched, errors, setFieldValue } = formik;

  useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  let companiesName = companies.map((c) => c.name);

  const onCompanyChange = async (t: string) => {
    setFieldValue("category", "");
    await getCategoriesByCompany(t, {});
    setFieldValue("company", t);
  };

  const onCategoryChange = (c: ICategory) => {
    setFieldValue("category", c.name);
    setFieldValue("category_data", c);
  };

  const onCountryChange = (data: { name: string; code: string }) => {
    setFieldValue("country", data.name);
    setFieldValue("country_data", data);
  };

  return (
    <Container>
      <Form>
        <Block>
          <InputLabel
            mark
            id="name"
            name="name"
            type="text"
            label="Name"
            error={errors.name}
            touched={touched.name}
            onChange={formik.handleChange}
            value={values.name}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
          />
          <InputLabel
            mark
            id="code"
            name="code"
            type="text"
            label="Code"
            error={errors.code}
            touched={touched.code}
            onChange={formik.handleChange}
            value={values.code}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
          />
          <InputLabel
            id="vat_id"
            name="vat_id"
            type="text"
            label="VAT ID"
            error={errors.vat_id}
            touched={touched.vat_id}
            onChange={formik.handleChange}
            value={values.vat_id}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
          />
          {activeAllCompanies && (
            <DropInput
              label="Company"
              list={companiesName}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainer}
              selected={formik.values.company}
              onClick={(t) => onCompanyChange(t)}
              error={touched.company && errors.company ? errors.company : ""}
            />
          )}

          {formik.values.company || !activeAllCompanies ? (
            <DropInput
              type="obj"
              objKey="name"
              label="Category"
              list={categories}
              labelStyle={styles.labelStyle}
              selected={formik.values.category}
              containerStyle={styles.inputContainer}
              onClick={onCategoryChange}
              error={touched.category && errors.category ? errors.category : ""}
            />
          ) : (
            <></>
          )}
        </Block>

        <Block>
          <InputLabel
            id="street"
            name="street"
            type="text"
            label="Street"
            error={errors.street}
            touched={touched.street}
            onChange={formik.handleChange}
            value={values.street}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
          />
          <InputLabel
            id="city"
            name="city"
            type="text"
            label="City"
            error={errors.city}
            touched={touched.city}
            onChange={formik.handleChange}
            value={values.city}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
          />
          <DropInput
            search
            type="obj"
            objKey="name"
            label="Country"
            list={COUNTRIES_ISO}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
            selected={formik.values.country_data.name}
            onClick={(t) => onCountryChange(t)}
            error={touched.country && errors.country ? errors.country : ""}
          />
          <InputLabel
            id="zipcode"
            name="zipcode"
            type="text"
            label="Zip code"
            error={errors.zipcode}
            touched={touched.zipcode}
            onChange={formik.handleChange}
            value={values.zipcode}
            labelStyle={styles.labelStyle}
            containerStyle={styles.inputContainer}
          />
        </Block>
      </Form>
      <Buttons>
        <CancelButton onClick={() => formik.resetForm()}>Cancel</CancelButton>
        <PrimaryButton
          title="ADD"
          loading={loading}
          onClick={formik.handleSubmit}
          style={styles.addBtn}
        />
      </Buttons>
    </Container>
  );
};

export default CreateForm;

const Container = styled.div`
  flex: 1;
  background: #fff;
  border: 0.4px solid #eeeeee;
  border-top: 0;
  border-radius: 10px;
  padding: 30px;
`;

const Form = styled.div`
  flex: 1;
  display: flex;
`;

const Block = styled.div`
  flex: 1;
  &:first-child {
    margin-right: 55px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 37px;
`;
