import React, { FC } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import SignUpConfirm from "../pages/Auth/SignUpConfirm";
import SignUpInvite from "../pages/Auth/SignUpInvite";
import ForgotPass from "../pages/Auth/ForgotPass";

interface Props {}

const AuthRoutes: FC<Props> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={SignIn} path={["/signin", "/"]} exact />
        <Route component={SignUp} path={"/signup"} exact />
        <Route component={SignUpConfirm} path={"/signup/confirm"} exact />
        <Route component={SignUpInvite} path={"/signup/invite"} exact />
        <Route component={ForgotPass} path={"/forgot"} exact />
        <Redirect to="/signin" />
      </Switch>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoutes);
