import React, { FC } from "react";
import styled from "styled-components/macro";

import PrimaryButton from "components/PrimaryButton";
import { Label } from "styles/text";

interface IProps {
  onClick: () => void;
}

const AddUser: FC<IProps> = ({ onClick }) => {
  return (
    <Container>
      <Label>Add a Company Employee</Label>
      <Text>
        Add a company employee to view and
        <br />
        manage your finances or add their
        <br />
        own items to Assistant
      </Text>
      <PrimaryButton
        title="ADD A USER"
        style={{ width: "160px", height: "45px", marginTop: "78px" }}
        onClick={onClick}
      />
    </Container>
  );
};

export default AddUser;

const Container = styled.div`
  min-width: 339px;
  height: calc(420px - 82px);
  padding-top: 82px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  margin-right: 45px;
  margin-bottom: 45px;
`;

const Text = styled.p`
  text-align: center;
  font-size: 18px;
  color: #9191af;
  font-family: "NunitoSans-SemiBold";
  margin-top: 30px;
`;
