import React, { FC, InputHTMLAttributes } from "react";
import styled from "styled-components";

import signIcon from "assets/images/icons/agreeSign.png";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
}

const CheckBox: FC<Props> = ({ checked, ...rest }) => {
  return (
    <Container>
      {checked && <Sign src={signIcon} />}
      <Box {...rest} type="checkbox" />
    </Container>
  );
};
export default CheckBox;

const Container = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #a665d1;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Box = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Sign = styled.img`
  width: 15px;
  height: 15px;
`;
