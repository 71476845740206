import React, { FC, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "redux/rotReducer";
import { AuthState } from "redux/auth/types";
import { AuthActions } from "redux/auth/actions";
import { ForgotResetSchema, ForgotSchema } from "helpers/validations";

import SideBar from "./components/SideBar";
import AuthInput from "components/AuthInput";
import PrimaryButton from "components/PrimaryButton";

import { ColoredTitle } from "styles/text";
import {
  ActionLink,
  AuthContainer,
  AuthLogo,
  AuthMain,
  AuthText,
  AuthTitle,
} from "./styles";

import logo from "assets/images/icons/logo.png";

const styles = {
  title: {
    marginTop: "100px",
    marginBottom: "187px",
  },
  buttonSend: {
    width: "332px",
    height: "53px",
    marginTop: "194px",
    marginBottom: "70px",
  },
  input: {
    marginBottom: "24px",
  },
};

interface Props extends RouteComponentProps {
  auth: AuthState;
  forgotPasswordSubmit: (email: string, code: string, password: string) => any;
  forgotPassword: (email: string) => any;
}

const ForgotPass: FC<Props> = ({
  history,
  auth,
  forgotPassword,
  forgotPasswordSubmit,
}) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);

  const formik = useFormik({
    validationSchema: ForgotSchema,
    initialValues: {
      email: "",
    },
    onSubmit: async ({ email }) => {
      const res = await forgotPassword(email);
      if (res === "success") {
        setStep(2);
      } else {
        formik.setErrors({ email: res });
      }
    },
  });

  const formik2 = useFormik({
    validationSchema: ForgotResetSchema,
    initialValues: {
      code: "",
      new_password: "",
      new_password_repeat: "",
    },
    onSubmit: async ({ code, new_password }) => {
      const res = await forgotPasswordSubmit(
        formik.values.email,
        code,
        new_password
      );
      if (res === "success") {
        setStep(3);
      } else {
        formik2.setErrors({ code: res });
      }
    },
  });

  return (
    <AuthContainer>
      <AuthMain>
        <AuthLogo src={logo} alt="logo" />
        <ColoredTitle>Assistant</ColoredTitle>
        {step === 1 && (
          <>
            <AuthTitle>
              Enter your email address and we will
              <br />
              send you a confirmation code to reset
              <br />
              your password
            </AuthTitle>
            <AuthInput
              id="email"
              name="email"
              type="text"
              touched={formik.touched.email}
              error={formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              placeholder="Email"
            />
            <PrimaryButton
              bigLoader
              title="SEND"
              loading={auth.loading}
              onClick={formik.handleSubmit}
              style={styles.buttonSend}
            />
            <AuthText>
              Already have an account?
              <ActionLink to={"/signin"}> Log in</ActionLink>
            </AuthText>
          </>
        )}
        {step === 2 && (
          <>
            <AuthTitle>
              Please check your email and follow instructions
            </AuthTitle>
            <AuthInput
              id="code"
              name="code"
              type="text"
              style={styles.input}
              touched={formik2.touched.code}
              error={formik2.errors.code}
              onBlur={formik2.handleBlur}
              onChange={formik2.handleChange}
              value={formik2.values.code}
              placeholder="Enter your confirmation code"
              autoComplete="off"
            />
            <AuthInput
              secure
              type="password"
              id="new_password"
              name="new_password"
              style={styles.input}
              touched={formik2.touched.new_password}
              error={formik2.errors.new_password}
              onBlur={formik2.handleBlur}
              onChange={formik2.handleChange}
              value={formik2.values.new_password}
              placeholder="Enter your new password"
            />
            <AuthInput
              secure
              type="password"
              id="new_password_repeat"
              name="new_password_repeat"
              touched={formik2.touched.new_password_repeat}
              error={formik2.errors.new_password_repeat}
              onBlur={formik2.handleBlur}
              onChange={formik2.handleChange}
              value={formik2.values.new_password_repeat}
              placeholder="Repeat your new password"
            />
            <PrimaryButton
              bigLoader
              title="CONFIRM"
              loading={auth.loading}
              onClick={formik2.handleSubmit}
              style={styles.buttonSend}
            />
            <AuthText>
              Already have an account?
              <ActionLink to={"/signin"}> Log in</ActionLink>
            </AuthText>
          </>
        )}
        {step === 3 && (
          <>
            <AuthTitle>
              Your password has been successfully changed.
              <br />
              You can now login into to your account
            </AuthTitle>
            <PrimaryButton
              title="LOG IN"
              onClick={() => history.push("/signin")}
              style={styles.buttonSend}
            />
          </>
        )}
      </AuthMain>

      <SideBar />
    </AuthContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  forgotPassword: AuthActions.forgotPassword,
  forgotPasswordSubmit: AuthActions.forgotPasswordSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
