import React, { CSSProperties, FC } from "react";
import styled from "styled-components";
import { greyColorDark } from "styles/colors";

import { RegularText } from "styles/text";

interface Props {
  label: string;
  value: string | number;
  labelStyle?: CSSProperties;
  containerStyle?: CSSProperties;
}

const LabelInfo: FC<Props> = ({ label, value, labelStyle, containerStyle }) => {
  return (
    <Container style={containerStyle}>
      <Label style={labelStyle}>{label}</Label>
      <Input>{value}</Input>
    </Container>
  );
};

export default LabelInfo;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Label = styled(RegularText)`
  min-width: 141px;
`;

export const Input = styled.p`
  flex: 1;
  font-family: "NunitoSans-SemiBold";
  font-size: 15px;
  color: ${greyColorDark};
  text-indent: 15px;
`;
