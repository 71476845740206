import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    height: 100vh;
`;

export const Container = styled.div`
    background-color: #f7f7f7;
    padding: 30px;
    width: 100%;
    overflow-y: auto;
`;

export const Filter = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 100;
`;

export const DisplayFlexCenter = styled.div`
    display: flex;
    align-items: center;
`;
