import PrimaryButton from "components/PrimaryButton";
import { useFormik } from "formik";
import { AddCountSchema } from "helpers/validations";
import React, { FC, InputHTMLAttributes, useEffect, useState } from "react";
import styled from "styled-components";

import InputLabel from "components/InputLabel";

const styles = {
  inputContainer: {
    marginBottom: 18,
  },
  labelStyle: {
    minWidth: 130,
    color: "#9191AF",
  },
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  path: string;
  count: number;
  onPress: (n: number, reset: boolean) => any;
}

const ToogleCount: FC<Props> = ({ path, count, onPress }) => {
  const [loading, setLoading] = useState(false);
  const [reset, setReset] = useState(false);

  const formik = useFormik({
    validationSchema: AddCountSchema,
    enableReinitialize: true,
    initialValues: {
      count,
    },
    onSubmit: async ({ count }) => {
      setLoading(true);
      await onPress(count, reset);
      setLoading(false);
    },
  });
  const { touched, values, errors } = formik;

  useEffect(() => {
    if (path) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <Container onSubmit={formik.handleSubmit}>
      <Block>
        <InputLabel
          mark
          id="count"
          name="count"
          type="text"
          label="Count"
          maxLength={5}
          error={errors.count}
          touched={touched.count}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={values.count}
          labelStyle={styles.labelStyle}
          containerStyle={styles.inputContainer}
        />
      </Block>

      <PrimaryButton
        loading={loading}
        title="UPDATE"
        onClick={() => {
          setReset(false);
          formik.handleSubmit();
        }}
        style={{ width: "90px", height: "35px", marginRight: 20 }}
      />
      <PrimaryButton
        loading={loading}
        title="RESET AND UPDATE"
        onClick={() => {
          setReset(true);
          formik.handleSubmit();
        }}
        style={{ width: "170px", height: "35px" }}
      />
    </Container>
  );
};

export default ToogleCount;

const Container = styled.form`
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  max-width: 650px;
`;

const Block = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;
