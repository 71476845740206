import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateRange } from 'react-date-range';
import SimpleInput from 'components/SimpleInput';
import PrimaryButton from 'components/PrimaryButton';
import moment from 'moment';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import closeImage from '../../../../assets/images/icons/close.png';
import calendarImage from '../../../../assets/images/icons/calendar.png';
import { EnumReceiptType } from '../../../../redux/receipt/types';
import CheckBox from '../CheckBox';
import { Text } from '../../../EditReceipt/components/Details/styles';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    z-index: 10;
`;
const PrimaryButtonS = styled(PrimaryButton)`
    width: 85px;
    height: 35px;
`;

const Block = styled.div`
    display: flex;
    align-items: center;
`;

const SelectedDateBlock = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #565682;
    border-radius: 8px;
    padding: 8px;
    margin-left: 8px;
`;

const SelectedDate = styled.span`
    font-size: 15px;
    padding: 0 2px;
`;

const DateBlock = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 12px;
`;

const DateContainer = styled.div`
    border-radius: 4px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    position: absolute;
    background-color: #fff;
    top: 0;
`;

const ButtonsBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
`;

const SearchButton = styled(PrimaryButton)`
    margin-right: 12px;
    width: 80px;
    height: 35px;
`;

interface Props {
    onSelectedDateRange: (startDate: string, endDate: string, v: boolean) => void;
    onClearDateRange: () => void;
    onShowDuplicates: (v: boolean) => void;
    isActiveDuplicates?: boolean;
    formik: any;
    setReceiptType: (type: EnumReceiptType | undefined) => void;
}

const SearchPanel: React.FC<Props> = ({
    formik,
    onSelectedDateRange,
    onClearDateRange,
    onShowDuplicates,
    isActiveDuplicates,
    setReceiptType
}) => {
    const [visibleDate, setVisibleDate] = useState(false);
    const [by_issue_date, setByIssueDate] = useState(false);
    const [dateState, setDateState] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    const [selectedDateRange, setSelectedDateRange] = useState<
        { startDate: Date; endDate: Date } | undefined
    >();
    const [isReceiptType, setIsReceiptType] = useState(true);
    const [isInvoiceType, setIsInvoiceType] = useState(true);

    useEffect(() => {
        if (isReceiptType && isInvoiceType) {
            setReceiptType(undefined);
            return;
        }

        if (!isReceiptType && !isInvoiceType) {
            setReceiptType(undefined);
            return;
        }

        setReceiptType(isReceiptType ? EnumReceiptType.Receipt : EnumReceiptType.Invoice);
    }, [isReceiptType, isInvoiceType]);

    const onApplyDate = () => {
        setVisibleDate(false);
        if (!dateState.startDate || !dateState.endDate) {
            return;
        }
        onSelectedDateRange(
            moment(dateState.startDate).startOf('day').toISOString(),
            moment(dateState.endDate).endOf('day').toISOString(),
            by_issue_date
        );

        setSelectedDateRange({
            startDate: dateState.startDate,
            endDate: dateState.endDate
        });
    };

    const onClearDate = () => {
        onClearDateRange();
        setSelectedDateRange(undefined);
        setDateState({ ...dateState, startDate: new Date(), endDate: new Date() });
    };

    const formatDate = (date: Date | string | number) => {
        return moment(date).format('DD/MM/YY');
    };

    return (
        <Container>
            <Block>
                <SimpleInput
                    name="value"
                    type="text"
                    id="receipt_value"
                    placeholder="value"
                    onChange={formik.handleChange}
                    value={formik.values.value}
                    containerStyle={{ width: 170 }}
                />
                <ButtonsBlock>
                    <SearchButton title="SEARCH" onClick={formik.handleSubmit} />
                    <PrimaryButton
                        title={!isActiveDuplicates ? 'SHOW ALL' : `SHOW DUPLICATES`}
                        onClick={() => onShowDuplicates(!isActiveDuplicates)}
                        style={{ width: '172px', height: '35px' }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '0 12px'
                        }}
                    >
                        <Text>RECEIPTS</Text>
                        <CheckBox
                            onClick={() => setIsReceiptType((prevState) => !prevState)}
                            active={isReceiptType}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '0 12px'
                        }}
                    >
                        <Text style={{ padding: '0 5px' }}>INVOICES</Text>
                        <CheckBox
                            onClick={() => setIsInvoiceType((prevState) => !prevState)}
                            active={isInvoiceType}
                        />
                    </div>
                </ButtonsBlock>
                <DateBlock>
                    <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => setVisibleDate(true)}
                    >
                        <img
                            src={calendarImage}
                            alt={'calendar'}
                            style={{ width: 25, height: 'auto' }}
                        ></img>
                    </div>
                    {visibleDate && (
                        <DateContainer style={{}}>
                            <DateRange
                                //@ts-ignore
                                showDateDisplay={false}
                                onChange={(item: any) => {
                                    setDateState(item.selection);
                                }}
                                //@ts-ignore
                                // showSelectionPreview={true}
                                maxDate={new Date()}
                                moveRangeOnFirstSelection={false}
                                ranges={[dateState]}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: '11px 0.833em'
                                }}
                            >
                                <PrimaryButton
                                    title="Date of creation"
                                    onClick={() => setByIssueDate(false)}
                                    style={{
                                        width: 85,
                                        height: 35,
                                        marginRight: 8,
                                        textAlign: 'center'
                                    }}
                                    inactive={by_issue_date}
                                />
                                <PrimaryButtonS
                                    title="	Issue Date"
                                    onClick={() => setByIssueDate(true)}
                                    style={{ width: 85, height: 35 }}
                                    inactive={!by_issue_date}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: '11px 0.833em'
                                }}
                            >
                                <PrimaryButtonS
                                    title="Apply"
                                    onClick={onApplyDate}
                                    style={{ width: 85, height: 35, marginRight: 8 }}
                                />
                                <PrimaryButtonS
                                    title="Cancel"
                                    onClick={() => setVisibleDate(false)}
                                    style={{ width: 85, height: 35 }}
                                />
                            </div>
                        </DateContainer>
                    )}
                </DateBlock>
            </Block>
            <Block>
                {selectedDateRange && (
                    <SelectedDateBlock>
                        {/* <SelectedDate>Received at</SelectedDate> */}
                        <SelectedDate>{formatDate(selectedDateRange.startDate)}</SelectedDate>
                        <SelectedDate>-</SelectedDate>
                        <SelectedDate>{formatDate(selectedDateRange.endDate)}</SelectedDate>
                        <div style={{ padding: 4, cursor: 'pointer' }} onClick={onClearDate}>
                            <img
                                src={closeImage}
                                alt="remove"
                                style={{ width: 10, height: 'auto' }}
                            />
                        </div>
                    </SelectedDateBlock>
                )}
            </Block>
        </Container>
    );
};

export default SearchPanel;
