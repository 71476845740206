import React, { FC } from "react";
import styled from "styled-components";

import InputLabel from "components/InputLabel";
import PrimaryButton from "components/PrimaryButton";

import { Filter } from "styles/blocks";

import closePng from "assets/images/icons/closeWhite.png";

const styles = {
  inputContainer: {
    marginBottom: 15,
  },
  labelStyle: {
    minWidth: 160,
  },
};

interface Props {
  formik: any;
  loading: boolean;
  closePopUp: () => void;
}

const ApiKeyPopUp: FC<Props> = ({ formik, loading, closePopUp }) => {
  const { errors, values, touched, handleChange, handleSubmit } = formik;

  return (
    <Filter className="fadeIn">
      <Container>
        <Header>
          <HeaderText>ENTER API KEY</HeaderText>
          <Close src={closePng} alt="close" onClick={closePopUp} />
        </Header>
        <Main>
          <form>
            <InputLabel
              mark
              id="api_key"
              name="api_key"
              type="text"
              label="API_KEY"
              error={errors.api_key}
              touched={touched.api_key}
              onChange={handleChange}
              value={values.api_key}
              labelStyle={styles.labelStyle}
              containerStyle={styles.inputContainer}
            />
            <Buttons>
              <PrimaryButton
                loading={loading}
                title="ACTIVATE"
                onClick={handleSubmit}
                style={{ width: 145, height: 55 }}
              />
            </Buttons>
          </form>
        </Main>
      </Container>
    </Filter>
  );
};

export default ApiKeyPopUp;

const Container = styled.div`
  width: 600px;
  background-color: #666975;
  z-index: 2;
  font-family: "NunitoSans-Bold";
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
  font-size: 18px;
  color: #fff;
`;

const Close = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const Main = styled.div`
  padding: 30px;
  font-size: 16px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`;
