import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { ICompany } from 'redux/user/types';

import graphIcon from 'assets/images/graph.png';
import trashIcon from '../../../../assets/images/icons/trash.png';
import { Icon } from '../../../Receipts/components/ActionPanel/styles';

interface Props {
    active: boolean;
    company: ICompany;
    onDelete: (item: ICompany) => any;
    onPress: (item: ICompany) => any;
}

const Company: FC<Props> = React.memo(({ active, company, onPress, onDelete }) => {
    // const maxSymbols = 79;
    let companyName = company.name;

    const maxLength = 20;
    const maxFont = 30;
    const minFont = 12;

    const fontSize = companyName.length > maxLength ? minFont : maxFont;

    const deleteCompany = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();
        onDelete(company);
    };

    return (
        <Wrapper>
            <Container active={active} onClick={() => onPress(company)}>
                <Graph src={graphIcon} alt="graph" />

                <Content>
                    <Name fontSize={fontSize}>{companyName}</Name>
                    <div>
                        <Email>Documents used per month: {company.used_documents_per_month}</Email>
                        <Email>
                            Duplicates per month: {company.duplicated_documents_per_month}
                        </Email>
                    </div>
                    <Email>Email-in: {company.email}</Email>
                </Content>
                {!active && company.role === 'admin' && (
                    <IconTrashCan active src={trashIcon} alt="trash" onClick={deleteCompany} />
                )}
            </Container>
        </Wrapper>
    );
});

export default Company;

const Wrapper = styled.div`
    position: relative;
    margin-right: 46px;
    margin-bottom: 46px;
`;

interface ContainerProps {
    active: boolean;
}

const Container = styled.div`
    cursor: pointer;
    width: 314px;
    height: 160px;
    position: relative;
    display: flex;
    background: #ffffff;
    border-radius: 16px;
    filter: ${({ active }: ContainerProps) =>
        active ? 'drop-shadow(4px 4px 4px #ba79d1)' : 'none'};
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    z-index: 10;
`;

interface NameProps {
    fontSize: number;
}

const Name = styled.p`
    color: #212145;
    font-size: ${({ fontSize }: NameProps) => fontSize}px;
    font-family: 'NunitoSans-Bold';
    word-break: break-all;
`;

const Email = styled.p`
    color: #9191af;
    font-size: 14px;
    font-family: 'NunitoSans-SemiBold';
    word-break: break-all;
`;

const Graph = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 314px;
    height: 45px;
`;

const IconTrashCan = styled(Icon)`
    position: absolute;
    top: 0;
    right: 0px;
    cursor: pointer;
    padding: 10px;
    z-index: 11;
`;

// version = 0.1
// [assistant]
// [assistant.deploy]
// [assistant.deploy.parameters]
// stack_name = "AssistantAPI"
// s3_bucket = "aws-sam-cli-managed-default-samclisourcebucket-1ad647pd0i9dk"
// s3_prefix = "AssistantAPI"
// region = "eu-west-1"
// capabilities = "CAPABILITY_IAM"

// [default]
// [default.deploy]
// [default.deploy.parameters]
// stack_name = "AssistantAPI"
// s3_bucket = "aws-sam-cli-managed-default-samclisourcebucket-1ad647pd0i9dk"
// s3_prefix = "AssistantAPI"
// region = "eu-west-1"
// confirm_changeset = false
// capabilities = "CAPABILITY_IAM"
// parameter_overrides = "StageName=\"dev\""
