import { API, EnumIntegrationServiceType, IIntegrationBody } from 'net/api';
import { Dispatch } from 'redux';
import { ReduxActions } from 'redux/types';
import { AppState } from 'redux/rotReducer';
import { CLEAR_GOOGLE_PATH_LIST, GooglePathItem, UPDATE_GOOGLE_PATH_LIST } from './types';

export class IntegrationActions {
    static getServiceImportListFolders =
        (service_type: EnumIntegrationServiceType, use_next?: boolean) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                if (!use_next) {
                    dispatch({ type: CLEAR_GOOGLE_PATH_LIST });
                }

                const next = getState().integration.googlePathList.next;
                const res = await API.getServiceImportListFolders({
                    service_type,
                    next: use_next ? next : undefined
                });
                if (use_next) {
                    dispatch({ type: UPDATE_GOOGLE_PATH_LIST, data: res.data });
                } else {
                    dispatch({ type: 'SET_GOOGLE_PATH_LIST', data: res.data });
                }
            } catch (error) {}
        };

    static activateImportReceipts =
        (service_type: EnumIntegrationServiceType, disable = false) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                const res = await API.getServiceImportAuthURL(service_type, disable);
                if (disable) {
                    const { activeCompany } = getState().user;
                    switch (service_type) {
                        case EnumIntegrationServiceType.DROPBOX:
                            dispatch({
                                type: 'CHANGE_INTEGRATION',
                                company: {
                                    ...activeCompany,
                                    dropbox_selected_import_folder_name: '',
                                    dropbox_import: false
                                }
                            });
                            break;
                        case EnumIntegrationServiceType.GOOGLE:
                            dispatch({
                                type: 'CHANGE_INTEGRATION',
                                company: {
                                    ...activeCompany,
                                    google_selected_import_folder_name: '',
                                    google_drive_import: false
                                }
                            });
                    }
                } else {
                    window.open(res.data.url, '_self');
                }
            } catch (error) {}
        };

    static postServiceImportFolder =
        (item: GooglePathItem, service_type: EnumIntegrationServiceType) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                const res = await API.postServiceImportFolder(item.id, item.name, service_type);
                const { activeCompany } = getState().user;

                switch (service_type) {
                    case EnumIntegrationServiceType.DROPBOX:
                        dispatch({
                            type: 'CHANGE_INTEGRATION',
                            company: {
                                ...activeCompany,
                                dropbox_selected_import_folder_name: item.name
                            }
                        });
                        break;
                    case EnumIntegrationServiceType.GOOGLE:
                        dispatch({
                            type: 'CHANGE_INTEGRATION',
                            company: {
                                ...activeCompany,
                                google_selected_import_folder_name: item.name
                            }
                        });
                }
            } catch (error) {}
        };

    static changeIntegration =
        (body: IIntegrationBody) => async (dispatch: Dispatch<ReduxActions>) => {
            try {
                const res = await API.changeIntegration(body);
                dispatch({
                    type: 'CHANGE_INTEGRATION',
                    company: res.data
                });
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    return error.response.data.message;
                }
            }
        };
}
