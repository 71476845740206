import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import iconDelete from 'assets/images/icons/fileError.png';
import SimpleInput from 'components/SimpleInput';
import { IContentSchema } from 'redux/editReceipt/types';
import { IReceiptShemaItem } from 'redux/setting/types';
import TinyLoader from 'components/TinyLoader';
import DropInput from 'components/DropInput';
import { greyColorDark } from 'styles/colors';
import { ButtonGreen } from 'pages/EditReceipt/components/Actions/styles';

const styles = {
    containerStyle: {
        borderRadius: 5
    }
};

interface Props {
    item: IContentSchema;
    disabled?: boolean;
    items: string[];
    config: IReceiptShemaItem[];
    onDelete: () => any;
    onTimerChange: (content_id: string) => any;
    onChangeHandle: (item: IContentSchema, value: string) => void;
    onChangeDropHandle: (item: IContentSchema, value: string) => void;
    onFoucs: (item: IContentSchema) => void;
    onEndList: () => void;
    onAddItemWord: (c: IContentSchema[], v: IContentSchema) => void;
}

const Item: FC<Props> = ({
    item,
    disabled,
    items,
    config,
    onDelete,
    onTimerChange,
    onChangeHandle,
    onChangeDropHandle,
    onFoucs,
    onEndList,
    onAddItemWord
}) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        await onDelete();
    };

    return (
        <StyledBlock>
            {config.map((el, ind, arr) => {
                const currentEl = item.items?.find((i) => el.id === i.schema_id);
                if (currentEl && item.items) {
                    return (
                        <div
                            style={{
                                width: 100 / config.length + '%',
                                paddingRight: arr.length - 1 !== ind ? 4 : undefined
                            }}
                            key={currentEl.id}
                        >
                            {el.settings?.items && !disabled ? (
                                <DropInput
                                    loadData={onEndList}
                                    type="str"
                                    // search={true}
                                    error={currentEl.error?.message}
                                    // label={el.label}
                                    list={items}
                                    placeholder={el.label}
                                    selected={currentEl.content?.value || ''}
                                    onClick={(t: any) => onChangeDropHandle(currentEl, t)}
                                    containerStyle={{
                                        backgroundColor: 'transparent',
                                        display: 'block'
                                    }}
                                    listItem={{ color: greyColorDark }}
                                    inputContainer={{
                                        boxShadow: 'none',
                                        borderRadius: 5,
                                        backgroundColor: '#fff'
                                    }}
                                    onFocus={() => onFoucs(currentEl)}
                                />
                            ) : (
                                <InputItem
                                    disabled={disabled}
                                    schema={el}
                                    placeholder={el.label}
                                    onFoucs={onFoucs}
                                    currentEl={currentEl}
                                    onTimerChange={onTimerChange}
                                    onChangeHandle={onChangeHandle}
                                    onAddItemWord={(v: IContentSchema) =>
                                        onAddItemWord(item.items || [], v)
                                    }
                                />
                            )}
                        </div>
                    );
                } else {
                    return null;
                }
            })}
            {loading ? (
                <LoaderBlock>
                    <TinyLoader />
                </LoaderBlock>
            ) : (
                <StyledIcon disabled={disabled} onClick={!disabled ? handleDelete : undefined}>
                    <img style={{ width: '100%', height: '100%' }} src={iconDelete} alt="delete" />
                </StyledIcon>
            )}
        </StyledBlock>
    );
};

const InputItem: React.FC<{
    disabled?: boolean;
    schema: IReceiptShemaItem;
    currentEl: IContentSchema;
    placeholder?: string;
    onTimerChange: (content_id: string) => any;
    onChangeHandle: (item: IContentSchema, value: string) => any;
    onFoucs: (item: IContentSchema) => any;
    onAddItemWord: (v: IContentSchema) => void;
}> = ({
    //@ts-ignore
    placeholder,
    currentEl,
    onTimerChange,
    onChangeHandle,
    onFoucs,
    schema,
    onAddItemWord,
    disabled
}) => {
    const [visible_add_item, setVisibleAddItem] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(ref_timer.current);
        };
    }, []);

    const ref = useRef<any>(null);
    const ref_timer = useRef<any>();
    const onChange = (value: string) => {
        onChangeHandle(currentEl, value);
        if (ref.current) {
            clearTimeout(ref.current);
        }
        ref.current = setTimeout(() => onTimerChange(currentEl.id), 2000);
    };

    const _onFocus = () => {
        if (schema.settings?.item_word) {
            clearTimeout(ref_timer.current);
            setVisibleAddItem(true);
        }
        onFoucs(currentEl);
    };

    const _onBlur = () => {
        if (schema.settings?.item_word) {
            ref_timer.current = setTimeout(() => {
                setVisibleAddItem(false);
            }, 0.1 * 1000);
        }
    };

    return (
        <StyledItem>
            <SimpleInput
                disabled={disabled}
                error={currentEl.error?.message}
                onFocus={_onFocus}
                onBlur={_onBlur}
                touched
                value={currentEl.content?.value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                containerStyle={styles.containerStyle}
                errorStyle={{ bottom: -28, marginLeft: 0 }}
            />
            {visible_add_item && (
                <div
                    style={{
                        position: 'absolute',
                        right: '100%',
                        top: '50%',
                        transform: 'translateY(-50%)'
                    }}
                >
                    <ButtonGreen
                        isValid={true}
                        onClick={() => {
                            onAddItemWord(currentEl);
                        }}
                    >
                        Add
                    </ButtonGreen>
                </div>
            )}
        </StyledItem>
    );
};

export default React.memo(Item);

const StyledBlock = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    padding: 4px 0px;
    margin-bottom: 20px;
`;

const StyledItem = styled.div`
    flex: 1;
    font-size: 14px;
    color: white;
    font-family: 'NunitoSans-SemiBold';
    position: relative;
`;

const StyledIcon = styled.div<{ disabled?: boolean }>`
    position: relative;
    width: 10px;
    height: 10px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${({ disabled }) =>
        disabled
            ? `opacity:0;
           `
            : `
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: rotate(180deg) scale(2);
    }`}
    :after {
        content: '';
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }
`;

const LoaderBlock = styled.div`
    width: 50px;
    display: flex;
    justify-content: flex-end;
`;
