import styled from 'styled-components/macro';

import { greyColor, greyColorBright, greyColorDark, redColor, whiteColor } from 'styles/colors';

interface IColoredTitle {
    fs?: number;
}

export const ColoredTitle = styled.h1`
    font-size: ${({ fs }: IColoredTitle) => (fs ? fs : 22)}px;
    background: -webkit-linear-gradient(90deg, #ffbbcf 5.73%, #a665d1 87.75%);
    background-clip: text;
    -webkit-background-clip: text;
    font-family: 'NunitoSans-ExtraBold';
    -webkit-text-fill-color: transparent;
`;

export const AuthTitle = styled.h2`
    font-size: 18px;
    color: #222222;
    text-align: center;
    font-family: 'NunitoSans-Bold';
`;

export const ExtraTitle = styled.p`
    font-size: 18px;
    color: ${greyColor};
    font-family: 'NunitoSans-ExtraBold';
`;

export const Title = styled.p`
    font-size: 16px;
    color: ${({ color }: PopUpTitleProps) => (color ? color : greyColor)};
    font-family: 'NunitoSans-Bold';
`;

interface PopUpTitleProps {
    color?: string;
    margin?: string;
}

export const PopUpTitle = styled.p`
    font-size: 18px;
    color: ${({ color }: PopUpTitleProps) => (color ? color : whiteColor)};
    margin: ${({ margin }: PopUpTitleProps) => (margin ? margin : '0px')};
    font-family: 'NunitoSans-Bold';
`;

interface BigTitleProps {
    color?: string;
    margin?: string;
}

export const BigTitle = styled.p`
    font-size: 18px;
    color: ${({ color }: BigTitleProps) => (color ? color : whiteColor)};
    margin: ${({ margin }: BigTitleProps) => (margin ? margin : '0px')};
    font-family: 'NunitoSans-Bold';
`;

interface LabelProps {
    color?: string;
    margin?: string;
}

export const Label = styled.h2<LabelProps>`
    font-size: 20px;
    color: ${({ color }: LabelProps) => (color ? color : greyColorDark)};
    margin: ${({ margin }: LabelProps) => (margin ? margin : '0px')};
    font-family: 'NunitoSans-Bold';
`;

interface RegularTextProps {
    color?: string;
    margin?: string;
}

export const RegularText = styled.p`
    font-size: 15px;
    color: ${({ color }: RegularTextProps) => (color ? color : greyColor)};
    margin: ${({ margin }: RegularTextProps) => (margin ? margin : '0px')};
    font-family: 'NunitoSans-SemiBold';
`;

interface SmallTextProps {
    color?: string;
    margin?: string;
}

export const SmallText = styled.p`
    font-size: 12px;
    color: ${({ color }: SmallTextProps) => (color ? color : whiteColor)};
    margin: ${({ margin }: SmallTextProps) => (margin ? margin : '0px')};
    font-family: 'NunitoSans-Bold';
`;

interface CancelButtonProps {
    light?: boolean;
    font?: number;
}

export const CancelButton = styled.p`
    color: ${({ light }: CancelButtonProps) => (light ? greyColorBright : greyColor)};
    cursor: pointer;
    font-family: 'NunitoSans-Bold';
    font-size: ${({ font }: CancelButtonProps) => (font ? font : 18)}px;
`;

export const Mark = styled.span`
    color: ${redColor};
`;

export const ErrorInput = styled.p`
    position: absolute;
    bottom: -16px;
    right: 10px;
    font-size: 10px;
    color: ${redColor};
    font-family: 'NunitoSans-ExtraBold';
`;

export const Chip = styled.span`
    position: absolute;
    margin-bottom: 28px;
    font-size: 14px;
    color: ${redColor};
`;
