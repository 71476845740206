import React, { Component } from "react";
import { matchPath, withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

interface IProps {
  icon: any;
  title: string;
  path: string;
}

class SideMenuItem extends Component<IProps & RouteComponentProps> {
  render() {
    const { path, icon, title } = this.props;
    const isActive = matchPath(this.props.location.pathname, { path });

    return (
      <NavLink to={path} style={{ textDecoration: "none", color: "#565682" }}>
        <Li className={isActive ? "active" : ""}>
          <Icon src={icon} alt={title} />
          {title}
        </Li>
      </NavLink>
    );
  }
}

export default withRouter(SideMenuItem);

const Li = styled.li`
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  font-family: "NunitoSans-SemiBold";
  font-size: 20px;
  color: #565682;
  padding-right: 44px;
  padding-left: 50px;
  transition: all ease-in-out 0.3s;
  &:hover {
    background-color: #eef2f6;
  }
`;
const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 37px;
`;
