import React from 'react';
import { ICoordinates } from './index';
// import { useSideMenuContext } from 'components/SideMenu/SideMenuContext';
import { Image, ImgContainer } from './styles';
import { IContentSchema, IContentSchemaGrid, IReceiptImageBox } from 'redux/editReceipt/types';
import { ISelectedBox } from 'pages/EditReceipt/EditReceipt';
import Editor from './Editor';
import { Grid, IGridLabel } from './Grid';
import { IUseGridBody } from 'net/api';

interface Props {
    available_template?: boolean;
    grid?: IContentSchemaGrid;
    gridTemplate?: IContentSchemaGrid;
    labels: IGridLabel[];
    url: string;
    isKey?: boolean;
    document: string;
    selected: boolean;
    floatText?: string;
    floatLabel?: string;
    selectedBoxes: ISelectedBox[];
    boxes: IReceiptImageBox[];
    selectedContent?: IContentSchema;
    isActive?: boolean;
    is_edit_grid: boolean;
    onUseTemplate: (v: IUseGridBody) => void;
    onSaveAsTemplate: (part: IContentSchemaGrid) => void;
    onStartSelectedArea: (document_id: string) => void;
    onEndSelectedArea: (document_id: string) => void;
    onSelected: (id: string, state?: boolean) => void;
    setHeight: (height: number) => void;
    onCancelText: () => void;
    onAcceptText: (document_id: string, value: string) => void;
    onSelectedArea: (
        document_id: string,
        original_coordinates: ICoordinates,
        textract_coordinates: ICoordinates
    ) => void;
    rotateDeg: number;
}

interface State {
    editor: boolean;
}

class Img extends React.PureComponent<Props, State> {
    state: State = {
        editor: false
    };

    image_ref = React.createRef<any>();
    grid_ref = React.createRef<Grid>();

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onLoad = () => {
        this.props.setHeight(this.image_ref.current.getBoundingClientRect().height);
        this.setState({ editor: true });
    };

    onResize = () => {
        this.props.setHeight(this.image_ref.current.getBoundingClientRect().height);
    };

    getGrid() {
        return this.grid_ref.current?.onApply();
    }

    render() {
        const { url, is_edit_grid, rotateDeg } = this.props;
        const { editor } = this.state;

        return (
            <ImgContainer>
                <Image
                    $rotateDeg={rotateDeg}
                    ref={this.image_ref}
                    src={url}
                    alt="edit image"
                    onLoad={this.onLoad}
                />
                {is_edit_grid ? (
                    <Grid
                        document={this.props.document}
                        ref={this.grid_ref}
                        labels={this.props.labels}
                        grid={this.props.grid}
                        img_width={this.image_ref.current.getBoundingClientRect().width}
                        img_height={this.image_ref.current.getBoundingClientRect().height}
                        naturalWidth={this.image_ref.current.naturalWidth}
                        naturalHeight={this.image_ref.current.naturalHeight}
                        onUseTemplate={this.props.onUseTemplate}
                        gridTemplate={this.props.gridTemplate}
                        onSaveAsTemplate={this.props.onSaveAsTemplate}
                        available_template={this.props.available_template}
                    />
                ) : editor ? (
                    <Editor
                        onStartSelectedArea={this.props.onStartSelectedArea}
                        onEndSelectedArea={this.props.onEndSelectedArea}
                        onSelectedArea={this.props.onSelectedArea}
                        url={this.props.url}
                        document={this.props.document}
                        boxes={this.props.boxes}
                        selectedBoxes={this.props.selectedBoxes}
                        setHeight={this.props.setHeight}
                        floatText={this.props.floatText}
                        floatLabel={this.props.floatLabel}
                        onSelected={this.props.onSelected}
                        selected={this.props.selected}
                        onAcceptText={this.props.onAcceptText}
                        onCancelText={this.props.onCancelText}
                        selectedContent={this.props.selectedContent}
                        isActive={this.props.isActive}
                        isKey={this.props.isKey}
                        img_width={this.image_ref.current.getBoundingClientRect().width}
                        img_height={this.image_ref.current.getBoundingClientRect().height}
                        naturalWidth={this.image_ref.current.naturalWidth}
                        naturalHeight={this.image_ref.current.naturalHeight}
                    />
                ) : null}
            </ImgContainer>
        );
    }
}

export default Img;
