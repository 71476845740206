import { EditReceiptActionTypes, EditReceiptState } from './types';

const initialState: EditReceiptState = {
    stakeholdersList: { items: [] },
    categoriesList: { items: [] },
    listIsLoading: false,
    imageBoxes: [],
    loading: true,
    actionLoading: false,
    editableReceipt: null,
    content: [],
    originalShema: [],
    schemas: [],
    selectedContent: undefined,
    selectedSchema: undefined,
    activeKey: false,
    gridTemplate: undefined,
    isStakeholdersLoading: true
};

const editReceiptReducer = (
    state = initialState,
    actions: EditReceiptActionTypes
): EditReceiptState => {
    switch (actions.type) {
        case 'UPDATE_ACTIVE_KEY':
            return {
                ...state,
                activeKey: actions.item
            };
        case 'SET_EDIT_STAKEHOLDERS_LIST':
            return {
                ...state,
                stakeholdersList: actions.data
            };
        case 'SET_EDIT_CATEGORIES_LIST':
            return {
                ...state,
                categoriesList: actions.data
            };
        case 'SET_EDIT_LIST_LOADING':
            return {
                ...state,
                listIsLoading: actions.val
            };
        case 'SET_IS_STAKEHOLDERS_LOADING':
            return {
                ...state,
                isStakeholdersLoading: actions.val
            };
        case 'SET_EDIT_SCENE_ACTION_LOADING':
            return {
                ...state,
                actionLoading: actions.loading
            };
        case 'SET_RECEIPT_IMAGE_BOXES':
            return {
                ...state,
                imageBoxes: actions.data
            };
        case 'SET_EDIT_SCENE_LOADING':
            return {
                ...state,
                loading: actions.loading
            };
        case 'SET_EDITABLE_RECEIPT':
            return {
                ...state,
                editableReceipt: actions.receipt
            };
        case 'SET_CONTENT':
            return {
                ...state,
                content: actions.data
            };
        case 'SET_ORIGINAL_SHEMA':
            return {
                ...state,
                originalShema: actions.data
            };
        case 'UPDATE_CONTENT_ITEM':
            return {
                ...state,
                content: state.content.map((item) => {
                    if (item.id === actions.item.id) {
                        return actions.item;
                    }
                    return item;
                }),
                selectedContent:
                    state.selectedContent && state.selectedContent.id === actions.item.id
                        ? actions.item
                        : state.selectedContent
            };
        case 'ADD_NEW_CONTENT_ITEMS':
            return {
                ...state,
                content: [...state.content, ...actions.data]
            };
        case 'SET_SELECTED_CONTENT':
            return {
                ...state,
                selectedContent: actions.item
            };
        case 'SET_SELECTED_SCHEMA':
            return {
                ...state,
                selectedSchema: actions.item
            };
        case 'SET_SCHEMAS':
            return {
                ...state,
                schemas: actions.data
            };
        case 'ADD_CONTENT':
            return {
                ...state,
                content: [...state.content, ...actions.data]
            };
        case 'DELETE_CONTENT':
            return {
                ...state,
                content: state.content.filter((c) => !actions.data.includes(c.id))
            };
        case 'UPDATE_GRID_TEMPLATE':
            return {
                ...state,
                gridTemplate: actions.data
            };
        case 'UPDATE_STAKEHOLDER_DATE_FORMAT':
            return {
                ...state,
                editableReceipt: !state.editableReceipt
                    ? null
                    : {
                          ...state.editableReceipt,
                          stakeholder_date_format: actions.stakeholderDateFormat
                      }
            };
        default:
            return state;
    }
};

export default editReceiptReducer;
