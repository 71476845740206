import React, { FC, useState } from 'react';

import Setting from '../../blocks/Setting';
import { EnumReceiptStatus, EnumReceiptType } from 'redux/receipt/types';
import PrimaryButton from 'components/PrimaryButton';

import repairIcon from 'assets/images/icons/repair.png';
import trashIcon from 'assets/images/icons/trash.png';
import downloadIcon from 'assets/images/icons/download.png';
import arrowIcon from 'assets/images/icons/arrowDownBig.png';
import { Container, Icon, IconBlock, RepairBlock } from './styles';

interface Props {
    active: boolean;
    canExport?: boolean;
    isArchive: boolean;
    receipt_type: EnumReceiptType | undefined;
    onCreate: () => void;
    onDelete: () => void;
    onExport: () => void;
    convertReceipt: () => any;
    chnageSelectedFlag: (flag: boolean) => any;
    changeStatusHandle: (status: EnumReceiptStatus) => any;
}

const ActionPanel: FC<Props> = ({
    active,
    canExport = false,
    isArchive,
    receipt_type,
    onCreate,
    onExport,
    onDelete,
    convertReceipt,
    chnageSelectedFlag,
    changeStatusHandle
}) => {
    const [setting, setSetting] = useState(false);

    const toogleSetting = () => setSetting(!setting);

    const isActive = (func: () => void) => {
        return () => {
            if (active) {
                func();
            }
        };
    };

    return (
        <Container>
            <IconBlock>
                <Icon
                    active={canExport}
                    margin="0 25px 0 0"
                    src={downloadIcon}
                    alt="dowload"
                    onClick={() => canExport && onExport()}
                />
                <RepairBlock onClick={isActive(toogleSetting)}>
                    <Icon active={active} src={repairIcon} alt="tool" />
                    <Icon active={active} src={arrowIcon} alt="arrow" />
                </RepairBlock>
                <Icon active={active} src={trashIcon} alt="trash" onClick={isActive(onDelete)} />
            </IconBlock>

            <PrimaryButton
                title={'+ ADD'}
                onClick={onCreate}
                style={{ width: '126px', height: '35px' }}
            />

            {setting && (
                <Setting
                    isArchive={isArchive}
                    receipt_type={receipt_type}
                    convertReceipt={convertReceipt}
                    chnageSelectedFlag={chnageSelectedFlag}
                    closePopUp={isActive(toogleSetting)}
                    changeStatusHandle={changeStatusHandle}
                />
            )}
        </Container>
    );
};

export default ActionPanel;
