import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { IDuplicate } from 'redux/receipt/types';

import TinyLoader from 'components/TinyLoader';

import { RegularText, SmallText } from 'styles/text';
import { greyColorDark, primaryColor, whiteColor } from 'styles/colors';

import duplicateIcon from 'assets/images/icons/duplicate.png';

interface Props {
    data: null | IDuplicate[];
    receipt_id: string;
    getDuplicateInfo: () => any;
}

const DuplicateInfo: FC<Props> = ({ data, getDuplicateInfo, receipt_id }) => {
    const [popUp, setPopUp] = useState(false);

    const onMouseEnter = () => {
        getDuplicateInfo();
        setPopUp(true);
    };

    const makeLink = (receipt_id: string, company_id: string) => {
        const origin = window.location.origin;

        return `${origin}/edit-receipt/${receipt_id}/?company_id=${company_id}`;
    };

    const format = (str: string) => {
        if (str.length > 21) {
            return `${str.slice(0, 18)}...`;
        }

        return str;
    };

    return (
        <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={() => setPopUp(false)}>
            <ActionIcon alt="duplicate" src={duplicateIcon} />
            {popUp && (
                <Container>
                    <RegularText color={whiteColor}>Duplicates</RegularText>
                    {data ? (
                        data.map((item) => (
                            <Item key={item.receipt_id}>
                                <Dot active={item.receipt_id === receipt_id} />
                                {item.disabled_for_editing ? (
                                    <SmallText style={{ textDecoration: 'underline' }}>
                                        {format(item.original_file_name || item.receipt_id)}
                                    </SmallText>
                                ) : (
                                    <a
                                        href={makeLink(item.receipt_id, item.company_id)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <SmallText style={{ textDecoration: 'underline' }}>
                                            {format(item.original_file_name || item.receipt_id)}
                                        </SmallText>
                                    </a>
                                )}
                                <SmallText style={{ paddingLeft: 4 }}>- {item.status}</SmallText>
                            </Item>
                        ))
                    ) : (
                        <LoaderWrapper>
                            <TinyLoader />
                        </LoaderWrapper>
                    )}
                </Container>
            )}
        </Wrapper>
    );
};

export default DuplicateInfo;

const Wrapper = styled.div`
    position: relative;
`;

const Container = styled.div`
    position: absolute;
    background-color: ${greyColorDark};
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 7px 14px;
    z-index: 100;
    width: 215px;

    &:before {
        position: absolute;
        top: -8px;
        left: 0;
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-width: 0 5px 8px;
        border-style: solid;
        border-left-color: transparent;
        border-bottom-color: ${greyColorDark};
        border-right-color: transparent;
        border-top-color: transparent;
        box-sizing: border-box;
    }

    cursor: default;
`;

const Dot = styled.div<{ active?: boolean }>`
    width: 7px;
    height: 7px;
    background-color: ${({ active }) => active && primaryColor};
    border-radius: 50%;
    margin-right: 10px;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const LoaderWrapper = styled.div`
    margin-top: 10px;
`;

interface ActionIconProps {
    width?: number;
    height?: number;
}

const ActionIcon = styled.img<ActionIconProps>`
    width: ${({ width }: ActionIconProps) => (width ? width : '20')}px;
    height: ${({ height }: ActionIconProps) => (height ? height : '20')}px;
    cursor: pointer;
    margin-right: 15px;
`;
