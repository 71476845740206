import React, { CSSProperties, FC, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { greyColorDark, greyColorBright } from "styles/colors";

import { RegularText } from "styles/text";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  domen?: string;
  containerStyle?: CSSProperties;
}

const InputLabel: FC<Props> = ({ label, domen, containerStyle, ...rest }) => {
  return (
    <Container style={containerStyle}>
      <Label>{label}</Label>
      <Input {...rest} />
      {domen && <Domen>{domen}</Domen>}
    </Container>
  );
};

export default InputLabel;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const Label = styled(RegularText)`
  min-width: 132px;
`;

export const Domen = styled(RegularText)`
  margin-left: 15px;
`;

export const Input = styled.input`
  flex: 1;
  height: 35px;
  background: #ffffff;
  border: 0.4px solid #eeeeee;
  box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
  border-radius: 5px;
  outline: none;
  font-family: "NunitoSans-SemiBold";
  font-size: 15px;
  color: ${greyColorDark};
  text-indent: 15px;
  &::placeholder {
    color: ${greyColorBright};
  }
`;
