import { Dispatch } from 'redux';
import { AppState } from 'redux/rotReducer';
import { UserActions } from 'redux/user/actions';
import { ICompany } from 'redux/user/types';
import {
    API,
    IDeleteReceiptParams,
    IGetCountParams,
    IGetReceiptParams,
    IGetUpload,
    ISelectCompanyParams,
    IUploadType
} from '../../net/api';
import { ReduxActions } from '../types';
import { EnumReceiptStatus, EnumReceiptType, IReceipt } from './types';

export class ReceiptActions {
    static setReceiptLoading = (loading: boolean): ReduxActions => ({
        type: 'SET_RECEIPT_LOADING',
        loading
    });

    static addReceipt = (receipt: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        dispatch({
            type: 'ADD_RECEIPT',
            receipt
        });
        dispatch({
            type: 'SET_COUNT',
            receipt_type: receipt.receipt_type,
            status: receipt.status,
            num: 1
        });
    };

    static deleteReceiptAction = (receipt_id: string): ReduxActions => ({
        type: 'DELETE_RECEIPT',
        receipt_id
    });

    static updateCount =
        (receipt: IReceipt) =>
        // (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
        (dispatch: Dispatch<any>, getState: () => AppState) => {
            dispatch(ReceiptActions.getCounts());
            // const { count } = getState().receipt;
            // if (count[receipt.receipt_type][EnumReceiptStatus.Processing]) {
            //   dispatch({
            //     type: "SET_COUNT",
            //     receipt_type: receipt.receipt_type,
            //     status: EnumReceiptStatus.Processing,
            //     num: -1,
            //   });
            // }

            // dispatch({
            //   type: "SET_COUNT",
            //   receipt_type: receipt.receipt_type,
            //   status: receipt.status,
            //   num: 1,
            // });
        };

    static updateReceipt =
        (receipt: IReceipt) => (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { receipts } = getState().receipt;
            const isExist = receipts.items.find((r) => r.receipt_id === receipt.receipt_id);

            dispatch<any>(ReceiptActions.updateCount(receipt));
            if (isExist) {
                dispatch({
                    type: 'UPDATE_RECEIPTS',
                    receipt
                });
            } else {
                dispatch({
                    type: 'ADD_RECEIPT',
                    receipt
                });
            }
        };

    static getReceipts =
        (params: IGetReceiptParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            dispatch(ReceiptActions.setReceiptLoading(true));

            const { user } = getState();
            if (!params.company_id) {
                if (
                    user.activeAllCompanies &&
                    params.receipt_status !== EnumReceiptStatus.Unassigned
                ) {
                    params.selected = 'ALL';
                }
            }
            try {
                const res = await API.getReceipts(params);
                dispatch({ type: 'SET_RECEIPTS', receipts: res.data });
            } catch (error) {
            } finally {
                dispatch(ReceiptActions.setReceiptLoading(false));
            }
        };

    static loadReceipts =
        (params: IGetReceiptParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            const { user, receipt } = getState();
            if (user.activeAllCompanies) {
                params.selected = 'ALL';
                if (receipt.receipts.next_company) {
                    params.next_company = receipt.receipts.next_company;
                }
            }
            if (receipt.receipts.next) {
                params.next = receipt.receipts.next;
            }

            if (!receipt.receipts.next && !receipt.receipts.next_company) {
                return;
            }

            try {
                const res = await API.getReceipts(params);
                dispatch({
                    type: 'SET_RECEIPTS',
                    receipts: {
                        ...res.data,
                        items: [...receipt.receipts.items, ...res.data.items]
                    }
                });
            } catch (error) {}
        };

    static getReceiptHistory = (receipt_id: string) => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.getReceiptHistory(receipt_id);
            dispatch({ type: 'SET_RECEIPT_HISTORY', history: res.data });
        } catch (error) {}
    };

    static uploadPhoto =
        (photos: Array<any>, upload_type: IUploadType, user_id?: string, process_rows?: boolean) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { activeAllCompanies } = getState().user;
            try {
                for (let i = 0; i < photos.length; i++) {
                    // 1. Get uploads params
                    const params: IGetUpload = {
                        receipt_type: photos[i].receipt_type,
                        type: photos[i].file.type,
                        upload_type,
                        notes: '',
                        receipt_id: '',
                        category: '',
                        original_file_name: photos[i].file.name,
                        user_id,
                        process_rows
                    };
                    if (activeAllCompanies) {
                        params['company_id'] = 'NONE';
                    }
                    const res = await API.getUpload(params);
                    // 2. Send to S3
                    const resS3 = await API.putUploadURL(res.data.uploadURL, photos[i].file);
                }
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.message) {
                    return error.response.data.message;
                } else {
                    return 'Error';
                }
            }
        };

    static changeStatus =
        (receipt: IReceipt, status: EnumReceiptStatus) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => any): Promise<void> => {
            const { user } = getState();
            if (user.activeAllCompanies) {
                const company = user.companies.items.find(
                    (item: ICompany) => item.company_id === receipt.company_id
                );
                if (company) {
                    await dispatch<any>(UserActions.setActiveCompany(company));
                }
            }

            try {
                const res = await API.changeStatus(receipt.receipt_id, status);
                dispatch({
                    type: 'UPDATE_RECEIPTS',
                    receipt: { ...receipt, status }
                });
                dispatch<any>(ReceiptActions.updateReceiptCount(receipt, { ...receipt, status }));
            } catch (error) {}
        };

    static exportReceipt = (receipt: IReceipt) => async (): Promise<void> => {
        try {
            const { data } = await API.getExport({}, receipt.receipt_id);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = `data:${data.mime};base64,${data.base64}`;
            // the filename you want
            a.download = data.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {}
    };

    static changeFlag =
        (receipt: IReceipt, flag: boolean) =>
        async (dispatch: Dispatch<ReduxActions>): Promise<void> => {
            try {
                dispatch({
                    type: 'UPDATE_RECEIPTS',
                    receipt: { ...receipt, flag }
                });
                const res = await API.changeFlag(receipt.receipt_id, flag);
            } catch (error) {}
        };

    static deleteReceipt = (receipt: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        await dispatch<any>(ReceiptActions.checkActiveAll(receipt.company_id));
        const params: IDeleteReceiptParams = {};
        if (receipt.company_id === receipt.user_id) {
            params.unassigned = 1;
        }
        try {
            const res = await API.deleteReceipt(receipt.receipt_id, params);
            dispatch(ReceiptActions.deleteReceiptAction(receipt.receipt_id));
            dispatch({
                type: 'SET_COUNT',
                receipt_type: receipt.receipt_type,
                status: receipt.status,
                num: -1
            });
        } catch (error) {}
    };

    static convertReceipt = (receipt: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        let receipt_type: EnumReceiptType =
            receipt.receipt_type === EnumReceiptType.Invoice
                ? EnumReceiptType.Receipt
                : EnumReceiptType.Invoice;
        try {
            const res = await API.convertReceipt(receipt.receipt_id, receipt_type);
            dispatch(ReceiptActions.deleteReceiptAction(receipt.receipt_id));
            dispatch<any>(
                ReceiptActions.updateReceiptCount(receipt, {
                    ...receipt,
                    receipt_type
                })
            );
        } catch (error) {}
    };

    static addItems = (receipt: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.editStakeholder({
                id: receipt.stakeholder_id,
                recognise_details: true
            });
        } catch (error) {}
    };

    static getCounts = () => async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
        const params: IGetCountParams = {};
        const { activeAllCompanies } = getState().user;
        if (activeAllCompanies) {
            params.selected = 'ALL';
        }
        try {
            const res = await API.getCounts(params);
            dispatch({ type: 'SET_COUNT_LIST', data: res.data });
        } catch (error) {}
    };

    static updateReceiptCount =
        (oldRec: IReceipt, receipt: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
            dispatch({
                type: 'SET_COUNT',
                receipt_type: oldRec.receipt_type,
                status: oldRec.status,
                num: -1
            });
            dispatch({
                type: 'SET_COUNT',
                receipt_type: receipt.receipt_type,
                status: receipt.status,
                num: 1
            });
        };

    static getXML = (receipt_id: string) => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            const res = await API.getExport({ xml: 1 }, receipt_id);
            return res.data;
        } catch (error) {}
    };

    static selectCompany =
        (params: ISelectCompanyParams, receipt: IReceipt) =>
        async (dispatch: Dispatch<ReduxActions>) => {
            try {
                const res = await API.selectCompany(params, receipt.receipt_id);
                receipt.receipt_id = res.data.receipt_id;
                dispatch({
                    type: 'UPDATE_RECEIPTS',
                    receipt: { ...receipt, status: EnumReceiptStatus.Review }
                });
                dispatch<any>(
                    ReceiptActions.updateReceiptCount(receipt, {
                        ...receipt,
                        status: EnumReceiptStatus.Review
                    })
                );

                return res.data.receipt_id;
            } catch (error) {
                console.error('[selectCompany] Err: ', error);
            }
        };

    static checkActiveAll =
        (company_id: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { activeAllCompanies, companies } = getState().user;
            if (activeAllCompanies) {
                const receiptCompany = companies.items.find((c) => c.company_id === company_id);
                if (receiptCompany) {
                    await dispatch<any>(UserActions.setActiveCompany(receiptCompany));
                }
            }
        };

    static getReceiptPreview = (r: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        await dispatch<any>(ReceiptActions.checkActiveAll(r.company_id));
        dispatch({ type: 'SET_RECEIPT_PREVIEW', data: null });
        try {
            let t = '';

            if (r.status === EnumReceiptStatus.Unassigned) {
                t = r.company_id;
            }
            const res = await API.getReceiptImage(r.receipt_id, t);
            dispatch({ type: 'SET_RECEIPT_PREVIEW', data: res.data });
        } catch (error) {}
    };

    static getReceiptEmailInfo = (r: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        if (r.email_id) {
            dispatch({ type: 'SET_RECEIPT_EMAIL_INFO', data: null });
            try {
                const res = await API.getReceiptEmailInfo(r.email_id);
                dispatch({ type: 'SET_RECEIPT_EMAIL_INFO', data: res.data });
            } catch (error) {
                console.error('[getReceiptEmailInfo] Err: ', error);
            }
        }
    };

    static toogleCaptureData = (key: string): ReduxActions => ({
        type: 'TOOGLE_CAPTURE_DATA',
        key
    });

    static getDuplicateInfo = (r: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        if (r.duplicate_id) {
            dispatch({ type: 'SET_DUPLICATE_INFO', data: null });
            try {
                const res = await API.getDuplicateInfo(r.receipt_id);
                dispatch({ type: 'SET_DUPLICATE_INFO', data: res.data });
            } catch (error) {
                console.error('[getDuplicateInfo] Err: ', error);
            }
        }
    };

    static restartReceipt = (r: IReceipt) => async (dispatch: Dispatch<ReduxActions>) => {
        try {
            await API.restartReceipt(r.receipt_id, r.company_id);
            r.can_be_restarted = false;
            dispatch({
                type: 'UPDATE_RECEIPTS',
                receipt: r
            });
        } catch (error) {
            console.error('[restartReceipt] Err: ', error);
        }
    };
}
