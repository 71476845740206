import React, { FC } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

//@ts-ignore
import PRIVACY_PDF from 'assets/pdf/privacy-policy.pdf';

import { AuthState } from 'redux/auth/types';
import { AuthActions, SignUpParams } from 'redux/auth/actions';
import { SignUpSchema } from 'helpers/validations';

import CheckBox from 'components/CheckBox';
import SideBar from './components/SideBar';
import AuthInput from 'components/AuthInput';
import PrimaryButton from 'components/PrimaryButton';
import {
    AuthText,
    AuthMain,
    AuthTitle,
    AgreeText,
    ActionLink,
    AuthContainer,
    AgreeContainer,
    AgreeError,
    DocLink
} from './styles';

const styles = {
    input: {
        marginBottom: 24
    },
    registerButton: {
        width: 332,
        height: 53,
        marginTop: 40,
        marginBottom: 70
    }
};

interface Props extends RouteComponentProps {
    auth: AuthState;
    signUp: (params: SignUpParams) => Promise<boolean>;
}

const SignUp: FC<Props> = ({ auth, history, signUp }) => {
    const formik = useFormik({
        validationSchema: SignUpSchema,
        initialValues: {
            name: '',
            surname: '',
            company: '',
            company_code: '',
            email: '',
            password: '',
            passwordRepeat: '',
            acceptTerms: false
        },
        onSubmit: async ({ name, surname, company, company_code, email, password }) => {
            const res = await signUp({
                name,
                surname,
                company,
                company_code,
                email,
                password
            });
            if (res) {
                history.push('/signup/confirm');
            } else {
                formik.setErrors({ email: 'The user already exists' });
            }
        }
    });

    const onAcceptPress = () => {
        formik.setFieldValue('acceptTerms', !formik.values.acceptTerms);
    };

    return (
        <AuthContainer>
            <AuthMain>
                <AuthTitle style={{ marginBottom: '40px' }}>Create an account</AuthTitle>
                <AuthInput
                    id="name"
                    name="name"
                    type="text"
                    style={styles.input}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="Name"
                />
                <AuthInput
                    id="surname"
                    name="surname"
                    type="text"
                    style={styles.input}
                    touched={formik.touched.surname}
                    error={formik.errors.surname}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.surname}
                    placeholder="Surname"
                />
                <AuthInput
                    id="company"
                    name="company"
                    type="text"
                    style={styles.input}
                    touched={formik.touched.company}
                    error={formik.errors.company}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    placeholder="Company name"
                />
                <AuthInput
                    id="company_code"
                    name="company_code"
                    type="text"
                    style={styles.input}
                    touched={formik.touched.company_code}
                    error={formik.errors.company_code}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.company_code}
                    placeholder="Company code"
                />
                <AuthInput
                    id="email"
                    name="email"
                    type="text"
                    style={styles.input}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Email"
                />
                <AuthInput
                    secure
                    id="password"
                    name="password"
                    type="password"
                    style={styles.input}
                    touched={formik.touched.password}
                    error={formik.errors.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Password"
                />
                <AuthInput
                    secure
                    type="password"
                    id="passwordRepeat"
                    name="passwordRepeat"
                    placeholder="Repeat password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.errors.passwordRepeat}
                    value={formik.values.passwordRepeat}
                    touched={formik.touched.passwordRepeat}
                />
                <AgreeContainer onClick={onAcceptPress}>
                    <AgreeText>
                        I agree to the Assistant bank terms of
                        <br />
                        Services and{' '}
                        <DocLink href={PRIVACY_PDF} target="_blank">
                            Privacy Policy
                        </DocLink>
                    </AgreeText>
                    <CheckBox checked={formik.values.acceptTerms} />
                    {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                        <AgreeError>{formik.errors.acceptTerms}</AgreeError>
                    )}
                </AgreeContainer>

                <PrimaryButton
                    bigLoader
                    loading={auth.loading}
                    style={styles.registerButton}
                    title="REGISTER"
                    onClick={formik.handleSubmit}
                />
                <AuthText>
                    Already have an account?
                    <ActionLink to={'/signin'}> Log in</ActionLink>
                </AuthText>
            </AuthMain>

            <SideBar />
        </AuthContainer>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

const mapDispatchToProps = {
    signUp: AuthActions.signUp
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
