import React, { FC } from "react";
import moment from "moment";
import styled from "styled-components";

import { AdminInfoProps } from "./index";

import PrimaryHeader from "components/PrimaryHeader";
import LabelInfo from "components/LabelInfo";
import ToogleCount from "./components/ToogleCount";

import { Box, Main } from "./styles";
import { Container } from "styles/blocks";
import { BigTitle } from "styles/text";
import { greyColorDark } from "styles/colors";
import { IPostAdminCountParams } from "net/api";

const AdminInfo: FC<AdminInfoProps> = ({
  match,
  history,
  companies,
  updateAdminCount,
}) => {
  // @ts-ignore
  const company_id = match.params.company_id;
  const company = companies.find((e) => e.company_id === company_id);

  if (!company) {
    history.push("/admin");
    return <></>;
  }

  const onSumbit = async (n: number, reset: boolean) => {
    const params: IPostAdminCountParams = {};
    if (reset) {
      params.reset = 1;
    }
    await updateAdminCount(
      company.company_id,
      { available_documents: n },
      params
    );
  };

  return (
    <Container>
      <PrimaryHeader
        title="Company Info"
        margin={`0 0 0 0`}
        onClick={() => history.push("/admin")}
      />
      <Main>
        <Box>
          <Label>Company Info</Label>
          <Block>
            <InfoBlock>
              <LabelInfo
                label="Date"
                value={moment(company.created_at).format("DD MMM YY")}
              />
              <LabelInfo label="Company" value={company.name} />
              <LabelInfo
                label="User"
                value={`${company.user.name} ${company.user.surname}`}
              />
              <LabelInfo label="Email" value={company.user.email} />
            </InfoBlock>
            <InfoBlock>
              <LabelInfo
                label="Documents used"
                value={company.used_documents}
              />
              <LabelInfo
                label="Documents available"
                value={company.available_documents}
              />
            </InfoBlock>
          </Block>
        </Box>
        <Box>
          <ToogleCount
            count={company.available_documents}
            onPress={onSumbit}
            path={match.path}
          />
        </Box>
      </Main>
    </Container>
  );
};

export default AdminInfo;

const Label = styled(BigTitle)`
  color: ${greyColorDark};
`;

const Block = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

const InfoBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: 60px;
  }
`;
