import styled from "styled-components/macro";

export const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0 2px;
  border-collapse: separate;
  text-align: left;
  thead th {
    font-size: 18px;
    color: #565682;
    font-family: "NunitoSans-Bold";
    padding-bottom: 32px;
  }
  tbody {
    display: block;
    height: 70vh;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 2px;
  }
  tbody tr {
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.2);
    border-radius: 10px;
    background: #ffffff;
    cursor: pointer;
  }
  tbody td {
    color: #9191af;
    font-size: 15px;
    font-family: "NunitoSans-SemiBold";
    height: 83px;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`;
