import React, { FC } from 'react';
import styled from 'styled-components';

import SmallLoader from 'components/SmallLoader';

import { Filter } from 'styles/blocks';
import { CancelButton } from 'styles/text';

import closePng from 'assets/images/icons/closeWhite.png';

interface Props {
    loading?: boolean;
    headerTitle?: string;
    title: string;
    closePopUp: () => void;
    onDelete: () => void;
}

const Delete: FC<Props> = ({
    headerTitle = 'Delete Items',
    title,
    loading,
    closePopUp,
    onDelete
}) => {
    const onDeleteHandle = () => {
        if (loading) {
            return;
        }
        onDelete();
    };

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>{headerTitle}</HeaderText>
                    {loading ? (
                        <SmallLoader />
                    ) : (
                        <Close src={closePng} alt="close" onClick={closePopUp} />
                    )}
                </Header>
                <Main>
                    <MainTitle>{title}</MainTitle>
                    <MainSubTitle>This actions is irreversible!</MainSubTitle>

                    <Buttons>
                        {loading ? (
                            <SmallLoader />
                        ) : (
                            <>
                                <CancelButton light onClick={closePopUp}>
                                    Cancel
                                </CancelButton>
                                <DeleteBtn onClick={onDeleteHandle}>Delete</DeleteBtn>
                            </>
                        )}
                    </Buttons>
                </Main>
            </Container>
        </Filter>
    );
};

export default Delete;

const Container = styled.div`
    margin-top: 70px;
    width: 600px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;
const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;
const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;
const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;
const MainTitle = styled.p`
    color: #fff;
    margin-bottom: 28px;
    word-break: break-all;
`;
const MainSubTitle = styled.p`
    color: #d16e6e;
    margin-bottom: 57px;
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const DeleteBtn = styled.button`
    width: 125px;
    height: 55px;
    background-color: #d16e6e;
    border: none;
    outline: none;
    font-family: 'NunitoSans-Bold';
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 28px;
`;
