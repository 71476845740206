import React, { FC } from "react";
import styled from "styled-components";
import Switch from "react-switch";

import { IReceiptShemaItem } from "redux/setting/types";

import { Title, RegularText } from "styles/text";
import { greyColorDark, greyColorBright, primaryColor } from "styles/colors";

const ItemSwitch = ({ disabled, checked, label, onChange, onColor }: any) => (
  <StyledSwitch>
    <Switch
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      onColor={onColor}
    />
    <SwitchLabel>{label}</SwitchLabel>
  </StyledSwitch>
);

interface Props {
  item: IReceiptShemaItem;
  disabled?: boolean;
  updateHidden: (id: string) => void;
  updateRequired: (id: string) => void;
}

const ItemCheck: FC<Props> = ({
  item,
  disabled,
  updateHidden,
  updateRequired,
}) => {
  const {
    hidden,
    constraints: { required },
  } = item;

  return (
    <Container checked={!hidden && required}>
      <SwitchersContainer>
        <ItemSwitch
          label="Visible"
          checked={!hidden}
          disabled={disabled}
          onChange={() => updateHidden(item.id)}
        />
        <ItemSwitch
          label="Required"
          onColor={primaryColor}
          checked={required}
          disabled={disabled}
          onChange={() => updateRequired(item.id)}
        />
      </SwitchersContainer>

      <Block>
        <Label>{item.label}</Label>
        <Description>{item.description}</Description>
      </Block>
    </Container>
  );
};

export default React.memo(ItemCheck);

interface StyleProps {
  checked: boolean;
}
const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ checked }: StyleProps) =>
    checked ? greyColorBright : "transparent"};
  padding: 15px 30px;
`;

const Label = styled(Title)`
  color: ${greyColorDark};
`;
const Description = styled(RegularText)``;

const Block = styled.div`
  margin-left: 20px;
`;

const SwitchersContainer = styled.div`
  display: flex;
`;

const StyledSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
`;
const SwitchLabel = styled(RegularText)`
  margin-top: 5px;
`;
