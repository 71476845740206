let _ApplicationConfig;

if (process.env.REACT_APP_ENV === 'development') {
    _ApplicationConfig = {
        API_URL: 'https://v0mjhoonx9.execute-api.eu-west-1.amazonaws.com/dev/',
        APP_WEB_SOCKET_URI:
            'wss://dpyyjmnfh1.execute-api.eu-west-1.amazonaws.com/dev?access_token=',
        LIST_LIMIT: 50
    };
} else {
    _ApplicationConfig = {
        API_URL: 'https://mm3ldnpl4g.execute-api.eu-west-1.amazonaws.com/prod/',
        APP_WEB_SOCKET_URI:
            'wss://zy82dds47j.execute-api.eu-west-1.amazonaws.com/prod?access_token=',
        LIST_LIMIT: 50
    };
}

export const ApplicationConfig = _ApplicationConfig;

// https://cors-anywhere.herokuapp.com/
