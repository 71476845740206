import React, { FC } from 'react';
import styled from 'styled-components';

import { whiteColor } from 'styles/colors';
import { RegularText } from 'styles/text';
import trashIcon from '../../../../assets/images/icons/trash.png';
import { Icon } from '../../../Receipts/components/ActionPanel/styles';

interface Props {
    item: any;
    deleteItem: () => void;
    onItemPress: () => void;
}

const ItemComponent: FC<Props> = ({ item, deleteItem, onItemPress }) => {
    return (
        <Container onClick={onItemPress}>
            {/* <ItemClick onClick={onItemPress} /> */}
            <ItemDelete
                onClick={(e) => {
                    e.stopPropagation();
                    deleteItem();
                }}
            />
            <RegularText>{item.name}</RegularText>
            <RegularText>{item.company_name}</RegularText>
            <IconTrashCan active src={trashIcon} alt="trash" />
        </Container>
    );
};

export default ItemComponent;

const Container = styled.div`
    position: relative;
    max-width: 814px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 45px;
    background-color: ${whiteColor};
    margin-bottom: 20px;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
`;

const ItemClick = styled.div`
    position: absolute;
    left: 0;
    max-width: 785px;
    height: 45px;
`;

const ItemDelete = styled.div`
    position: absolute;
    right: -10px;
    width: 34px;
    height: 45px;
    z-index: 10;
    cursor: pointer;
`;

/*const Icon = styled.img`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 10px;
    height: 10px;
`;*/

const IconTrashCan = styled(Icon)`
    position: absolute;
    top: 2px;
    right: 0;
    cursor: pointer;
    padding: 10px;
`;
