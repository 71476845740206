import { Dispatch } from 'redux';

import {
    API,
    ICreateStakeholderParams,
    IEditStakeholder,
    IGetCategoriesParams,
    IGetStakeholdersParams,
    IGetUploadImport,
    IStakeholderType,
    UploadImportType
} from 'net/api';
import { ReduxActions } from 'redux/types';
import { IEditCategory, IItem, ReduxListKeys } from './types';
import { ICompany } from 'redux/user/types';
import { UserActions } from 'redux/user/actions';
import { AppState } from 'redux/rotReducer';
import { FileType } from 'components/DropZone/DropZone';

export class ListActions {
    static restoreLists = () => async (dispatch: Dispatch<ReduxActions>) => {
        dispatch({
            type: 'SET_LIST',
            key: 'customers',
            data: { items: [] }
        });
        dispatch({
            type: 'SET_LIST',
            key: 'suppliers',
            data: { items: [] }
        });
        dispatch({
            type: 'SET_LIST',
            key: 'categories',
            data: { items: [] }
        });
    };

    static loadListPage =
        (title: string, search?: string) => async (dispatch: Dispatch<ReduxActions>) => {
            dispatch<any>({ type: 'SET_IS_LOADING', value: true });
            switch (title) {
                case 'categories': {
                    await dispatch<any>(ListActions.getCategories({ search_name: search }));
                    dispatch<any>({ type: 'SET_IS_LOADING', value: false });
                    return;
                }
                case 'items': {
                    await dispatch<any>(ListActions.getItems());
                    dispatch<any>({ type: 'SET_IS_LOADING', value: false });
                    return;
                }
                case 'customers': {
                    await dispatch<any>(
                        ListActions.getStakeholders({ search_name: search }, 'customer')
                    );
                    dispatch<any>({ type: 'SET_IS_LOADING', value: false });
                    return;
                }
                case 'suppliers': {
                    await dispatch<any>(
                        ListActions.getStakeholders({ search_name: search }, 'supplier')
                    );
                    dispatch<any>({ type: 'SET_IS_LOADING', value: false });
                    return;
                }
            }
        };

    static getCategories =
        (params: IGetCategoriesParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            const { activeAllCompanies } = getState().user;
            if (activeAllCompanies) {
                params.selected = 'ALL';
            }

            try {
                const res = await API.getCategories(params);
                let items = res.data.items.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : 0
                );

                dispatch({
                    type: 'SET_LIST',
                    key: 'categories',
                    data: { ...res.data, items }
                });
            } catch (error) {}
        };

    static getCategoriesByCompany =
        (companyName: string, params: IGetCategoriesParams) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            const { companies } = getState().user;
            const company = companies.items.find((c) => c.name === companyName);
            if (company) {
                await dispatch<any>(UserActions.setActiveCompany(company));
            }
            try {
                const res = await API.getCategories(params);
                let items = res.data.items.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : 0
                );

                dispatch({
                    type: 'SET_LIST',
                    key: 'categoriesByCompany',
                    data: { ...res.data, items }
                });
            } catch (error) {}
        };

    static loadCategories =
        (params: IGetCategoriesParams) =>
        async (disaptch: Dispatch<ReduxActions>, getState: () => any) => {
            const { user, list } = getState();
            if (user.activeAllCompanies) {
                params.selected = 'ALL';
                if (list.categories.next_company) {
                    params.next_company = list.categories.next_company;
                }
            }
            if (list.categories.next) {
                params.next = list.categories.next;
            }

            if (!list.categories.next && !list.categories.next_company) {
                return;
            }

            try {
                const res = await API.getCategories(params);
                let items = res.data.items.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                        ? 1
                        : a.name.toLowerCase() < b.name.toLowerCase()
                        ? -1
                        : 0
                );

                disaptch({
                    type: 'SET_LIST',
                    key: 'categories',
                    data: { ...res.data, items: [...items, ...list.categories.items] }
                });
            } catch (error) {}
        };

    static getStakeholders =
        (params: IGetStakeholdersParams, type?: IStakeholderType) =>
        async (disaptch: Dispatch<ReduxActions>, getState: () => any) => {
            const { activeAllCompanies } = getState().user;
            if (activeAllCompanies) {
                params.selected = 'ALL';
            }

            try {
                const res = await API.getStakeholders(params, type);
                if (type === 'customer') {
                    const customers = res.data.items.filter((item) => item.type === 'customer');
                    disaptch({
                        type: 'SET_LIST',
                        key: 'customers',
                        data: { ...res.data, items: customers }
                    });
                } else {
                    const suppliers = res.data.items.filter((item) => item.type === 'supplier');
                    disaptch({
                        type: 'SET_LIST',
                        key: 'suppliers',
                        data: { ...res.data, items: suppliers }
                    });
                }
            } catch (error) {}
        };

    static createCategory =
        (name: string, code: string, company: string) =>
        async (disaptch: Dispatch<ReduxActions>, getState: () => any) => {
            const { companies } = getState().user;
            const selectedCompany = companies.items.find((item: ICompany) => item.name === company);
            if (selectedCompany) {
                await disaptch<any>(UserActions.setActiveCompany(selectedCompany));
            }

            try {
                const res = await API.postCategories(name, code);
                disaptch({ type: 'ADD_LIST_ITEM', key: 'categories', item: res.data });
            } catch (error) {
                return 'This category already exists';
            }
        };

    static editCategory =
        (data: IEditCategory, company_id?: string) =>
        async (disaptch: Dispatch<ReduxActions>, getState: () => any) => {
            try {
                const res = await API.editCategories(data, company_id);

                disaptch({
                    type: 'EDIT_LIST_ITEM',
                    key: 'categories',
                    item: res.data
                });
            } catch (error) {}
        };

    static createStakeholder =
        (params: ICreateStakeholderParams, company_name?: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => any) => {
            const { companies } = getState().user;
            if (company_name) {
                const company = companies.items.find((c: ICompany) => c.name === company_name);
                await dispatch<any>(UserActions.setActiveCompany(company));
            }

            try {
                const res = await API.postStakeholders(params);
                if (params.type === 'customer') {
                    dispatch({ type: 'ADD_LIST_ITEM', key: 'customers', item: res.data });
                }
                if (params.type === 'supplier') {
                    dispatch({ type: 'ADD_LIST_ITEM', key: 'suppliers', item: res.data });
                }
                return res.data;
            } catch (error) {
                return { error: true, text: `This ${params.type} already exists` };
            }
        };

    static deleteStakeholder =
        (id: string, key: ReduxListKeys) => async (disaptch: any, getState: () => AppState) => {
            try {
                disaptch({ type: 'SET_IS_LOADING', key, value: true });
                const {
                    user: { activeAllCompanies },
                    list: { categories }
                } = getState();
                let company_id = '';

                switch (key) {
                    case 'categories': {
                        if (activeAllCompanies) {
                            company_id =
                                categories.items.find((item) => item.id === id)?.company_id || '';
                        }
                        await API.deleteCategory(id, company_id);
                        disaptch({ type: 'DELETE_LIST_ITEM', key, id });
                        disaptch({ type: 'SET_IS_LOADING', key, value: false });
                        return;
                    }
                    case 'items': {
                        await disaptch(ListActions.deleteItem(id));
                        disaptch({ type: 'SET_IS_LOADING', key, value: false });
                        return;
                    }
                    default:
                        await API.deleteStakeholder(id);
                        disaptch({ type: 'DELETE_LIST_ITEM', key, id });
                        disaptch({ type: 'SET_IS_LOADING', key, value: false });
                }
            } catch (error) {
                disaptch({ type: 'SET_IS_LOADING', key, value: false });
            }
        };

    static editStakeholder =
        (data: IEditStakeholder) => async (disaptch: Dispatch<ReduxActions>) => {
            try {
                const res = await API.editStakeholder(data);
                if (data.type === 'customer') {
                    disaptch({
                        type: 'EDIT_LIST_ITEM',
                        key: 'customers',
                        item: res.data
                    });
                }
                if (data.type === 'supplier') {
                    disaptch({
                        type: 'EDIT_LIST_ITEM',
                        key: 'suppliers',
                        item: res.data
                    });
                }
            } catch (error) {
                return `This ${data.type} already exists`;
            }
        };

    static getItems = () => async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
        try {
            const {
                list: { items },
                user: { activeAllCompanies }
            } = getState();
            let params: any = {};
            if (activeAllCompanies) {
                params.selected = 'ALL';
            }
            const res = await API.getItems({ ...params, next: items.next });

            dispatch({
                type: 'SET_LIST',
                key: 'items',
                data: res.data
            });
        } catch (error) {}
    };

    static createItem =
        (name: string, words: string[], company_name: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                const { companies } = getState().user;
                if (company_name) {
                    const company = companies.items.find((c: ICompany) => c.name === company_name);
                    if (!company) {
                        return;
                    }
                    await dispatch<any>(UserActions.setActiveCompany(company));
                }
                const res = await API.createItem(name, words);
                dispatch({ type: 'ADD_LIST_ITEM', key: 'items', item: res.data });
            } catch (error) {}
        };

    static editItem = (item: IItem) => async (disaptch: any) => {
        try {
            const res = await API.editItem(item);
            disaptch({
                type: 'EDIT_LIST_ITEM',
                key: 'item',
                item: item
            });
        } catch (error) {}
    };

    static deleteItem =
        (name: string) => async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            try {
                await API.deleteItem(name);
                dispatch({ type: 'DELETE_LIST_ITEM', key: 'items', id: name });
            } catch (error) {}
        };

    static uploadImport = (file: FileType, upload_type: UploadImportType) => async () => {
        try {
            const params: IGetUploadImport = {
                upload_type,
                content_type: file.type
            };

            const res = await API.getUploadImport(params);
            await API.putUploadURL(res.data.uploadURL, file);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            }

            throw new Error(error);
        }
    };
}
