import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { redColor } from 'styles/colors';
import { AuthTitle as AuthTitleStyle } from 'styles/text';

export const AuthContainer = styled.div`
    display: flex;
    min-height: 100vh;
    justify-content: space-between;
`;

export const AuthMain = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 170px;
    background-color: #fff;
    @media only screen and (max-height: 1445px) {
        padding-top: 40px;
    }
`;

export const AuthLogo = styled.img`
    width: 199px;
    height: 127px;
    margin-bottom: 5px;
`;

export const AuthTitle = styled(AuthTitleStyle)`
    margin-top: 24px;
    margin-bottom: 80px;
`;

export const AuthText = styled.p`
    color: #8a94a6;
    font-size: 14px;
    user-select: none;
    font-family: 'NunitoSans-Bold';
`;

export const ActionLink = styled(Link)`
    color: #827bf3;
    font-size: 14px;
    user-select: none;
    text-decoration: none;
    font-family: 'NunitoSans-Bold';
`;

export const ForgotContainer = styled.div`
    width: 332px;
    display: flex;
    justify-content: flex-end;
`;

export const AgreeContainer = styled.div`
    position: relative;
    width: 332px;
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
`;

export const AgreeText = styled(AuthText)`
    color: #222222;
`;

export const AgreeError = styled.p`
    font-size: 10px;
    color: ${redColor};
    font-family: 'NunitoSans-ExtraBold';
    position: absolute;
    bottom: -20px;
    left: 25px;
`;

export const DocLink = styled.a`
    color: inherit;
    // text-decoration: none;
    font-family:inherit;

    &:hover { 
      opacity:.75
    }
`;
