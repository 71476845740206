import { Auth } from 'aws-amplify';
import { Dispatch } from 'redux';
import { ReduxActions } from 'redux/types';
import { IInfoPopUpItem } from './types';

export interface SignUpParams {
    name: string;
    surname: string;
    company_code: string;
    company: string;
    email: string;
    password: string;
}

export class AuthActions {
    static onLoad = () => async (dispatch: any) => {
        try {
            await Auth.currentSession();
            if (window.location.pathname === '/signup/invite') {
                dispatch(AuthActions.logOut());
            } else {
                dispatch(AuthActions.setIsAuth(true));
            }
        } catch (error) {
        } finally {
            dispatch(AuthActions.setIsOnLoad(false));
        }
    };

    static singIn =
        (email: string, password: string) => async (dispatch: Dispatch<ReduxActions>) => {
            dispatch<any>(AuthActions.setLoading(true));
            dispatch({ type: 'SET_AUTH_DATA', email, password });
            try {
                await Auth.signIn(email, password);
                dispatch<any>(AuthActions.setIsAuth(true));
                dispatch({ type: 'SET_AUTH_DATA', email: '', password: '' });
            } catch (error) {
                return error;
            } finally {
                dispatch<any>(AuthActions.setLoading(false));
            }
        };

    static forgotPassword = (email: string) => async (dispatch: any) => {
        dispatch(AuthActions.setLoading(true));
        try {
            await Auth.forgotPassword(email);
            return 'success';
        } catch (error) {
            if (error.message) {
                return error.message;
            } else {
                return 'Something went wrong!';
            }
        } finally {
            dispatch(AuthActions.setLoading(false));
        }
    };

    static forgotPasswordSubmit =
        (email: string, code: string, password: string) => async (dispatch: any) => {
            dispatch(AuthActions.setLoading(true));
            try {
                await Auth.forgotPasswordSubmit(email, code, password);
                return 'success';
            } catch (error) {
                if (error.message) {
                    return error.message;
                } else {
                    return 'Something went wrong!';
                }
            } finally {
                dispatch(AuthActions.setLoading(false));
            }
        };

    static signUp =
        ({ name, email, surname, company_code, company, password }: SignUpParams) =>
        async (dispatch: any) => {
            dispatch(AuthActions.setLoading(true));
            try {
                const user = await Auth.signUp({
                    username: email,
                    password,
                    attributes: {
                        email,
                        name: name,
                        'custom:surname': surname,
                        'custom:company': company,
                        'custom:company_code': company_code
                    }
                });
                dispatch({ type: 'SET_AUTH_DATA', email, password });
                return true;
            } catch (error) {
                return false;
            } finally {
                dispatch(AuthActions.setLoading(false));
            }
        };

    static signUpConfirm = (code: string) => async (dispatch: any, getState: () => any) => {
        const { email } = getState().auth;
        dispatch(AuthActions.setLoading(true));
        try {
            await Auth.confirmSignUp(email, code);
            return true;
        } catch (error) {
            return false;
        } finally {
            dispatch(AuthActions.setLoading(false));
        }
    };

    static resendCode = () => async (dispatch: any, getState: () => any) => {
        const { email } = getState().auth;
        try {
            await Auth.resendSignUp(email);
        } catch (error) {}
    };

    static logOut = () => async (dispatch: any) => {
        try {
            await Auth.signOut();
            dispatch({ type: 'USER_LOGOUT' });
        } catch (e) {}
    };

    static setIsAuth = (isAuth: boolean) => ({
        type: 'SET_IS_AUTH',
        isAuth
    });

    static setIsOnLoad = (isOnLoad: boolean) => ({
        type: 'SET_IS_ON_LOAD',
        isOnLoad
    });

    static setLoading = (loading: boolean) => ({
        type: 'SET_AUTH_LOADING',
        loading
    });

    static setInfoPopUp = (data: IInfoPopUpItem) => (dispatch: Dispatch<ReduxActions>) => {
        const id = Date.now().toString();
        dispatch({
            type: 'SET_INFO_POPUP',
            data: { ...data, id }
        });
        setTimeout(() => {
            dispatch({
                type: 'DELETE_INFO_POPUP',
                id
            });
        }, 3000);
    };
}
