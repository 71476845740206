import { EventEmitter } from 'events';

import { ApplicationConfig } from '../config/applicationConfig';

export enum AssistantNetEvent {
    receipt = 'receipt',
    'receipt-update' = 'receipt-update',
    'receipt-delete' = 'receipt-delete',
    'company' = 'company',
    'company-delete' = 'company-delete'
}

export interface IAssistantNetMessage<T> {
    event: string;
    payload: T;
}

export class AssistantNet extends EventEmitter {
    private static _instance: AssistantNet;
    static get instance(): AssistantNet {
        if (AssistantNet._instance == null) {
            AssistantNet._instance = new AssistantNet();
        }

        return AssistantNet._instance;
    }

    ws: WebSocket | null = null;

    private _token: string = '';
    private _reconnectTimerId: any;

    private logTag = '[AssistantNet]';

    get isConnected() {
        return !!this.ws;
    }

    public send(event: string, payload: any) {
        this.ws?.send(JSON.stringify({ event, payload }));
    }

    connect(token: string, compay_id: string) {
        if (!token) {
            return;
        }

        if (this.isConnected) {
            return;
        }

        this.ws = new WebSocket(
            ApplicationConfig.APP_WEB_SOCKET_URI + token + `&company_id=${compay_id}`
        );

        this.ws.onopen = this.onOpen;
        this.ws.onmessage = this.onMessage;
        this.ws.onerror = this.onError;
        this.ws.onclose = this.onClose;

        this._token = token;
    }

    close() {
        if (this._reconnectTimerId) {
            clearTimeout(this._reconnectTimerId);
        }

        if (!this.isConnected) {
            return;
        }

        this.ws?.close();

        this._token = '';
    }

    private onOpen = () => {
        this.emit('open');
    };

    private onMessage = (e: any) => {
        try {
            const data: IAssistantNetMessage<Object> = JSON.parse(e.data);

            this.emit('message', data);
            this.emit(`message:${data.event}`, data);
        } catch (err) {}
    };

    private onError = (e: any) => {
        try {
            this.emit('error');
        } catch (err) {}
    };

    private onClose = (e: any) => {
        this.ws = null;

        this.emit('close', e);
    };
}
