import * as Yup from "yup";

export const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "At least one uppercase, one lowercase, one number"
    )
    .required("Required field"),
});

export const ForgotSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required field"),
});

export const ForgotResetSchema = Yup.object().shape({
  code: Yup.string()
    .min(4, "Code must be at least 4 characters")
    .required("Required field"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "At least one uppercase, one lowercase, one number"
    )
    .required("Required field"),
  new_password_repeat: Yup.string()
    .required("Required field")
    .min(8, "Password is too short - should be at least 8 chars")
    .when("new_password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both passwords need to be the same"
      ),
    }),
});

export const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Required field"),
  surname: Yup.string().required("Required field"),
  company: Yup.string().required("Required field"),
  company_code: Yup.string().required("Required field"),
  email: Yup.string().email("Invalid email").required("Required field"),
  password: Yup.string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "At least one uppercase, one lowercase, one number"
    )
    .min(8, "Password is too short - should be at least 8 chars"),
  passwordRepeat: Yup.string()
    .required("Required field")
    .min(8, "Password is too short - should be at least 8 chars")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both passwords need to be the same"
      ),
    }),
  acceptTerms: Yup.bool().oneOf([true], "Accepting the rules is required"),
});

export const SignUpConfirmSchema = Yup.object().shape({
  code: Yup.string()
    .min(4, "Code must be at least 4 characters")
    .required("Required field"),
});

export const SendViaEmailSchema = Yup.object().shape({
  yourName: Yup.string()
    .required("Required field")
    .min(2, "At least 2 characters"),
  recipientName: Yup.string()
    .required("Required field")
    .min(2, "At least 2 characters"),
  recipientEmail: Yup.string()
    .email("Invalid email")
    .required("Required field"),
  customMessage: Yup.string().max(1000, "To much sumbols"),
});

export const ChangePassSchemaSchema = Yup.object().shape({
  current_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "At least one uppercase, one lowercase, one number"
    )
    .required("Required field"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "At least one uppercase, one lowercase, one number"
    )
    .required("Required field"),
  repeat_password: Yup.string()
    .required("Required field")
    .min(8, "Password is too short - should be at least 8 chars")
    .when("new_password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("new_password")],
        "Both passwords need to be the same"
      ),
    }),
});

export const ListSchema = Yup.object().shape({
  name: Yup.string().required("Required field").min(2, "At least 2 characters"),
  company: Yup.string().required("Required field"),
});

export const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Required field").min(2, "At least 2 characters"),
  code: Yup.string()
});

export const ListSchemaSimple = Yup.object().shape({
  name: Yup.string().required("Required field").min(2, "At least 2 characters"),
});

export const SendInvitationSchema = Yup.object().shape({
  name: Yup.string().required("Required field").min(2, "At least 2 characters"),
  surname: Yup.string()
    .required("Required field")
    .min(2, "At least 2 characters"),
  role: Yup.string().required("Required field"),
  email: Yup.string().email("Invalid email").required("Required field"),
});

export const EditCompanyUserSchema = Yup.object().shape({
  name: Yup.string().required("Required field").min(2, "At least 2 characters"),
  surname: Yup.string()
    .required("Required field")
    .min(2, "At least 2 characters"),
  role: Yup.string().required("Required field"),
  email: Yup.string()
    .required("Required field")
    .matches(/^[a-zA-Z0-9.]{4,40}$/, "Imvalid email"),
});

export const SignUpInviteSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      "At least one uppercase, one lowercase, one number"
    )
    .min(8, "Password is too short - should be at least 8 chars"),
  passwordRepeat: Yup.string()
    .required("Required field")
    .min(8, "Password is too short - should be at least 8 chars")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both passwords need to be the same"
      ),
    }),
  acceptTerms: Yup.bool().oneOf([true], "Accepting the rules is required"),
});

export const EditReceiptSchema = Yup.object({
  total: Yup.string()
    .required("Required field")
    .matches(/^(?:0|[1-9]\d*)(?:\.\d\d?)?$/, "0.00 format"),
  subtotal: Yup.string()
    .required("Required field")
    .matches(/^(?:0|[1-9]\d*)(?:\.\d\d?)?$/, "0.00 format"),
  tax_total: Yup.string()
    .required("Required field")
    .matches(/^(?:0|[1-9]\d*)(?:\.\d\d?)?$/, "0.00 format"),
  invoice_number: Yup.string().required("Required field"),
  supplier: Yup.string().required("Required field"),
  // invoice_serial: Yup.string().required("Required field"),
});

export const CreateStakeholderSchema = Yup.object().shape({
  name: Yup.string().required("Required field"),
  code: Yup.string().required("Required field"),
  street: Yup.string(),//.required("Required field"),
  city: Yup.string(),//.required("Required field"),
  country: Yup.string(),//.required("Required field"),
  category: Yup.string(),//.required("Required field"),
  company: Yup.string(),//.required("Required field"),
});

export const EditStakeholderSchema = Yup.object().shape({
  name: Yup.string().required("Required field"),
  code: Yup.string().required("Required field"),
  street: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  category: Yup.string(),
});

export const CreateStakeholderSimpleSchema = Yup.object().shape({
  name: Yup.string().required("Required field"),
  code: Yup.string().required("Required field"),
  street: Yup.string(),//.required("Required field"),
  city: Yup.string(),//.required("Required field"),
  country: Yup.string(),//.required("Required field"),
  category: Yup.string(),//.required("Required field"),
});

export const AddCompanySchema = Yup.object({
  name: Yup.string().trim().required("Required field"),
});

export const CreateItemSchema = Yup.object({
  name: Yup.string().trim().required("Required field"),
  words: Yup.string()
    // .matches(/^(\w+[, ]+)*\w+$/, "Wrong format: item, item item, item")
    // .matches(/^[a-z/s]+(,[a-z/s]+)*$/, "Wrong format: item,item item,item")

    .required("Format: item, item. Exact format: ^item$, ^item$"),
});

export const AddCountSchema = Yup.object({
  count: Yup.string()
    .matches(/^[\d]*$/, "Not a number")
    .required("Required field"),
});

export const IntegrationSchema = Yup.object({
  host: Yup.string().required("Required field"),
  user: Yup.string().required("Required field"),
  password: Yup.string().required("Required field"),
});

export const SelectCompanySchema = Yup.object({
  name: Yup.string().trim().required("Required field"),
});

export const SelectApiKeySchema = Yup.object({
  api_key: Yup.string().trim().required("Required field"),
});
