import moment from 'moment';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const ISO_8601_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

type TParseDateParams = {
    date: string;
    fromFormat?: string;
    toFormat?: string;
    strict?: boolean;
};

const parseDate = ({
    date,
    fromFormat,
    toFormat = DEFAULT_DATE_FORMAT,
    strict
}: TParseDateParams): string => {
    if (!date) {
        return '';
    }

    const momentDate = fromFormat ? moment(date, fromFormat, strict) : moment(date);

    if (!momentDate.isValid()) {
        return '';
    }

    return momentDate.format(toFormat);
};

const isIso8601Format = ({ date }: { date: string }) => {
    return moment(date, ISO_8601_DATE_FORMAT, true).isValid();
};

export const DateUtils = { parseDate, DEFAULT_DATE_FORMAT, ISO_8601_DATE_FORMAT, isIso8601Format };
