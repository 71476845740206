import React, { FC, useCallback, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import CheckBox from "components/CheckBox";
import SideBar from "./components/SideBar";
import AuthInput from "components/AuthInput";
import PrimaryButton from "components/PrimaryButton";
import {
  AuthMain,
  AuthTitle,
  AgreeText,
  AgreeError,
  AuthContainer,
  AgreeContainer,
} from "./styles";
import { InvitationActions } from "redux/invitation/actions";
import { SignUpInviteSchema } from "helpers/validations";
import { AppState } from "redux/rotReducer";
import { IInviteInfo } from "redux/invitation/types";

const styles = {
  input: {
    marginBottom: 24,
  },
  registerButton: {
    width: 332,
    height: 53,
    marginTop: 40,
    marginBottom: 70,
  },
};

interface Props extends RouteComponentProps {
  loading: boolean;
  user: IInviteInfo;
  getUserByToken: () => any;
  signUpConfirm: (password: string) => any;
}

const SignUpInvite: FC<Props> = ({
  loading,
  user,
  history,
  signUpConfirm,
  getUserByToken,
}) => {
  const getUserHandle = useCallback(async () => {
    const err = await getUserByToken();
    if (err) {
      history.push("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserByToken]);

  useEffect(() => {
    getUserHandle();
  }, [getUserHandle]);

  useEffect(() => {
    if (user && user.email && !user.new_account_required) {
      history.push("/signin", {
        email: user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const formik = useFormik({
    validationSchema: SignUpInviteSchema,
    initialValues: {
      password: "",
      passwordRepeat: "",
      acceptTerms: false,
    },
    onSubmit: async (values) => {
      await signUpConfirm(values.password);
    },
  });

  const onAcceptPress = () => {
    formik.setFieldValue("acceptTerms", !formik.values.acceptTerms);
  };

  return (
    <AuthContainer>
      <AuthMain>
        <AuthTitle style={{ marginBottom: "40px" }}>
          Create an account
        </AuthTitle>
        <AuthInput
          secure
          id="password"
          name="password"
          type="password"
          style={styles.input}
          touched={formik.touched.password}
          error={formik.errors.password}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password}
          placeholder="Password"
        />
        <AuthInput
          secure
          type="password"
          id="passwordRepeat"
          name="passwordRepeat"
          placeholder="Repeat password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.errors.passwordRepeat}
          value={formik.values.passwordRepeat}
          touched={formik.touched.passwordRepeat}
        />
        <AgreeContainer onClick={onAcceptPress}>
          <AgreeText>
            I agree to the Assistant bank terms of
            <br />
            Services and Privacy Policy
          </AgreeText>
          <CheckBox checked={formik.values.acceptTerms} />
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <AgreeError>{formik.errors.acceptTerms}</AgreeError>
          )}
        </AgreeContainer>

        <PrimaryButton
          bigLoader
          loading={loading}
          style={styles.registerButton}
          title="REGISTER"
          onClick={formik.handleSubmit}
        />
      </AuthMain>

      <SideBar />
    </AuthContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.invitation.loading,
  user: state.invitation.user,
});

const mapDispatchToProps = {
  signUpConfirm: InvitationActions.signUpConfirm,
  getUserByToken: InvitationActions.getUserByToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpInvite);
