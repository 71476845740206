import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;
export const Wrapper = styled.div`
  position: relative;
  width: 1035px;
  background-color: #666974;
  margin: 50px 0;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 110;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 41px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
`;
export const HeaderTitle = styled.p`
  font-size: 18px;
  color: #fff;
  font-family: "NunitoSans-Bold";
  margin-left: 30px;
`;
export const HeaderIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 30px;
  cursor: pointer;
`;
export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Selector = styled.div`
  display: flex;
`;
export const SelectorBlock = styled.div`
  width: 442px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  color: #fff;
  font-family: "NunitoSans-Bold";
  cursor: pointer;
  user-select: none;
`;
export const SelectorLine = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 30px;
  background: #fff;
`;
export const Title = styled.p`
  font-size: 18px;
  color: #fff;
  font-family: "NunitoSans-Bold";
  margin: 32px 0;
`;
export const GenerateBlock = styled.div`
  width: 593px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 41px;
`;

export const MoveArchive = styled.div`
  display: flex;
  align-items: center;
`;

export const MoveText = styled.p`
  font-size: 18px;
  color: #fff;
  font-family: "NunitoSans-Bold";
  margin-left: 30px;
`;

export const CSVSuccessImage = styled.img`
  width: 120px;
  height: 120px;
  margin-top: 110px;
  margin-bottom: 8px;
`;

export const ChooseDirContainer = styled.div`
  width: 593px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 72px;
`;

export const ExportText = styled.p`
  font-size: 18px;
  color: #fff;
  font-family: "NunitoSans-Bold";
`;

export const ChooseInput = styled.input`
  height: 35px;
  background-color: white;
  border: none;
  outline: none;
  font-size: 18px;
  color: #666975;
  font-family: "NunitoSans-Bold";
  text-indent: 15px;
  margin-top: 10px;
`;
