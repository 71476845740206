import React, { FC } from "react";
import styled from "styled-components";
import moment from "moment";
import { IReceiptHistory } from "redux/receipt/types";

interface Props {
  receiptHistory: IReceiptHistory[];
}

const History: FC<Props> = ({ receiptHistory }) => {
  return (
    <Table>
      <thead>
        <tr>
          <Th className="history_col1">Date</Th>
          <Th className="history_col2">Action</Th>
          <Th className="history_col3">User</Th>
        </tr>
      </thead>
      <tbody>
        {receiptHistory.length ? (
          receiptHistory.map((h) => (
            <tr key={h.id}>
              <Td className="history_col1">
                {moment(new Date(h.date)).format("lll")}
              </Td>
              <Td className="history_col2">{h.name}</Td>
              <Td className="history_col3">
                {h.user.name} {h.user.surname}
              </Td>
            </tr>
          ))
        ) : (
          <tr>
            <Td className="history_col1">No history yet</Td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default History;

const Table = styled.table`
  width: 100%;
  text-align: left;
`;

const Th = styled.th`
  font-family: "NunitoSans-Bold";
  color: #565682;
  font-size: 18px;
  padding-bottom: 20px;
`;

const Td = styled.td`
  font-family: "NunitoSans-SemiBold";
  color: #9191af;
  font-size: 13px;
  padding-bottom: 20px;
`;
