import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  advanced: boolean;
  onPress: () => void;
}

const DetailsHeader: FC<Props> = ({ advanced, onPress }) => {
  const colorBack = "linear-gradient(180deg, #a665d1 0%, #ffbbcf 100%)";
  return (
    <Container onClick={onPress}>
      <Block>
        Item Details
        <Line
          style={advanced ? { background: "#FFF" } : { background: colorBack }}
        />
      </Block>
      <Block style={{ alignItems: "flex-end" }}>
        Advanced
        <Line
          style={!advanced ? { background: "#FFF" } : { background: colorBack }}
        />
      </Block>
    </Container>
  );
};

export default DetailsHeader;

const Container = styled.div`
  display: flex;
  font-size: 20px;
  color: #565682;
  font-family: "NunitoSans-Bold";
  cursor: pointer;
  margin-bottom: 45px;
`;

const Block = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 10px;
`;
