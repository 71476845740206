import React, { FC, useEffect } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { AuthState } from "redux/auth/types";
import { AuthActions } from "redux/auth/actions";
import { SignUpConfirmSchema } from "helpers/validations";

import SideBar from "./components/SideBar";
import AuthInput from "components/AuthInput";
import PrimaryButton from "components/PrimaryButton";
import { AuthMain, AuthTitle, AuthContainer, AuthLogo } from "./styles";

import logo from "assets/images/icons/logo.png";
import { ColoredTitle } from "styles/text";

const styles = {
  input: {
    marginBottom: 24,
  },
  registerButton: {
    width: 332,
    height: 53,
    marginTop: "194px",
    marginBottom: "70px",
  },
};

interface Props extends RouteComponentProps {
  auth: AuthState;
  resendCode: () => Promise<any>;
  signUpConfirm: (code: string) => Promise<boolean>;
  signIn: (email: string, password: string) => Promise<true | undefined>;
}

const SignUpConfirm: FC<Props> = ({
  auth,
  history,
  signIn,
  resendCode,
  signUpConfirm,
}) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const notconfirmed = urlParams.get("notconfirmed");
    if (!auth.email && !auth.password) {
      history.replace("/signin");
    }
    if (notconfirmed && auth.email && auth.password) {
      resendCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    validationSchema: SignUpConfirmSchema,
    initialValues: {
      code: "",
    },
    onSubmit: async ({ code }) => {
      const res = await signUpConfirm(code);
      if (res) {
        await signIn(auth.email, auth.password);
      } else {
        formik.setErrors({ code: "Invalid code!" });
      }
    },
  });
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = formik;

  return (
    <AuthContainer>
      <AuthMain>
        <AuthLogo src={logo} alt="logo" />
        <ColoredTitle>Assistant</ColoredTitle>
        <AuthTitle>
          Please check your email.
          <br />
          To verify the data provided we have sent you a confirmation code.
          <br />
          Please confirm your email for successful registration!
        </AuthTitle>
        <AuthInput
          id="code"
          name="code"
          type="text"
          style={styles.input}
          touched={touched.code}
          error={errors.code}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.code}
          placeholder="Confirmation code"
          autoComplete="off"
        />
        <PrimaryButton
          bigLoader
          loading={auth.loading}
          style={styles.registerButton}
          title="CONFIRM"
          onClick={handleSubmit}
        />
      </AuthMain>

      <SideBar />
    </AuthContainer>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  signIn: AuthActions.singIn,
  resendCode: AuthActions.resendCode,
  signUpConfirm: AuthActions.signUpConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpConfirm);
