import React, { FC } from "react";

import { PopUpTitle, RegularText } from "styles/text";
import {
  redColor,
  whiteColor,
  greenColor,
  greyColorBright,
} from "styles/colors";
import styled from "styled-components";
import fileError from "assets/images/icons/fileError.png";
import fileSuccess from "assets/images/icons/fileSuccess.png";
import { UploadedFileType } from "components/DropZone/DropZone";

interface IProps {
  uploaded: UploadedFileType[];
}

export const ResultList: FC<IProps> = ({ uploaded }) => {
  const error = uploaded.filter((file) => !file.success);
  return (
    <>
      <PopUpTitle color={greyColorBright}>
        Your uploaded items ({uploaded.length} files, {error.length} failure)
        <br />
        Successful items can now be found in{" "}
        <WhiteText>"In Processing"</WhiteText>
      </PopUpTitle>
      <List>
        {uploaded.map((file: UploadedFileType, index) => (
          <Item key={index.toString()} success={file.success}>
            <ItemIcon
              src={file.success ? fileSuccess : fileError}
              alt="result"
            />

            <ItemName>{file.name}</ItemName>

            {file.message.includes("too low") ? (
              <ItemText>
                Resolution of the image is too low
                <br /> to be processed
              </ItemText>
            ) : (
              <ItemText>{file.message}</ItemText>
            )}
          </Item>
        ))}
      </List>
    </>
  );
};

const WhiteText = styled.span`
  color: ${whiteColor};
  font-family: "NunitoSans-ExtraBold";
  font-size: 18px;
`;

const List = styled.div`
  display: flex;
  margin: 50px 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

interface ItemProps {
  success?: boolean;
}
const Item = styled.div`
  position: relative;
  min-width: 390px;
  height: 180px;
  border-radius: 5px;
  margin-right: 30px;
  border: ${({ success }: ItemProps) =>
    `1px solid ${success ? greenColor : redColor};`};
`;

const ItemIcon = styled.img`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 68px;
  right: 20px;
`;

const ItemText = styled(RegularText)`
  color: ${whiteColor};
  margin-left: 20px;
`;

const ItemName = styled(PopUpTitle)`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;

  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;
