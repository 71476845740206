import React, { useState, useContext } from "react";

const SideMenuContext = React.createContext<any>(null);

export const SideMenuProvider = ({ children }: any) => {
  const [hidden, setHidden] = useState(false);

  return (
    <SideMenuContext.Provider value={{ hidden, setHidden }}>
      {children}
    </SideMenuContext.Provider>
  );
};

export const useSideMenuContext = () => {
  const context = useContext(SideMenuContext);
  if (context === undefined) {
    throw new Error("useSideMenuContext must be used within a CounterProvider");
  }
  return context;
};
