import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import styled from 'styled-components'

export const DnDList = SortableContainer(({ children }: any) => {
   return <Ul>{children}</Ul>
})

const Ul = styled.ul`
   max-height: 315px;
   overflow: auto;
   overflow-x: hidden;
`
