import React, { FC, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";

import { COUNTRIES } from "utils/countries";
import { IUpdateUserParams } from "net/api";
import { UserState } from "redux/user/types";

import InputLabel from "../InputLabel";
import DropInput from "components/DropInput";
import ChangePass from "../../blocks/ChangePass";
import PrimaryButton from "components/PrimaryButton";

import { greyColorDark, primaryColor } from "styles/colors";
import { BigTitle, CancelButton, RegularText } from "styles/text";

const styles = {
  container: {
    marginBottom: 20,
  },
  label: {
    minWidth: 132,
  },
  saveBtn: { width: 90, height: 35, marginLeft: 28 },
};

interface Props {
  user: UserState;
  updateUser: (params: IUpdateUserParams) => any;
}

const MyDetails: FC<Props> = ({ user, updateUser }) => {
  const [loading, setLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user.profile.name,
      surname: user.profile.surname,
      phone: user.profile.phone,
      email: user.profile.email,
      address: user.profile.address,
      city: user.profile.city,
      country: user.profile.country,
      post_code: user.profile.post_code,
      global_email: user.profile.global_email,
    },
    onSubmit: async (values) => {
      setLoading(true);
      const data: IUpdateUserParams = {};
      Object.keys(values).forEach((key) => {
        // @ts-ignore
        if (values[key] !== user.profile[key]) {
          // @ts-ignore
          data[key] = values[key];
        }
      });
      await updateUser(data);
      setLoading(false);
    },
  });

  const { values } = formik;

  const isSame =
    values.name === user.profile.name &&
    values.surname === user.profile.surname &&
    values.phone === user.profile.phone &&
    values.address === user.profile.address &&
    values.city === user.profile.city &&
    values.country === user.profile.country &&
    values.global_email === user.profile.global_email &&
    values.post_code === user.profile.post_code;

  return (
    <React.Fragment>
      <Label>Contact Info</Label>
      <ContactInfo>
        <Block>
          <InputLabel
            type="text"
            id="name"
            name="name"
            label="First Name"
            value={values.name}
            onChange={formik.handleChange}
            placeholder="Enter your first name"
          />
          <InputLabel
            type="text"
            id="surname"
            name="surname"
            label="Last name"
            value={values.surname}
            onChange={formik.handleChange}
            placeholder="Enter your last name"
          />
          <InputLabel
            type="text"
            id="phone"
            name="phone"
            value={values.phone}
            onChange={formik.handleChange}
            label="Mobile number"
            placeholder="Enter your mobile number"
          />
          <InputLabel
            type="email"
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={formik.handleChange}
            placeholder="Enter your email"
          />
          <InputLabel
            type="text"
            id="global_email"
            name="global_email"
            label="Global email"
            value={values.global_email}
            onChange={formik.handleChange}
            placeholder="Enter your global email"
          />
        </Block>
        <Block>
          <InputLabel
            type="text"
            id="address"
            name="address"
            label="Address line"
            value={values.address}
            onChange={formik.handleChange}
            placeholder="Enter your address line"
          />
          <InputLabel
            id="city"
            name="city"
            label="City"
            value={values.city}
            onChange={formik.handleChange}
            placeholder="Enter your city"
          />
          <DropInput
            search={true}
            label="Country"
            selected={values.country}
            onClick={(text) => formik.setFieldValue("country", text)}
            list={COUNTRIES}
            containerStyle={styles.container}
            labelStyle={styles.label}
          />
          <InputLabel
            id="post_code"
            name="post_code"
            value={values.post_code}
            onChange={formik.handleChange}
            label="Post code"
            placeholder="Enter your post code"
          />
        </Block>
      </ContactInfo>

      <BoottomBlock>
        <div>
          <Label margin="0 0 20px 0">Security</Label>
          <Security onClick={() => setPopUp(true)}>
            <RegularText>Password</RegularText>
            <PasswordAction>Change Password</PasswordAction>
          </Security>
        </div>
      </BoottomBlock>
      {!isSame && (
        <Buttons>
          {!loading && (
            <CancelButton onClick={() => formik.resetForm()}>
              Cancel
            </CancelButton>
          )}
          <PrimaryButton
            loading={loading}
            title="SAVE"
            onClick={formik.handleSubmit}
            style={styles.saveBtn}
          />
        </Buttons>
      )}

      {popUp && <ChangePass closePopUp={() => setPopUp(false)} />}
    </React.Fragment>
  );
};

export default MyDetails;

export const Label = styled(BigTitle)`
  color: ${greyColorDark};
`;

export const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Block = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: 60px;
  }
`;

export const BoottomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  div {
    flex: 1;
  }
`;

export const Security = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const PasswordAction = styled(BigTitle)`
  color: ${primaryColor};
  margin-left: 64px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ColorBlock = styled.div`
  max-width: 415px;
  padding: 20px;
  background-color: ${primaryColor};
  margin: 20px 0;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
