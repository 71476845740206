import React, { FC, InputHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';

import { Chip, ErrorInput, Mark, SmallText } from 'styles/text';
import { greyColor, primaryColor } from 'styles/colors';
import { Block, Label } from '../Details/styles';
import { IEditValue } from 'pages/EditReceipt/EditReceipt';
import { IContentSchema } from 'redux/editReceipt/types';
import { DisplayFlexCenter } from '../../../../styles/blocks';

const NOT_CALCULATED_ITEMS = 300;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    active: boolean;
    item: IEditValue;
    onFocusElement: (item: any) => any;
    onTimerChange: (content_id: string) => any;
    handleChange: (content: IContentSchema, value: string) => any;
    displayNewMarker?: boolean;
}

const SimpleField: FC<Props> = ({
    item,
    active,
    handleChange,
    onTimerChange,
    onFocusElement,
    displayNewMarker = false,
    ...rest
}) => {
    const ref = useRef<any>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);

    const id = item.content.id;
    const label = item.shema.label;
    const value = item.content.content?.value || '';
    const related_value = item.content.related_content?.value || '';
    const required = item.shema.constraints.required;
    const err = item.content.error && item.content.error.message;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target as any;

        handleChange(item.content, value);
        if (ref.current) {
            clearTimeout(ref.current);
        }
        ref.current = setTimeout(() => onTimerChange(item.content.id), 2000);
    };

    const onFocus = () => {
        onFocusElement(item);
    };

    return (
        <Block ref={elementRef}>
            <DisplayFlexCenter>
                <Label active={active}>
                    {label}
                    {required && <Mark> *</Mark>}
                </Label>

                {displayNewMarker && <Chip>new</Chip>}
            </DisplayFlexCenter>

            <InputBlock>
                {related_value && <RelatedContent>{related_value}</RelatedContent>}
                <Input
                    disabled
                    {...rest}
                    selected={active}
                    value={value}
                    {...{ id, onChange, onFocus }}
                />
                {err && <ErrorText>{err}</ErrorText>}
            </InputBlock>
        </Block>
    );
};

export default React.memo(SimpleField);

const InputBlock = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
`;

interface InputProps {
    selected?: boolean;
    align?: 'flex-start';
    justify?: 'flex-end';
}

const Input = styled.input`
    height: 35px;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
    font-size: 15px;
    font-family: 'NunitoSans-SemiBold';
    color: #565682;
    text-indent: 15px;
    outline: none;
    border: ${({ selected }: InputProps) =>
        selected ? `0.4px solid ${primaryColor}` : '0.4px solid #eeeeee'};
`;

const RelatedContent = styled(SmallText)`
    font-size: 10px;
    color: ${greyColor};
`;

const ErrorText = styled(ErrorInput)`
    position: static;
    max-width: 195px;
`;
