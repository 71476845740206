import React, { FC } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Block, Label } from "../Details/styles";

import calendarIcon from "assets/images/icons/calendar.png";
import { primaryColor } from "styles/colors";
import { ErrorInput, Mark } from "styles/text";

interface Props {
  error?: string;
  item: any;
  active: boolean;
  onBlur: () => any;
  onFocusElement: (item: any) => any;
  handleChangeValue: (item: any, value: any) => void;
}

const DateField: FC<Props> = ({
  item,
  error,
  active,
  onBlur,
  onFocusElement,
  handleChangeValue,
}) => {
  const val = item.value;
  const id = item.shema_id;
  const label = item.label;
  const activeStyle = {
    borderRadius: 5,
    border: active ? `1px solid ${primaryColor}` : "none",
  };

  return (
    <Block>
      <Label active={active}>
        {label} {item.required && <Mark> *</Mark>}
      </Label>
      <DateBlock>
        <label className="date_label" style={activeStyle}>
          <DateIcon src={calendarIcon} alt="calendar" />
          <DatePicker
            id={id}
            name={id}
            className="date-picker"
            selected={val ? new Date(val) : new Date()}
            onChange={(date) =>
              handleChangeValue(item, date ? date.toISOString() : "")
            }
            dateFormat="dd/MM/yyyy"
            onFocus={() => onFocusElement(item)}
            onBlur={onBlur}
          />
        </label>
      </DateBlock>
      {error && <ErrorInput>{error}</ErrorInput>}
    </Block>
  );
};

export default React.memo(DateField);

const DateBlock = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

const DateIcon = styled.img`
  position: absolute;
  right: 5px;
  top: 7px;
  width: 20px;
  height: 20px;
  z-index: 1;
  cursor: pointer;
`;
