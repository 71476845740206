import React, { FC } from "react";
import styled from "styled-components";

import PrimaryButton from "../PrimaryButton";
import { Filter } from "styles/blocks";

interface Props {
  text: string;
  title: string;
  buttonText: string;
  onPress: () => any;
  closePopUp: () => void;
}

const InfoPopUp: FC<Props> = ({
  text,
  title,
  buttonText,
  closePopUp,
  onPress,
}) => {
  const onPressHandle = async () => {
    await onPress();
    closePopUp();
  };

  return (
    <Filter className="fadeIn">
      <Container>
        <Header>
          <HeaderText>{title}</HeaderText>
        </Header>
        <Main>
          <MainTitle>{text}</MainTitle>

          <Buttons>
            <PrimaryButton
              title={buttonText}
              onClick={onPressHandle}
              style={{ width: 125, height: 55 }}
            />
          </Buttons>
        </Main>
      </Container>
    </Filter>
  );
};

export default InfoPopUp;

const Container = styled.div`
  margin-top: 70px;
  width: 600px;
  background-color: #666975;
  z-index: 2;
  font-family: "NunitoSans-Bold";
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
  font-size: 18px;
  color: #fff;
`;

const Main = styled.div`
  padding: 30px;
  font-size: 16px;
`;

const MainTitle = styled.p`
  color: #fff;
  margin-bottom: 28px;
  word-break: break-all;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
