import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AppState } from 'redux/rotReducer';
import { ListActions } from 'redux/list/actions';
import { ReceiptActions } from 'redux/receipt/actions';
import { EditReceiptState, IContentSchema, IContentSchemaGrid } from 'redux/editReceipt/types';
import { EditReceiptActions } from 'redux/editReceipt/actions';
import {
    ISelectBox,
    IEditReceiptBody,
    IEditReceiptParams,
    IGetCategoriesParams,
    IGetStakeholdersParams,
    ICreateStakeholderParams,
    IUseGridBody
} from 'net/api';
import { IReceipt, ReceiptState, EnumReceiptStatus, IDuplicate } from '../../redux/receipt/types';

import Component from './EditReceipt';
import { IReceiptShema, IReceiptShemaItem } from 'redux/setting/types';

export type EditReceiptProps = LinkStateProps & LinkDispatchProps & RouteComponentProps & {};

interface LinkStateProps {
    receipt: ReceiptState;
    editReceiptState: EditReceiptState;
    duplicates: null | IDuplicate[];
}
interface LinkDispatchProps {
    selectBox: (receipts_id: string, receipt_content_id: string, body: ISelectBox) => any;
    editReceipt: (receipt: IReceipt, body: IEditReceiptBody, params: IEditReceiptParams) => any;
    getEditStakeholders: (params: IGetStakeholdersParams, receipt: IReceipt) => any;
    loadEditStakeholders: (params: IGetStakeholdersParams, receipt: IReceipt) => any;
    createStakeholder: (params: ICreateStakeholderParams, company_name: string) => any;
    updateContentItem: (item: IContentSchema) => any;
    getXML: (receipt_id: string) => any;
    deleteReceipt: (receipt: IReceipt) => any;
    onLoadEditItems: (receipt: IReceipt) => any;
    getReceiptHistory: (receipt_id: string) => any;
    setEditSceneLoading: (loading: boolean) => void;
    changeFlag: (receipt: IReceipt, flag: boolean) => any;
    loadEditCategories: (params: IGetCategoriesParams) => any;
    updateContent: (receipt_id: string, content_id: string) => any;
    mergeReceipt: (receipts_id: string, related_id: string) => any;
    onLoadEditScene: (receipt_id: string, company_id: string) => any;
    changeStatus: (receipt: IReceipt, status: EnumReceiptStatus) => any;
    setSelectedContent: (selectedContent?: IContentSchema) => void;
    setSelectedSchema: (schema?: IReceiptShemaItem) => void;
    updateActiveKey: (item: boolean) => void;
    updateGrid: (parts: IContentSchemaGrid[]) => void;
    saveGridAsTemplate: (part: IContentSchemaGrid) => void;
    useTemplateAsGrid: (v: IUseGridBody) => void;
    updateStakeholderDateFormat: (stakeholderDateFormat?: string) => void;
    getDuplicateInfo: (r: IReceipt) => any;
    exportReceipt: (receipt: IReceipt) => void;
}

const mapStateToProps = (state: AppState) => ({
    receipt: state.receipt,
    editReceiptState: state.editReceipt,
    duplicates: state.receipt.duplicateInfo
});

const mapDispatchToProps = {
    getXML: ReceiptActions.getXML,
    changeFlag: ReceiptActions.changeFlag,
    selectBox: EditReceiptActions.selectBox,
    changeStatus: ReceiptActions.changeStatus,
    deleteReceipt: ReceiptActions.deleteReceipt,
    editReceipt: EditReceiptActions.editReceipt,
    mergeReceipt: EditReceiptActions.mergeReceipt,
    updateContent: EditReceiptActions.updateContent,
    createStakeholder: ListActions.createStakeholder,
    getReceiptHistory: ReceiptActions.getReceiptHistory,
    onLoadEditScene: EditReceiptActions.onLoadEditScene,
    updateContentItem: EditReceiptActions.updateContentItem,
    loadEditCategories: EditReceiptActions.loadEditCategories,
    setEditSceneLoading: EditReceiptActions.setEditSceneLoading,
    getEditStakeholders: EditReceiptActions.getEditStakeholders,
    loadEditStakeholders: EditReceiptActions.loadEditStakeholders,
    setSelectedContent: EditReceiptActions.setSelectedContent,
    setSelectedSchema: EditReceiptActions.setSelectedSchema,
    updateActiveKey: EditReceiptActions.updateActiveKey,
    updateGrid: EditReceiptActions.updateGrid,
    saveGridAsTemplate: EditReceiptActions.saveGridAsTemplate,
    useTemplateAsGrid: EditReceiptActions.useTemplateAsGrid,
    updateStakeholderDateFormat: EditReceiptActions.updateStakeholderDateFormat,
    getDuplicateInfo: ReceiptActions.getDuplicateInfo,
    exportReceipt: ReceiptActions.exportReceipt
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
