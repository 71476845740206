import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { EnumIntegrationServiceType, IIntegrationBody } from "net/api";
import { AppState } from "redux/rotReducer";
import { ICompany } from "redux/user/types";
import { IntegrationActions } from "redux/integration/actions";

import Component from "./Integration";

export type IntegrationProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  activeCompany: ICompany;
}
interface LinkDispatchProps {
  activateImportReceipts: (
    service_type: EnumIntegrationServiceType,
    disable?: boolean
  ) => Promise<void>;
  changeIntegration: (body: IIntegrationBody) => any;
}

const mapStateToProps = (state: AppState) => ({
  activeCompany: state.user.activeCompany,
});

const mapDispatchToProps = {
  changeIntegration: IntegrationActions.changeIntegration,
  activateImportReceipts: IntegrationActions.activateImportReceipts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
