import React, { FC, useEffect } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { greyColorDark } from 'styles/colors';
import SmallLoader from 'components/SmallLoader';
import { Loader } from 'components/Other/Loader';

interface Props {
    title: string;
    bigLoader?: boolean;
    loading?: boolean;
    onClick: (e: any) => void;
    inactive?: boolean;
    style?: CSSProperties;
    className?: string;
    invokeOnPressEnter?: boolean;
}

const PrimaryButton: FC<Props> = ({
    title,
    style,
    loading,
    bigLoader,
    onClick,
    inactive,
    className,
    invokeOnPressEnter
}) => {
    useEffect(() => {
        if (!invokeOnPressEnter) {
            return;
        }

        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onClick(event);
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [invokeOnPressEnter]);

    if (loading) {
        return (
            <LoaderContainer big={bigLoader} style={style} className={className}>
                {bigLoader ? <Loader /> : <SmallLoader />}
            </LoaderContainer>
        );
    }

    return (
        <Button inactive={inactive} onClick={(e) => onClick(e)} style={style} className={className}>
            {title}
        </Button>
    );
};

export default PrimaryButton;

const Button = styled.div<{ inactive?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: ${({ inactive }) =>
        inactive ? greyColorDark : 'linear-gradient(198.17deg, #ffbbcf 5.73%, #a665d1 87.75%)'};
    box-shadow: 0px 12px 14px rgba(209, 212, 226, 0.15);
    color: #fff;
    font-size: 14px;
    font-family: 'NunitoSans-ExtraBold';
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }
`;

interface LoaderContainerProps {
    big?: boolean;
}

const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ big }: LoaderContainerProps) => (big ? 'center' : 'flex-end')};
`;
