import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { RegularText } from 'styles/text';
import { API } from 'net/api';

import search from 'assets/images/icons/search.png';
import { greyColorDark, whiteColor } from 'styles/colors';
import { ICategory } from 'redux/list/types';

interface Props {
    label?: string;
    selected: string;
    list: ICategory[];
    error?: string;
    labelStyle?: CSSProperties;
    disabled?: boolean;
    loadData?: () => any;
    onFocus?: () => void;
    onSelect: (c: any) => any;
    withUpdate?: boolean;
}

const CategoriesInput: FC<Props> = ({
    list,
    label,
    error,
    selected,
    disabled,
    labelStyle,
    loadData,
    onSelect,
    onFocus,
    withUpdate
}) => {
    const timer = useRef<any>(null);
    const timerUpdate = useRef<any>(null);

    const [value, setValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [filteredList, setFiltered] = useState(list);

    useEffect(() => {
        setValue(selected ? selected : '');
    }, [selected]);

    useEffect(() => {
        if (withUpdate) {
            timerUpdate.current = setTimeout(() => {
                onSelect({ name: value });
            }, 1000);
        }
        return () => {
            clearTimeout(timerUpdate.current);
        };
    }, [value]);

    useEffect(() => {
        if (value) {
            let isExist = list.find((i) => i.name === value);
            if (!isExist) {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                    getDataHandle(value);
                }, 500);
            }
        }
        return () => {
            clearTimeout(timer.current);
        };
    }, [value]);

    const getDataHandle = async (t: string) => {
        try {
            const res = await API.getCategories({
                search_name: t,
                with_external_data: 1
            });
            setFiltered(res.data.items);
        } catch (error) {}
    };

    const _onChange = (t: string) => {
        if (!visible) {
            setVisible(true);
        }
        setValue(t);
    };

    const _onSelect = (t: ICategory) => {
        onSelect(t);
        setValue(t.name);
        setVisible(false);
        clearTimeout(timerUpdate.current);
    };

    const _onClose = () => {
        // let isExist = list.find((c) => c.name === value);
        // if (!isExist) {
        //   setValue("");
        //   onSelect({ name: "" });
        // }
        setVisible(false);
    };

    const _onFocus = () => {
        setFiltered(list);
        setVisible(true);
        onFocus && onFocus();
    };

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (loadData) {
                loadData();
            }
        }
    };

    return (
        <>
            {visible && <Filter onClick={_onClose} />}
            <Container zIndex={visible}>
                <Label style={labelStyle} onClick={() => setVisible(!visible)}>
                    {label}
                </Label>
                <Block>
                    <InputBlock disabled={disabled}>
                        <InputText
                            value={value}
                            disabled={disabled}
                            onChange={(e) => _onChange(e.target.value)}
                            onFocus={_onFocus}
                        />
                        <Icon src={search} alt="search" />
                    </InputBlock>
                    {visible && (
                        <List onScroll={handleScroll}>
                            {filteredList.length ? (
                                filteredList.map((el, i) => (
                                    <Item key={el.name + i} onClick={() => _onSelect(el)}>
                                        <ItemText>{el.name}</ItemText>
                                        {el.external && <IsExternal>DB</IsExternal>}
                                    </Item>
                                ))
                            ) : (
                                <></>
                            )}
                        </List>
                    )}
                </Block>
                {error && <Error>{error}</Error>}
            </Container>
        </>
    );
};

export default CategoriesInput;

const Filter = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.2);
`;

interface ContainerProps {
    zIndex: boolean;
}

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    z-index: ${({ zIndex }: ContainerProps) => (zIndex ? 10 : 1)};
`;

export const Label = styled(RegularText)`
    min-width: 100px;
`;

export const Block = styled.div`
    flex: 1;
    position: relative;
`;

interface InputBlockProps {
    disabled?: boolean;
}

export const InputBlock = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    height: 35px;
    background: #ffffff;
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
    opacity: ${({ disabled }: InputBlockProps) => (disabled ? 0.2 : 1)};
    border: '0.4px solid #eeeeee';
`;

export const InputText = styled.input`
    width: calc(100% - 20px);
    font-size: 15px;
    color: ${greyColorDark};
    font-family: 'NunitoSans-SemiBold';
    text-indent: 15px;
    border: none;
    outline: none;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const Icon = styled.img`
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
`;

interface ItemProps {
    empty?: boolean;
}

export const Item = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    height: 35px;
    background: ${({ empty }: ItemProps) => (empty ? '#9191AF' : '#ffffff')};
    border: 0.4px solid #eeeeee;
    box-shadow: 0px 4px 40px rgba(148, 149, 204, 0.18);
    border-radius: 5px;
`;

interface ItemTextProps {
    empty?: boolean;
}

export const ItemText = styled(RegularText)`
    color: ${({ empty }: ItemTextProps) => (empty ? whiteColor : greyColorDark)};
    text-indent: 15px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const List = styled.div`
    position: absolute;
    top: 37px;
    width: 100%;
    max-height: 222px;
    overflow: auto;
    z-index: 10;
`;

export const Error = styled.p`
    position: absolute;
    font-size: 10px;
    color: #d16e6e;
    font-family: 'NunitoSans-Regular';
`;

const IsExternal = styled.p`
    position: absolute;
    font-size: 10px;
    color: #d16e6e;
    font-family: 'NunitoSans-Bold';
    right: 10px;
    background-color: ${whiteColor};
    width: 30px;
    text-align: center;
    right: 0;
`;
