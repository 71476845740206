import styled from 'styled-components/macro';
import { redColor } from 'styles/colors';

export const Wrapper = styled.div`
    flex: 1;
    position: relative;
`;

export const Container = styled.div`
    max-height: calc(100vh - 131px);
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 21;
`;

export const ImgContainer = styled.div`
    position: relative;
    margin-bottom: 12px;
`;

export const Image = styled.img<{ $rotateDeg: number }>`
    width: 100%;
    height: auto;
    display: block;
    transform: rotate(${({ $rotateDeg }) => $rotateDeg + 'deg'});
    // -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    // -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    // box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
`;

export const Inner = styled.div`
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
`;

interface MarkProps {
    hover?: boolean;
    isActive?: boolean;
    isKeyActive?: boolean;
    transparent?: boolean;
    isSelected?: string;
}

export const Mark = styled.div`
    position: absolute;
    cursor: ${({ hover }: MarkProps) => (hover ? 'pointer' : 'default')};
    border: ${({ isActive, isKeyActive }: MarkProps) =>
        isActive
            ? '2px solid #11adff'
            : isKeyActive
            ? `thin solid rgba(209,110,110,.1)`
            : 'thin solid rgba(6,54,52,.1)'};
    background-color: ${({ isKeyActive, transparent }) =>
        transparent ? 'transparent' : isKeyActive ? 'rgba(209,110,110,.1)' : 'rgba(78,237,228,.1)'};

    pointer-events: ${({ isKeyActive, hover }: MarkProps) =>
        !isKeyActive && !hover ? 'none' : 'all'};

    &:hover {
        border: ${({ hover, isKeyActive }: MarkProps) =>
            isKeyActive ? `2px dashed ${redColor}` : hover ? `2px dashed #11adff` : 'initial'};
    }
`;
