import { APIListResponseModify } from 'net/api';
import { IStakeholder } from 'redux/list/types';

export enum EnumReceiptStatus {
    Review = 'review',
    Processing = 'processing',
    Converting = 'converting',
    Ready = 'ready',
    Archive = 'archive',
    Error = 'error',
    Unassigned = 'unassigned',
    Rejected = 'rejected',
    Saving = 'saving',
    Failed = 'failed'
}

export enum EnumReceiptDocumentStatus {
    Processing = 'processing',
    Ready = 'ready',
    Error = 'error'
}

export interface ReceiptState {
    loading: boolean;
    receiptHistory: IReceiptHistory[];
    receipts: APIListResponseModify<IReceipt>;
    receiptPreview: any;
    receiptEmailInfo: null | IReceiptEmailInfo;
    duplicateInfo: null | IDuplicate[];

    // TODO: review it!
    capturedConfig: {
        document_category: { label: string; visible: boolean };
        account_number: { label: string; visible: boolean };
        bank_code: { label: string; visible: boolean };
        iban: { label: string; visible: boolean };
        bic_swift: { label: string; visible: boolean };
        code: { label: string; visible: boolean };
        vat_id: { label: string; visible: boolean };
        street: { label: string; visible: boolean };
        city: { label: string; visible: boolean };
        zipcode: { label: string; visible: boolean };
        country: { label: string; visible: boolean };
        document_id: { label: string; visible: boolean };
        issue_date: { label: string; visible: boolean; is_date?: boolean };
        amount_total: { label: string; visible: boolean };
        amount_total_base: { label: string; visible: boolean };
        amount_tax: { label: string; visible: boolean };
        currency: { label: string; visible: boolean };
    };
    count: {
        [EnumReceiptStatus.Processing]: number;
        [EnumReceiptStatus.Archive]: number;
        [EnumReceiptStatus.Error]: number;
        [EnumReceiptStatus.Review]: number;
        [EnumReceiptStatus.Ready]: number;
        [EnumReceiptStatus.Unassigned]: number;
        [EnumReceiptStatus.Rejected]: number;
        [EnumReceiptStatus.Converting]: number;
        [EnumReceiptStatus.Saving]: number;
        [EnumReceiptStatus.Failed]: number;
    };
}

export interface IReceiptEmailInfo {
    arrived_at: string;
    files: {
        company_id: string;
        original_file_name: string;
        receipt_id: string;
    }[];
    company_id: string;
    receipt_id: string;
    from: string;
    id: string;
    recipients: string[];
    subject: string;
    text: string;
    text_as_html: string;
}

export interface IReceiptPage {
    document: string;
    url: string;
    status: EnumReceiptDocumentStatus;
}

export interface IRelatedDoc {
    url: string;
    receipt_id: string;
}

export interface IReceipt {
    available_template?: boolean;
    email_id?: string;
    user_id: string;
    receipt_id: string;
    company_id: string;
    created_at: string | number;
    status: EnumReceiptStatus;
    category: string;
    receipt_type: EnumReceiptType;
    flag: boolean;
    error: { message: string };
    company_name: string;
    user: {
        id: string;
        name: string;
        surname: string;
        global_email: string;
    };
    category_id: string;
    stakeholder_id: string;
    pages: IReceiptPage[];
    related_documents: IRelatedDoc[];
    content: {
        account_number: string;
        bank_code: string;
        iban: string;
        bic_swift: string;
        amount_tax: string;
        amount_total: string;
        amount_total_base: string;
        currency: string;
        customer_city: string;
        customer_code: string;
        customer_country: string;
        customer_delivery: string;
        customer_name: string;
        customer_street: string;
        customer_vat_id: string;
        customer_zipcode: string;
        customer_date_format: string;
        document_category: string;
        document_id: string;
        issue_date: string;
        supplier_city: string;
        supplier_code: string;
        supplier_country: string;
        supplier_email: string;
        supplier_name: string;
        supplier_street: string;
        supplier_vat_id: string;
        supplier_zipcode: string;
        supplier_date_format: string;
    };
    can_be_restarted?: boolean;
    stakeholder?: IStakeholder;
    display_date_format?: string; // date format to present for the user
    stakeholder_date_format?: string; // date format in receipt document
    duplicate_id?: string;
    // comes from notifications and not directly from database
    supplierCustomerName?: string;
}

export enum EnumReceiptType {
    Receipt = 'receipt',
    Invoice = 'invoice'
}

export enum IReceiptErrorCodes {
    ErrorFileFormat = 1,
    ErrorUnknown = 2
}

export interface IReceiptHistory {
    action: string;
    date: string;
    id: string;
    name: string;
    user: { id: string; name: string; surname: string };
    user_id: string;
}

export interface IDuplicate {
    original_file_name: string;
    receipt_id: string;
    company_id: string;
    status: EnumReceiptStatus;
    disabled_for_editing?: boolean;
}

export const SET_RECEIPT_LOADING = 'SET_RECEIPT_LOADING';
export const SET_RECEIPTS = 'SET_RECEIPTS';
export const UPDATE_RECEIPTS = 'UPDATE_RECEIPTS';
export const ADD_RECEIPT = 'ADD_RECEIPT';
export const DELETE_RECEIPT = 'DELETE_RECEIPT';
export const SET_RECEIPT_HISTORY = 'SET_RECEIPT_HISTORY';
export const SET_COUNT_LIST = 'SET_COUNT_LIST';
export const SET_UNASSIGNED_LIST = 'SET_UNASSIGNED_LIST';
export const SET_COUNT = 'SET_COUNT';
export const DELETE_COUNT = 'DELETE_COUNT';
export const SET_RECEIPT_EMAIL_INFO = 'SET_RECEIPT_EMAIL_INFO';
export const SET_RECEIPT_PREVIEW = 'SET_RECEIPT_PREVIEW';
export const TOOGLE_CAPTURE_DATA = 'TOOGLE_CAPTURE_DATA';
export const SET_DUPLICATE_INFO = 'SET_DUPLICATE_INFO';

export interface SetReceiptLoadingAction {
    type: typeof SET_RECEIPT_LOADING;
    loading: boolean;
}

export interface SetReceiptsAction {
    type: typeof SET_RECEIPTS;
    receipts: APIListResponseModify<IReceipt>;
}

export interface UpdateReceiptAction {
    type: typeof UPDATE_RECEIPTS;
    receipt: IReceipt;
}

export interface AddReceiptAction {
    type: typeof ADD_RECEIPT;
    receipt: IReceipt;
}

export interface DeleteReceiptAction {
    type: typeof DELETE_RECEIPT;
    receipt_id: string;
}

export interface SetReceiptHistoryAction {
    type: typeof SET_RECEIPT_HISTORY;
    history: IReceiptHistory[];
}

export interface SetCountListAction {
    type: typeof SET_COUNT_LIST;
    data: any;
}

export interface SetUnassignedListAction {
    type: typeof SET_UNASSIGNED_LIST;
    data: {
        total_unassigned_receipts: number;
        total_unassigned_invoices: number;
    };
}

export interface SetCountAction {
    type: typeof SET_COUNT;
    receipt_type: EnumReceiptType;
    status: EnumReceiptStatus;
    num: number;
}

export interface SetReceiptEmailInfoAction {
    type: typeof SET_RECEIPT_EMAIL_INFO;
    data: null | IReceiptEmailInfo;
}

export interface SetReceiptPreviewAction {
    type: typeof SET_RECEIPT_PREVIEW;
    data: null | any;
}

export interface ToogleCaptureDataAction {
    type: typeof TOOGLE_CAPTURE_DATA;
    key: string;
}

export interface SetDuplicateInfoAction {
    type: typeof SET_DUPLICATE_INFO;
    data: null | IDuplicate[];
}

export type ReceiptActionTypes =
    | SetDuplicateInfoAction
    | ToogleCaptureDataAction
    | SetReceiptPreviewAction
    | SetReceiptEmailInfoAction
    | SetUnassignedListAction
    | SetCountAction
    | SetCountListAction
    | SetReceiptHistoryAction
    | DeleteReceiptAction
    | AddReceiptAction
    | UpdateReceiptAction
    | SetReceiptLoadingAction
    | SetReceiptsAction;
