import { FileType } from 'components/DropZone/DropZone';
import {
    IEditStakeholder,
    ICreateStakeholderParams,
    IGetCategoriesParams,
    UploadImportType
} from 'net/api';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ListActions } from 'redux/list/actions';
import { IEditCategory, IItem, ListState, ReduxListKeys } from 'redux/list/types';
import { ICompany, UserState } from 'redux/user/types';

import Component from './List';

export type ListProps = LinkStateProps & LinkDispatchProps & RouteComponentProps & {};

interface LinkStateProps {
    user: UserState;
    list: ListState;
    companies: ICompany[];
}
interface LinkDispatchProps {
    createCategory: (name: string, code: string, company: string) => any;
    editStakeholder: (data: IEditStakeholder) => any;
    getCategoriesByCompany: (companyName: string, params: IGetCategoriesParams) => any;
    loadCategories: (params: IGetCategoriesParams) => any;
    deleteStakeholder: (id: string, key: ReduxListKeys) => any;
    createStakeholder: (params: ICreateStakeholderParams, company_name?: string) => any;
    loadListPage: (title: string, search?: string) => any;
    createItem: (val: string, words: string[], company: string) => Promise<void>;
    updateItem: (item: IItem) => Promise<void>;
    editCategory: (data: IEditCategory, company_id?: string) => Promise<void>;
    uploadImport: (file: FileType, upload_type: UploadImportType) => Promise<void>;
}

const mapStateToProps = (state: any) => ({
    user: state.user,
    list: state.list,
    companies: state.user.companies.items
});

const mapDispatchToProps = {
    loadListPage: ListActions.loadListPage,
    loadCategories: ListActions.loadCategories,
    createCategory: ListActions.createCategory,
    editStakeholder: ListActions.editStakeholder,
    createStakeholder: ListActions.createStakeholder,
    deleteStakeholder: ListActions.deleteStakeholder,
    getCategoriesByCompany: ListActions.getCategoriesByCompany,
    createItem: ListActions.createItem,
    updateItem: ListActions.editItem,
    editCategory: ListActions.editCategory,
    uploadImport: ListActions.uploadImport
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
