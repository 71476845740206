import React, { FC } from 'react';

import arrowBack from 'assets/images/icons/arrowBack.png';
import exportIcon from 'assets/images/icons/download.png';
import archiveIcon from 'assets/images/icons/archive.png';
import flagIcon from 'assets/images/icons/flag.png';
import flagActive from 'assets/images/icons/flagActive.png';
import trashIcon from 'assets/images/icons/trash.png';
import arrowLeft from 'assets/images/icons/arrowLeft.png';
import arrowRight from 'assets/images/icons/arrowRight.png';
import {
    Icon,
    Block,
    Loading,
    Container,
    IconsBlock,
    PagesBlock,
    ButtonGreen,
    ButtonReject,
    ButtonToReview,
    GreenContainer,
    ButtonsContainer
} from './styles';
import { EnumReceiptStatus, IDuplicate, IReceipt } from 'redux/receipt/types';
import DuplicateInfo from 'components/DuplicateInfo';

interface Props {
    pageNum: number;
    count: number;
    receipt: IReceipt;
    loading: boolean;
    isValid: boolean;
    activeKey?: boolean;

    duplicates: null | IDuplicate[];
    // onSelect: () => void;
    // getDuplicateInfo: () => any;

    toogleActiveKey: () => void;

    goBack: () => void;
    onDelete: () => void;
    onExport: () => void;
    moveToReview: () => any;
    moveToArchive: () => any;
    reviewAndSend: () => any;
    reviewReceipt: () => any;
    rejectReceipt: () => any;
    chnageFlagHandle: () => any;
    nextReceipt: (n: number) => void;
  getDuplicateInfo: (r: IReceipt) => any;

}

const Actions: FC<Props> = ({
    count,
    pageNum,
    receipt,
    loading,
    isValid,
    activeKey,
    toogleActiveKey,
    goBack,
    onDelete,
    onExport,
    nextReceipt,
    moveToReview,
    reviewAndSend,
    reviewReceipt,
    rejectReceipt,
    moveToArchive,
    chnageFlagHandle,
    duplicates,
    getDuplicateInfo
}) => {
    return (
        <Container>
            <IconsBlock>
                <Block onClick={goBack}>
                    <Icon src={arrowBack} style={{ height: '15px' }} />
                    Back
                </Block>
                <Block onClick={onExport}>
                    <Icon src={exportIcon} />
                    Export
                </Block>
                <Block onClick={moveToArchive}>
                    <Icon src={archiveIcon} />
                    {receipt.status === EnumReceiptStatus.Archive ? 'Unarchive' : 'Archive'}
                </Block>
                <Block onClick={chnageFlagHandle}>
                    <Icon src={receipt.flag ? flagActive : flagIcon} />
                    Flag
                </Block>
                <Block style={{ color: '#D16E6E' }} onClick={onDelete}>
                    <Icon src={trashIcon} />
                    Delete
                </Block>
                {receipt.duplicate_id && (
                    <DuplicateInfo
                        receipt_id={receipt.receipt_id}
                        data={duplicates}
                        getDuplicateInfo={()=>getDuplicateInfo(receipt)}
                    />
                )}
            </IconsBlock>
            <div>
                {
                    loading ? (
                        <Loading>Processing</Loading>
                    ) : receipt.status !== EnumReceiptStatus.Archive ? (
                        <ButtonsContainer>
                            <GreenContainer>
                                {receipt.status !== EnumReceiptStatus.Rejected && (
                                    <ButtonReject isValid={true} onClick={rejectReceipt}>
                                        REJECT
                                    </ButtonReject>
                                )}
                                {receipt.status === 'ready' && (
                                    <ButtonToReview isValid={isValid} onClick={moveToReview}>
                                        MOVE TO REVIEW
                                    </ButtonToReview>
                                )}
                            </GreenContainer>

                            <GreenContainer>
                                <ButtonGreen isValid={isValid} onClick={reviewAndSend}>
                                    REVIEW AND SEND
                                </ButtonGreen>
                                <ButtonGreen isValid={isValid} onClick={reviewReceipt}>
                                    CONFIRM
                                </ButtonGreen>
                            </GreenContainer>
                            <GreenContainer>
                                <ButtonGreen isValid={true} onClick={toogleActiveKey}>
                                    {!activeKey ? 'SHOW KEYS' : 'SHOW VALUES'}
                                </ButtonGreen>
                            </GreenContainer>
                        </ButtonsContainer>
                    ) : null
                    // <ButtonsContainer>
                    //     <GreenContainer>
                    //         <ButtonToReview isValid={isValid} onClick={moveToReview}>
                    //             MOVE TO REVIEW
                    //         </ButtonToReview>
                    //     </GreenContainer>
                    // </ButtonsContainer>
                }
            </div>
            <PagesBlock>
                <Icon
                    inActive={pageNum === 1}
                    src={arrowLeft}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                        if (pageNum !== 1) {
                            nextReceipt(-1);
                        }
                    }}
                />
                {pageNum}/{count}
                <Icon
                    inActive={pageNum === count}
                    src={arrowRight}
                    style={{ marginLeft: '12px' }}
                    onClick={() => {
                        if (pageNum !== count) {
                            nextReceipt(1);
                        }
                    }}
                />
            </PagesBlock>
        </Container>
    );
};

export default Actions;
