import { AdminActionTypes, AdminState } from "./types";

const initialState: AdminState = {
  companies: { items: [] },
  info: { customers: 0, documents: 0 },
};

const adminReducer = (
  state = initialState,
  actions: AdminActionTypes
): AdminState => {
  switch (actions.type) {
    case "SET_ADMIN_COMPANIES":
      return {
        ...state,
        companies: actions.data,
      };
    case "SET_ADMIN_INFO":
      return {
        ...state,
        info: actions.data,
      };
    case "SET_ADMIN_COMPANY_COUNT":
      return {
        ...state,
        companies: {
          ...state.companies,
          items: state.companies.items.map((item) => {
            if (item.company_id === actions.company_id) {
              return {
                ...item,
                ...actions.data,
              };
            }
            return item;
          }),
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
