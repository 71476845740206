import React from "react";
import { FC } from "react";

import { Loader } from "../Other/Loader";
import { Container } from "./styles";

interface Props {
  opacity?: boolean;
}

const FullScreenLoader: FC<Props> = ({ opacity }) => {
  return (
    <Container style={opacity ? { opacity: 0.5 } : {}}>
      <Loader />
    </Container>
  );
};

export default FullScreenLoader;
