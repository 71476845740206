import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import queryString from 'query-string';

import { EditReceiptProps } from './index';
import { EnumReceiptStatus, EnumReceiptType } from 'redux/receipt/types';
import { useSideMenuContext } from 'components/SideMenu/SideMenuContext';
import { ICreateStakeholderParams, IEditReceiptBody, IEditReceiptParams } from 'net/api';

import Actions from './components/Actions';
import Details, { STAKEHOLDER_MAP } from './components/Details';
import { Loader } from 'components/Other/Loader';
import DeletePopUp from 'components/DeletePopUp';
import RelatedPages from './components/RelatedPages';
import MultivaluePopUp from 'components/MultivaluePopUp';
import Images, { ICoordinates } from './components/Images';

import { Container } from 'styles/blocks';
import { Inner, LoaderContainer } from './styles';
import { scrollWithAnimation } from 'helpers/other';
import { useFormik } from 'formik';
import FullScreenLoader from 'components/FullScreenLoader';
import { IContentSchema, IContentSchemaGrid } from 'redux/editReceipt/types';
import { IReceiptShemaItem } from 'redux/setting/types';
import { IGridLabel } from './components/Images/Grid';
import { IStakeholder } from '../../redux/list/types';

type EditAction = 'review' | 'review_and_send' | 'rejected';

export interface IEditValue {
    shema: IReceiptShemaItem;
    content: IContentSchema;
}

export interface ISelectedBox {
    key: 'content' | 'related_content';
    position: ICoordinates;
    id: string;
    document: string;
    text: string;
    isKey?: boolean;
}

const EditReceipt: FC<EditReceiptProps> = ({
    match,
    history,
    receipt,
    duplicates,
    location,
    editReceiptState,
    getXML,
    selectBox,
    changeFlag,
    editReceipt,
    changeStatus,
    mergeReceipt,
    updateContent,
    deleteReceipt,
    onLoadEditScene,
    createStakeholder,
    getReceiptHistory,
    updateContentItem,
    loadEditCategories,
    setEditSceneLoading,
    getEditStakeholders,
    loadEditStakeholders,
    setSelectedContent,
    setSelectedSchema,
    updateActiveKey,
    updateGrid,
    saveGridAsTemplate,
    useTemplateAsGrid,
    updateStakeholderDateFormat,
    getDuplicateInfo,
    exportReceipt
}) => {
    // @ts-ignore
    const isInbox = location.state && location.state.inbox;
    const imageRef = useRef<any>(null);
    const fieldsRef = useRef<HTMLDivElement | null>(null);

    const { id }: any = match.params;
    const receipt_company_id = queryString.parse(location.search).company_id;

    const { receiptHistory, receipts } = receipt;
    const {
        editableReceipt,
        content,
        originalShema,
        selectedContent,
        selectedSchema,
        activeKey,
        schemas,
        gridTemplate
    } = editReceiptState;

    const item_labels: IGridLabel[] = useMemo(() => {
        const items = schemas.filter((item) => item.parent_schema_id === 'line_items_container');

        return items.map((item) => ({ label: item.label, value: item.id }));
    }, [schemas]);

    const grids: IContentSchemaGrid[] = useMemo(() => {
        const t = content.find((item) => item.schema_id === 'line_items');
        return t ? t.grid?.parts || [] : [];
    }, [content]);

    const allReceipt = useMemo(() => {
        const result = receipts.items.filter((r) => {
            if (isInbox) {
                return (
                    r.receipt_type === editableReceipt?.receipt_type &&
                    // r.status !== "archive" &&
                    r.status !== EnumReceiptStatus.Processing &&
                    r.status !== EnumReceiptStatus.Unassigned
                );
            }
            return (
                r.receipt_type === editableReceipt?.receipt_type &&
                r.status === editableReceipt.status
            );
        });

        return result;
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receipts.items, editableReceipt]);

    const activeReceiptIndex = allReceipt.findIndex((r) => r.receipt_id === id);

    const [imageHeight, setImageHeight] = useState<number>(0);
    const [editAction, setEditAction] = useState<EditAction>('rejected');
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [fieldsPosition, setFieldPosition] = useState<{
        [key: string]: number;
    }>({});

    const [multivalue, setMultivalue] = useState<{
        schema: IReceiptShemaItem | null;
    }>({
        schema: null
    });
    const [popUp, setPopUp] = useState({
        delete: false,
        isMerge: false,
        multivalue: false
    });
    const { setHidden } = useSideMenuContext();

    useEffect(() => {
        setHidden(true);
        onLoadEditScene(id, receipt_company_id as string);
        setPopUp({
            delete: false,
            isMerge: false,
            multivalue: false
        });
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, onLoadEditScene, receipt_company_id]);

    useEffect(() => {
        return () => {
            setSelectedContent();
            setSelectedSchema();
            updateActiveKey(false);
        };
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            receipt_type: editableReceipt?.receipt_type || EnumReceiptType.Receipt,
            category: { name: editableReceipt?.category }
        },
        onSubmit: () => editReceiptHandle()
    });

    const visibleStakeholderFields = useMemo(() => {
        if (formik.values.receipt_type === EnumReceiptType.Invoice) {
            return Object.keys(STAKEHOLDER_MAP.invoice);
        }
        return Object.keys(STAKEHOLDER_MAP.receipt);
    }, [formik.values.receipt_type]);

    const stakeholderKeys = STAKEHOLDER_MAP[formik.values.receipt_type];

    const stakeholdersList = useMemo(
        () => editReceiptState.stakeholdersList.items,
        [editReceiptState.stakeholdersList.items]
    );

    const setHeight = (height: number) => {
        if (imageHeight !== height) {
            setImageHeight(height);
        }
    };

    const rejectReceipt = () => {
        // editReceiptHandle('rejected');
        changeStatusHandle(EnumReceiptStatus.Rejected);
        nextReceipt(1);
    };

    const reviewAndSend = async () => {
        setEditAction('review_and_send');
        formik.handleSubmit();
    };

    const reviewReceipt = async () => {
        setEditAction('review');
        formik.handleSubmit();
    };

    const editReceiptHandle = async (actionType?: EditAction) => {
        if (editableReceipt) {
            const { values }: any = formik;
            const body: IEditReceiptBody = {};
            const params: IEditReceiptParams = {};
            const isReviewAndSend = actionType !== 'rejected' && editAction === 'review_and_send';

            if (isReviewAndSend) {
                params.with_send = 1;
            }
            if (actionType === 'rejected') {
                body.receipt_status = EnumReceiptStatus.Rejected;
            }

            body.receipt_type = values.receipt_type;

            if ('id' in values.category) {
                if (values.category.external) {
                    body.external_category_id = values.category.id;
                } else {
                    body.category_id = values.category.id;
                }
            }

            const res = await editReceipt(editableReceipt, body, params);

            if (res) {
                if (isReviewAndSend) {
                    changeStatusHandle(EnumReceiptStatus.Archive);
                }

                nextReceipt(1);
            } else {
                alert(
                    'Failed to update the receipt. Please check if all mandatory fields are filled.'
                );
            }
        }
    };

    const selectedBoxes = useMemo(() => {
        const boxes: ISelectedBox[] = [];
        content.forEach((c) => {
            if (activeKey) {
                if (c.file && c.related_content && c.related_content.position.top) {
                    boxes.push({
                        key: 'related_content',
                        id: c.id,
                        position: c.related_content?.position,
                        document: c.file,
                        text: c.schema_id
                    });
                }
            } else {
                if (c.file && c.content && c.content.position.top) {
                    boxes.push({
                        key: 'content',
                        id: c.id,
                        position: c.content?.position,
                        document: c.file,
                        text: c.schema_id
                    });
                }
            }
        });
        return boxes;
    }, [activeKey, content]);

    const tooglePopUp = (key: string) => {
        return () => {
            // @ts-ignore
            setPopUp({ ...popUp, [key]: !popUp[key] });
        };
    };

    useEffect(() => {
        if (editableReceipt && editableReceipt.status === 'unassigned') {
            tooglePopUp('selectCompany')();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableReceipt]);

    const goBack = () => {
        if (isInbox) {
            history.push(`/receipts/inbox`);
            setHidden(false);
        } else {
            history.push(`/receipts/${editableReceipt?.status}`);
            setHidden(false);
        }
    };

    const nextReceipt = (n: number) => {
        if (allReceipt[activeReceiptIndex + n]) {
            setEditSceneLoading(true);
            const nextRec = allReceipt[activeReceiptIndex + n];
            setSelectedSchema(undefined);
            setSelectedContent(undefined);
            history.push({
                pathname: `/edit-receipt/${nextRec.receipt_id}`,
                search: `?company_id=${nextRec.company_id}`,
                state: {
                    inbox: isInbox
                }
            });
        } else {
            goBack();
        }
    };

    const changeStatusHandle = async (status: EnumReceiptStatus) => {
        if (editableReceipt) {
            await changeStatus(editableReceipt, status);
        }
    };

    const chnageFlagHandle = async () => {
        if (editableReceipt) {
            await changeFlag(editableReceipt, !editableReceipt.flag);
        }
    };

    const deleteReceiptHandle = async () => {
        if (editableReceipt) {
            setDeleteLoading(true);
            await deleteReceipt(editableReceipt);
            tooglePopUp('delete')();
            nextReceipt(1);
        }
    };

    const moveToReview = () => {
        changeStatusHandle(EnumReceiptStatus.Review);
        nextReceipt(1);
    };

    const moveToArchive = async () => {
        if (editableReceipt?.status === EnumReceiptStatus.Archive) {
            await changeStatusHandle(EnumReceiptStatus.Review);
        } else {
            await changeStatusHandle(EnumReceiptStatus.Archive);
        }
        nextReceipt(1);
    };

    const exportXML = async () => {
        if (editableReceipt) exportReceipt(editableReceipt);
        // const date = moment(editableReceipt?.created_at).format('YYYY-MM-DD');
        // const xmltext = await getXML(editableReceipt!.receipt_id);
        // if (xmltext) {
        //     var filename = `receipt-${date}.xml`;
        //     var pom = document.createElement('a');
        //     var bb = new Blob([xmltext.text], { type: 'text/plain' });

        //     pom.setAttribute('href', window.URL.createObjectURL(bb));
        //     pom.setAttribute('download', filename);

        //     pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
        //     pom.draggable = true;

        //     pom.click();
        //     pom.remove();
        // } else {
        //     alert('[getXML] function in EditReceipt throw new Error');
        // }
    };

    const createStakeholderHandle = async (data: ICreateStakeholderParams) => {
        if (editableReceipt) {
            const res = await createStakeholder(data, editableReceipt?.company_name);
            if ('error' in res) {
                return res.text;
            }
        }
    };

    const keysShema = useMemo(() => {
        const data: IReceiptShemaItem[] = [];
        originalShema.forEach((section) => {
            if (section.items) {
                section.items.forEach((item) => {
                    if (item.category !== 'multivalue') {
                        data.push(item);
                    }
                });
            }
        });
        return data;
    }, [originalShema]);

    const switchToNextField = () => {
        if (popUp.multivalue) {
            setSelectedSchema(undefined);
            setSelectedContent(undefined);
            return;
        }
        if (!selectedSchema) {
            return;
        }
        const currentIndex = keysShema.indexOf(selectedSchema);
        if (currentIndex === keysShema.length - 1) {
            return;
        } else {
            const nextShema = keysShema[currentIndex + 1];
            if (nextShema) {
                const c = content.find((c) => c.schema_id === nextShema.id);
                if (c) {
                    setSelectedContent(c);
                    setSelectedSchema(nextShema);
                }
            }
        }
    };

    const onSelectedArea = useCallback(
        (
            document_id: string,
            original_coordinates: ICoordinates,
            textract_coordinates: ICoordinates
        ) => {
            if (!selectedContent) {
                return;
            }
            selectBox(id, selectedContent.id, {
                file: document_id,
                position: {
                    left: textract_coordinates.left,
                    top: textract_coordinates.top,
                    width: textract_coordinates.width,
                    height: textract_coordinates.height
                },
                is_related_content: activeKey
            });
        },
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id, selectedContent, activeKey]
    );

    const onFocusElement = useCallback(
        (c: IEditValue) => {
            setSelectedContent(c.content);
            setSelectedSchema(c.shema);
        },
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editableReceipt, imageHeight, content]
    );

    const onBlurElement = useCallback(() => {
        setSelectedContent();
        setSelectedSchema();
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editableReceipt, imageHeight, content]);

    useEffect(() => {
        if (!imageRef.current) {
            return;
        }
        if (editableReceipt && selectedContent?.content?.position.top) {
            const PAGE_NUM =
                editableReceipt.pages.findIndex((p) => p.document === selectedContent.file) + 1;
            const IMAGE_HEIGHT = imageRef.current.getBoundingClientRect().height;
            let boxTop = selectedContent?.content.position.top;
            const topValue = IMAGE_HEIGHT * boxTop;
            const top = PAGE_NUM !== 1 ? imageHeight * (PAGE_NUM - 1) + topValue : topValue;
            scrollWithAnimation(imageRef.current, top, 350);
        }
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContent, editableReceipt, imageRef.current]);

    useEffect(() => {
        if (!fieldsRef.current || !selectedSchema) {
            return;
        }
        const top = fieldsPosition[selectedSchema.id];
        scrollWithAnimation(fieldsRef.current, top, 350);
        // TODO: fix exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSchema, fieldsRef.current]);

    const handleChange = useCallback(
        (content: IContentSchema, value: string) => {
            if (
                content.schema_id === 'supplier_date_format' ||
                content.schema_id === 'customer_date_format'
            ) {
                // update in stakeholder date format in redux store, for situations when stakeholder is not assigned
                updateStakeholderDateFormat(value);
            }
            const c = {
                ...content,
                content: { ...content.content!, value }
            };
            updateContentItem(c);
        },
        [updateContentItem, updateStakeholderDateFormat]
    );

    const onTimerChange = useCallback(
        (content_id: string) => {
            if (editableReceipt) {
                updateContent(editableReceipt.receipt_id, content_id);
            }
        },
        [editableReceipt, updateContent]
    );

    // const onTextPress = (el: IEdtiImageBox) => {
    //     if (selectedContent && editableReceipt) {
    //         if (activeKey) {
    //             selectBox(editableReceipt.receipt_id, selectedContent.id, {
    //                 block_id: el.originalData.id,
    //                 position: el.originalData.position,
    //                 file: el.originalData.file,
    //                 is_related_content: true
    //             });
    //         } else {
    //             setActiveBox(el);
    //         }
    //     }
    // };

    const onStakeholderSelect = (s: Partial<IStakeholder> | null | undefined) => {
        if (s) {
            // @ts-ignore
            const fetchData: IContentSchema[] = [];
            visibleStakeholderFields.forEach((key) => {
                const item = content.find((c) => c.schema_id === key);
                if (item) {
                    // @ts-ignore
                    const value = s[stakeholderKeys[key]] || '';
                    fetchData.push({
                        ...item,
                        content: {
                            ...item.content!,
                            value
                        }
                    });
                }
            });

            fetchData.forEach((content: IContentSchema) => {
                handleChange(content, content.content?.value || '');
                onTimerChange(content.id);
            });
        }
    };

    const onAcceptText = (document_id: string, value: string) => {
        if (selectedContent && editableReceipt) {
            const c = content.find((c) => c.id === selectedContent.id);
            if (c) {
                let field = 'content';
                if (activeKey) {
                    field = 'related_content';
                }
                const newItem = {
                    ...c,
                    file: document_id,
                    //@ts-ignore
                    [field]: { ...c[field], value }
                };

                if (
                    selectedContent.schema_id === 'supplier_name' ||
                    selectedContent.schema_id === 'customer_name'
                ) {
                    const stakeholder = stakeholdersList.find(
                        (stakeholder) => stakeholder.name === value
                    );

                    if (stakeholder) {
                        onStakeholderSelect(stakeholder);
                    }
                } else if (
                    selectedContent.schema_id === 'supplier_code' ||
                    selectedContent.schema_id === 'customer_code'
                ) {
                    const stakeholder = stakeholdersList.find(
                        (stakeholder) => stakeholder.code === value
                    );

                    if (stakeholder) {
                        onStakeholderSelect(stakeholder);
                    }
                } else {
                    updateContentItem(newItem);
                    updateContent(editableReceipt.receipt_id, newItem.id);
                }
            }
        }
        switchToNextField();
    };

    const onCancelText = () => {
        if (selectedContent && editableReceipt) {
            const c = content.find((c) => c.id === selectedContent.id);
            if (c) {
                let field = 'content';
                if (activeKey) {
                    field = 'related_content';
                }
                const newItem = {
                    ...c,
                    file: '',
                    //@ts-ignore

                    [field]: { ...c[field], value: '', block_id: '' }
                };
                updateContentItem(newItem);
                updateContent(editableReceipt.receipt_id, newItem.id);
            }
            switchToNextField();
        }
    };

    const onMergePress = () => {
        setSelectedContent();
        setSelectedSchema();
        tooglePopUp('isMerge')();
    };

    const toogleActiveKey = useCallback(() => {
        updateActiveKey(!activeKey);
        setSelectedContent();
        setSelectedSchema();
    }, [activeKey, setSelectedContent, setSelectedSchema, updateActiveKey]);

    const onMultivaluePress = (s: IReceiptShemaItem) => {
        return () => {
            setMultivalue({ schema: s });
            setSelectedSchema();
            setSelectedContent();
            tooglePopUp('multivalue')();
        };
    };

    if (!editableReceipt || editReceiptState.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    return (
        <Container>
            {editReceiptState.actionLoading && <FullScreenLoader />}
            <Actions
                getDuplicateInfo={getDuplicateInfo}
                duplicates={duplicates}
                count={allReceipt.length}
                loading={editReceiptState.loading}
                isValid={true}
                receipt={editableReceipt}
                pageNum={activeReceiptIndex + 1}
                activeKey={activeKey}
                toogleActiveKey={toogleActiveKey}
                goBack={goBack}
                onExport={exportXML}
                nextReceipt={nextReceipt}
                moveToReview={moveToReview}
                rejectReceipt={rejectReceipt}
                reviewReceipt={reviewReceipt}
                reviewAndSend={reviewAndSend}
                moveToArchive={moveToArchive}
                onDelete={tooglePopUp('delete')}
                chnageFlagHandle={chnageFlagHandle}
            />
            <Inner>
                <Images
                    disabled={editableReceipt.status === EnumReceiptStatus.Archive}
                    updateGrid={updateGrid}
                    labels={item_labels}
                    grids={grids}
                    ref={imageRef}
                    activeKey={activeKey}
                    selectedBoxes={selectedBoxes}
                    boxes={editReceiptState.imageBoxes}
                    pages={editReceiptState.editableReceipt!.pages}
                    setHeight={setHeight}
                    onAcceptText={onAcceptText}
                    onCancelText={onCancelText}
                    onMergePress={onMergePress}
                    onSelectedArea={onSelectedArea}
                    onSaveAsTemplate={saveGridAsTemplate}
                    onUseTemplate={useTemplateAsGrid}
                    gridTemplate={gridTemplate}
                    available_template={editableReceipt.available_template}
                    floatText={
                        activeKey
                            ? selectedContent?.related_content?.value
                            : selectedContent?.content?.value
                    }
                    // TODO:
                    floatLabel={selectedSchema?.label || ''}
                    isActive={!!selectedContent}
                    selectedContent={selectedContent}
                />
                {popUp.isMerge ? (
                    <RelatedPages
                        receipt_id={editableReceipt.receipt_id}
                        related_documents={editableReceipt.related_documents}
                        mergeReceipt={mergeReceipt}
                        onClose={tooglePopUp('isMerge')}
                    />
                ) : (
                    <Details
                        formik={formik}
                        content={content}
                        shema={originalShema}
                        receipt={editableReceipt}
                        activeContent={selectedSchema}
                        receiptHistory={receiptHistory}
                        editReceiptState={editReceiptState}
                        handleChange={handleChange}
                        onTimerChange={onTimerChange}
                        onFocusElement={onFocusElement}
                        onBlurElement={onBlurElement}
                        getReceiptHistory={getReceiptHistory}
                        onMultivaluePress={onMultivaluePress}
                        loadEditCategories={loadEditCategories}
                        getEditStakeholders={getEditStakeholders}
                        loadEditStakeholders={loadEditStakeholders}
                        createStakeholder={createStakeholderHandle}
                        fieldsRef={fieldsRef}
                        setFieldPosition={setFieldPosition}
                        disabled={editableReceipt.status === EnumReceiptStatus.Archive}
                        visibleStakeholderFields={visibleStakeholderFields}
                        onStakeholderSelect={onStakeholderSelect}
                        stakeholderKeys={stakeholderKeys}
                        stakeholdersList={stakeholdersList}
                    />
                )}
            </Inner>
            {popUp.delete && (
                <DeletePopUp
                    loading={deleteLoading}
                    title="Are you sure you want to delete the selected items?"
                    onDelete={deleteReceiptHandle}
                    closePopUp={tooglePopUp('delete')}
                />
            )}
            {popUp.multivalue && (
                <MultivaluePopUp
                    receiptId={id}
                    multivalue={multivalue}
                    closePopUp={tooglePopUp('multivalue')}
                    disabled={editableReceipt.status === EnumReceiptStatus.Archive}
                />
            )}
        </Container>
    );
};

export default EditReceipt;
