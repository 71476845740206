import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import { IEditCompanyUser, IInviteParams } from 'net/api';
import { EditCompanyUserSchema, SendInvitationSchema } from 'helpers/validations';

import InputLabel from 'components/InputLabel';
import PrimaryButton from 'components/PrimaryButton';

import { Filter } from 'styles/blocks';

import closePng from 'assets/images/icons/closeWhite.png';
import { ICompanyUser, UserState } from 'redux/user/types';
import DropInput from 'components/DropInput';

const styles = {
    inputContainer: {
        marginBottom: 20
    },
    labelStyle: {
        minWidth: 120,
        color: 'white'
    }
};

interface Props {
    user?: ICompanyUser | null;
    company_id?: string;
    closePopUp: () => void;
    sendInvite: (params: IInviteParams, company_id?: string) => any;
    editCompanyUser: (user_id: string, params: IEditCompanyUser,company_id?: string) => any;
}

const AddUser: FC<Props> = ({ user, company_id, closePopUp, sendInvite, editCompanyUser }) => {
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    // const { companies, activeAllCompanies } = userState;
    // const companiesName = user?.companies.items.map((c) => c.name);

    const formik = useFormik({
        validationSchema: user ? EditCompanyUserSchema : SendInvitationSchema,
        initialValues: {
            name: user && user.name ? user.name : '',
            surname: user && user.surname ? user.surname : '',
            role: user && user.role ? user.role : 'basic',
            email: user && user.email ? user.email.split('@')[0] : ''
        },
        onSubmit: async () => {
            if (user) {
                editUserCompany();
            } else {
                sendUserInvite();
            }
        }
    });

    const { errors, values, touched, handleChange, handleSubmit, setFieldValue } = formik;

    const editUserCompany = async () => {
        if (user) {
            setLoading(true);
            const newData: IEditCompanyUser = {};
            if (user.email.split('@')[0] !== values.email) {
                newData['email_prefix'] = values.email;
            }
            if (user.name !== values.name) {
                newData['name'] = values.name;
            }
            if (user.role !== values.role) {
                newData['role'] = values.role;
            }
            if (user.surname !== values.surname) {
                newData['surname'] = values.surname;
            }

            const error = await editCompanyUser(user.user_id, newData, company_id);
            if (error) {
                formik.setErrors({ email: error.response.data.message });
                setLoading(false);
            } else {
                closePopUp();
            }
        }
    };

    const sendUserInvite = async () => {
        setLoading(true);
        const error = await sendInvite(
            {
                name: values.name,
                surname: values.surname,
                email: values.email,
                role: values.role === 'admin' ? 'admin' : 'basic'
            },
            company_id
        );
        if (error) {
            formik.setErrors({ email: error.response.data.message });
            setLoading(false);
        } else {
            setStep(2);
        }
    };

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>{user ? 'EDIT' : 'ADD'} A USER</HeaderText>
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                <Main>
                    {step === 1 ? (
                        <form>
                            <BlockTitle>1. Enter user details</BlockTitle>
                            <InputLabel
                                id="name"
                                name="name"
                                mark
                                type="text"
                                label="First name"
                                error={errors.name}
                                touched={touched.name}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                value={values.name}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                id="surname"
                                name="surname"
                                mark
                                type="text"
                                label="Last name"
                                error={errors.surname}
                                touched={touched.surname}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                                value={values.surname}
                                labelStyle={styles.labelStyle}
                                containerStyle={styles.inputContainer}
                            />
                            <InputLabel
                                id="email"
                                name="email"
                                mark
                                type="email"
                                label={user ? 'Email-in' : 'Email address'}
                                value={values.email}
                                error={errors.email}
                                touched={touched.email}
                                onBlur={formik.handleBlur}
                                onChange={handleChange}
                                containerStyle={styles.inputContainer}
                                labelStyle={styles.labelStyle}
                            />
                            {/* {activeAllCompanies && (
                                <DropInput
                                    mark
                                    type="obj"
                                    objKey="name"
                                    label="Company"
                                    list={user?.companies || []}
                                    labelStyle={styles.labelStyle}
                                    containerStyle={styles.inputContainer}
                                    selected={formik.values.company}
                                    onClick={(t) => {
                                        formik.setFieldValue('company', t.name);
                                        formik.setFieldValue('company_id', t.company_id);
                                    }}
                                    error={touched.company && errors.company ? errors.company : ''}
                                />
                            )} */}

                            <BlockTitle style={{ marginTop: '30px' }}>
                                2. Choose a role for{' '}
                                {values.name && values.surname
                                    ? `${values.name} ${values.surname}`
                                    : 'New User'}
                            </BlockTitle>

                            <ChooseRoleContainer>
                                <ChooseRole style={{ marginRight: '110px' }}>
                                    <ChooseRoleTitle>Admin user</ChooseRoleTitle>
                                    <ChooseRoleCircle
                                        onClick={() => setFieldValue('role', 'admin')}
                                    >
                                        {values.role === 'admin' && <ChooseRoleInside />}
                                    </ChooseRoleCircle>
                                </ChooseRole>

                                <ChooseRole>
                                    <ChooseRoleTitle>Basic user</ChooseRoleTitle>
                                    <ChooseRoleCircle
                                        onClick={() => setFieldValue('role', 'basic')}
                                    >
                                        {values.role === 'basic' && <ChooseRoleInside />}
                                    </ChooseRoleCircle>
                                </ChooseRole>
                            </ChooseRoleContainer>

                            <Buttons>
                                <PrimaryButton
                                    loading={loading}
                                    title={user ? 'EDIT USER' : 'ADD USER'}
                                    onClick={handleSubmit}
                                    style={{ width: 125, height: 55 }}
                                />
                            </Buttons>
                        </form>
                    ) : (
                        <>
                            <HeaderText>Your invitation has been successfully sent.</HeaderText>
                            <Buttons>
                                <PrimaryButton
                                    title="OK"
                                    onClick={closePopUp}
                                    style={{ width: 125, height: 55 }}
                                />
                            </Buttons>
                        </>
                    )}
                </Main>
            </Container>
        </Filter>
    );
};

export default AddUser;

const Container = styled.div`
    width: 600px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;

const BlockTitle = styled.p`
    font-family: 'NunitoSans-Bold';
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
`;

const ChooseRoleContainer = styled.div`
    display: flex;
    padding-left: 30px;
    margin: 30px 0;
`;
const ChooseRole = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ChooseRoleTitle = styled.p`
    font-family: 'NunitoSans-SemiBold';
    font-size: 15px;
    color: #fff;
    margin-bottom: 15px;
`;
const ChooseRoleCircle = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ChooseRoleInside = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(180deg, #a665d1 0%, #ffbbcf 100%);
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`;
