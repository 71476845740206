import styled from 'styled-components/macro';
import { primaryColor, redColor } from 'styles/colors';

export const Container = styled.div`
    flex: 1;
    max-width: 450px;
    padding-left: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
`;

interface BlockProps {
    align?: 'flex-start';
    justify?: 'flex-end';
}

export const Block = styled.div`
    display: flex;
    position: relative;
    align-items: ${({ align }: BlockProps) => (align ? align : 'center')};
    margin-bottom: 20px;
    justify-content: ${({ justify }: BlockProps) => (justify ? justify : 'initial')};
`;

interface LabelProps {
    active?: boolean;
}

export const Label = styled.p<LabelProps>`
    min-width: 210px;
    font-size: 18px;
    color: ${({ active }: LabelProps) => (active ? primaryColor : '#9191af')};
    font-family: 'NunitoSans-SemiBold';
`;

export const Text = styled.p`
    font-size: 15px;
    color: #9191af;
    font-family: 'NunitoSans-SemiBold';
`;

export const ErrorText = styled.p`
    position: absolute;
    top: 62px;
    font-size: 14px;
    color: ${redColor};
    font-family: 'NunitoSans-Regular';
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 268px);
    padding-right: 5px;
`;

export const Buttons = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
