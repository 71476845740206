import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Filter } from 'styles/blocks';
import { CancelButton } from 'styles/text';

import closePng from 'assets/images/icons/closeWhite.png';
import { DropZone, FileType, UploadedFileType } from 'components/DropZone/DropZone';
import { UploadImportType } from 'net/api';
import { Loader } from 'components/Other/Loader';

const MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
];

interface Props {
    onClose: () => void;
    onUpload: (files: FileType, upload_type: UploadImportType) => Promise<void>;
    uploadType: UploadImportType;
}

const UploadXLSX: FC<Props> = ({ onClose, onUpload, uploadType }) => {
    const [uploaded, setUploaded] = useState<UploadedFileType[]>([]);
    const [loading, setLoading] = useState(false);
    const uploadFileHandle = async (file: FileType) => {
        let success = false;
        let message = 'This file type is not allowed';

        if (file) {
            success = true;
            message = '';
            if (MIME_TYPES.includes(file.type)) {
                message = 'Successfully uploaded item';
                if (file.size > 10485760) {
                    success = false;
                    message = 'Image size limits 10MB';
                }
            }
        }

        if (success) {
            try {
                setLoading(true);
                await onUpload(file, uploadType);
                onClose();
            } catch (err) {
                message = err as string;
                success = false;
                setNewResult(file, success, message);
                setLoading(false);
            }
        }
    };

    const setNewResult = (file: FileType, success: boolean, message: string) => {
        let fileName = file ? file.name : 'Unknown';
        let fileSize = file ? file.size : 0;
        let fileType = file ? file.type : 'unknown';

        setUploaded([
            {
                id: fileName + fileType,
                name: fileName,
                size: fileSize,
                type: fileType,
                success,
                message
            },
            ...uploaded
        ]);
    };
    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>Upload XLSX</HeaderText>
                    <Close src={closePng} alt="close" onClick={onClose} />
                </Header>
                <Main>
                    <Content>
                        {!loading ? (
                            <DropZone
                                setNewResult={setNewResult}
                                uploadFileHandle={uploadFileHandle}
                                desc={'Max file size is 10 mb for XLSX and XLS'}
                                accept={'.xlsx,.xls'}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Content>
                    <Buttons>
                        <CancelButton light onClick={onClose}>
                            Close
                        </CancelButton>
                    </Buttons>
                </Main>
            </Container>
        </Filter>
    );
};

export default UploadXLSX;

const Container = styled.div`
    width: 650px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`;
