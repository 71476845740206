import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import queryString from 'query-string';

import { SelectCompanyProps } from './index';
import { SelectCompanySchema } from 'helpers/validations';
import DeletePopUp from 'components/DeletePopUp';

import Image from './components/Image';
import Actions from './components/Actions';
import DropInput from 'components/DropInput';
import { Loader } from 'components/Other/Loader';

import { Container } from 'styles/blocks';
import { Inner, LoaderContainer, DetailContainer, ImageContainer } from './styles';

const styles = {
    dropContainer: {
        marginBottom: 20
    },
    dropLabel: {
        minWidth: 170,
        fontSize: 18
    }
};

const SelectCompany: FC<SelectCompanyProps> = ({
    match,
    history,
    location,
    companies,
    editReceiptState,
    selectCompany,
    onLoadSelectCompanyScene,
    deleteReceipt
}) => {
    const { id }: any = match.params;
    const { editableReceipt, loading } = editReceiptState;
    const receipt_company_id = queryString.parse(location.search).company_id;

    const [visible_delete_popup, setVisibleDeletePopup] = useState(false);
    const [delete_loading, setDeleteLoading] = useState(false);

    const timerRef = useRef<any>();

    const formik = useFormik({
        validationSchema: SelectCompanySchema,
        initialValues: {
            name: ''
        },
        onSubmit: async ({ name }) => {
            const company = companies.find((c) => c.name === name);
            if (company && editableReceipt) {
                const receipt_id = await selectCompany(
                    { company_id: 'NONE', targe_company_id: company.company_id },
                    { ...editableReceipt, company_name: name }
                );

                timerRef.current = setTimeout(() => {
                    history.push(`/edit-receipt/${receipt_id}`, {
                        inbox: false
                    });
                }, 2.5 * 1000);
            }
        }
    });
    const { isValid, touched, errors, setFieldValue, handleSubmit } = formik;

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (id) {
            onLoadSelectCompanyScene(id, receipt_company_id as string);
        }
    }, [id, onLoadSelectCompanyScene, receipt_company_id]);

    const onDelete = async () => {
        if (!editableReceipt) {
            return;
        }
        setDeleteLoading(true);
        await deleteReceipt(editableReceipt);
        history.goBack();
    };

    if (!editableReceipt || editableReceipt.receipt_id !== id || loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        );
    }

    return (
        <Container>
            <Actions
                loading={loading}
                isValid={isValid}
                onSave={handleSubmit}
                goBack={history.goBack}
                onDelete={() => setVisibleDeletePopup(true)}
            />
            <Inner>
                <ImageContainer>
                    {editableReceipt.pages.map((p) => (
                        <Image key={p.url} url={p.url} />
                    ))}
                </ImageContainer>
                <DetailContainer>
                    <DropInput
                        mark
                        label="Company"
                        selected={formik.values.name}
                        list={companies.map((c) => c.name)}
                        onClick={(t) => setFieldValue('name', t)}
                        error={touched.name && errors.name ? errors.name : undefined}
                        labelStyle={styles.dropLabel}
                        containerStyle={styles.dropContainer}
                    />
                </DetailContainer>
            </Inner>

            {visible_delete_popup && (
                <DeletePopUp
                    loading={delete_loading}
                    title="Are you sure you want to delete the selected items?"
                    onDelete={onDelete}
                    closePopUp={() => setVisibleDeletePopup(false)}
                />
            )}
        </Container>
    );
};

export default SelectCompany;
