import { IGetReceiptParams, IUploadType } from 'net/api';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ReceiptActions } from 'redux/receipt/actions';
import { AppState } from 'redux/rotReducer';
import { UserState } from 'redux/user/types';

import {
    EnumReceiptStatus,
    IDuplicate,
    IReceipt,
    IReceiptEmailInfo,
    ReceiptState
} from '../../redux/receipt/types';
import Component from './Receipts';

export type ReceiptsProps = LinkStateProps & LinkDispatchProps & RouteComponentProps & {};

interface LinkStateProps {
    match: any;
    user: UserState;
    data: IReceipt[];
    loading: boolean;
    preview: null | any;
    duplicateInfo: null | IDuplicate[];
    emailInfo: null | IReceiptEmailInfo;
    capturedConfig: ReceiptState['capturedConfig'];
}

interface LinkDispatchProps {
    loadReceipts: (params: IGetReceiptParams) => any;
    deleteReceipt: (receipt: IReceipt) => any;
    convertReceipt: (receipt: IReceipt) => any;
    getReceipts: (params: IGetReceiptParams) => any;
    changeFlag: (receipt: IReceipt, flag: boolean) => any;
    changeStatus: (receipt: IReceipt, status: EnumReceiptStatus) => any;
    uploadPhoto: (
        photo: Array<any>,
        upload_type: IUploadType,
        user_id?: string,
        process_rows?: boolean
    ) => Promise<void>;
    getReceiptEmailInfo: (r: IReceipt) => any;
    getReceiptPreview: (r: IReceipt) => any;
    toogleCaptureData: (k: string) => void;
    getDuplicateInfo: (r: IReceipt) => any;
    restartReceipt: (r: IReceipt) => void;
    exportReceipt: (receipt: IReceipt) => void;
}

const mapStateToProps = (state: AppState) => ({
    user: state.user,
    loading: state.receipt.loading,
    data: state.receipt.receipts.items,
    emailInfo: state.receipt.receiptEmailInfo,
    duplicateInfo: state.receipt.duplicateInfo,
    preview: state.receipt.receiptPreview,
    capturedConfig: state.receipt.capturedConfig
});

const mapDispatchToProps = {
    changeFlag: ReceiptActions.changeFlag,
    uploadPhoto: ReceiptActions.uploadPhoto,
    getReceipts: ReceiptActions.getReceipts,
    loadReceipts: ReceiptActions.loadReceipts,
    changeStatus: ReceiptActions.changeStatus,
    deleteReceipt: ReceiptActions.deleteReceipt,
    convertReceipt: ReceiptActions.convertReceipt,
    getDuplicateInfo: ReceiptActions.getDuplicateInfo,
    getReceiptPreview: ReceiptActions.getReceiptPreview,
    toogleCaptureData: ReceiptActions.toogleCaptureData,
    getReceiptEmailInfo: ReceiptActions.getReceiptEmailInfo,
    restartReceipt: ReceiptActions.restartReceipt,
    exportReceipt: ReceiptActions.exportReceipt
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
