import React, { FC } from "react";
import styled, { css } from "styled-components/macro";
import { connect } from "react-redux";

import { UserState } from "redux/user/types";
import signOutIcon from "assets/images/icons/signOut.png";
import { AuthActions } from "redux/auth/actions";

interface Props {
  user: UserState;
  title: string;
  margin?: string;
  onClick: () => void;
  logOut: () => any;
}

const PrimaryHeader: FC<Props> = ({ user, title, margin, onClick, logOut }) => {
  const {
    profile: { name, surname },
    companies,
    activeCompany,
    activeAllCompanies,
  } = user;
  const isOneCompany = companies.items.length === 1;

  const username = name && surname ? `${name} ${surname}` : "Name Surname";
  const company =
    activeAllCompanies && !isOneCompany ? "All" : activeCompany.name;

  return (
    <Header style={{ margin }}>
      <PageTitle>{title ? title : "Title"}</PageTitle>
      <div>
        <CompanyInfo onClick={onClick}>
          {company} - {username}
        </CompanyInfo>
        <SignBlock onClick={logOut}>
          <SignOut src={signOutIcon} />
          <Text>Sign Out</Text>
        </SignBlock>
      </div>
    </Header>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logOut: AuthActions.logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryHeader);

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const SignBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
`;

const SignOut = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;

const titleStyle = css`
  font-size: 20px;
  color: #565682;
  font-family: "NunitoSans-SemiBold";
`;

const PageTitle = styled.h1`
  ${titleStyle};
  margin-right: 20px;
  min-width: 110px;
`;

const CompanyInfo = styled.p`
  ${titleStyle};
  cursor: pointer;
  text-align: right;
`;

const Text = styled.p`
  ${titleStyle};
  cursor: pointer;
`;
