import { Auth } from 'aws-amplify';
import { Dispatch } from 'redux';
import { AuthActions } from 'redux/auth/actions';
import { AppState } from 'redux/rotReducer';
import { UserActions } from 'redux/user/actions';

import { API, API_OPEN, IInviteParams } from '../../net/api';
import { ReduxActions } from '../types';

export class InvitationActions {
    static setLoading = (loading: boolean): ReduxActions => ({
        type: 'SET_INVITATION_LOADING',
        loading
    });

    static sendInvite =
        (params: IInviteParams, company_id?: string) =>
        async (dispatch: Dispatch<ReduxActions>, getState: () => AppState) => {
            // const { companies } = getState().user;
            // if (company_name) {
            //   const company = companies.items.find((c) => c.company_id === company_name);
            //   if (company) {
            //     await dispatch<any>(UserActions.setActiveCompany(company));
            //   }
            // }
            try {
                await API.sendInvite(params, company_id);

                dispatch(UserActions.getUsersByCompany() as any);
            } catch (error) {
                return error;
            }
        };

    static getUserByToken = () => async (dispatch: Dispatch<ReduxActions>) => {
        dispatch(InvitationActions.setLoading(true));
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            if (token) {
                const res = await API_OPEN.getUserByToken(token);
                dispatch({
                    type: 'SET_INVITATION_USER',
                    user: {
                        ...res.data.invitation,
                        new_account_required: res.data.new_account_required
                    }
                });

                if (!res.data.new_account_required) {
                    await API_OPEN.putUserByToken(token);
                }
            }
        } catch (error) {
            dispatch<any>(
                AuthActions.setInfoPopUp({
                    message: 'Something went wrong',
                    id: '',
                    type: ''
                })
            );
            return error;
        } finally {
            dispatch(InvitationActions.setLoading(false));
        }
    };

    static signUpConfirm =
        (password: string) => async (dispatch: any, getState: () => AppState) => {
            dispatch(InvitationActions.setLoading(true));
            const { user } = getState().invitation;

            try {
                await Auth.signUp({
                    username: user.email,
                    password,
                    attributes: {
                        email: user.email,
                        'custom:invitation_id': user.id
                    }
                });

                await dispatch(AuthActions.singIn(user.email, password));
            } catch (error) {
            } finally {
                dispatch(InvitationActions.setLoading(false));
            }
        };
}
