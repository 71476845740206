import React, { FC } from "react";
import styled from "styled-components";

import { IInfoPopUpItem } from "redux/auth/types";

import MessagePopUp from "components/MessagePopUp";

interface Props {
  data: IInfoPopUpItem[];
}

const MessagePopUpContainer: FC<Props> = ({ data }) => {
  if (!data.length) {
    return null;
  }

  return (
    <Container>
      {data.map((t) => (
        <MessagePopUp key={t.id} text={t.message} />
      ))}
    </Container>
  );
};

export default MessagePopUpContainer;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: auto;
  z-index: 10;
  padding: 15px;
`;
