import styled, { css } from "styled-components";
import { greenColor, primaryColor } from "styles/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;
export const Block = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #9191af;
  font-family: "NunitoSans-Bold";
  margin-right: 25px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

export const IconsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const buttonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: "NunitoSans-ExtraBold";
  cursor: pointer;
  padding: 0 10px;
  height: 36px;
  border-radius: 5px;
  margin: 5px;
  &:hover {
    opacity: 0.8;
  }
`;

interface ButtonProps {
  isValid: boolean;
  type?: "default" | "loading";
}
export const Button = styled.div`
  background-color: ${({ type }: ButtonProps) => {
    switch (type) {
      case "default":
        return greenColor;
      case "loading":
        return primaryColor;
      default:
        return greenColor;
    }
  }};
  color: #fff;
  ${buttonStyle};
  &:hover {
    opacity: ${({ isValid }: ButtonProps) => (isValid ? 0.5 : 0.5)};
  }
  opacity: ${({ isValid }: ButtonProps) => (isValid ? 1 : 0.5)};
`;
