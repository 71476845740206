import styled from "styled-components/macro";

interface MenuProps {
  hide: boolean;
}
export const Menu = styled.div`
  width: ${({ hide }: MenuProps) => (hide ? "0px" : "300px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 0;
  transition: ease-in-out 0.3s all;
  overflow-y: scroll;
`;

export const CollapsIcon = styled.img`
  width: 12px;
  height: 12px;
`;

interface CollapsProps {
  hide: boolean;
}
export const Collaps = styled.div`
  opacity: ${({ hide }: CollapsProps) => (hide ? "1" : "0")};
  position: absolute;
  top: 10px;
  right: -10px;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease-in-out 0.3s all;

  &:hover {
    background: linear-gradient(198.17deg, #ffbbcf 5.73%, #a665d1 87.75%);
  }
  &:hover ${CollapsIcon} {
    filter: brightness(0) invert(1);
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  background-color: #dce1ee;

  &:hover ${Collaps} {
    opacity: 1;
  }
`;

export const Logo = styled.img`
  width: 127px;
  height: 80px;
`;

export const LabelContainer = styled.div`
  width: 100%;
  margin: 30px 0;
`;

export const Label = styled.label`
  font-size: 18px;
  font-family: "NunitoSans-SemiBold";
  color: #9191af;
  margin-left: 50px;
`;

export const List = styled.ul`
  width: 100%;
`;

export const CollapsContainer = styled.div`
  position: relative;
`;

export const CollapsEmpty = styled.div`
  width: 25px;
  height: 100%;
  background-color: #dce1ee;
`;
