import React, { FC, useEffect } from "react";

import { SettingsProps } from "./index";

import ItemCheck from "./components/ItemCheck";
import PrimaryButton from "components/PrimaryButton";
import PrimaryHeader from "components/PrimaryHeader";

import { Container } from "styles/blocks";
import { Box, Main, Label, Block, SaveBlock, ItemWrapper } from "./styles";
import { CancelButton } from "styles/text";
import SmallLoader from "components/SmallLoader";

const styles = {
  saveBtn: {
    width: 90,
    height: 35,
    marginLeft: 28,
  },
};

const Settings: FC<SettingsProps> = ({
  history,
  setting,
  updateHidden,
  updateRequired,
  getReceiptSchema,
  saveReceiptSchema,
}) => {
  useEffect(() => {
    getReceiptSchema();
  }, [getReceiptSchema]);

  const { schema, loading } = setting;

  return (
    <Container>
      <PrimaryHeader
        title="Integration"
        margin={`0 0 0 0`}
        onClick={() => history.push("/admin")}
      />

      <Main>
        <Box>
          <Label>Fields to capture</Label>
          <Block>
            {schema.map((item) => {
              if (item.category === "datapoint") {
                return (
                  <ItemWrapper key={item.id}>
                    <ItemCheck
                      item={item}
                      disabled={loading}
                      updateHidden={updateHidden}
                      updateRequired={updateRequired}
                    />
                  </ItemWrapper>
                );
              }
              return null;
            })}
          </Block>
          <SaveBlock>
            {loading ? (
              <SmallLoader />
            ) : (
              <>
                <CancelButton>Cancel</CancelButton>
                <PrimaryButton
                  title="SAVE"
                  onClick={saveReceiptSchema}
                  style={styles.saveBtn}
                />
              </>
            )}
          </SaveBlock>
        </Box>
      </Main>
    </Container>
  );
};

export default Settings;
