import React, { FC, useCallback, useState } from 'react';

import { DashboardProps } from './index';

import Company from './components/Company';
import AddCompany from 'components/AddCompany';
import AddUser from './components/AddUser';
import GetApp from './components/GetApp';
import PrimaryHeader from 'components/PrimaryHeader';

import { Label, RegularText } from 'styles/text';
import { Container } from 'styles/blocks';
import {
    CheckBoxContainer,
    CompanyList,
    EmptyContainer,
    EmptyText,
    PeopleContainer
} from './styles';
import PrimaryButton from 'components/PrimaryButton';
import { ICompany } from 'redux/user/types';
import DeletePopUp from 'components/DeletePopUp';
import DarkCheckBox from 'components/DarkCheckBox';

const Dashboard: FC<DashboardProps> = ({
    user,
    history,
    deleteCompany,
    createCompany,
    toggleActiveCompany,
    setActiveAllCompanies
}) => {
    const { companies, activeCompany, activeAllCompanies } = user;
    const [popUp, setPopUp] = useState({
        addCompany: false,
        deleteCompany: false
    });
    const [companyForDelete, setCompanyForDelete] = useState<ICompany>();
    const [loading, setLoading] = useState(false);

    const tooglePopUp = useCallback(
        (key: string) => {
            return () => {
                // @ts-ignore
                setPopUp({ ...popUp, [key]: !popUp[key] });
            };
        },
        [popUp]
    );

    const addUser = () => {
        history.push('/my-team', { addUser: true });
    };

    const deleteCompanyHandle = async () => {
        if (companyForDelete) {
            setLoading(true);
            await deleteCompany(companyForDelete!.company_id);
            setLoading(false);
            tooglePopUp('deleteCompany')();
        }
    };

    const toggleActiveCompanyHandle = useCallback(
        (item: ICompany) => {
            toggleActiveCompany(item);
        },
        [toggleActiveCompany]
    );

    const onDeleteCompany = useCallback(
        (item: ICompany) => {
            setCompanyForDelete(item);
            tooglePopUp('deleteCompany')();
        },
        [tooglePopUp]
    );

    return (
        <Container>
            <PrimaryHeader
                title="Dashboard"
                margin={`0 0 54px 0`}
                onClick={() => history.push('/my-profile')}
            />

            <Label>My companies</Label>
            {user.companies.items.length > 1 && (
                <CheckBoxContainer onClick={() => setActiveAllCompanies(!activeAllCompanies)}>
                    <DarkCheckBox active={activeAllCompanies} />
                    <RegularText margin="0 0 0 10px">VIEW ALL COMPANIES</RegularText>
                </CheckBoxContainer>
            )}

            <CompanyList>
                {companies.items.length > 0 ? (
                    companies.items.map((item, index) => (
                        <Company
                            key={index}
                            company={item}
                            active={
                                item.company_id === activeCompany.company_id || activeAllCompanies
                            }
                            onPress={toggleActiveCompanyHandle}
                            onDelete={onDeleteCompany}
                        />
                    ))
                ) : (
                    <EmptyContainer>
                        <EmptyText>No companies yet</EmptyText>
                    </EmptyContainer>
                )}
            </CompanyList>
            <PrimaryButton
                title="ADD COMPANY"
                style={{ width: '160px', height: '45px', marginBottom: 46 }}
                onClick={tooglePopUp('addCompany')}
            />

            <Label>People</Label>
            <PeopleContainer>
                {activeCompany.role === 'admin' && <AddUser onClick={addUser} />}
                <GetApp />
            </PeopleContainer>

            {popUp.addCompany && (
                <AddCompany createCompany={createCompany} closePopUp={tooglePopUp('addCompany')} />
            )}
            {popUp.deleteCompany && (
                <DeletePopUp
                    loading={loading}
                    title={`Are you sure to delete ${companyForDelete!.name}?`}
                    headerTitle="Delete company"
                    onDelete={deleteCompanyHandle}
                    closePopUp={tooglePopUp('deleteCompany')}
                />
            )}
        </Container>
    );
};

export default Dashboard;
