import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  text: string;
}

const MessagePopUp: FC<Props> = ({ text }) => {
  return (
    <Container className="bounceInRight">
      <Header>
        <HeaderText>Assistant message</HeaderText>
      </Header>
      <Main>
        <MainTitle>{text}</MainTitle>
      </Main>
    </Container>
  );
};

export default MessagePopUp;

const Container = styled.div`
  margin-top: 20px;
  width: 500px;
  z-index: 2;
  font-family: "NunitoSans-Bold";
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  background-color: #888c9b;
`;

const HeaderText = styled.p`
  font-size: 18px;
  color: #fff;
`;

const Main = styled.div`
  padding: 15px;
  font-size: 16px;
  background-color: #666975;
`;

const MainTitle = styled.p`
  color: #fff;
  margin-bottom: 28px;
  word-break: break-all;
`;
