import React, { FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { useSideMenuContext } from './SideMenuContext';

import { TitleMain } from '../Title/TitleMain';
import {
    Collaps,
    CollapsContainer,
    CollapsEmpty,
    CollapsIcon,
    Label,
    LabelContainer,
    List,
    Logo,
    Menu,
    MenuContainer
} from './styles';
import SideMenuItem from './SideMenuItem';
import SideMenuItemMultiple from './SideMenuItemMultiple';
import ItemList from './ItemList';

import logo from '../../assets/images/icons/logo.png';
import dashboardIcon from '../../assets/images/icons/dashboard.png';
import costIcon from '../../assets/images/icons/cost.png';
import customersIcon from '../../assets/images/icons/customers.png';
import categoriesIcon from '../../assets/images/icons/categories.png';
import supplierIcon from '../../assets/images/icons/supplier.png';
import myProfileIcon from '../../assets/images/icons/myProfile.png';
import myTeamIcon from '../../assets/images/icons/myTeam.png';
import arrowRight from '../../assets/images/icons/arrowRight.png';
import arrowLeft from '../../assets/images/icons/arrowLeft.png';
import settingsIcon from '../../assets/images/icons/settings-menu.png';
import adminIcon from '../../assets/images/icons/admin.png';
import integrationIcon from '../../assets/images/icons/integration.png';

import { EnumReceiptStatus, ReceiptState } from 'redux/receipt/types';
import { UserState } from 'redux/user/types';
import { AppState } from 'redux/rotReducer';

type TConfig = {
    title: string;
    status: EnumReceiptStatus | undefined;
};

const config: TConfig[] = [
    {
        title: 'Inbox',
        status: undefined
    },
    {
        title: 'In Processing',
        status: EnumReceiptStatus.Processing
    },
    {
        title: 'To Review',
        status: EnumReceiptStatus.Review
    },
    {
        title: 'Ready',
        status: EnumReceiptStatus.Ready
    },
    {
        title: 'Archive',
        status: EnumReceiptStatus.Archive
    },
    {
        title: 'Unassigned',
        status: EnumReceiptStatus.Unassigned
    },
    {
        title: 'Rejected',
        status: EnumReceiptStatus.Rejected
    },
    {
        title: 'Saving',
        status: EnumReceiptStatus.Saving
    },
    {
        title: 'Failed',
        status: EnumReceiptStatus.Failed
    }
];

interface Props {
    user: UserState;
    count: ReceiptState['count'];
}

const SideMenu: FC<Props> = ({ user, count }) => {
    const { hidden, setHidden } = useSideMenuContext();
    const { activeCompany, activeAllCompanies, profile } = user;
    const [state, setState] = useState({
        costs: false
    });
    const totalReceiptsInvoices = useMemo(() => {
        return Object.values({ ...count, unassigned: 0 }).reduce((a, b) => a + b);
    }, [count]);

    const getReceiptAndInvoiceCount = (route: TConfig) => {
        const receiptsInvoicesCount = route.status
            ? // @ts-ignore
              count[route.status] || 0
            : totalReceiptsInvoices;
        return receiptsInvoicesCount;
    };

    return (
        <MenuContainer>
            <Menu hide={hidden} className="side-bar">
                <Logo src={logo} alt="logo" />
                <TitleMain style={{ fontSize: '20px', marginBottom: '35px' }} />
                <List>
                    <SideMenuItem title="Dashboard" path="/dashboard" icon={dashboardIcon} />
                    <LabelContainer>
                        <Label>Workplaces</Label>
                    </LabelContainer>
                    <SideMenuItemMultiple
                        title="Receipts"
                        icon={costIcon}
                        openMenu={() => setState({ ...state, costs: !state.costs })}
                        active={state.costs}
                    />
                    {state.costs && (
                        <div>
                            {config.map((route, i) => (
                                <ItemList
                                    key={i}
                                    info={getReceiptAndInvoiceCount(route)}
                                    title={route.title}
                                    path={`/receipts/${route.status || 'inbox'}`}
                                />
                            ))}
                        </div>
                    )}
                    <LabelContainer>
                        <Label>List</Label>
                    </LabelContainer>
                    <SideMenuItem title="Customers" path="/customers" icon={customersIcon} />
                    <SideMenuItem title="Suppliers" path="/suppliers" icon={supplierIcon} />
                    <SideMenuItem title="Categories" path="/categories" icon={categoriesIcon} />
                    <SideMenuItem title="Items" path="/items" icon={categoriesIcon} />
                    <LabelContainer>
                        <Label>Manage</Label>
                    </LabelContainer>
                    <SideMenuItem title="My Profile" path="/my-profile" icon={myProfileIcon} />
                    {activeCompany.role === 'admin' && (
                        <SideMenuItem title="My Team" path="/my-team" icon={myTeamIcon} />
                    )}
                    {activeCompany.role === 'admin' && !activeAllCompanies && (
                        <SideMenuItem
                            title="Integration"
                            path="/integration"
                            icon={integrationIcon}
                        />
                    )}
                    {profile.is_admin && (
                        <SideMenuItem title="Admin" path="/admin" icon={adminIcon} />
                    )}
                    <SideMenuItem title="Settings" path="/settings" icon={settingsIcon} />
                </List>
            </Menu>

            <CollapsContainer>
                {hidden && <CollapsEmpty />}
                <Collaps onClick={() => setHidden(!hidden)} hide={hidden}>
                    <CollapsIcon src={hidden ? arrowRight : arrowLeft} />
                </Collaps>
            </CollapsContainer>
        </MenuContainer>
    );
};

const mapStateToProps = (state: AppState) => ({
    user: state.user,
    count: state.receipt.count
});

export default connect(mapStateToProps)(SideMenu);
