import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import InputLabel from 'components/InputLabel';
import PrimaryButton from 'components/PrimaryButton';

import { Filter } from 'styles/blocks';

import closePng from 'assets/images/icons/closeWhite.png';
import { AddCompanySchema } from 'helpers/validations';

const styles = {
    inputContainer: {
        marginBottom: 15
    },
    labelStyle: {
        minWidth: 160
    }
};

interface Props {
    closePopUp: () => void;
    createCompany: (company_name: string, code: string) => any;
}

const AddCompany: FC<Props> = ({ closePopUp, createCompany }) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validationSchema: AddCompanySchema,
        initialValues: {
            name: '',
            code: ''
        },
        onSubmit: async ({ name, code }) => {
            setLoading(true);
            const err = await createCompany(name, code);
            if (err) {
                formik.setErrors({ name: err });
                setLoading(false);
            } else {
                closePopUp();
            }
        }
    });

    const { errors, values, touched, handleChange, handleSubmit } = formik;

    return (
        <Filter className="fadeIn">
            <Container>
                <Header>
                    <HeaderText>ADD COMPANY</HeaderText>
                    <Close src={closePng} alt="close" onClick={closePopUp} />
                </Header>
                <Main>
                    <form>
                        <InputLabel
                            mark
                            id="name"
                            name="name"
                            type="text"
                            label="Company name"
                            error={errors.name}
                            touched={touched.name}
                            onChange={handleChange}
                            maxLength={80}
                            value={values.name}
                            labelStyle={styles.labelStyle}
                            containerStyle={styles.inputContainer}
                        />
                        <InputLabel
                            mark
                            id="code"
                            name="code"
                            type="text"
                            label="Company code"
                            error={errors.code}
                            touched={touched.code}
                            onChange={handleChange}
                            maxLength={80}
                            value={values.code}
                            labelStyle={styles.labelStyle}
                            containerStyle={styles.inputContainer}
                        />
                        <Buttons>
                            <PrimaryButton
                                loading={loading}
                                title="ADD COMPANY"
                                onClick={handleSubmit}
                                style={{ width: 145, height: 55 }}
                            />
                        </Buttons>
                    </form>
                </Main>
            </Container>
        </Filter>
    );
};

export default AddCompany;

const Container = styled.div`
    width: 600px;
    background-color: #666975;
    z-index: 2;
    font-family: 'NunitoSans-Bold';
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderText = styled.p`
    font-size: 18px;
    color: #fff;
`;

const Close = styled.img`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;

const Main = styled.div`
    padding: 30px;
    font-size: 16px;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
`;
