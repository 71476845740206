import { IEditCompanyUser, IInviteParams } from 'net/api';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { InvitationActions } from 'redux/invitation/actions';
import { AppState } from 'redux/rotReducer';
import { UserActions } from 'redux/user/actions';
import { ICompanyUser, UserState } from 'redux/user/types';

import Component from './MyTeam';

export type MyTeamProps = LinkStateProps & LinkDispatchProps & RouteComponentProps & {};

interface LinkStateProps {
    user: UserState;
}
interface LinkDispatchProps {
    getUsersByCompany: () => Promise<void>;
    sendInvite: (params: IInviteParams, company_id?: string) => any;
    editCompanyUser: (
        user_id: string,
        params: IEditCompanyUser,
        company_id?: string
    ) => Promise<void>;
    deleteCompanyUser: (user: ICompanyUser) => void;
}

const mapStateToProps = (state: AppState) => ({
    user: state.user
});

const mapDispatchToProps = {
    sendInvite: InvitationActions.sendInvite,
    editCompanyUser: UserActions.editCompanyUser,
    getUsersByCompany: UserActions.getUsersByCompany,
    deleteCompanyUser: UserActions.deleteCompanyUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
