import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';

import { AuthState } from 'redux/auth/types';
import { AuthActions } from 'redux/auth/actions';
import { SignInSchema } from 'helpers/validations';

import SideBar from './components/SideBar';
import AuthInput from 'components/AuthInput';
import PrimaryButton from 'components/PrimaryButton';
import {
    ActionLink,
    AuthContainer,
    AuthLogo,
    AuthMain,
    AuthText,
    AuthTitle,
    ForgotContainer
} from './styles';
import { ColoredTitle } from 'styles/text';

import logo from 'assets/images/icons/logo.png';
import { RouteComponentProps } from 'react-router-dom';

const styles = {
    button: {
        width: '332px',
        height: '53px',
        marginBottom: '68px',
        marginTop: '80px'
    }
};

interface Props extends RouteComponentProps {
    auth: AuthState;
    signIn: (email: string, password: string) => Promise<any>;
}

const SignIn: FC<Props> = ({ history, auth, location, signIn }) => {
    const state = location.state as any;
    const formik = useFormik({
        validationSchema: SignInSchema,
        initialValues: {
            email: state && state.email ? (state.email as string) : '',
            password: ''
        },
        onSubmit: async ({ email, password }) => {
            const res = await signIn(email, password);
            if (res) {
                if (res.code && res.code === 'UserNotConfirmedException') {
                    history.push('/signup/confirm?notconfirmed=true');
                } else if (res.code === 'NetworkError') {
                    formik.setErrors({
                        email: ' ',
                        password: 'No connection to the internet'
                    });
                } else {
                    formik.setErrors({
                        email: ' ',
                        password: 'Incorrect email or password'
                    });
                }
            }
        }
    });

    return (
        <AuthContainer>
            <AuthMain>
                <AuthLogo src={logo} alt="logo" />
                <ColoredTitle>Assistant</ColoredTitle>
                <AuthTitle>
                    Please sign into Assistant to submit your
                    <br /> Receipts & Invoices
                </AuthTitle>
                <AuthInput
                    id="email"
                    name="email"
                    type="text"
                    style={{ marginBottom: 24 }}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Email"
                />
                <AuthInput
                    secure
                    id="password"
                    name="password"
                    type="password"
                    style={{ marginBottom: 18 }}
                    touched={formik.touched.password}
                    error={formik.errors.password}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Password"
                />
                <ForgotContainer>
                    <AuthText>
                        Forgot password?
                        <ActionLink to={'/forgot'}> Click here</ActionLink>
                    </AuthText>
                </ForgotContainer>

                <PrimaryButton
                    bigLoader
                    title="LOG IN"
                    style={styles.button}
                    loading={auth.loading}
                    onClick={formik.handleSubmit}
                    invokeOnPressEnter
                />

                <AuthText>
                    Don’t have an account?
                    <ActionLink to={'/signup'}> Register here</ActionLink>
                </AuthText>
            </AuthMain>

            <SideBar />
        </AuthContainer>
    );
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
    signIn: (email: string, password: string) => dispatch(AuthActions.singIn(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
