import React from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import styled from "styled-components";

import burgerMenu from "assets/images/icons/burgerMenu.png";
import editIcon from "assets/images/icons/edit.png";
import deleteImage from "assets/images/icons/trash.png";
import saveImage from "assets/images/icons/agreeSign.png";
import { ExportedItem } from "./index";

type Props = {
  item: ExportedItem;
  onDelete: (id: string) => void;
  toogleEdit: (id: string) => void;
  onChangeItem: (id: string, text: string) => void;
};

const DragHandle = SortableHandle(() => (
  <BurgerImage src={burgerMenu} alt="burger" />
));

export const DnDItem = SortableElement(
  ({ item, onDelete, toogleEdit, onChangeItem }: Props) => {
    return (
      <Item>
        <ItemContext>
          <DragHandle />
          {item.isEditing ? (
            <InputEdit
              value={item.key}
              onChange={(e) => onChangeItem(item.id, e.target.value)}
            />
          ) : (
            <span onDoubleClick={() => toogleEdit(item.id)}>{item.key}</span>
          )}
        </ItemContext>
        <ItemButtons>
          {item.isEditing ? (
            <SaveImage
              src={saveImage}
              alt="edit"
              onClick={() => toogleEdit(item.id)}
            />
          ) : (
            <EditImage
              src={editIcon}
              alt="edit"
              onClick={() => toogleEdit(item.id)}
            />
          )}

          <DeleteImage
            src={deleteImage}
            alt="delete"
            onClick={() => onDelete(item.id)}
          />
        </ItemButtons>
      </Item>
    );
  }
);

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 563px;
  min-height: 35px;
  padding: 0 15px;
  list-style: none;
  background-color: #fff;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: "NunitoSans-Bold";
  color: #666975;
`;
const ItemContext = styled.div`
  display: flex;
  align-items: center;
`;
const ItemButtons = styled.div`
  display: flex;
  align-items: center;
`;
const BurgerImage = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 15px;
  cursor: move;
`;
const EditImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const SaveImage = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 30px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const DeleteImage = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
const InputEdit = styled.input`
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding-left: 5px;
  font-size: 18px;
  color: #666975;
  font-family: "NunitoSans-SemiBold";
`;
