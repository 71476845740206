import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AdminState } from "redux/admin/types";
import { AppState } from "redux/rotReducer";
import { AdminActions } from "redux/admin/actions";

import Component from "./Admin";
import { IGetAdminCompanyStat } from "net/api";

export type AdminProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {
  admin: AdminState;
}

interface LinkDispatchProps {
  getAdminInfo: () => any;
  getAdminCompanies: (params: IGetAdminCompanyStat) => any;
  loadAdminCompanies: (params: IGetAdminCompanyStat) => any;
}

const mapStateToProps = (state: AppState) => ({
  admin: state.admin,
});

const mapDispatchToProps = {
  getAdminInfo: AdminActions.getAdminInfo,
  getAdminCompanies: AdminActions.getAdminCompanies,
  loadAdminCompanies: AdminActions.loadAdminCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
