import { APIListResponse } from "../../net/api";

export interface IntegrationState {
  googlePathList: APIListResponse<GooglePathItem>;
}

export interface GooglePathItem {
  id: string;
  name: string;
}

export const SET_GOOGLE_PATH_LIST = "SET_GOOGLE_PATH_LIST";
export const UPDATE_GOOGLE_PATH_LIST = "UPDATE_GOOGLE_PATH_LIST";
export const CLEAR_GOOGLE_PATH_LIST = "CLEAR_GOOGLE_PATH_LIST";

export interface SetGooglePathListAction {
  type: typeof SET_GOOGLE_PATH_LIST;
  data: APIListResponse<GooglePathItem>;
}
export interface UpdateGooglePathListAction {
  type: typeof UPDATE_GOOGLE_PATH_LIST;
  data: APIListResponse<GooglePathItem>;
}
export interface ClearGooglePathListAction {
  type: typeof CLEAR_GOOGLE_PATH_LIST;
}

export type IntegrationActionTypes = SetGooglePathListAction | ClearGooglePathListAction | UpdateGooglePathListAction;
