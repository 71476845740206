import React, { FC, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { greyColorBright, greyColorDark } from 'styles/colors';

import { IEdtiImageBox } from '../Images';

import { SmallText } from '../../../../styles/text';

import checkIcon from 'assets/images/icons/check-mark.png';
import clearIcon from 'assets/images/icons/clear-button.png';
import { IReceiptPage } from 'redux/receipt/types';

interface Props {
    label: string;
    top: number;
    left: number;
    width: number;
    text: string;
    onCancelText: () => any;
    onAcceptText: (value: string) => any;
}

const FloatInput = React.forwardRef<any, Props>(
    ({ label, top, left, text, width, onCancelText, onAcceptText }, ref) => {
        const [value, setValue] = useState(text);

        useEffect(() => {
            setValue(text);
        }, [text]);

        return (
            <Wrapper
                style={{
                    top,
                    left,
                    width
                }}
            >
                <Container>
                    <TextArea value={value} onChange={(e) => setValue(e.target.value)} ref={ref} />
                    <KeyTitle>{label}</KeyTitle>
                    <Actions>
                        <IconWrapper background={false} onClick={onCancelText}>
                            <ActionIcon src={clearIcon} alt="clear" />
                        </IconWrapper>
                        <IconWrapper background={true} onClick={() => onAcceptText(value)}>
                            <ActionIcon src={checkIcon} alt="check" />
                        </IconWrapper>
                    </Actions>
                </Container>
            </Wrapper>
        );
    }
);

export default FloatInput;

const Wrapper = styled.div`
    width: 100%;
    min-width: 150px;
    position: absolute;
    z-index: 99;
`;

const Container = styled.div`
    background: ${greyColorBright};
    border-radius: 10px;
    padding: 3px 10px 8px;
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TextArea = styled.textarea`
    font-size: 18px;
    width: 100%;
    height: 26px;
    white-space: nowrap;
    background: transparent;
    border-style: none;
    color: black;
    text-align: center;
    resize: none;
    max-height: 200px;
    overflow: hidden;
    outline: none;
    font-family: 'NunitoSans-Bold';
`;

const KeyTitle = styled(SmallText)`
    color: ${greyColorDark};
    margin-top: 6px;
    text-align: center;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100px;
    justify-content: space-between;
`;

interface IconWrapperProps {
    background?: boolean;
}
const IconWrapper = styled.div`
    background-color: ${({ background }: IconWrapperProps) =>
        background ? '#fff' : 'transparent'};
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: 0.5;
    }
`;

const ActionIcon = styled.img`
    width: 20px;
    height: 20px;
    opacity: 0.5;
`;
