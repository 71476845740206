import { EnumReceiptStatus } from 'redux/receipt/types';
import styled from 'styled-components/macro';
import { greenColor, orangeColor, redColor } from 'styles/colors';

export const TableWrapper = styled.div`
    overflow-y: auto;
    min-width: 930px;
    background-color: #dce1ee;
    padding: 20px;
    border-radius: 10px;
`;

export const TableContainer = styled.table`
    width: 100%;
    border-spacing: 0 10px;
    text-align: left;

    thead th {
        font-size: 18px;
        color: #565682;
        font-family: 'NunitoSans-Bold';
        padding-bottom: 32px;
        white-space: nowrap;
    }

    tbody td {
        color: #9191af;
        font-size: 15px;
        font-family: 'NunitoSans-SemiBold';
        height: 83px;
        white-space: nowrap;

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
`;
export const Tr = styled.tr`
    border-radius: 10px;
    background: #ffffff;
    position: relative;
    cursor: pointer;

    &:hover {
        background-color: #eef2f6;
    }
`;
export const Td1Icons = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 55px;
`;

export const CheckBoxWrapper = styled.div`
    margin-right: 15px;
`;

interface ActionIconProps {
    width?: number;
    height?: number;
}

export const ActionIcon = styled.img`
    width: ${({ width }: ActionIconProps) => (width ? width : '20')}px;
    height: ${({ height }: ActionIconProps) => (height ? height : '20')}px;
    cursor: pointer;
    margin-right: 15px;
`;

interface StatusProps {
    status: EnumReceiptStatus;
}

export const Status = styled.p`
    color: ${({ status }: StatusProps) =>
        status === 'ready'
            ? greenColor
            : status === 'review' || status === 'error'
            ? redColor
            : orangeColor};
`;

export const CompanyName = styled.p`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
export const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DotsText = styled.p`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
`;
