import { RouteComponentProps } from "react-router-dom";

import Component from './IntegrationGoogleAuth';

export type IntegrationGoogleProps = LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps & {};

interface LinkStateProps {}
interface LinkDispatchProps {}

export default Component;
